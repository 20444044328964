 
    const mockedFields = [
        "First Name",
        "Last Name",
        "Email",
        "Phone Number",
        "Position",
        "Department",
        "Pod",
        "Recruiter",
        "Sourcer",
        "Source of Hire",
        "Hire Status",
        "Date of Hire",
        "Added on",
        "Notes",
        "US Citizen",
        


        // "Gender Identity",
        // "Hometown",
        // "Race/Ethnicity",
        // "Couple's Match",
        // "Medical School Name",
        // "AOA Status",
        // "Submission Date",
    ]

    export default mockedFields;