import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from '../ApplicationForm';
import { Formik, Form, Field, ErrorMessage,FieldArray } from 'formik';
import {allopathicMedicalSchools, osteopathicMedicalSchools} from './schoolNames';
import { getAllInstitutionsQuery } from '../graphql/queries';
import languages from './languages';
import Select from 'react-select';
import ClerkshipGrades from './clerkshipGrades';
import Research from './research';
import SignificantExp from './significantExp';
import WorkExp from './workExp';
import infoIcon from '../../../../assets/imgs/infoIcon.svg';
import { Tooltip } from 'reactstrap';
import { UserContext } from '../../../../context/userContext';
import Spinner from '../../../common/spinner';
import { useQuery } from '@apollo/client';
import packageInfo from '../../../../../package.json';

// const medicalSchools = [...allopathicMedicalSchools, ...osteopathicMedicalSchools]

function countWords(input) {
  console.log("input =>", input)
  if (input) {
    const words = input.trim().split(/\s+/);
    return words.length;
  }
  return 0;
}

const ApplicationFormStep2 = ({validateForm,isValid, isNested, applicationData, applicant, updateUserData, updatePhase, phases, setFieldTouched, viewOnly, loadingApplication}) => {
  const { values, setStep, step, shortQuestions, setFieldValue, isSubmitting } = useContext(FormContext);
  const [isEdit, setIsEdit] = useState(!viewOnly ? phases.secondPhase? false : true : false);
  const [inputValue, setInputValue] = useState('');
  const [addedCustomInput, setAddedCustomInput] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [finishSave, setFinishSave] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const { user} = useContext(UserContext);
  const [medicalSchools, setMedicalSchools] = useState([]);
  const [medicalSchoolsData, setMedicalSchoolsData] = useState([]);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [applicationCustomFields, setApplicationCustomFields] = useState([]);
  const [certificationsAnswers, setCertificationsAnswers] = useState(
    Array(values.positionId.certifications.length).fill('')
  );
  const [minimumQualificationsAnswers, setMinimumQualificationsAnswers] = useState(
    Array(values.positionId.minimumQualifications.length).fill('')
  );
  const [preferredQualificationsAnswers, setPreferredQualificationsAnswers] = useState(
    Array(values.positionId.preferredQualifications.length).fill('')
  );

  console.log("values in step2 =>", values)
  const { data: institutionsData, error: institutionsError, loading: institutionsLoading} = useQuery(getAllInstitutionsQuery, {
    variables: {},
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
      if(institutionsLoading) {
        return;
      } else if(institutionsError) {
        console.error(institutionsError)
        return;
      } else if(institutionsData && institutionsData.getAllInstitutions) {
        console.log("institutionsData =>", institutionsData)
        if(JSON.stringify(institutionsData.getAllInstitutions) !== JSON.stringify(medicalSchools)) {
          const data = institutionsData.getAllInstitutions
          setMedicalSchoolsData(data)
          var schools = []
          data.forEach(elem => schools.push(elem.name))
          console.log("schools =>", schools)
          setMedicalSchools(schools)
        }
      }
  }, [institutionsData, institutionsError, institutionsLoading])

  useEffect(() => {
    if(values.certsAnswers && values.certsAnswers.length > 0) {
      console.log("values.certsAnswers =>", values.certsAnswers)
      var certs =[]
      values.positionId.certifications.map(cert => {
        const filtered = values.certsAnswers.filter(cer => cer.name === cert)
        console.log("filtered =>", filtered)
        if(filtered.length > 0) {
          const element = filtered[0]
          if(element.value === 'yes') {
            certs.push('yes')
          } else {
            certs.push('no')
          }
        }
      })
      console.log("certs =>", certs)
      setCertificationsAnswers(certs)
    }
  }, [values.certsAnswers])
  
  useEffect(() => {
    if (values.qualifications && values.qualifications.length > 0) {
      console.log("values.qualifications =>", values.qualifications)
      var minQuali = []
      values.positionId.minimumQualifications.map(qualification => {
        const filtered = values.qualifications.filter(qual => qual.name === qualification)
        console.log("filtered =>", filtered)
        if(filtered.length > 0) {
          const element = filtered[0]
          if(element.value === 'yes') {
           minQuali.push('yes')
          } else {
            minQuali.push('no')
          }
        }
       })
       console.log("minQuali =>", minQuali)
       setMinimumQualificationsAnswers(minQuali)
      var prefQuali = []
      values.positionId.preferredQualifications.map(qualification => {
        const filtered = values.qualifications.filter(qual => qual.name === qualification)
        if(filtered.length > 0) {
          const element = filtered[0]
          if(element.value === 'yes') {
            prefQuali.push('yes')
           } else {
            prefQuali.push('no')
           }
        }
      })
      console.log("prefQuali =>", prefQuali)
      setPreferredQualificationsAnswers(prefQuali)
    }
  }, [values.qualification])



  useEffect(() => {
    let timer;
    if (finishSave) {
      timer = setTimeout(() => {
        setFinishSave(false);
      }, 5000); // 5 seconds
    }
  
    // Cleanup timer
    return () => clearTimeout(timer);
  }, [finishSave]);

  useEffect(() => {
    console.log("researchExperience changed:", values.researchExperience);
  }, [values.researchExperience]);

  useEffect(() => {
    if (applicationData) {
      setFieldValue('medicalSchoolName', user.institution)
      setFieldValue('medicalSchoolType', user.schoolType)
    }
  }, [user])
      


  console.log(values,'values in Second step')

  console.log(isValid, 'isValid in second step')


  console.log(user, 'user in second step')
  
  const handleQualificationChange = (index, value) => {
    const newAnswers = [...minimumQualificationsAnswers];
    newAnswers[index] = value;
    setMinimumQualificationsAnswers(newAnswers);
  };

  const handlePrefferedChange = (index, value) => {
    const newAnswers = [...preferredQualificationsAnswers];
    newAnswers[index] = value;
    setPreferredQualificationsAnswers(newAnswers);
  };

  const handleCertificationsChange = (index, value) => {
    const newAnswers = [...certificationsAnswers]
    newAnswers[index] = value
    setCertificationsAnswers(newAnswers)
  }


  const handleSave = async () => {
    if (saveError !== null) {
      setSaveError(null); 
    } 
    setIsSaveLoading(true)
    try {
      console.log("applicationCustomFields to save =>", applicationCustomFields)
      // generate qualifications array
      var qualifications = []
      values.positionId.minimumQualifications.forEach((qualification, index) => {
        qualifications.push({
          name: qualification,
          value: minimumQualificationsAnswers[index]
        })
      })
      values.positionId.preferredQualifications.forEach((qualification, index) => {
        qualifications.push({
          name: qualification,
          value: preferredQualificationsAnswers[index]
        })
      })
      console.log("qualifications =>", qualifications)
      var certsAnswers = []
      values.positionId.certifications.forEach((cert, index) => {
        certsAnswers.push({
          name: cert,
          value: certificationsAnswers[index]
        })
      })
      console.log("certsAnswers =>", certsAnswers)
      
      
      
      await updateUserData({...values, applicationCustomFields, qualifications, certsAnswers}, 2);
      setIsSaveLoading(false);
      setFinishSave(true); // Indicate success
    } catch (errorMessage) {
      setIsSaveLoading(false);
      console.error(errorMessage);
      setSaveError(errorMessage); // Indicate failure
    }
  }

  function handleCustomFieldChange(field, newValue) {
    setApplicationCustomFields(prevFields => {
      const fieldExists = prevFields.some(f => f.fieldName === field.fieldName);
      if (!fieldExists) {
        return [...prevFields, { fieldName: field.fieldName, fieldType: field.fieldType, fieldValue: newValue }];
      }
      return prevFields.map(f =>
        f.fieldName === field.fieldName
          ? { ...f, fieldValue: newValue }
          : f
      );
    });
  }
  

  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    console.log("file =>", file)
    if(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('masterApplicationId', applicationData.id)
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_document`, {
      method: 'POST',
      headers: {'App-Version': packageInfo.version},
      body: formData
    });
    const data = await response.json();
    console.log('Image uploaded successfully:', data.imageUrl);

    }
  }



  const checkAoaOrSigmaDisable = (values) => {
    if (values.medicalSchoolType === 'US Allopathic Medical School (MD)') {
      return !values.aoaStatus
    }
    if (values.medicalSchoolType === 'US Osteopathic Medical School (DO)') {
      return !values.sigmaPhi
    }

  }

  const checkDisabled = (values, isSubmitting) => {
    return isSubmitting || !values.fluentLanguages 
    || !values.coreClerkshipType || !values.awayRotations  || !values.shortA1 || 
    !values.shortA2 || !values.shortA3 || countWords(values.hobbiesAndInterests)>100
    // return isSubmitting || !values.fluentLanguages || !values.aoaStatus ||
    // !values.goldHumanism || !values.coreClerkshipType || !values.awayRotations  
  }

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleCreateOption = (newValue) => {
    const newOption = { label: newValue, value: newValue };
    // Add the new option to the medicalSchools list
    var tmp = [...medicalSchools]
    tmp.push(newValue)
    // medicalSchools.push(newValue);
    setMedicalSchools(tmp)
    // Set the field value in Formik
    setFieldValue("medicalSchoolName", newValue);
    // Set the medical school type
    // Clear the input value
    setInputValue('');
    setAddedCustomInput(true)
  };


  const customNoOptionsMessage = () => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onMouseDown={(e) => {
          e.preventDefault();
          handleCreateOption(inputValue);
        }}
      >
        Add "{inputValue}" manually
      </div>
    );
  };

  function isValidDate(dateString) {
    const shortDateRegExp = /^(0[1-9]|1[0-2])\/\d{4}$/;
    return shortDateRegExp.test(dateString);
  }




    return(
<>
{
  !viewOnly &&
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', width: '94%', flexDirection: 'column'}}>
  {finishSave && <div className="floating-btn" style={{ fontFamily: 'Montserrat', color: "#145388", marginBottom: '2vh', marginTop: '-4vh'}}>Application saved!</div>}
  {saveError && <div className="floating-btn" style={{ color:'red',fontFamily: 'Montserrat', marginBottom: '2vh', marginTop: '-4vh'}}>Not Saved. please try again.</div>}
  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '15%', flexDirection: 'row'}}>
    {!isSaveLoading&&<button className='white-button floating-btn'  style={{width: '10vw'}} disabled={!isEdit} onClick={ () => handleSave()}>Save</button>}
    { isSaveLoading && <div className='floating-btn' style={{ width: '20px'}}><Spinner/></div>}
  </div>
</div>
}

  
<div>

{/* <div className='application-form-field-group' style={{ marginBottom: '3vh'}}>
  <label htmlFor="medicalSchoolName" className='applicant-form-field-label'>Choose your medical school</label>
  <Field name="medicalSchoolName" disabled={!isEdit}>
  {({ field, form }) => (
    <Select
      name="medicalSchoolName"
      options={medicalSchools.map(medicalSchool => ({ label: medicalSchool, value: medicalSchool }))}
      isDisabled={!isEdit}
    //   className='application-form-standard-input one'
      onChange={option => {
        form.setFieldValue(field.name, option.value)
        setMedSchoolType(option.value)
        setFieldValue("medicalSchoolName", option.value)
        setAddedCustomInput(false)
      }}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      noOptionsMessage={customNoOptionsMessage}
      value={medicalSchools.find(option => option.value === values.medicalSchoolName) || { label: values.medicalSchoolName, value: values.medicalSchoolName }}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: '10'
          }),
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          zIndex:'1000',
          position:'relative'
          
        }),
        option: (provided) => ({
          ...provided,
          zIndex:'1000',
          position:'relative'
          
        }),
      }}
    />
  )}
</Field>
        <div className='application-form-error-message'> 
          <ErrorMessage name="medicalSchoolName" component="div" />
        </div>
</div>
{
    addedCustomInput === true &&
    <div className='application-form-field-group' style={{ marginBottom: '3vh'}}>
    <label className='applicant-form-field-label'>Choose your medical school type</label>
    <Field as="select" name="medicalSchoolType"className='application-form-standard-input one' onChange={(event) => setFieldValue("medicalSchoolType", event.target.value)}>
    <option value="">Choose medical school type...</option>
    <option value="US Allopathic Medical School (MD)">US Allopathic Medical School (MD)</option>
    <option value="US Osteopathic Medical School (DO)">US Osteopathic Medical School (DO)</option>
    <option value="International Medical School">International Medical School</option>
    </Field>
    <div className='application-form-error-message'> 
    <ErrorMessage name="medicalSchoolType" component="div" />
    </div>
    </div>
  } */}


<div className='application-form-field-group' style={{position:'relative', zIndex:'5', marginBottom: '3vh'}}>
<label className='applicant-form-field-label'>In addition to English, are you fluent in any other languages?</label>
<div role="group" aria-labelledby="radio-group" className='application-form-group-radio'>
  <label>
    <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw'}} type="radio" name="isLanguages" value="yes" disabled={!isEdit}/> Yes
  </label>
  <label>
  <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw'}} type="radio" name="isLanguages" value="no" disabled={!isEdit}/> No
  </label>
</div>
<div className='application-form-error-message'>
<ErrorMessage name="isLanguages" component="div" />
</div>
{values.isLanguages === 'yes' && (
<div style={{ marginTop: '2vh'}}>
<label className='applicant-form-field-label'>What other language(s) are you fluent in?</label>
<Field name="fluentLanguages" disabled={!isEdit}>
  {({ field, form }) => (
    <Select
    isDisabled={!isEdit}
      isMulti
      name="fluentLanguages"
      options={languages.map(language => ({ label: language, value: language }))}
    //   className='application-form-standard-input one'
      onChange={option => form.setFieldValue(field.name, option.map(item => item.value))}
      value={values.fluentLanguages.map(language => ({ label: language, value: language }))}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          height: '61px',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          padding: '11px 23px',  // Note: last padding value used, overriding previous
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          // zIndex:'5'
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: '5'
            }),
        // Add more parts to style as needed
      }}
    />
  )}
</Field>
<div className='application-form-error-message'> 
<ErrorMessage name="fluentLanguages" component="div" />
</div>
</div>
)}
</div>


<div className='application-form-field-group four' style={{ marginBottom: '3vh'}}>
  <label className='applicant-form-field-label'>Including undergraduate, please list all degrees obtained as well as the date and institution from which they were obtained:</label>
  <FieldArray name="higherEducation">
    {({ insert, remove, push }) => (
      <>
        {values.higherEducation.length > 0 && (
          <div style={{display:'flex', flexDirection:'column', gap:'1vh'}}>
            <div className="row" style={{ display: 'flex', gap: '1vw' }}>
              <div className="col">
                <label className="four-group-inner-header" style={{ width: '23vw' }}>Institution</label>
              </div>
              <div className="col">
                <label className='four-group-inner-header' style={{ width: '23vw' }}>Degree</label>
              </div>
              <div className="col">
                <label className='four-group-inner-header' style={{ width: '10vw' }}>Date</label>
              </div>
            </div>

            {values.higherEducation.map((degree, index) => (
                <div className="row" style={{ display: 'flex', gap: '1vw' }} key={index}>
                  <div className="col">
                  <Field
                      name={`higherEducation.${index}.institution`}
                      placeholder="Institution"
                      type="text"
                      className='application-form-standard-input'
                      style={{ width: '23vw' }}
                      disabled={!degree.isEditable} // This line disables the field if isEditable is false
                    />
                  <ErrorMessage
                    name={`higherEducation.${index}.institution`}
                    component="div"
                    className="field-error"
                    style={{ width: '23vw' }}
                  />
                </div>
                <div className="col">
                <Field
                      name={`higherEducation.${index}.degree`}
                      placeholder="Degree"
                      type="text"
                      className='application-form-standard-input'
                      style={{ width: '23vw' }}
                      disabled={!degree.isEditable} // This line disables the field if isEditable is false
                    />
                  <ErrorMessage
                    name={`higherEducation.${index}.degree`}
                    component="div"
                    className="field-error"
                  />
                </div>
                <div className="col">
                <Field
        name={`higherEducation.${index}.date`}
        type="Text"
        className='application-form-standard-input'
        style={{ width: '10vw' }}
        placeholder="MM/YYYY"
        title="Enter date in MM/YYYY format"
        disabled={!degree.isEditable} // This line disables the field if isEditable is false
      />
                  <ErrorMessage
                    name={`higherEducation.${index}.date`}
                    component="div"
                    className="field-error"
                    style={{ color: 'red' }}
                  />
                </div>
                <div className="col" style={{ marginTop: '0.1vh', display:'flex', alignItems:'center', gap:'1vw'}}>
                <button
        type="button"
        className="four-group-remove-button"
        onClick={() => {
          const newValues = [...values.higherEducation];
          newValues[index].isEditable = !newValues[index].isEditable;
          setFieldValue('higherEducation', newValues);
        }}
        disabled={!isEdit}
        style={!isEdit ? { opacity: '.5', cursor: 'not-allowed' } : {}}
      >
        {degree.isEditable ? 'Save' : 'Edit'}
      </button>
      <button
        type="button"
        className="four-group-remove-button"
        onClick={() => remove(index)}
        disabled={!isEdit}
        style={!isEdit ? { opacity: '.5', cursor: 'not-allowed' } : {}}
      >
        Remove
      </button>
    </div>
              </div>
            ))}
          </div>
        )}
<button
  type="button"
  className="four-group-add-button"
  onClick={() => push({ institution: '', degree: '', date: '', isEditable: true })}
  disabled={!isEdit}
  style={!isEdit ? { opacity: '.5', cursor: 'not-allowed' } : {}}
>
  Add Degree
</button>
      </>
    )}
  </FieldArray>
</div>




<div className='application-form-field-group four' style={{ marginBottom: '3vh'}}>
  <label className='applicant-form-field-label'>Please list any other courses completed</label>
  <FieldArray name="nonCoreClerkshipGrades">
  {({ insert, remove, push }) => (
    <>
      {values.nonCoreClerkshipGrades.length > 0 && (
        <div>
          <div className="row" style={{ display: 'flex', gap:'1vw'}}>
            <label className="four-group-inner-header" htmlFor={`nonCoreClerkshipGrades.course`} style={{width:'23vw'}}>Name of Additional Course</label>
            <label className='four-group-inner-header' htmlFor={`nonCoreClerkshipGrades.grade`} style={{width:'23vw'}}>Grade Received</label>
          </div>
          {values.nonCoreClerkshipGrades.map((degree, index) => (
            <div className="row" style={{ display: 'flex', gap: '1vw', marginBottom: '1vh' }} key={index}>
              <div className="col">
                <Field
                  name={`nonCoreClerkshipGrades.${index}.course`}
                  placeholder="Course Name"
                  type="text"
                  className='application-form-standard-input'
                  style={{ width: '23vw' }}
                  disabled={!degree.isEditable}
                />
                <ErrorMessage
                  name={`nonCoreClerkshipGrades.${index}.course`}
                  component="div"
                  className="field-error"
                  style={{ width: '23vw' }}
                />
              </div>
              <div className="col">
                <Field
                  name={`nonCoreClerkshipGrades.${index}.grade`}
                  placeholder="Grade"
                  type="text"
                  className='application-form-standard-input'
                  style={{ width: '23vw' }}
                  disabled={!degree.isEditable}
                />
                <ErrorMessage
                  name={`nonCoreClerkshipGrades.${index}.grade`}
                  component="div"
                  className="field-error"
                />
              </div>
              <div className="col" style={{ alignContent: 'center', display:'flex', gap:'1vw'}}>
              <button
                  type="button"
                  className="four-group-remove-button"
                  onClick={() => {
                    const newValues = [...values.nonCoreClerkshipGrades];
                    newValues[index].isEditable = !newValues[index].isEditable;
                    setFieldValue('nonCoreClerkshipGrades', newValues);
                  }}
                  disabled={!isEdit}
                  style={!isEdit ? { opacity: '.5', cursor: 'not-allowed' } : {}}
                >
                  {degree.isEditable ? 'Save' : 'Edit'}
                </button>
                <button
                  type="button"
                  className="four-group-remove-button"
                  onClick={() => remove(index)}
                  disabled={!isEdit}
                  style={!isEdit ? { opacity: '.5', cursor: 'not-allowed' } : {}}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      <button
        type="button"
        className="four-group-add-button"
        onClick={() => push({ course: '', grade: '', isEditable: true })}
        disabled={!isEdit}
        style={!isEdit ? { opacity: '.5', cursor: 'not-allowed' } : {}}
      >
        Add Additional Course
      </button>
    </>
  )}
</FieldArray>
</div>


<div style={{ fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 18,
  color: '#474747',
  lineHeight: '27px',
  marginBottom: 12,
  textAlign:'justify'}}>
    Please add any relevant work experience.
    </div>
<WorkExp isEdit={isEdit}/>

<div className='application-form-field-group' style={{marginBottom:'3vh'}}>
  <label className='applicant-form-field-label'>Minimum Qualifications</label>
  {values.positionId.minimumQualifications.map((qualification, index) => (
    <div key={index} style={{marginBottom: '2vh'}}>
      <label className='applicant-form-field-label' style={{fontWeight: 'normal'}}>{qualification}</label>
      <div role="group" aria-labelledby="radio-group" className='application-form-group-radio'>
        <label>
          <input
            type="radio"
            name={`qualification-${index}`}
            value="yes"
            checked={minimumQualificationsAnswers[index] === 'yes'}
            onChange={() => handleQualificationChange(index, 'yes')}
            style={{display:'unset', marginRight:'.4vw'}}
            disabled={!isEdit}
          /> Yes
        </label>
        <label>
          <input
            type="radio"
            name={`qualification-${index}`}
            value="no"
            checked={minimumQualificationsAnswers[index] === 'no'}
            onChange={() => handleQualificationChange(index, 'no')}
            style={{display:'unset', marginRight:'.4vw'}}
            disabled={!isEdit}
          /> No
        </label>
      </div>
    </div>
  ))}
</div>

<div className='application-form-field-group' style={{marginBottom:'3vh'}}>
  <label className='applicant-form-field-label'>Preferred Qualifications</label>
  {values.positionId.preferredQualifications.map((qualification, index) => (
    <div key={index} style={{marginBottom: '2vh'}}>
      <label className='applicant-form-field-label' style={{fontWeight: 'normal'}}>{qualification}</label>
      <div role="group" aria-labelledby="radio-group" className='application-form-group-radio'>
        <label>
          <input
            type="radio"
            name={`pref-qualification-${index}`}
            value="yes"
            checked={preferredQualificationsAnswers[index] === 'yes'}
            onChange={() => handlePrefferedChange(index, 'yes')}
            style={{display:'unset', marginRight:'.4vw'}}
            disabled={!isEdit}
          /> Yes
        </label>
        <label>
          <input
            type="radio"
            name={`pref-qualification-${index}`}
            value="no"
            checked={preferredQualificationsAnswers[index] === 'no'}
            onChange={() => handlePrefferedChange(index, 'no')}
            style={{display:'unset', marginRight:'.4vw'}}
            disabled={!isEdit}
          /> No
        </label>
      </div>
    </div>
  ))}
</div>
<div className='application-form-field-group' style={{marginBottom:'3vh'}}>
  <label className='applicant-form-field-label'>Certifications</label>
  {values.positionId.certifications.map((cert, index) => (
    <div key={index} style={{marginBottom: '2vh'}}>
      <label className='applicant-form-field-label' style={{fontWeight: 'normal'}}>{cert}</label>
      <div role="group" aria-labelledby="radio-group" className='application-form-group-radio'>
        <label>
          <input
            type="radio"
            name={`cert-${index}`}
            value="yes"
            checked={certificationsAnswers[index] === 'yes'}
            onChange={() => handleCertificationsChange(index, 'yes')}
            style={{display:'unset', marginRight:'.4vw'}}
            disabled={!isEdit}
          /> Yes
        </label>
        <label>
          <input
            type="radio"
            name={`cert-${index}`}
            value="no"
            checked={certificationsAnswers[index] === 'no'}
            onChange={() => handleCertificationsChange(index, 'no')}
            style={{display:'unset', marginRight:'.4vw'}}
            disabled={!isEdit}
          /> No
        </label>
      </div>
    </div>
  ))}
</div>





{/* custom fields */}
<div style={{ fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 18,
  color: '#474747',
  lineHeight: '27px',
  marginBottom: 12,
  textAlign:'justify'}}>
    Custom Fields
    {
      values.positionId.customFields.map(field => (
        field.fieldType === "text" &&
        <div>
          <label>{field.fieldName}</label>
          <Field
            disabled={!isEdit}
            name={field.fieldName}
            placeholder={field.fieldName}
            type="text"
            className='application-form-standard-input'
            style={{width:'100%'}}
            onChange={(e) => handleCustomFieldChange(field, e.target.value)}
            />
        </div>
      ))
    }
    </div>





{/* 
<div style={{
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    color: '#145388',
    lineHeight: '51px',
    borderBottom: '1px solid #145388',
    marginBottom: '30px'
}}>
        Short Answer Questions
    </div>

<div className='application-form-field-group' style={{ marginBottom: '2vh'}}>
  <label htmlFor="shortQ1" className='applicant-form-field-label'>{shortQuestions[0]}</label>
  <Field as="textarea" 
   onChange={(e) => {
    const words = countWords(e.target.value)
    console.log("words =>", words)
    if (words <= 100) {
     setFieldValue("shortA1", e.target.value);
    }
  }}
  name={`shortA1`} disabled={!isEdit} className='application-form-standard-input textBox' style={{height:'12vh',width:'100%', marginBottom:'.5vh'}}/>
  <div>Word count: {countWords(values.shortA1)}/100</div>
  <div className='application-form-error-message'> 
  <ErrorMessage name="shortA1" component="div" />
  </div>
  </div>
<div className='application-form-field-group' style={{ marginBottom: '2vh'}}>
  <label htmlFor="shortQ2" className='applicant-form-field-label'>{shortQuestions[1]}</label>
  <Field as="textarea" 
  onChange={(e) => {
    const words = countWords(e.target.value)
    console.log("words =>", words)
    if (words <= 100) {
     setFieldValue("shortA2", e.target.value);
    }
  }}
  name={`shortA2`}  disabled={!isEdit} className='application-form-standard-input textBox' style={{height:'12vh',width:'100%', marginBottom:'.5vh'}}/>
  <div>Word count: {countWords(values.shortA2)}/100</div>
  <div className='application-form-error-message'> 
  <ErrorMessage name="shortA2" component="div" />
  </div>
  </div>
<div className='application-form-field-group' style={{ marginBottom: '2vh'}}>
  <label htmlFor="shortQ3" disabled={!isEdit} className='applicant-form-field-label'>{shortQuestions[2]}</label>
  <Field 
    onBlur={(e) => {
      setFieldTouched(e.target.name, true, true);
    }}
  as="textarea" 
  onChange={(e) => {
    const words = countWords(e.target.value)
    console.log("words =>", words)
    if (words <= 100) {
     setFieldValue("shortA3", e.target.value);
    }
  }}
  name={`shortA3`}  disabled={!isEdit}  className='application-form-standard-input textBox' style={{height:'12vh',width:'100%', marginBottom:'.5vh'}}/>
  <div>Word count: {countWords(values.shortA3)}/100</div>
  <div className='application-form-error-message'> 
  <ErrorMessage name="shortA3" component="div" />
  </div>
  </div> */}


{/* 
  <div style={{
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    color: '#145388',
    lineHeight: '51px',
    borderBottom: '1px solid #145388',
    marginBottom: '30px'
}}>
        Hobbies and Interests
    </div>
    <div className='application-form-field-group' style={{ marginBottom: '3vh'}}>
  <label htmlFor="hobbiesAndInterests" className='applicant-form-field-label'>In 100 words or less, please describe any hobbies or interests</label>
  <Field as="textarea" name={`hobbiesAndInterests`} disabled={!isEdit}  className='application-form-standard-input textBox' style={{height:'12vh',width:'100%', marginBottom:'0'}}/>
  <div style={countWords(values.hobbiesAndInterests)>100?{color:'red'}:{}}>Word count: {countWords(values.hobbiesAndInterests)}/100</div>
  <div className='application-form-error-message'> 
  <ErrorMessage name="hobbiesAndInterests" component="div" />
  </div>
  </div> */}


  {!isNested && isEdit &&(
  <button 
  disabled={ isSubmitting}

  className="mark-as-complete" type="button" onClick={(event) => {

    event.preventDefault()
    handleSave()
    updatePhase("secondPhase")
    setStep(prevStep => prevStep + 1);

  }}>
    Next
  </button>
)}

{!isNested && !isEdit && (
  <button className="white-button"
  style={{width:'100%'}}
  onClick={() => {
 setIsEdit(true)
 updatePhase("secondPhase")
    }}>
    Reopen Task
  </button>
)}
</div>
</>
)
}

export default ApplicationFormStep2;