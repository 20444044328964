import React, { useState, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import { useMutation } from '@apollo/client';
import { CREATE_WORKFLOW } from './graphql/mutations';
import { ProgramIdContext } from "../../progIdContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const InviteUserModal = ({ isOpen, onClose, onAddUser }) => {
  const programId = useContext(ProgramIdContext);
  const [createWorkflow, { data, loading, error }] = useMutation(CREATE_WORKFLOW);
    const [workflowData, setWorkflowData] = useState({
        name: '',
        description: '',
        programId: programId,
        // steps: [],
        // triggers: [],
        // associatedPositions: '',
        // associatedDepartments: [],
        // applicableTo: [],
        // visibility: '',
        // allowedRoles: [],
        // allowedUsers: [],
        // status: '',
        // version: 0,
        // effectiveDate: '',
        // expiryDate: '',
        // totalInstancesRun: 0,
        // averageCompletionTime: 0.0,
        // successRate: 0.0,
        // lastRunAt: '',
        // automationEnabled: false,
        // externalIntegrations: [],
        // notificationSettings: {},
        // customFields: [],
        // notes: [],
        // errorHandling: {},
        // complianceRequirements: [],
        // dataRetentionPolicy: '',
        // reports: [],
        // tags: [],
        // schedule: {},
        // advancedSettings: {}
    });

    

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setWorkflowData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
      try {
        const result = await createWorkflow({ variables: { input: workflowData } });
        toast.success('Workflow created successfully');

        onAddUser()
        onClose();
    } catch (err) {
        toast.error('Failed to create workflow', err);
    }
        // Handle form submission logic here
        console.log('Workflow Data:', workflowData);
        onAddUser();
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="add-field-modal">
                <ToastContainer
                    theme="colored"
                />
                <div className='add-field-modal-header'>
                    <h2>CREATE WORKFLOW</h2>
                    <img src={logo} alt='Logo' className='logo' style={{ width: '4vw' }} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group" style={{maxHeight:'66vh', overflowY:'scroll'}}>
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={workflowData.name}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Description</label>
                        <textarea
                            name="description"
                            value={workflowData.description}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        {/* <label>Associated Positions</label>
                        <input
                            type="text"
                            name="associatedPositions"
                            value={workflowData.associatedPositions}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Associated Departments</label>
                        <input
                            type="text"
                            name="associatedDepartments"
                            value={workflowData.associatedDepartments}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Applicable To</label>
                        <input
                            type="text"
                            name="applicableTo"
                            value={workflowData.applicableTo}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Visibility</label>
                        <input
                            type="text"
                            name="visibility"
                            value={workflowData.visibility}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Allowed Roles</label>
                        <input
                            type="text"
                            name="allowedRoles"
                            value={workflowData.allowedRoles}
                            onChange={handleChange}
                        />
                        <label>Allowed Users</label>
                        <input
                            type="text"
                            name="allowedUsers"
                            value={workflowData.allowedUsers}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Status</label>
                        <input
                            type="text"
                            name="status"
                            value={workflowData.status}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Version</label>
                        <input
                            type="number"
                            name="version"
                            value={workflowData.version}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Effective Date</label>
                        <input
                            type="date"
                            name="effectiveDate"
                            value={workflowData.effectiveDate}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Expiry Date</label>
                        <input
                            type="date"
                            name="expiryDate"
                            value={workflowData.expiryDate}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Total Instances Run</label>
                        <input
                            type="number"
                            name="totalInstancesRun"
                            value={workflowData.totalInstancesRun}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Average Completion Time</label>
                        <input
                            type="number"
                            step="0.01"
                            name="averageCompletionTime"
                            value={workflowData.averageCompletionTime}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Success Rate</label>
                        <input
                            type="number"
                            step="0.01"
                            name="successRate"
                            value={workflowData.successRate}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Last Run At</label>
                        <input
                            type="datetime-local"
                            name="lastRunAt"
                            value={workflowData.lastRunAt}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Automation Enabled</label>
                        <input
                            type="checkbox"
                            name="automationEnabled"
                            checked={workflowData.automationEnabled}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Data Retention Policy</label>
                        <input
                            type="text"
                            name="dataRetentionPolicy"
                            value={workflowData.dataRetentionPolicy}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        />
                        <label>Tags</label>
                        <input
                            type="text"
                            name="tags"
                            value={workflowData.tags}
                            onChange={handleChange}
                            className="border rounded p-2 w-full mb-4"
                        /> */}
                    </div>
                    <div className='button-container' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '2vw' }}>
                        <button type="submit" className='gradient-button' style={{ fontWeight: '500' }}>Create Workflow</button>
                        <button type="button" className='white-button' onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InviteUserModal;