import React, { useEffect, useRef } from 'react';

const JitsiMeeting = ({ roomName, jwt }) => {

  JitsiMeeting.defaultProps = {
    roomName: 'defaultRoomName',
  };
  
  const containerRef = useRef(null);
  useEffect(() => {
    // Load Jitsi script dynamically if not already loaded
    if (!window.JitsiMeetExternalAPI) {
      const script = document.createElement('script');
      script.src =
        'https://8x8.vc/vpaas-magic-cookie-3942b9f6d544478db1607652005f3d13/external_api.js';
      script.async = true;
      script.onload = initializeJitsi;
      document.body.appendChild(script);
    } else {
      initializeJitsi();
    }

    function initializeJitsi() {
      if (containerRef.current && window.JitsiMeetExternalAPI) {
        const api = new window.JitsiMeetExternalAPI('8x8.vc', {
          // roomName:`vpaas-magic-cookie-3942b9f6d544478db1607652005f3d13/${roomName}`,
          roomName,
          parentNode: containerRef.current,
          jwt,
        });

        // Optional: Add event listeners or configure API further
        api.addEventListener('videoConferenceJoined', () => {
          console.log('User joined the conference');
        });

        api.addEventListener('videoConferenceLeft', () => {
          console.log('User left the conference');
        });

        return () => api.dispose(); // Clean up when component unmounts
      }
    }

    return () => {
      if (window.JitsiMeetExternalAPI) {
        window.JitsiMeetExternalAPI = null; // Clean up global variable
      }
    };
  }, [roomName, jwt]);

  return <div ref={containerRef} style={{ height: '100%', width: '100%' }} />;
};

export default JitsiMeeting;
