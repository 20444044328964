import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { getApplicantByIdQuery, getMasterApplicationByUserIdAndSpecialtyIdQuery} from '../graphql/queries.jsx';
import {GET_CAREER_FAIR_BY_ID} from './graphql/queries.jsx'
import {CREATE_LEAD} from './graphql/mutations.jsx'
import { UserContext } from '../../../../context/userContext.js';
import { useNavigate } from 'react-router-dom';
import Spinner from "../../../common/spinner.jsx";
import logo from "../../../../assets/imgs/logo.svg";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


function removeTypename(obj) {
    if (obj === null || obj === undefined) {
        return obj;
    }
  
    if (Array.isArray(obj)) {
        return obj.map(removeTypename);
    }
  
    if (typeof obj === 'object') {
        const newObj = {};
        for (let property in obj) {
            if (property !== '__typename') {
                newObj[property] = removeTypename(obj[property]);
            }
        }
        return newObj;
    }
  
    return obj;
  }


const LeadRoute = () => {
    const { token } = useParams();
    const [careerFair, setCareerFair] = useState({});
    const [successfulSubmission, setSuccessfulSubmission] = useState(false);
    const [error, setError] = useState('');

    const [createLead] = useMutation(CREATE_LEAD);
    
    



    const { data: careerFairData, loading: careerFairLoading, error: careerFairError } = useQuery(GET_CAREER_FAIR_BY_ID, {
      variables: { link: token },
      fetchPolicy: 'network-only',
      onError: (error) => {
          console.error("Error fetching career fair data:", error);
      }
  });

useEffect(() => {
  if (!careerFairLoading && !careerFairError && careerFairData) {
      console.log("careerFairData =>", careerFairData);
      setCareerFair(careerFairData.getCareerFairByLink);
  }
}, [careerFairData, careerFairLoading, careerFairError]);

let initialValues = {
  programId: careerFair.programId,
  CareerFairId: '', // ID To handleeeeeeeee
  sourcer: '', // ID To handle
  recruiter: '', // ID To handle
  notes: '',
  usCitizen: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  genderIdentity: '',
  homeAddress: '',
}; 

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone Number is required'),
});


const handleSubmit = (values, { setSubmitting }) => {


    
  createLead({
      variables: {
          input: {
              programId: careerFair.programId,
              CareerFairId: careerFair.id,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phone: values.phone,
          }
      }
  })
  .then((res) => {
      console.log("res=>", res);
      setSuccessfulSubmission(true);
  }).catch((err) => {
      setError(err.message);
  }
  );
};






    return (
        <div>

               <div
               className="faculty-home-container"
               style={{ width: "100%", marginLeft: "auto", marginRight: "auto", overflowY: "hidden" }}
             >
               <ellipse className="ellipseDesignTop1" />
               <ellipse className="ellipseDesignTop2" />
               <ellipse className="ellipseDesignTop3" />
               <ellipse className="ellipseDesignTop4" />
               <div className="faculty-home-header" style={{display:'flex', justifyContent:'center'}}>

                 <h2 className="faculty-home-header-title">
                   <img src={logo} alt="logo" style={{ width:'7vw' }} />
                 </h2>

               </div>
               <div className="faculty-home-body">
                {careerFairLoading && <Spinner />}
                {careerFairError && <p>Error: {careerFairError.message}</p>}
                {successfulSubmission && <p>Thank you for submitting your information. You will receive an email and text message with a link to the fair.</p>}
                
                {careerFair && !successfulSubmission && (
                  <div>
                    <div >
                  <h2>{careerFair.name}</h2>
                  <h3>{careerFair.description}</h3>
                  {careerFair.isVirtual &&(
                  <p>To access the <strong>{careerFair.name}</strong> fair, please complete the following form. A link will be sent to you via email and text message.  
                    You will also receive a reminder about the fair 24 hours before it begins.</p>
                    )}
                    </div>
                    <separator className="faculty-home-header-seperator" />
                  {/* form body */}
                  <div style={{display:'flex', justifyContent:'center'}}>
                    </div>
                    <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <div className='form-modal-body' style={{marginBottom:'3vh', overflowY:'auto', height:'45vh'}}>

              {/* First Name */}
              <div style={{display:'flex', gap:'1vw'}}>
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="firstName" className="applicant-form-field-label">
                  First Name <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="firstName"
                  placeholder="First Name"
                  className="application-form-standard-input"
                  // disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="firstName" component="div" />
                </div>
              </div>

              {/* Last Name */}
              <div className="application-form-field-group">
                <label htmlFor="lastName" className="applicant-form-field-label">
                  Last Name <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="lastName"
                  placeholder="Last Name"
                  className="application-form-standard-input"
                  // disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="lastName" component="div" />
                </div>
              </div>
              </div>


              <div style={{display:'flex', gap:'1vw'}}>
              {/* Email */}
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="email" className="applicant-form-field-label">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="application-form-standard-input"
                  // disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="email" component="div" />
                </div>
              </div>

              {/* Phone Number */}
              <div className="application-form-field-group">
                <label htmlFor="phone" className="applicant-form-field-label">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="phone"
                  placeholder="Phone Number"
                  className="application-form-standard-input"
                  // disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="phone" component="div" />
                </div>
              </div>

              </div>

          </div>
          {error && <p>Error: {error}</p>}
            
            <div className='buttons-row' style={{marginBottom:'3vh', display:'flex', gap:'2vw', justifyContent:'center'}}>
              {/* Submit Button */}
              <button type="submit" className="green-button">
                Submit
              </button>
              </div>
            </Form>
          )}
        </Formik>


                  </div>
                )}
        
               </div>
             </div>


        </div>
    )

}

export default LeadRoute;