// src/components/applicant/directSchedule/SuccessMessage.jsx
import React from 'react';

const SuccessMessage = ({ message }) => {
    return (
        <div className="p-4 bg-green-200 text-green-800 rounded-lg shadow-lg">
            <p>{message}</p>
        </div>
    );
};

export default SuccessMessage;
