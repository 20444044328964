import { gql } from '@apollo/client';

export const createApplicationsFromPendingProgramsMutation = gql`
    mutation createApplicationsFromPendingPrograms($id: ID, $pendingPrograms: String, $scoreId: ID, $lorIdsToAdd: [ID]) {
        createApplicationsFromPendingPrograms(id: $id, pendingPrograms: $pendingPrograms, scoreId:$scoreId, lorIdsToAdd:$lorIdsToAdd) {
            id
        }
    }
`


export const updateUserMutation = gql`
    mutation updateUser($id: ID!, $input: UpdateUserInput! ){
        updateUser(id: $id, input: $input) {
            id
            email
            firstName
            lastName
            phone
            profilePhotoUrl
            institution
            institutionId
            graduationYearUpdated
            graduationYear
            applicationInformation {
                previousLastName
                prefferedName
                linkedinLink
                pronouns
                usmleId
                nbomeId
                aoaId
                permanentAddress
                licensureRevoked
                licensureRevokedDetails
                militaryObligation
                militaryObligationDetails
                otherServiceObligations
                medicalTrainingInterrupted
                medicalTrainingInterruptedDetails
                sigmaPhi
                aamc
                nrmpId
                gender
                otherRaceEthnicity
                otherWorkAuthorization
                partnerName
                dateOfBirth
                homeTown
                ethnicity
                homeAddress
                workAuthorization
                misdemeanorOrFelony
                misdemeanorOrFelonyDetails
                coupleMatch
                malpractice
                partnerFeild
                medicalSchoolType
                medicalSchoolName
                isLanguages
                fluentLanguages
                higherEducation {
                    institution
                    degree
                    date
                }
                aoaStatus
                goldHumanism
                awardsAndHonors
                coreClerkshipType
                otherCkMethod
                coreClerkshipGrades {
                    clerkshipEm
                    clerkshipFm
                    im
                    neurology
                    obgyn
                    pediatrics
                    psychiatry
                    surgery
                }

                awayRotations {
                    institution
                    degree
                    dateCompleted
                }
                nonCoreClerkshipGrades {
                    course
                    grade
                }
                firstAuthorPublications
                peerReviewedPublications
                posterPresentations
                podiumPresentations
              
            }
        }
    }
`

export const updateUserPasswordMutation = gql`
    mutation updateUserPassword($id: ID!, $previousPass: String!, $newPass: String!) {
        updateUserPassword(id: $id, previousPass: $previousPass, newPass: $newPass) {
            id
        }
    }
`

export const createMasterApplicationMutation = gql`
    mutation createMasterApplication($input: CreateMasterApplicationInput!){
        createMasterApplication(input: $input) {
            id
            userId
            specialtyId
            paymentStatus
            releaseDate
            name
            createdAt
            updatedAt 
            firstPhase
            secondPhase
            thirdPhase
            fourthPhase
            fifthPhase
            sixthPhase
            seventhPhase
            carryingOutResponsibilities
            carryingOutResponsibilitiesElaboration
            previouslyAppliedToSpecialty
            applicationYear
            personalStatement
            researchExperience {
                category
                title
                authors
                journal
                datePublished
                pubMedId
                isFirstAuthor
                significantResearchExperience
                meeting
                meetingDate
                order
            }
            additionalResearchUrl
            meaningfulExperiences {
                category
                title
                startDate
                endDate
                description
            }
            workExperiences {
                employer
                jobTitle
                startDate
                endDate
                mentorName
                mentorEmail
                description
            }
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            approvedCodeOfEthics
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            references{
            name
            company
            role
            phone
            email
            }
            cvUrl
            additionalDocsUrl
            status
        }
    }
`

export const updateMasterApplicationMutation = gql`
    mutation updateMasterApplication($id: ID!, $input: UpdateMasterApplicationInput!) {
        updateMasterApplication(id: $id, input: $input) {
            id
            userId
            qualifications {
                name
                value 
            }
            certsAnswers {
                name
                value
            }
            positionId{
            id
            jobTitle
            department
            summary
            programId
            minimumQualifications
            preferredQualifications
            certifications
                
            customFields {
                fieldName
                fieldType
            }
            }
            specialtyId
            showSignalMessage
            paymentStatus
            releaseDate
            name
            createdAt
            updatedAt 
            firstPhase
            secondPhase
            thirdPhase
            fourthPhase
            fifthPhase
            sixthPhase
            seventhPhase
            carryingOutResponsibilities
            carryingOutResponsibilitiesElaboration
            previouslyAppliedToSpecialty
            applicationYear
            personalStatement
            researchExperience {
                category
                title
                authors
                journal
                datePublished
                pubMedId
                isFirstAuthor
                significantResearchExperience
                meeting
                meetingDate
                order
            }
            additionalResearchUrl
            meaningfulExperiences {
                category
                title
                startDate
                endDate
                description
            }
            workExperiences {
                employer
                jobTitle
                startDate
                endDate
                mentorName
                mentorEmail
                description
            }
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            approvedCodeOfEthics
            shortAnswerQuestions {
                question
                answer
            }
            hobbiesAndInterests
            references{
            name
            company
            role
            phone
            email
            }
            cvUrl
            additionalDocsUrl
            status
            applicationCustomFields {
                fieldName
                fieldType
                fieldValue
            }
        }
    }
`

export const updatePhaseMutation = gql`
    mutation updatePhase($id: ID!, $phase: String) {
        updatePhase(id: $id, phase: $phase) {
            id
            firstPhase
            secondPhase
            thirdPhase
            fourthPhase
            fifthPhase
            sixthPhase
            seventhPhase
        }
    }
`

export const updateScoreMutation = gql`
    mutation updateScore($id: ID!, $input: ScoreInput!) {
        updateScore(id: $id, input: $input) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
    }

`

export const createScoreMutation = gql`
    mutation createScore($input: ScoreInput!) {
        createScore(input: $input) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
    }
`


export const createLorMutation = gql`
    mutation createLor($input: CreateLorInput!){
        createLor(input: $input) {
            id
            applicantId
            recommenderId
            stage
            docUrl
            requestedAt
            submittedAt
        }
    }
`

export const createApplicationMutation = gql`
    mutation createApplication($input: CreateApplicationInput!){
        createApplication(input: $input) {
            id
        }
    }
`

export const createMultipleApplicationsMutation = gql`
    mutation createMultipleApplications($applications: String) {
        createMultipleApplications(applications:$applications)
    }
`

export const deleteLorMutation = gql`
    mutation deleteLor($id: ID!) {
        deleteLor(id: $id) {
            id
        }
    }

`

export const updateLorApplicationsMutation = gql`
    mutation updateLorApplications($id: ID!, $applicationId: [ID]!, $allLorsIds: [ID]!) {
        updateLorApplications(id: $id, applicationId: $applicationId, allLorsIds: $allLorsIds) {
            id
        }
    }
`

export const updatePendingProgramsInMasterApplicationMutation = gql`
    mutation updatePendingProgramsInMasterApplication($id: ID, $pendingPrograms: String) {
        updatePendingProgramsInMasterApplication(id: $id, pendingPrograms: $pendingPrograms) {
            id
        }
    }
`

export const updateShowSignalMessageMutation = gql`
    mutation updateShowSignalMessage($id: ID) {
        updateShowSignalMessage(id: $id) {
            id
        }
    }
`

export const updateApplicantGraduationYearMutation = gql`
    mutation updateApplicantGraduationYear($applicantId: ID, $graduationYear: Int) {
        updateApplicantGraduationYear(applicantId: $applicantId, graduationYear: $graduationYear){
            id
        }
    }
`