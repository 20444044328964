import React, { useState,useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles/calendar.css'; 
import { useInterviewSchedule } from './interviewScheduleContext';
import { ProgramIdContext } from '../../progIdContext';
import {UserContext} from '../../../../context/userContext';
import {getProgramPopulatedDatesQuery, getSpecificProgramPopulatedDateQuery, getPopulatedDatesForMonth} from "./graphql/queries";
import {useMutation, useQuery, useApolloClient} from '@apollo/client';
import ProgressBar from './dayCustomize/ProgressBar';
import {updateProgramPopulatedDates} from "./graphql/mutations";
import thumbsUp from '../../../../assets/imgs/thumbsUp.svg';
import thumbsDown from '../../../../assets/imgs/thumbsDown.svg';
import SlotForm from './dayCustomize/slotsForm';
import inPersonInterviewIcon from '../../../../assets/imgs/in_person_interview_icon.svg';
import virtualInterviewIcon from '../../../../assets/imgs/virtual_interview_icon.svg';
import interviewDayDoneIcon from '../../../../assets/imgs/inteviewDayDone.svg';
import Spinner from '../../../common/spinner';

const InterviewCalendar = () => {
  const programId = useContext(ProgramIdContext);
  const user = useContext(UserContext);
  const [breakBool, setBreakBool] = useState(false);
  const [slotsBool, setSlotsBool] = useState(false);
  const { selectedDates, setSelectedDates,
     page,setPage,
     step,setStep,
     startTime, setStartTime,
     interviewLength, setInterviewLength,
     breakTime, setBreakTime,
     slots, setSlots,
     interviewersPerInterviewee, setInterviewersPerInterviewee,
     numberOfSlots, setNumberOfSlots,
     dayType, setDayType,
     isLocked, setIsLocked,
     numOfInterviewers, setNumOfInterviewers,
     resetValues,
     } = useInterviewSchedule();
  const [populatedDatesOverview, setPopulatedDatesOverview] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  const client = useApolloClient();
  const [updateProgramPopulatedDatesMutation] = useMutation(updateProgramPopulatedDates);
  const { data: scheduleOverview, loading: scheduleOverviewLoading, error: scheduleOverviewError } = useQuery(getPopulatedDatesForMonth, {
    variables: { 
      hostId: user.user.userId,
      month: new Date().getMonth()+1,
      year: new Date().getFullYear(),

    },
  });


  useEffect(() => {
    if (scheduleOverview && scheduleOverview.getPopulatedDatesForMonth) {
      const populatedDatesToUpdate = scheduleOverview.getPopulatedDatesForMonth.map(({ date, status, startTime, endTime }) => {
        const startDate = new Date(date);
        const endDate = new Date(date);
        const [startHours, startMinutes] = startTime.split(':');
        const [endHours, endMinutes] = endTime.split(':');
  
        startDate.setHours(parseInt(startHours), parseInt(startMinutes));
        endDate.setHours(parseInt(endHours), parseInt(endMinutes));
  
        return {
          date: startDate,
          endDate: endDate, // Assuming you want to add an endDate property
          status
        };
      }) || [];
      console.log(populatedDatesToUpdate, 'populatedDatesToUpdate');
  
      setPopulatedDatesOverview(populatedDatesToUpdate);
    }
  }, [scheduleOverview]);



  const times = ['08:00 AM', '09:00 AM', '10:00 AM', '12:00 PM', '02:00 PM'];
  const interviewTimesOptions = [15,30,45,60,90];
  const interviewersPerIntervieweeOptions = [1,2,3,4,5];
  const numberOfInterviewersOptions = [1,2,3,4,5];
  const numberOfSlotsOptions = [1,3,5,7,10]


  const handleDateChange = (date) => {
    // check if this date is in the past
    const today = new Date();
    if (date.getTime() < today.getTime()) {
      return;
    }
    // Set the selected date directly
    setSelectedDates([date]); // Now it will only allow one date to be selected at a time
  };

  const handleClicked = async () => {
  if (step === 0) {
    // check if the selected dates are already populated
    const foundDates = populatedDatesOverview.filter(populatedDate => selectedDates.some(selectedDate => selectedDate.getTime() === populatedDate.date.getTime()));
    if (foundDates.length > 0) {
      
      const {data: specificDateData, loading: specificDateLoading, error: specificDateError } = await client.query({
        query: getSpecificProgramPopulatedDateQuery,
        variables: { programId: programId, date: selectedDates[0].toISOString() },
      });
      if (specificDateData && specificDateData.getSpecificProgramPopulatedDate) {
        const specificDate = specificDateData.getSpecificProgramPopulatedDate;
        // clean up the slots array from typename and other unnecessary fields
        const cleanedSlots = specificDate.slots.map(slot => {
          const { __typename, ...rest } = slot;
          return rest;
        });
        setStartTime(specificDate.startTime);
        setInterviewLength(specificDate.interviewLength);
        setBreakTime(specificDate.breakTime);
        setSlots(specificDate.slots===null?[]:cleanedSlots);
        setSlotsBool(specificDate.slots.length!==0);
        setBreakBool(specificDate.breakTime!==0);
        setInterviewersPerInterviewee(specificDate.interviewersPerInterviewee);
        setNumOfInterviewers(specificDate.numOfInterviewers);
        setNumberOfSlots(specificDate.totalAvailableSlots[0].slotsNumber);
        setDayType(specificDate.isRegularDay);
        setIsLocked(specificDate.status===0);
      }

      setStep(1);
      setPage('progress');
    }    
  }
  if(step!==8 && step!==9) {
    setStep(step+1);
    setPage('progress');
  }
  else {
    setIsLoading(true);
    const input = {
      startTime: startTime,
      interviewLength: interviewLength,
      breakTime: breakBool?breakTime:0,
      slots: slotsBool?slots:[],
      interviewersPerInterviewee: interviewersPerInterviewee,
      totalAvailableSlots: [{level:1,slotsNumber:numberOfSlots}],
      currentAvailableSlots: [{level:1,slotsNumber:numberOfSlots}],
      isRegularDay: dayType,
      status: isLocked?0:1,
      dates: selectedDates,
      numOfInterviewers: numOfInterviewers,
      interviewersPerInterviewee: interviewersPerInterviewee,
    };
      await sendUpdateMutation(input);
  }
  if (step===9) {
    resetValues();
  }
}



const sendUpdateMutation = async (input) => {
  try {
    const res = await updateProgramPopulatedDatesMutation({
      variables: {
        programId: programId,
        input: {
          populatedDates: input
        }
      }
    });
    if (res.data.updateProgramPopulatedDates) {
      setIsLoading(false);
      setStep(9);
      setPage('progress');
    }
  } catch (error) {
    console.error(error);
    setError(error);
  }
};

  
  const handleTimeClick = (time) => {
    setStartTime(time);
  };

  const handleInterviewersPerIntervieweeClick = (time) => {
    setInterviewersPerInterviewee(Math.max(0, time)); 
  }

  const handleNumberOfInterviewersClick = (time) => {
    setNumOfInterviewers(Math.max(0, time)); 
  }

  const handleNumberOfSlotsClick = (time) => {
    setNumberOfSlots(Math.max(0, time)); 
  }

  const handleInterviewTimeClick = (time) => {
    setInterviewLength(Math.max(0, time)); 
  }

  const handleBreakTimeChange = (e) => {
    setBreakTime(Math.max(0, parseInt(e.target.value)));
  }

const handleInterviewersPerIntervieweeChange = (e) => {
  setInterviewersPerInterviewee(Math.max(0, parseInt(e.target.value)));
}
const handleNumberOfInterviewersChange = (e) => {
  setNumOfInterviewers(Math.max(0, parseInt(e.target.value)));
}
const handleNumberOfSlotsChange = (e) => {
  setNumberOfSlots(Math.max(0, parseInt(e.target.value)));
}
const handleInterviewTimeChange = (e) => {
  setInterviewLength(Math.max(0, parseInt(e.target.value)));
}



  return (
    <>
  {scheduleOverviewLoading && (
    <Spinner style={{alignSelf:'center'}}/>
  )}
{step===0 && scheduleOverview && !scheduleOverviewError && !scheduleOverviewLoading&& (
  <div className="calendar-container">
  <Calendar
  onChange={handleDateChange}
  value={null} // Not using selectedDates here since react-calendar doesn't support array of dates for value
  selectRange={false}
  minDetail="month"
  prev2Label={null}
  next2Label={null}
  tileClassName={({ date, view }) => {
    // Logic to gray out dates not in the current month
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    if (date.getTime() < today.getTime()) {
      return 'past-date'; 
    }
  
    // Existing logic to highlight selected dates
    if (selectedDates.some(selectedDate => selectedDate.getTime() === date.getTime())) {
      return 'highlight'; // This class will highlight selected dates
    }
    const calendarDate = new Date(date);
  calendarDate.setHours(0, 0, 0, 0);

  const foundDate = populatedDatesOverview.find(populatedDate => {
    // Also ensure the populatedDate is set to midnight for a fair comparison
    const populatedDateMidnight = new Date(populatedDate.date);
    populatedDateMidnight.setHours(0, 0, 0, 0);

    return populatedDateMidnight.getTime() === calendarDate.getTime();
  });

  if (foundDate) {
    // // Apply different classes based on the status
    // switch (foundDate.status) {
    //   case 0:
    //     return 'date-full';
    //   case 1:
    //     return 'date-available';
    //   case 2:
    //     return 'date-locked';
    //   default:
    //     return '';
    // }
    return 'date-available';
  }
  }}
/>
  {/* <button className="plan-button" disabled={selectedDates.length===0} style={{cursor:selectedDates.length===0?'not-allowed':'pointer', background: selectedDates.length===0 ? 'gray' : ''}} onClick={handleClicked}>Plan Interview Day</button> */}
  </div>

)}
   {step===1 && (
    <>
    <div style={{display:'flex', flexDirection:'row'}}>
  <h2 style={{marginRight:'1vw'}}>
    {selectedDates.map((selectedDate) => {
      const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
      const day = selectedDate.getDate();
      return `${month} ${day}`;
    }).join(', ')} 
  </h2>
  <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
  </div>
  <ProgressBar step={step}/>
  <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
  <p style={{fontWeight:'500'}}>What time would you like your interview day to begin?</p>
  <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        {times.map(time => (
          <button
            key={time}
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: startTime === time ? '#14538896' : '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: startTime === time ? '1px solid #C7E5E4' : '1px solid #C7E5E4',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px'
            }}
            onClick={() => handleTimeClick(time)}
          >
            {time}
          </button>
        ))}
  </div>

<div>
  <p>Or Enter Manually</p>
  <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
</div>
<button className="plan-button" disabled={startTime===''} style={{cursor:startTime===''?'not-allowed':'pointer', background: startTime==='' ? 'gray' : ''}} onClick={handleClicked}>Next</button>

  </>
)}
   {step===2 && (
   <>
   <div style={{display:'flex', flexDirection:'row'}}>
 <h2 style={{marginRight:'1vw'}}>
   {selectedDates.map((selectedDate) => {
     const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
     const day = selectedDate.getDate();
     return `${month} ${day}`;
   }).join(', ')} 
 </h2>
 <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
 </div>
 <ProgressBar step={step}/>
 <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
 <p style={{fontWeight:'500'}}>How many interview rooms (different interviewers) would you like to have?</p>
 <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
       {numberOfInterviewersOptions.map(time => (
         <button
           key={time}
           style={{
             padding: '10px 20px',
             border: 'none',
             borderRadius: '5px',
             backgroundColor: numOfInterviewers === time ? '#14538896' : '#1453880F',
             // color: startTime === time ? 'white' : 'black',
             border: numOfInterviewers === time ? '1px solid #C7E5E4' : '1px solid #C7E5E4',
             cursor: 'pointer',
             marginRight:'.25vw',
             marginLeft:'.25vw',
             fontSize:'12px'
           }}
           onClick={() => handleNumberOfInterviewersClick(time)}
         >
           {time}
         </button>
       ))}
 </div>

<div>
 <p>Or Enter Manually</p>
 <input 
  type="number" 
  value={numOfInterviewers} 
  onChange={(e) => handleNumberOfInterviewersChange(e)}
  style={{width:'5vw', padding:'5px', borderRadius:'5px', border:'1px solid #DCDCDC', backgroundColor:'#F3F3F3'}}
  />
</div>
<button className="plan-button" disabled={numOfInterviewers===0} style={{cursor:numOfInterviewers===0?'not-allowed':'pointer', background: numOfInterviewers===0 ? 'gray' : ''}} onClick={handleClicked}>Next</button>

 </>
)}
   {step===3 && (
    <>
    <div style={{display:'flex', flexDirection:'row'}}>
  <h2 style={{marginRight:'1vw'}}>
    {selectedDates.map((selectedDate) => {
      const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
      const day = selectedDate.getDate();
      return `${month} ${day}`;
    }).join(', ')} 
  </h2>
  <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
  </div>
  <ProgressBar step={step}/>
  <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
  <p style={{fontWeight:'500'}}>How long would you like each interview to be?</p>
  <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        {interviewTimesOptions.map(interviewTimeOption => (
          <button
            key={interviewTimeOption}
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: interviewLength === interviewTimeOption ? '#14538896' : '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: interviewLength === interviewTimeOption ? '1px solid #C7E5E4' : '1px solid #C7E5E4',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px'
            }}
            onClick={() => handleInterviewTimeClick(interviewTimeOption)}
          >
            {interviewTimeOption}
          </button>
        ))}
  </div>

<div>
  <p>Or Enter Manually</p>
  <input 
  type="number" 
  value={interviewLength} 
  onChange={(e) => handleInterviewTimeChange(e)}
  style={{width:'5vw', padding:'5px', borderRadius:'5px', border:'1px solid #DCDCDC', backgroundColor:'#F3F3F3'}}
  />
</div>
<button className="plan-button" disabled={interviewLength===0} style={{cursor:interviewLength===0?'not-allowed':'pointer', background: interviewLength===0 ? 'gray' : ''}} onClick={handleClicked}>Next</button>

  </>
)}
   {step===4 && (
    <>
    <div style={{display:'flex', flexDirection:'row'}}>
  <h2 style={{marginRight:'1vw'}}>
    {selectedDates.map((selectedDate) => {
      const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
      const day = selectedDate.getDate();
      return `${month} ${day}`;
    }).join(', ')} 
  </h2>
  <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
  </div>
  <ProgressBar step={step}/>
  <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
  <p style={{fontWeight:'500'}}>Would you like any break time in between each interview?</p>
  <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor:  '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: breakBool?  '1px solid #145388' : '1px solid #C7E5E4',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px',
              display:'flex',
            }}
            onClick={() => setBreakBool(true)}
          >Yes
          <img src={thumbsUp} style={{width:'15px', height:'15px', marginLeft:'5px'}}/>
          </button>

          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor:  '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: breakBool?  '1px solid #C7E5E4' : '1px solid #145388',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px',
              display:'flex',
            }}
            onClick={() => setBreakBool(false)}
          >No
          <img src={thumbsDown} style={{width:'15px', height:'15px', marginLeft:'5px'}}/>
          </button>

  </div>

  {breakBool && (
<div>
  <p>Break Duration</p>
  <input 
  type="number" 
  value={breakTime} 
  onChange={(e) => setBreakTime(parseInt(e.target.value))}
  style={{width:'5vw', padding:'5px', borderRadius:'5px', border:'1px solid #DCDCDC', backgroundColor:'#F3F3F3'}}
  />
</div>
  )}
<button className="plan-button" disabled={(breakBool===true&&breakTime===0)&&breakBool===null} style={{cursor:(breakBool===true&&breakTime===0)&&breakBool===null?'not-allowed':'pointer', background: (breakBool===true&&breakTime===0)&&breakBool===null ? 'gray' : ''}} onClick={handleClicked}>Next</button>

  </>
)}
   {step===5 && (
    <>
    <div style={{display:'flex', flexDirection:'row'}}>
  <h2 style={{marginRight:'1vw'}}>
    {selectedDates.map((selectedDate) => {
      const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
      const day = selectedDate.getDate();
      return `${month} ${day}`;
    }).join(', ')} 
  </h2>
  <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
  </div>
  <ProgressBar step={step}/>
  <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
  <p style={{fontWeight:'500'}}>Would you like to have any time built in for additional activities such as presentations or meals?</p>
  <div style={{ display: 'flex', justifyContent: 'space-around'}}>
          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor:  '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: slotsBool?  '1px solid #145388' : '1px solid #C7E5E4',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px',
              display:'flex',
            }}
            onClick={() => setSlotsBool(true)}
          >Yes
          <img src={thumbsUp} style={{width:'15px', height:'15px', marginLeft:'5px'}}/>
          </button>

          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor:  '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: slotsBool?  '1px solid #C7E5E4' : '1px solid #145388',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px',
              display:'flex',
            }}
            onClick={() => setSlotsBool(false)}
          >No
          <img src={thumbsDown} style={{width:'15px', height:'15px', marginLeft:'5px'}}/>
          </button>

  </div>

  {slotsBool && (
<div>
  <SlotForm slots={slots} setSlots={setSlots} />
  
</div>
  )}
<button className="plan-button" disabled={slotsBool===true&&slots.length===0} style={{cursor:slotsBool===true&&slots.length===0?'not-allowed':'pointer', background: slotsBool===true&&slots.length===0 ? 'gray' : ''}} onClick={handleClicked}>Next</button>

  </>
)}
   {step===6 && (
   <>
   <div style={{display:'flex', flexDirection:'row'}}>
 <h2 style={{marginRight:'1vw'}}>
   {selectedDates.map((selectedDate) => {
     const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
     const day = selectedDate.getDate();
     return `${month} ${day}`;
   }).join(', ')} 
 </h2>
 <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
 </div>
 <ProgressBar step={step}/>
 <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
 <p style={{fontWeight:'500'}}>How many Different applicants would you like to interview {selectedDates.length>1?'each day?':'this day?'}</p>
 <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
       {numberOfSlotsOptions.map(time => (
         <button
           key={time}
           style={{
             padding: '10px 20px',
             border: 'none',
             borderRadius: '5px',
             backgroundColor: numberOfSlots === time ? '#14538896' : '#1453880F',
             // color: startTime === time ? 'white' : 'black',
             border: numberOfSlots === time ? '1px solid #C7E5E4' : '1px solid #C7E5E4',
             cursor: 'pointer',
             marginRight:'.25vw',
             marginLeft:'.25vw',
             fontSize:'12px'
           }}
           onClick={() => handleNumberOfSlotsClick(time)}
         >
           {time}
         </button>
       ))}
 </div>

<div>
 <p>Or Enter Manually</p>
 <input 
  type="number" 
  value={numberOfSlots} 
  onChange={(e) => handleNumberOfSlotsChange(e)}
  style={{width:'5vw', padding:'5px', borderRadius:'5px', border:'1px solid #DCDCDC', backgroundColor:'#F3F3F3'}}
  />
</div>
<button className="plan-button" disabled={numberOfSlots===0} style={{cursor:numberOfSlots===0?'not-allowed':'pointer', background: numberOfSlots===0 ? 'gray' : ''}} onClick={handleClicked}>Next</button>

 </>
)}
   {step===7 && (
    <>
    <div style={{display:'flex', flexDirection:'row'}}>
  <h2 style={{marginRight:'1vw'}}>
    {selectedDates.map((selectedDate) => {
      const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
      const day = selectedDate.getDate();
      return `${month} ${day}`;
    }).join(', ')} 
  </h2>
  <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
  </div>
  <ProgressBar step={step}/>
  <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
  <p style={{fontWeight:'500'}}>Would you like to interview in person or virtually?</p>
  <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor:  '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: dayType?  '1px solid #145388' : '1px solid #C7E5E4',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px',
              display:'flex',
            }}
            onClick={() => setDayType(true)}
          >
          <img src={inPersonInterviewIcon} style={{width:'15px', height:'15px', marginRight:'.5vw'}}/>
          In Person
          </button>

          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor:  '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: dayType?  '1px solid #C7E5E4' : '1px solid #145388',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px',
              display:'flex',
            }}
            onClick={() => setDayType(false)}
          >
          <img src={virtualInterviewIcon} style={{width:'15px', height:'15px', marginRight:'.5vw'}}/>
          Virtually
          </button>

  </div>

<button className="plan-button" disabled={dayType===null} style={{cursor:dayType===null?'not-allowed':'pointer', background: dayType===null ? 'gray' : ''}} onClick={handleClicked}>Next</button>

  </>
)}
{step===8 && isLoading && (
  <Spinner  style={{alignSelf:'center'}}/>
)}
  {step===8 && !isLoading &&(
    <>
    <div style={{display:'flex', flexDirection:'row'}}>
  <h2 style={{marginRight:'1vw'}}>
    {selectedDates.map((selectedDate) => {
      const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
      const day = selectedDate.getDate();
      return `${month} ${day}`;
    }).join(', ')} 
  </h2>
  <button style={{color:'#0032E1', textDecoration:'underline'}} onClick={()=>setStep(0)} >change</button>
  </div>
  <ProgressBar step={step}/>
  <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
  <p style={{fontWeight:'500'}}>How many interviews does each applicant need to attend on {selectedDates.length > 1 ? 'these days?' : 'this day?'}</p>
  <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        {interviewersPerIntervieweeOptions.map(time => (
          <button
            key={time}
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: interviewersPerInterviewee === time ? '#14538896' : '#1453880F',
              // color: startTime === time ? 'white' : 'black',
              border: interviewersPerInterviewee === time ? '1px solid #C7E5E4' : '1px solid #C7E5E4',
              cursor: 'pointer',
              marginRight:'.25vw',
              marginLeft:'.25vw',
              fontSize:'12px'
            }}
            onClick={() => handleInterviewersPerIntervieweeClick(time)}
          >
            {time}
          </button>
        ))}
  </div>
 
 <div>
  <p>Or Enter Manually</p>
  <input 
   type="number" 
   value={interviewersPerInterviewee} 
   onChange={(e) => handleInterviewersPerIntervieweeChange(e)}
   style={{width:'5vw', padding:'5px', borderRadius:'5px', border:'1px solid #DCDCDC', backgroundColor:'#F3F3F3'}}
   />
 </div>
 <button className="plan-button" disabled={interviewersPerInterviewee===0} style={{cursor:interviewersPerInterviewee===0?'not-allowed':'pointer', background: interviewersPerInterviewee===0 ? 'gray' : ''}} onClick={handleClicked}>Save Interview {selectedDates.length>1?'Days':'Day'}</button>
 
  </>
)}
  {step===9 && (
    <>
    <div style={{display:'flex', flexDirection:'row'}}>
  <h2 style={{marginRight:'1vw'}}>
    {selectedDates.map((selectedDate) => {
      const month = selectedDate.toLocaleString('default', { month: 'long' }); // 'long' for full month name
      const day = selectedDate.getDate();
      return `${month} ${day}`;
    }).join(', ')} 
  </h2>
  </div>
  <ProgressBar step={step}/>
  <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
  <div style={{display:'flex',alignSelf:'center', flexDirection:'column'}}>
  <img src={interviewDayDoneIcon} style={{width:'4vw', margin:'auto', display:'block'}}/>
  <h2 style={{textAlign:'center', fontSize:'20px', fontWeight:'600', marginBottom:'3vh'}}>Interview Day Planned</h2>
  <p style={{textAlign:'center', fontSize:'12px', fontWeight:'400'}}>Your Interview Day has been Planned. <br /> Now, you can see the dedicated slots and breaks on the right side.</p>
  <a style={{color:'#0032E1', textDecoration:'underline', cursor:'pointer', textAlign:'center', marginTop:'2vh'}} onClick={()=>handleClicked(0)}>Return to Interview Home</a>
  </div>
 


  </>
)}


</>
  );
};

export default InterviewCalendar;
