import {gql} from '@apollo/client';

export const getUserByInvitationTokenQuery = gql`
    query getUserByInvitationToken($token: String!) {
        getUserByInvitationToken(token: $token) {
            userId
            userType
            programId
            token
            interviews{
            id
            programId
            programName
            status
            interviewDate
            specialty
            datesToSend {
                date
                status
                isRegularDay
                startTime
                totalAvailableSlots
                currentAvailableSlots
              }
            }
        }
    }
`;


export const getInviteByTokenQuery = gql`
    query getInviteByToken($token: String!) {
        getInviteByToken(token: $token) {
        invitedBy {
        id
        firstName
        lastName
        email
        timeZone
        }
        invitedUser {
        id
        firstName
        lastName
        email
        }
        invitedProgram {
        id
        name
        }
        invitedPosition {
        id
        jobTitle
        }
        invitationType
        status
        }
    }
`;


export const getAvailableDatesForMonthQuery = gql`
    query getAvailableDatesForMonth($hostId: ID!, $year: Int!, $month: Int!) {
        getAvailableDatesForMonth(hostId: $hostId, year: $year, month: $month)
    }
`;

export const getAvailableSlotsForDayQuery = gql`
  query GetAvailableSlotsForDay($hostId: ID!, $date: String!) {
    getAvailableSlotsForDay(hostId: $hostId, date: $date) {
      date
      startTime
      endTime
      hostTime
      applicantTime
      isAvailable
    }
  }
`;

export const getEventQuery = gql`
    query getEvent($hostId: ID!, $guestId: ID!) {
        getEvent(hostId: $hostId, guestId: $guestId) {
        id
        date
        startTime
        endTime
        }
    }
`;