import InterviewBuilderProgress from "./progress";
import PropTypes from "prop-types";
import "../styles/progress.css";
import InterviewCalendar from "../calendar";
import { useInterviewSchedule } from '../interviewScheduleContext';
const ProgressCont = ({ comp, className = "" }) => {

  const { selectedDates, setSelectedDates,
    page,setPage,
    step,setStep,
    startTime, setStartTime,
    interviewLength, setInterviewLength,
    breakTime, setBreakTime,
    slots, setSlots,
    interviewersPerInterviewee, setInterviewersPerInterviewee,
    numberOfSlots, setNumberOfSlots,
    dayType, setDayType,
    isLocked, setIsLocked,
    } = useInterviewSchedule();
  
  
    return (
    <div className={`content-summary-description ${className}`}>
      <div className="your-interview-day-parent">
        {comp === "progress" ? (
        <div className="your-interview-day">Your Interviews for the Day</div>
        ) : (
        <div className="your-interview-day">Select a Date to View</div>
        )}
      </div>
      {comp === "progress" ? (
      <InterviewBuilderProgress />
      ) : (
      <InterviewCalendar />
      )}
    </div>
  );
};

export default ProgressCont;
