import React, { useState,createContext, useContext, useEffect, useRef } from 'react';
import './styles/signInModalInApplyRoute.css'; // Make sure to create a corresponding CSS file
import backIcon from '../../../../assets/imgs/backIcon.svg'; // Import the backIcon image
import logo from '../../../../assets/imgs/logo.svg'; // Import the logo image
// import medicalSchools from '../../common/listOfInstitutions.jsx'; // Import the list of institutions
import client from '../../../../config/apolloClient';
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from '../../../../context/userContext.js';
import { 
  AuthenticateUserMutation,
  SmsAuthenticateUserMutation,
  resetPasswordRequest,
  resetPassword,
  createUser,
  verifyUser,
} from '../../../home/components/graphql/mutations';

import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Spinner from '../../../common/spinner';
import Select from 'react-select';
import {jwtDecode} from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const SignInModal = ({isVisible, onClose, isReset, isVerify, type, setFinishedSignUp}) => {
  const navigate = useNavigate();
  const isResetBoolean = isReset;
  const isVerifyBoolean = isVerify;
  const { user, setUser } = useContext(UserContext);
  const token = window.location.pathname.split('/')[2];

// This is the state that will store the values of the username and password inputs
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [institution, setInstitution] = useState('');
  const [authenticatedUser, setUserForFirstStep] = useState(null);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [medicalSchools, setMedicalSchools] = useState([]);
  const [medicalSchoolsData, setMedicalSchoolsData] = useState([]);


const [createUserMutation] = useMutation(createUser);
const [resetPasswordReqMutation] = useMutation(resetPasswordRequest);
const [resetPasswordMutation] = useMutation(resetPassword);
const [verifyUserMutation] = useMutation(verifyUser);

// State handle to compare password and re-entered password
const [passwordConfirm, setPasswordConfirm] = useState('');


//   This is the state that will determine which stage of the sign-in process the user is in
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(isResetBoolean?true:false);
  const [isSignUpVisible, setIsSignUpVisible] = useState(false);
  const [isSignInVisible, setIsSignInVisible] = useState(true);
  const [signInStage, setSignInStage] = useState(isResetBoolean?1:isVerifyBoolean?5:0);
  const [signUpStage, setSignUpStage] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [accept, setAccept] = useState(false);
  const [isPreSeason, setIsPreSeason] = useState(false);
  const [lastFourDigits, setLastFourDigits] = useState('0000');
  const [isPending, setIsPending] = useState(false)
  const [registerWithGoogle, setRegisterWithGoogle] = useState(false)
  const inputRef = useRef(null);
  const inputPassRef = useRef(null);





  useEffect(() => {
    if (signInStage === 5 && isSignInVisible) {
      handleVerifyUser();
    }
  }, [signInStage, isSignInVisible]);


  const resetStateAndGoHome = () => {
      // Reset all state here
      setIsSignUpVisible(false);
      setIsSignInVisible(true);
      setSignInStage(0);
      setSignUpStage(0);
      setUsername('');
      setPassword('');
      setCode('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setInstitution('');
      setUserForFirstStep(null);
      setPasswordConfirm('');
      setIsForgotPasswordVisible(false);
      setIsSuccess(false);
      setFinishedSignUp(true);
    };

   

  

    useEffect(() => {
      if (isVisible) {
        const inputElement = inputRef.current;
  
        const clearAutofill = () => {
          setTimeout(() => {
            if (inputElement && inputElement.value) {
              inputElement.value = '';
              setUsername(''); // Reset the state as well
            }
          }, 50); // Delay to allow autofill to complete
        };
  
        if (inputElement) {
          inputElement.addEventListener('focus', clearAutofill);
  
          // Clear the input once when the component mounts
          clearAutofill();
        }
  
        // Cleanup event listener on component unmount
        return () => {
          if (inputElement) {
            inputElement.removeEventListener('focus', clearAutofill);
          }
        };
      }
    }, [isVisible]);


    
  

const handleSignUp = () => {
    setSignInStage(0);
    setSignUpStage(0);
    setIsSignUpVisible(true);
    setIsSignInVisible(false);
}

const handleForgotPassword = () => {
  resetPasswordMutation({
    variables: {
      resetPasswordToken: token,
      password: password
    }
  }).then(res => {
    setSignInStage(2);
    setIsForgotPasswordVisible(true);
    setIsSuccess(true);
  })
}

const handleVerifyUser = () => {
  verifyUserMutation({
    variables: {
      token: token
    }
  }).then(res => {
    let verifiedUser = res.data.verifyUser
    if(verifiedUser ) {
      setIsSuccess(true);
      setUser(verifiedUser);
      localStorage.setItem('login_token', verifiedUser.token)
      if(verifiedUser.userType==='faculty' || verifiedUser.userType === 'admin') {
        navigate('/faculty')
      }
      if(verifiedUser.userType==='applicant') {
        navigate('/applicant')
      }
      if(verifiedUser.userType==='medSchool') {
        navigate('/med-school')
      }
    }
  })
  .catch(error => {
    setError(error.message);
    setIsPending(false)
  })  
}

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);   
  };

  const handlePasswordResetReq = () => {
    resetPasswordReqMutation({
      variables: {
        email: email
      }
    }).then(res => {
      setIsSuccess(true);
      setSignInStage(2);
    }
    ).catch(error => {
      setError(error.message);
    })
  }

  const handleGoToFogotPassword = () => {
    setSignInStage(0);
    setIsForgotPasswordVisible(true);
    setIsSignInVisible(false);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value)
  }

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    };

const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    };

const handlePasswordConfirmChange = (event) => {
    setPasswordConfirm(event.target.value);
    };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsPending(true)
    // Handle the sign-in logic here, potentially calling an API
    if(signInStage === 0) {
      client.mutate({
        mutation: AuthenticateUserMutation,
        variables: {
          email: username,
          password
        }
      })
      .then(res => {
        let authenticatedUser = res.data.authenticateUser
        setLastFourDigits(authenticatedUser.phone.slice(-4));
        setIsPending(false)
        if (authenticatedUser.bypassSMS === true) {
          setLoading(true);
          client.mutate({
            mutation: SmsAuthenticateUserMutation,
            variables: {
              id: authenticatedUser.id,
              phoneNumber: authenticatedUser.phone,
              code: '0000'
            }
          }).then(res => {
            setIsPending(false)
            setLoading(false);
            let userFromSms = res.data.smsAuthenticateUser
            if(userFromSms) {
              setError(null)
              let token = userFromSms.token
              localStorage.setItem('login_token', userFromSms.token)
              if(token) {
                setUser(userFromSms);
                if(userFromSms.userType==='faculty' || userFromSms.userType==='admin' ) {
                  navigate('/faculty')
                }
                if(userFromSms.userType==='applicant') {
                  navigate('/applicant')
                }
                if(userFromSms.userType==='medSchool') {
                  navigate('/med-school')
                }
              }
            }
          }).catch(err => {
            setLoading(false);
            setError(err.message)
          })
        } else
        if(authenticatedUser) {
          setSignInStage(1);
          setUserForFirstStep(authenticatedUser)
          setError(null)
        } 
      })
      .catch(err => {
        console.log("error =>", err.message)
        setIsPending(false)
        setError(err.message)
      })
    }
    if(signInStage === 1) {
      client.mutate({
        mutation: SmsAuthenticateUserMutation,
        variables: {
          id: authenticatedUser.id,
          phoneNumber: authenticatedUser.phone,
          code
        }
      }).then(res => {
        setIsPending(false)
        let userFromSms = res.data.smsAuthenticateUser
        if(userFromSms) {
          setError(null)
          let token = userFromSms.token
          localStorage.setItem('login_token', userFromSms.token)
          if(token) {
            setUser(userFromSms);
            if(userFromSms.userType==='faculty' || userFromSms.userType==='admin' ) {
              navigate('/faculty')
            }
            if(userFromSms.userType==='applicant') {
              navigate('/applicant')
            }
            if(userFromSms.userType==='medSchool') {
              navigate('/med-school')
            }
          }
        }
      }).catch(err => {
        console.log("error =>", err.message)
        setError(err.message)
      })
    }
   
  };

  const handleRegisterWithGoogle = (response) => {
    console.log("register with google!", response)
    const token = response.credential;
    const decoded = jwtDecode(token);
    console.log("decoded =>", decoded)
    setRegisterWithGoogle(true)
    setFirstName(decoded.given_name)
    setLastName(decoded.family_name)
    setEmail(decoded.email)
    setPassword(response.clientId)

  }


  const handleError = () => {
    console.error("Google Sign-In Error");
  };

  const handleGoHome = () => {
setSignInStage(0);
setIsForgotPasswordVisible(false);
setSignUpStage(0);
  onClose();
  setIsSignInVisible(true);
  setIsSignUpVisible(false);
  setIsSuccess(false);
  };

const resendCode = () => {
  client.mutate({
    mutation: AuthenticateUserMutation,
    variables: {
      email: username,
      password
    }
  })
  .then(res => {
    let authenticatedUser = res.data.authenticateUser
    if(authenticatedUser) {
      setSignInStage(1);
      setUserForFirstStep(authenticatedUser)
    } 
  }) 
  .catch(err => {
    console.log("error =>", err.message)
    setError(err.message)
  })
}

const handleSignUpSubmit = (event) => {
  setLoading(true);
  event.preventDefault();

  const userInput = {
    email: email,
    password: password,
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    userType : 'applicant',
    institution: institution,

};

  createUserMutation({
    variables: {
      input: userInput,
  },

  }).then(response => {
    console.log('response:', response.data)
    let token = response.data.createUser.token
    localStorage.setItem('login_token', token)
    
    const userToUpdate = {
      token: token,
      userId: response.data.createUser.id,
      userType: 'applicant'
    }

    setUser(response.data);
    setLoading(false);
    setIsSignUpSuccess(true);
  }).catch(error => {
    setLoading(false);
    setError(error.message);
  });

}

const handleEmailChange = (event) => {
    setEmail(event.target.value);
    };
const handlePhoneChange = (event) => {
    setPhone(event.target.value);
    };

const handleInstitutionChange = (event) => {
    setInstitution(event.value);
    };

const passwordsMatch = () => password === passwordConfirm && password.length > 7;

const checkPasswordRequirements = () => {
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if (!passwordPattern.test(password)) {
    // If not, display an error message and return early
    setPasswordError("Password must contain at least 8 characters, one letter, one number, and one special character.");
  }
  else {
    setPasswordError('');
  }
}


  if (!isVisible) return null;

  if (loading) {
    return( 
      <div className="signin-modal-backdrop">
      <div className="signin-modal">
      <div className='header-container'>
    <div onClick={handleGoHome} className="return-home-container">
    <img
        src={backIcon}
        alt="Back Icon"
        className="back-icon"
    />
    <div className="return-home">Return to Home</div>
</div>
<img src={logo} alt='Logo' className='logo' style={{width:'5vw'}} />
</div>
      <h2 className='sign-in-title'>Sign-Up to Start</h2>
    <Spinner />;
    </div>
    </div>
    )
  }


  return (
    // <div className="signin-modal-backdrop">
    <GoogleOAuthProvider clientId="411941052949-h7nvdibh6svel1q029edemvl9h82uqob.apps.googleusercontent.com">

    <div className="signin-modal" style={{position:'relative', padding:'2vw', top:'0',left:'0', width:'55vw', transform:'none', boxShadow:' 0 3px 9px #00000038', minHeight:'82vh', maxHeight:'82vh'}}>
{isPreSeason&&
    <div className="signin-modal-content">
      <h2 className='sign-in-title' style={{textAlign:'center'}}>Welcome to AccessGME</h2>
      <p style={{textAlign:'justify', fontFamily:'Montserrat'}}>We're eagerly preparing for the 2024-2025 recruitment season!<br />To ensure the best experience, signing in and signing up functionalities will be temporarily unavailable. These features will be back soon.<br />We appreciate your patience and look forward to welcoming you soon.</p>
      </div>}
    {signInStage === 0 && isSignInVisible===true&&!isPreSeason&&(
        <>
        <h2 className='sign-in-title' style={{textAlign:'center', fontFamily:'Montserrat', fontSize:'5vh', fontWeight:'500'}}>SIGN IN TO APPLY</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={handleUsernameChange}
          placeholder="Enter Email"
          className="signin-input"
          autocomplete="new-username"
          ref={inputRef}
          />

          
        <input
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter Password"
          className="signin-input"
          autocomplete="off"
          ref={inputPassRef}
        />
        <button type='button' onClick={handleGoToFogotPassword} className="forgot-password">Forgot Password?</button>
        {!error ? null : <p style={{color:'red'}}>{error}</p>}
        <button onClick={handleSubmit} className="signin-button" disabled={!username || !password || isPending}>Sign In</button>
      </form>

      <div className="signup-link">
        {/* Don’t have an account? requesting access for the 2024-2025 will become available on June 15, 2024 */}
        <button
  className='ReqAccess'
  onClick={handleSignUp}
  aria-label="Sign up for an account if you don't have one"
>
  Don't have an account? Sign Up
</button>
        
      </div>


      </>
    )}
    {signInStage === 1 && isSignInVisible===true&&isForgotPasswordVisible===false&&!isSignUpSuccess&&!isPreSeason&&(
        <>
      <h2 className='sign-in-title'>Two Factor Authentication</h2>
      <p style={{fontFamily:'Montserrat', marginBottom:'2vh'}}>A verification code was just sent to the number ending in {lastFourDigits}. Please enter the code you received.</p>
      <form onSubmit={handleSubmit}>
      <input
          type="text"
          value={code}
          onChange={handleCodeChange}
          placeholder="Enter Code"
          className="signin-input"
        />
        <label className='resend-title'onClick={resendCode}>Click here to resend code</label>
      <button type="submit" className="signin-button" disabled={!code}>Sign In</button>        
      {!error ? null : <p style={{color:'red'}}>{error}</p>}                          
      </form>
      </>
    )}


{signInStage === 5 && isSignInVisible===true&&!isPreSeason&&(
        <>
      <h2 className='sign-in-title'>Verify Your Account</h2>
      {!isSuccess &&
        <p style={{color:'red'}}>{error}</p>
      }
{isSuccess &&
        <p style={{fontFamily:'Montserrat'}}>Thank you. your account is now verified, please log in.</p>
      }
      

      </>
      )
}

{isForgotPasswordVisible===true && signInStage===0 && !isSuccess&&!isSignUpSuccess&&!isPreSeason&&(
        <>
      <h2 className='sign-in-title'>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>

        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your E-mail address"
          className="signin-input"
        />
      </form>
      {!error ? null : <p style={{color:'red'}}>{error}</p>}
      <button type="submit" onClick={handlePasswordResetReq} className="signin-button" disabled={!email}>Verify and Proceed</button>
      </>
    )}

{isForgotPasswordVisible===true && signInStage===0 && isSuccess&&!isPreSeason&&(
        <>
      <h2 className='sign-in-title'>Reset Your Password</h2>
    <p style={{fontFamily:'Montserrat'}}>Thank you.</p>
    <p style={{fontFamily:'Montserrat'}}>If you are a registered user, you will receive an email shortly with instructions on how to reset your password.</p>
      </>
    )}

    

{isForgotPasswordVisible===true && signInStage===1 &&!isSignUpSuccess&&!isPreSeason&& (
        <>
      <h2 className='sign-in-title'>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          onBlur={checkPasswordRequirements}
          placeholder="Enter New Password"
          className="signin-input"
        />
        {passwordError !=='' && <label style={{color:'red', marginTop:'-1vh', fontSize:'1.5vh'}}>{passwordError}</label>}
  <input
          type="password"
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
          placeholder="Re-enter New Password"
          className="signin-input"
        />
      </form>
      <button type="submit" onClick={handleForgotPassword} className="signin-button" disabled={!passwordsMatch()}>Reset Password</button>
      </>
)}

{isForgotPasswordVisible===true && signInStage===2 && isSuccess&&!isSignUpSuccess&&!isPreSeason&&(
        <>
      <h2 className='sign-in-title'>Reset Your Password</h2>
    <p style={{fontFamily:'Montserrat'}}>Thank you.</p>
    <p style={{fontFamily:'Montserrat'}}>Reset Password email was sent successfully.</p>
    <button  className="signin-button" onClick={resetStateAndGoHome}>Go Home</button>
      </>
    )}


{isSignUpVisible && signUpStage===0 &&!isSignUpSuccess&&!isPreSeason&& (
        <>
      <h2 className='sign-in-title'>Sign-Up to Start</h2>
      <GoogleLogin onSuccess={handleRegisterWithGoogle} onError={handleError} />

      
      <form style={{display:'flex', flexDirection:'column'}} onSubmit={handleSubmit}>
        <div className='first-last-names' style={{display:'flex',flexDirection:'row', }}>
        <input
          type="text"
          value={firstName}
          onChange={handleFirstNameChange}
          placeholder="Enter First Name"
          className="signin-input"
          style={{marginRight:'.5vw', marginBottom:'1.5vh', height:'48px'}}
        />
        <input
          type="text"
          value={lastName}
          onChange={handleLastNameChange}
          placeholder="Enter Last Name"
          className="signin-input"
          style={{marginLeft:'.5vw', marginBottom:'1.5vh', height:'48px'}}
        />
        </div>
        <label st className='phone-2fa-label'>This number must receive text messages for 2 Factor Authentication</label>
        <PhoneInput
          defaultCountry="US"
          placeholder="Enter your Cellphone number"
          value={phone}
          onChange={setPhone}
          className="signin-input"
          style={{height:'48px'}}
        />
         <input
          type="text"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter Email"
          className="signin-input"
          style={{height:'48px'}}
        />
  {
  registerWithGoogle === false &&
  <div>
      <input
          type="password"
          value={password}
          autocomplete="new-password"
          onChange={handlePasswordChange}
          placeholder="Enter Password"
          className="signin-input"
          onBlur={checkPasswordRequirements}
          style={{height:'48px'}}
          // style={{ marginTop: '2vh'}}
         
        />
        {passwordError !=='' && <label style={{color:'red', marginTop:'-1vh', fontSize:'1.5vh'}}>{passwordError}</label>}
        </div>
}
{
  registerWithGoogle === false &&
  <div>
    <input
          type="password"
          value={passwordConfirm}
          autocomplete="new-password"
          onChange={handlePasswordConfirmChange}
          placeholder="Re-enter Password"
          className="signin-input"
          style={{height:'48px'}}
        />
        {!error ? null : <p style={{color:'red'}}>{error}</p>}
  </div>
}

      </form>


      <label className='terms-of-use-title' style={{fontSize:'2vh', fontWeight:'unset'}} onClick={() => setModalOpen(!modalOpen)}>By clicking 'Sign Up' below, I acknowledge that I have read and agree to the <label style={{ color: "#145388"}}>Terms of Use</label>.</label>
            {
              modalOpen &&
              <div className="modal-backdrop" >
                <div className="add-field-modal" style={{ width: '70vw', height:'80vh', overflow: 'scroll'}}>
                  <p className='terms-title'>Terms of Service</p>
                  <p className='terms-normal'>By using this website, the user ("You") is bound by the terms of this User Agreement ("Agreement"). By clicking Accept below, You acknowledge that You have read and agree to the terms in this Agreement.</p>
                  <br/><br/>
                  <p className='terms-normal'>This Agreement governs the use of The Residency Smart Recruiting (Open Application) through accessGME.com, which is owned and operated by Learner-Centric Applications, LLC ("DDH"). Use of accessGME.com constitutes agreement to all terms and conditions herein. This Agreement constitutes a contract between You and DDH. You accept the terms of this Agreement by access and submission of the Open Application through accessGME.com. If You violate this Agreement, Your access may be suspended or terminated without warning.</p>
                  <br/><br/>
                  <p className='terms-normal'>This Agreement may be revised periodically. You will be notified of the changes and asked to accept those changes as an updated Agreement. Continued use of accessGME.com constitutes Your acceptance of the newest Agreement.</p>
                  <br/><br/>
                  <p className='terms-normal' style={{ fontWeight: 'bold' }}>Users</p>
                  <br/><br/>
                  <p className='terms-normal'>To participate in the Open Application on accessGME.com, you must be (1) an applicant to residency currently eligible to begin training in the upcoming academic year ("Applicant"), (2) a person affiliated with an approved residency training program (“Program User” [e.g., Coordinator, Director, Reviewer]), (3) a person supporting a residency applicant with a letter of recommendation (“Recommender”) or (4) a person affiliated with an Applicant’s medical school verifying information, or uploading their medical school transcript or Medical School Performance Evaluation (“Medical School Representative”). The Open Application is for use only to: (1) apply to a residency program (“Residency”), (2) to review an applicant’s data who is applying to Your residency program, or (3) to submit documents required for the application. Any other use of the Open Application is prohibited. Elements of the Open Application and accessGME.com, including content and software coding, are protected under copyright, trademark and other laws. Do not copy or adapt such code.</p>
                  <br/><br/>
                  <p className='terms-normal'>You are responsible for maintaining the confidentiality of Your account and all data available through that account, including Applicant data from Program Users’ accounts. As a verified recommender, reviewer, program director, or program coordinator, your name, email address, and institution will be searchable by both applicants using the letter of recommender search tool and unverified users seeking verification status. Do not share passwords, account information, or login credentials. You are liable for data breaches resulting from inappropriate account access. Immediately notify DDH of any unauthorized use of or access to Your account (<a href="mailto:help@accessGME.com" style={{ color: "blue"}}>help@accessGME.com</a>).</p>
                  <br/><br/>
                  <p className='terms-normal'>Applicants will not submit any information on behalf of another Applicant, submit more than one application to a single specialty, submit any incomplete, false or inaccurate information or information which is not their own. Program Users will not access data not intended for their Residency. Both You and DDH agree to use appropriate physical, technical, and administrative measures to protect and secure Open Application data from loss, misuse, unauthorized access, disclosure, alteration or destruction. Violations will be reported to specialty society leadership, and Your accessGME.com account will be terminated.</p>
                  <br/><br/>
                  <p className='terms-normal'>DDH and accessGME.com will not tolerate defamatory, libelous, implicitly or explicitly offensive, vulgar, obscene, threatening, abusive, hateful, racist, or discriminatory content. Violations of system or network security may result in civil and/or criminal liability. DDH will investigate and cooperate with law enforcement authorities in prosecuting criminal violations.</p>
                  <br/><br/>
                  <p className='terms-normal' style={{ fontWeight: 'bold' }}>Obligations, Outcomes and Data </p>
                  <br/><br/>
                  <p className='terms-normal'>DDH will maintain confidentiality and privacy of data submitted to accessGME.com. DDH is responsible for the proper function and support of accessGME.com, but does not warrant errorless operations. We will address all concerns promptly (<a href="mailto:help@accessGME.com" style={{ color: "blue"}}>help@accessGME.com</a>).</p>
                  <br/><br/>
                  <p className='terms-normal'>DDH is not responsible for any outcomes from Application through the Open Application or any residency selection decisions or outcomes. By using accessGME.com, You agree You will not hold DDH liable for any damages, either incidental or consequential. You agree that the maximum liability arising out of any action associated with DDH, regardless of the causes or damages, will not exceed Your cost for Application through the Open Application. You agree to indemnify and hold harmless DDH from any claims resulting from use of accessGME.com. Should You bring any legal action in connection with Your use or outcomes of the Open Application, You will be responsible for attorneys’ fees and all other reasonable costs to DDH as part of that action. The NRMP is responsible for outcomes of the Match. DDH maintains all rights and intellectual property associated with the Open Application and <a href="https://accessGME.com">accessGME.com</a>.</p>
                  <br/><br/>
                  <p className='terms-normal'>You are solely responsible for the information You submitted to Open Application. Data submitted must be accurate and describe You. You acknowledge and agree that You are solely responsible for the form, content and accuracy of any data or material you submit on accessGME.com. Participation in the Open Application grants rights to your data to DDH perpetuity, Program Users and Recommenders in perpetuity. Your data may be used by DDH to improve the Application through the Open Application or accessGME.com. DDH is not liable for the deletion, loss, or unauthorized modification of any data.</p>
                  <br/><br/>
                  <p className='terms-normal'>Programs are responsible for verifying the accuracy of all application data. DDH does not represent or guarantee the truthfulness, accuracy, or reliability of Open Application content. You acknowledge that use of accessGME.com and any reliance on Open Application data will be at Your own risk. Search, scoring and analytic features may yield disparate or incomplete results. Only a complete, holistic review of applications can provide a summation of Applicants by a Residency.</p>
                  <br/><br/>
                  <p className='terms-normal'>DDH may remove any content that violates this Agreement, violates applicable laws, rules or regulations, is abusive, disruptive, offensive or illegal, or violates the rights of persons. DDH reserves the right to expel Users and prevent their further access to AccessGME.com. DDH will make all reasonable efforts to verify Users and content on AccessGME.com. The Open Application may contain errors and feedback is welcome (<a href="mailto:help@accessGME.com" style={{ color: "blue"}}>help@accessGME.com</a>). User-entered inaccuracies are the responsibility of the User. If You believe that something on <a href="https://accessGME.com">accessGME.com</a> violates this Agreement please contact us (<a href="mailto:help@accessGME.com" style={{ color: "blue"}}>help@accessGME.com</a>).</p>
                  <br/><br/>
                  <p className='terms-normal'>These Terms are governed by the laws of Tennessee. Jurisdiction for any claims arising under this agreement shall lie within the exclusive jurisdiction of the United States District Court for the Middle District of Tennessee or a local court sitting in Davidson County, Tennessee. No changes to this Agreement shall be made except by a revised posting on this page.</p>
                  <br/><br/>
                  <p className='terms-title'>AUTHORIZATION FOR VERIFICATION OF USMLE SCORES AND TRANSFER OF TRANSCRIPT AND MSPE DOCUMENTS TO THE RESIDENCY Smart Recruiting</p>
                  <br/><br/>
                  <p className='terms-normal'>I request and grant my consent for my medical school officials to verify my USMLE scores and transfer my transcript and MSPE (Medical Student Performance Evaluation) documents to the Residency Smart Recruiting. I understand that this information is essential for the residency application process, and I willingly authorize the release of these documents to facilitate my application to residency programs. I acknowledge that the Residency Smart Recruiting is a secure platform designed to improve equity in the residency selection process by eliminating financial barriers and enhancing holistic review. I am aware that this platform has been rigorously developed and piloted, and its usage aims to promote a fair and transparent application process for all applicants.</p>
                  <br/><br/>
                  <p className='terms-normal'>By granting this consent, I confirm that I have carefully reviewed and understand the implications of sharing my USMLE scores, transcript, and MSPE documents through the Residency Smart Recruiting. I trust that the medical school and Residency Smart Recruiting will handle my information responsibly and with utmost confidentiality. I hereby agree to allow the medical school to proceed with the verification of my USMLE scores and the transfer of my transcript and MSPE documents to the Residency Smart Recruiting as part of my residency application process.</p>
                  <br/><br/>
                  <p className='terms-title'>APPLICANT CODE OF ETHICS</p>
                  <br/><br/>
                  <p className='terms-normal'>The information I submit in this application is complete, factual, and accurate.</p>
                  <br/>
                  <p className='terms-normal'>There are no falsehoods by commission or omission, and the content is all my own work.</p>
                  <br/>
                  <p className='terms-normal'>Applicants found in violation of the code of ethics will be reported to their medical school, program director groups, and to the appropriate member board of the American Board of Medical Specialties.</p>
                  <br/>
                  <p className='terms-normal'>Match results based on irregularities or inaccuracies may not be honored by the program.</p>
                  <div className='buttons-tos'>
                    <button type="submit" className='white-button' onClick={() => {setAccept(true); setModalOpen(!modalOpen); }}>Accept</button>
                    <button type="submit" className='green-button' onClick={() => setModalOpen(!modalOpen)}>Close</button>
                  </div>
               

                </div>
              </div>
            }





      <button type="submit" onClick={handleSignUpSubmit} className="signin-button" disabled={registerWithGoogle ? false : !passwordsMatch()}>Sign Up</button>
      </>
    )}
{isSignUpSuccess&& isSignUpVisible&&!isPreSeason&&(
        <>
      <h2 className='sign-in-title'>Sign-Up to Start</h2>
      
        <p style={{fontFamily:'Montserrat'}}>Your account was created successfully.</p>
      
    <button  className="signin-button" onClick={resetStateAndGoHome}>Continue your Application</button>
      </>
    )}

    
    
    </div>
    </GoogleOAuthProvider>
  );
};

export default SignInModal;
