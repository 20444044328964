import React,{useState, useContext, useEffect} from "react";
import "./index.css";
import InviteUserModal from "./inviteUserModal";
import TeamMembersTable from "./teamMembersTable";
import UpdateRoleModal from "./updateRoleModal";
import { ProgramIdContext } from "../../progIdContext";
import {useQuery, useMutation} from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LIST_POSITIONS} from './graphql/queries';
import { DELETE_POSITION  } from "./graphql/mutations";
import dropdownArrowIcon from '../../../../assets/imgs/DropdownArrowIcon.svg'
import Spinner from "../../../common/spinner";
import packageInfo from '../../../../../package.json';





const Positions = () => {
    const programId = useContext(ProgramIdContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [sort, setSort] = useState(JSON.parse(sessionStorage.getItem('sort')) || { field: 'lastName', direction: 'asc'});
    const [openMenuRowId, setOpenMenuRowId] = useState(null);
    const [data, setData] = useState([]);


    const {loading:loadingWorkflows, error:errorWorkflows, data:rawDataWorkflows, refetch} = useQuery(LIST_POSITIONS, {
        variables: { programId }
    });
    const [deleteWorkflow] = useMutation(DELETE_POSITION);
    
    // useEffect(() => {
    //     if (!loading && !error && rawData) {
    //         setData(transformRoles(rawData.getProgramMembersByProgramIdWithSort));
    //     }
    // }, [loading, error, rawData]);

    useEffect(() => {
        if (!loadingWorkflows && !errorWorkflows && rawDataWorkflows) {
            console.log("rawDataWorkflows=>", rawDataWorkflows)
            const listPositionsWithLinks = rawDataWorkflows.listPositions.map((item, index) => {
                return {
                    ...item, // Spread the existing properties of the item
                    link: `${process.env.REACT_APP_URL}/apply/${item.id}` // Add the new link property
                };
            });
            setData(listPositionsWithLinks);
        }
    }, [loadingWorkflows, errorWorkflows, rawDataWorkflows]);



    


    const handleRemind = (user, id) => {
        console.log("send reminder!", user)
        fetch(`${process.env.REACT_APP_SERVER_URL}/send_invitation_reminder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({
              user
            })
        })
        handleMenuToggle(id)
    }

    const handleMenuToggle = (rowId) => {
        setOpenMenuRowId((prev) => (prev === rowId ? null : rowId));
      };

    const columns = [
    {
        Header: 'id',
        accessor: 'id',
    },
        {
            Header: 'Title',
            accessor: 'jobTitle',
        },
        {
            Header: 'Description',
            accessor: 'summary',
        },
        {
            Header: 'Link',
            accessor: 'link',
            Cell: ({ value }) => (
                <span
                style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                onClick={() => {
                    navigator.clipboard.writeText(value)
                        .then(() => {
                            // Use toast for a better user experience
                            toast.success('Link copied to clipboard!');
                        })
                        .catch(err => {
                            // Use toast for error handling
                            toast.error('Failed to copy link: ' + err);
                        });
                }}
            >
                {value}
            </span>
            ),
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div className="dropdown" style={{border:'0',margin:'0',padding:'0'}}>
                <button className="white-button dropdown-toggle"  onClick={() => handleMenuToggle(row.id)}>
                    Manage 
                </button>
                {openMenuRowId === row.id && (
                    <div className="dropdown-menu team" style={{position:'absolute',zIndex:'3', right:'-.5vw', textAlign: 'center',overflow: 'visible'}}>
                        {row.original.isPending === true && <div className="dropdown-item" style={{ cursor: "pointer", padding:'1vh 2vw' }} onClick={() => handleRemind(row.original, row.id)}>Remind</div> }
                        <div className="dropdown-item team" style={{ cursor: "pointer" }} onClick={()=>handleEdit( row.original,row.original.id)} >Edit</div>
                        <div className="dropdown-item team" style={{ cursor: "pointer"}} onClick={()=>handleDelete(row.original.id)} >Delete</div>
                    </div>
                )}
                </div>
                // <div style={{display:'flex', flexDirection:'row', gap:'.2vw', marginRight:'.3vw'}}>
              
                // </div>
            ),
        },
    ]

    const handleEdit = (rowData, id) => {
        setEditingUser(rowData);
        handleMenuToggle(id)
        setIsEditModalOpen(true);
    }
    
    const handleDelete = async (id) => {
        console.log("id=>", id)
        try {
            const response = await deleteWorkflow({ variables: { id } }); // Ensure the id is passed correctly
            refetch();
            handleMenuToggle(id)
            toast.success('Position removed successfully');
        } catch (error) {
            toast.error('Failed to remove position',error);
        }
    }
    
    const handleAddUser = () => {
        console.log('inside handleAddUser performing refetch')
        refetch();
    };


    return (
        <div className='faculty-home-container'>
                  <ToastContainer 
                    theme= "colored"
                  />
            <ellipse className='ellipseDesignTop1' />
            {/* <ellipse className='ellipseDesignTop2' /> */}
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">Positions</h2>
                <p className="faculty-home-header-description" style={{marginBottom:'0'}}>Manage your positions here.</p>
                <button style={{marginLeft:'auto'}} className="green-button" onClick={() => setIsModalOpen(!isModalOpen)}>+ New Position</button>
            </div>
            <seperator style={{marginTop:'.5vh', marginBottom:'.5vh'}} className="faculty-home-header-seperator"/>

            {loadingWorkflows && <Spinner />}
            {errorWorkflows && <p>Error: {errorWorkflows.message}</p>}
            <TeamMembersTable columns={columns} data={data} />
            <InviteUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onAddUser={handleAddUser}/>
            {/* {editingUser && <UpdateRoleModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} user={editingUser} onEditUser={handleEditUser}/>} */}
            {editingUser && <UpdateRoleModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} user={editingUser}/>}
           
        </div>
    );
}

export default Positions;

