import React,{useState,useContext, useEffect} from "react";
import "./index.css";
import Card from "../../../components/common/card"
import { updateUserMutation, updateUserPasswordMutation, revokeUserMutation, addUserToAccountAccessMutation } from './graphql/mutations';
import { getUserById, getUserWithAccountUsersById, getUsersQuery } from './graphql/queries';
import {useQuery} from '@apollo/client';
import client from '../../../config/apolloClient';
import { UserContext } from '../../../context/userContext';
import AccountsTable from './accountsTable';
import { useNavigate } from 'react-router-dom';
import Spinner from "../../common/spinner";
import Select from 'react-select';
import { ProgramIdContext } from '../../faculty/progIdContext';
import { InstitutionIdContext } from '../institutionIdContext';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import packageInfo from '../../../../package.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Settings = ({ setPage }) => {
    const { user, topUser, setUser, setTopUser } = useContext(UserContext);
    console.log(user,'user in the settings')
    const navigate = useNavigate();
    const institution = useContext(InstitutionIdContext);
    console.log(institution, 'institution in settings')
    const institutionId = institution.id;
    const institutionName = institution.name;
    const [userData, setUserData] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    // const [institution, setInstitution] = useState("")
    const [currentPass, setCurrentPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [accountUsers, setAccountUsers] = useState([])
    const [optionalUsers, setOptionalUsers] = useState([])
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState({})
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);


    const programId = useContext(ProgramIdContext);






    const updateFormData = () => {
        if(userData) {
            setFirstName(userData.firstName)
            setLastName(userData.lastName)
            setEmail(userData.email)
            setPhone(userData.phone)
            // setInstitution(userData.institution)

        }
    }

    const updateData = () => {
        client.mutate({
            mutation: updateUserMutation,
            variables: {
                id: user.userId,
                input: {
                    firstName,
                    lastName,
                    email,
                    phone,
                    institution
                }
            }
        })
        .then(res => {
            toast.success('Personal Information Changed Successfully');
            setEditSuccess(true)
            setTimeout(() => {
                setEditSuccess(false)
            }, 3000);
        }).catch(err => {
            toast.error('Failed to update personal information');
        }
        )
    }

    React.useEffect(() => {
        updateFormData()
    }, [userData])


    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        console.log("file =>", file)
        const previewImg = document.getElementById('previewImage');
        if(file) {
            const reader = new FileReader();
        
            reader.onload = function(e) {
              previewImg.src = e.target.result; // Set the src attribute of the img tag to the data URL of the selected file
            };
            const fileInput = document.getElementById('imageInput');

            reader.readAsDataURL(fileInput.files[0]);

            const formData = new FormData();
            formData.append('image', file);
            formData.append('loginToken', localStorage.getItem('login_token'));
    
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_profile`, {
                method: 'POST',
                headers: {'App-Version': packageInfo.version},
                body: formData
              });
            const data = await response.json();
            console.log('pdf uploaded successfully:');
        }

      
      
    }



    const updateUserPassword = async () => {
        if(newPass !== confirmPass) {
            // alert password different
            console.log("different pass!")
        } else {
            client.mutate({
                mutation: updateUserPasswordMutation,
                variables: {
                    id: user.userId,
                    previousPass: currentPass,
                    newPass
                }
            })
            .then(res => {
                toast.success('Password Changed Successfully');
                setPasswordChanged(true)
                setTimeout(() => {
                    setPasswordChanged(false)
                }, 5000);
            }).catch(err => {
                toast.error('Failed to update password', err);
            }
            )
        }
    }

    console.log("user in settings =>", userData)



  
    return (
        <div className='applicant-home-container'>
              <ToastContainer 
                    theme= "colored"
                  />
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">EDIT PROFILE</h2>
                <seperator className="applicant-home-header-seperator"/>
            </div>
               
                <img id="previewImage" src={userData.profilePhotoUrl ? userData.profilePhotoUrl : "https://via.placeholder.com/282"} alt="profile" className="settings-profile-image"/>
                <input type="file" id="imageInput" accept="image/*" onChange={handleImageChange} style={{marginBottom:'4vh'}}/>
                <div className='settings-container'>
                <h2 className="settings-body-top">Edit Personal Information</h2>
                { editSuccess && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388"}}><label>Personal Information Changed Successfully.</label></div> }
                    <row className='settings-row'>
                         <column className='settings-column'>
                             {console.log("firstName =>", firstName)}
                                <label className='settings-label' htmlFor="firstName">First Name</label>
                                <input className="settings-input"  value={user.firstName} type="firstName" id="firstName" disabled={!isEditable} onChange={(e) => setFirstName(e.target.value)} />
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="lastName">Last Name</label>
                                <input className="settings-input" value={user.lastName} type="lastName" id="lastName" disabled={!isEditable} onChange={(e) => setLastName(e.target.value)}/>
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="email">Email Address</label>
                                <input className="settings-input" value={user.email} type="email" id="email" disabled={!isEditable} onChange={(e) => setEmail(e.target.value)}/>
                            </column>
                    </row>

                    <row className='settings-row'>
                    <column className='settings-column'>
                                <label className='settings-label' htmlFor="phoneNumber">Phone Number</label>
                                {/* <input className="settings-input" value={user.phone} type="phoneNumber" id="phoneNumber" disabled={!isEditable} onChange={(e) => setPhone(e.target.value)}/> */}
                                <PhoneInput
                                    style={{background:'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff'}}
                                    international
                                    defaultCountry="US"
                                    value={phone}
                                    onChange={newPhone => setPhone(newPhone)}
                                    className='application-form-standard-input'
                                    disabled={!isEditable}
                                    />
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">Institution</label>
                                <input className="settings-input" value={institutionName} type="institution" id="institution"  disabled={!isEditable}/>
                            </column>
                    </row>
                </div>
                <row className='settings-row1'>
                {!isEditable &&(<button className="edit-button" onClick={()=>setIsEditable(!isEditable)}>Edit</button>)}
                {isEditable &&(<button className="edit-button" 
                disabled={firstName === "" || lastName === "" || email === "" || phone === "" || institution === ""}
                onClick={()=>{
                    setIsEditable(!isEditable)
                    updateData()
                }}>Save</button>)}
                {isEditable &&(<button className="edit-button" onClick={()=>setIsEditable(!isEditable)} style={{color:'#145388', border:' solid #145388', backgroundColor:'white', position:'relative', }}>Cancel</button>)}
                </row>
                

                <h2 className="settings-body-top">Change Password</h2>
                { passwordChanged && <div style={{ display: 'flex', justifyContent: 'flex-end', color: "#145388"}}><label>Password Changed Successfully.</label></div> }
                <row className='settings-row'>
                    <column className='settings-column'>
                                <label className='settings-label' htmlFor="phoneNumber">Current Password</label>
                                <input className="settings-input" type="password" id="password"  value={currentPass} onChange={(e) => setCurrentPass(e.target.value)}/>
                            </column>

                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">New Password</label>
                                <input className="settings-input" type="password" id="password"  value={newPass} onChange={(e) => setNewPass(e.target.value)}/>
                            </column>
                            <column className='settings-column'>
                                <label className='settings-label' htmlFor="institution">Confirm Password</label>
                                <input className="settings-input" type="password" id="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)}/>
                            </column>
                </row>
                <row className='settings-row1'>
                <button className="edit-button" onClick={updateUserPassword}>Update Password</button>
                </row>



        </div>
    );
}

export default Settings;

