import React from 'react';
import { useParams } from "react-router-dom";
import JitsiMeeting from './JitsiMeeting';

const VideoComp = () => {
    const jitsiAPI = process.env.REACT_APP_JITSI_API;
    const { token } = useParams();
    const jwtToken = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InZwYWFzLW1hZ2ljLWNvb2tpZS0zOTQyYjlmNmQ1NDQ0NzhkYjE2MDc2NTIwMDVmM2QxMy9jMjhiMDQifQ.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtMzk0MmI5ZjZkNTQ0NDc4ZGIxNjA3NjUyMDA1ZjNkMTMiLCJyb29tIjoiKiIsImNvbnRleHQiOnsiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJpZCI6ImphY29iIiwibmFtZSI6IkphY29iIEdvdHRoZWxmIiwiZW1haWwiOiJqYWNvYkAzcmRmcmlkYXkuY29tIn19LCJpYXQiOjE3MzYzMzczMTV9.KdcmI68ecuq7cts-ERyAaZxSQfEiQMGzinL7lfPt7BTJO9v-L-nIk7S2T7x-rI4uzFwktdVdEcBSZAcE4gpdncyTmvdKkL9g8UsRa0fFyxZVEtgifBmBesz51O2etCYHXVPlZmQLWEjvT4vrGBbcN86sghwAr7d7xDXlrZaXFtQimn6l242Gtv9Ay-Z1UJYZbc3VMvnjS_7Xp0U2-XHSYmJms5TLqDXwH8PaM18_pniSyEypCK8X9t4vYfvxn9ijOT4L6vjFDmxW7k4vr0GtiSfbcv_AoUh-Fqhmheon91VMZtm0_EiRq8xnxLnhxqaaMfXv0-CZKmkBkP4pW9PrTA';
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <JitsiMeeting
        // roomName={`${process.env.JITSI_API}/${token}`}
        roomName={`${jitsiAPI}/${token}`}
        // roomName={`${process.env.JITSI_API}/SampleAppHandyOddsChainProbably`}
        jwt={jwtToken}
      />
    </div>
  );
};

export default VideoComp;
