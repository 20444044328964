import React, { useState, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import {useMutation} from '@apollo/client';
import {INVITE_USER} from './graphql/mutations';
import { ProgramIdContext } from "../../progIdContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const InviteUserModal = ({ isOpen, onClose, onAddUser }) => {
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isReviewerAutoSelected, setIsReviewerAutoSelected] = useState(false);
    const [inviteUser, { data, loading, error }] = useMutation(INVITE_USER);
    const programId = useContext(ProgramIdContext);

if (!isOpen) return null;

const handleSubmit = async (event) => {
    event.preventDefault();
    const newUser = {
        programId: programId,
        firstName: firstName,
        lastName: lastName,
        // userName: 'Pending Invitation',
        email: email,
        roles: roles,
        // action: 'action',
    };
    try {
        const result = await inviteUser({ variables: { input:newUser } });
       toast.success('User invited successfully');

        onAddUser()
        onClose();
    } catch (err) {
        toast.error('Error inviting user',err);
    }

    setRoles([]); // reset roles
    setEmail(''); // reset email

}

const handleRoleChange = (e) => {
    const { value, checked } = e.target;
        setRoles((prevRoles) =>
          checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
        );
};


return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <ToastContainer
    theme='colored'
    />
    <div className='add-field-modal-header'>
    <h2>INVITE A MEMBER</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>
    {error && <p style={{ color: 'red' }}>{error.message}</p>}
    <form onSubmit={handleSubmit}>
        <div className="form-group">
        <label style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#848484',
            lineHeight: '24px',
            marginBottom: '19px',
        }}>Enter the first name of the team member you would like to invite.</label>
        <input
            type="text"
            className="application-form-standard-input"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
        />

        <label style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#848484',
            lineHeight: '24px',
            marginBottom: '19px',
        }}>Enter the last name of the team member you would like to invite.</label>
        <input
            type="text"
            className="application-form-standard-input"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
        />
            <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '19px',
            }}>Enter the email address of the team member you would like to invite.</label>
          <input
            type="email"
            placeholder="Email"
            className="application-form-standard-input"
            onChange={(e) => setEmail(e.target.value)}
        />
 <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '2vh',
                marginTop: '2vh'    
            }}>Select roles</label>
            <div className='role-selection-container' style={{display:'flex', flexDirection:'row'}}>

            <div style={{display:'flex'}}>
            <input type="checkbox" id="PD" name="HRAdmin" value="HRAdmin" 
            checked={roles.includes('HRAdmin')} onChange={handleRoleChange} />
            <label style={{marginBottom:'0'}} for="role1">HR Admin</label>
            </div>

            <div style={{display:'flex'}}>
            <input type="checkbox" id="HRS" name="HRS" value="HRS"  checked={roles.includes('HRS')} onChange={handleRoleChange}
            />
            <label  style={{marginBottom:'0'}}  for="role2">HR Sourcer</label>
            </div>
            


            <div style={{display:'flex'}}>
            <input type="checkbox" id="HRR" name="HRR" value="HRR" 
            checked={roles.includes('HRR')} onChange={handleRoleChange}   style={{
                accentColor: roles.includes('HRR') ? (isReviewerAutoSelected ? '#8f8686' : '#145388') : 'transparent', }}
            />
            <label style={{marginBottom:'0'}}  for="role3">HR Recruiter</label>
            </div>

            <div style={{display:'flex'}}>
            <input type="checkbox" id="HRC" name="HRC" value="HRC" 
            checked={roles.includes('HRC')} onChange={handleRoleChange}
            />
            <label style={{marginBottom:'0'}}  for="role4">HR Compensation</label>
            </div>

            <div style={{display:'flex'}}>
            <input type="checkbox" id="HM" name="HM" value="HM" 
            checked={roles.includes('HM')} onChange={handleRoleChange}
            />
            <label style={{marginBottom:'0'}}  for="role5">Hiring Manager</label>
            </div>

            </div>
        </div>

        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
        <button type="submit" className='gradient-button' 
        disabled={email === "" || roles.length  === 0 || firstName === "" || lastName === ""}
        style={{fontWeight:'500'}}>Send Invite</button>
            <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
        </div>
    </form>
    
</div>
</div>
)
};

export default InviteUserModal;