import { gql } from "@apollo/client";

export const GET_CAREER_FAIRS = gql`
  query GetCareerFairs($programId: ID!) {
    getCareerFairs(programId: $programId) {
    id
    name
    }
  }
`;


export const GET_PROGRAM_USERS_BY_PROGRAM_ID = gql`
query getProgramMembersByProgramId($programId: ID!) {
  getProgramMembersByProgramId(programId: $programId) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;

export const LIST_POSITIONS = gql`
  query ListPositions($programId: ID!) {
    listPositions(programId: $programId) {
      id
      programId
      jobTitle
      summary
      numberOfOpenings
      positionStatus
    }
  }
`;


export const LIST_POSITIONS_NAMES = gql`
  query ListPositions($programId: ID!) {
    listPositions(programId: $programId) {
      id
      jobTitle
    }
  }
`;


export const getApplicationsPaginationQuery = gql`
    query getApplicationsPagination($userId: ID, $programId:ID, $limit: Int, $offset: Int, $filters: FilterInput, $search:ApplicationSearch, $sort: ApplicationSort, $insideGroups: Boolean, $applicationsIds:[ID],$positionId:ID) {
        getApplicationsPagination(userId: $userId, programId:$programId limit: $limit, offset: $offset, filters: $filters, search:$search, sort: $sort, insideGroups:$insideGroups, applicationsIds: $applicationsIds, positionId:$positionId) {
            id
            medicalSchoolState
            region
            signalEssay
            archived
            reviewDetails
            signalType
            watchUsersIds
            createdAt
            applicationRubrics {
                label
                inputType
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                result 
            }
            userId {
                _id
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                graduationYear
                graduationYearUpdated
                mspe {
                    docUrl
                }
                transcript {
                    docUrl
                }
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    partnerFeild
                    dateOfBirth
                    homeTown
                    homeAddress
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    medicalSchoolType
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    fluentLanguages
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipType
                    otherCkMethod
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }
    
                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            isSignal
            selectedTracks {
                trackName
            }
            scoreId {
                usmleStep1Score
                comlexLevel1Score
                usmleStep2Score
                usmleStep3Score
                comlexLevel2Score
                previousUsmleComlexFails
            }
            masterApplicationId {
                _id
                previouslyAppliedToSpecialty
                applicationYear
                carryingOutResponsibilities
                carryingOutResponsibilitiesElaboration
                status
                researchExperience {
                    category
                    title
                    authors
                    journal
                    datePublished
                    pubMedId
                    isFirstAuthor
                    significantResearchExperience
                    meeting
                    meetingDate
                    order
                }
                workExperiences {
                    employer
                    jobTitle
                    startDate
                    endDate
                    mentorName
                    mentorEmail
                    description
                }
                meaningfulExperiences {
                    category
                    title
                    startDate
                    endDate
                    description
                }
                hobbiesAndInterests
                shortAnswerQuestions {
                    question
                    answer
                }
                personalStatement
            }
        }
    }
`