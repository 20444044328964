// src/components/SchedulingScreen.js
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { format, isSameDay } from 'date-fns';
import { useQuery, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { getAvailableDatesForMonthQuery, getInviteByTokenQuery, getAvailableSlotsForDayQuery, getEventQuery } from './graphql/queries';
import { SCHEDULE_EVENT, RESCHEDULE_EVENT, CANCEL_EVENT } from './graphql/mutations';
// import {EVENT_UPDATED_SUBSCRIPTION} from './graphql/subscriptions';
import Modal from './Modal';
import SuccessMessage from './SuccessMessage';
import logo from "../../../assets/imgs/logo.svg";

const SchedulingScreen = () => {
    const { token } = useParams();
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [hostInfo, setHostInfo] = useState(null);
    const [availableDates, setAvailableDates] = useState([]);
    const [timeZoneView, setTimeZoneView] = useState('host');
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [scheduledEvent, setScheduledEvent] = useState(null);
    const [openReschedule, setOpenReschedule] = useState(false);

    const { data: inviteData, refetch: refetchInvite } = useQuery(getInviteByTokenQuery, {
        variables: { token },
    });

    const { refetch: refetchAvailableDates } = useQuery(getAvailableDatesForMonthQuery, {
        skip: !hostInfo,
        variables: {
            hostId: inviteData?.getInviteByToken.invitedBy.id,
            month: currentMonth,
            year: currentYear,
        },
        onCompleted: (data) => setAvailableDates(data.getAvailableDatesForMonth),
    });


    useEffect(() => {
        console.log('availableDates:', availableDates);
    }, [availableDates]);

    const [fetchAvailableSlots, { loading: isLoadingSlots, data: slotsData, refetch:refetchASlots }] = useLazyQuery(getAvailableSlotsForDayQuery);

    const { data: eventData } = useQuery(getEventQuery, {
        skip: inviteData?.getInviteByToken.status !== 'Scheduled' && inviteData?.getInviteByToken.status !== 'Rescheduled',
        variables: {
            hostId: inviteData?.getInviteByToken.invitedBy.id,
            guestId: inviteData?.getInviteByToken.invitedUser.id,
        },
        onCompleted: (data) => {
            setScheduledEvent(data.getEvent);
        },
    });

    useEffect(() => {
        if (inviteData) {
            const hostInfoToUpdate = {
                name: `${inviteData.getInviteByToken.invitedBy.firstName} ${inviteData.getInviteByToken.invitedBy.lastName}`,
                timeZone: inviteData.getInviteByToken.invitedBy.timeZone,
                invitationType: inviteData.getInviteByToken.invitationType,
                invitedUserName: `${inviteData.getInviteByToken.invitedUser.firstName} ${inviteData.getInviteByToken.invitedUser.lastName}`,
                invitedPosition: inviteData.getInviteByToken.invitedPosition.jobTitle,
                programName: inviteData.getInviteByToken.invitedProgram.name,
            };
            setHostInfo(hostInfoToUpdate);
        }
    }, [inviteData]);

    useEffect(() => {
        if (slotsData) {
            setAvailableSlots(slotsData.getAvailableSlotsForDay);
        }
    }, [slotsData]);

    useEffect(() => {
        if (hostInfo) {
            refetchAvailableDates({
                hostId: inviteData?.getInviteByToken.invitedBy.id,
                month: currentMonth,
                year: currentYear,
            });
        }
    }, [currentMonth, currentYear, hostInfo]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        fetchAvailableSlots({
            variables: {
                hostId: inviteData.getInviteByToken.invitedBy.id,
                date: format(date, 'yyyy-MM-dd'),
            },
        });
    };

    const handleSlotSelect = (slot) => {
        setSelectedSlot(slot);
        setConfirmationModal(true);
    };

    const handleMonthChange = (direction) => {
        if (direction === 'prev') {
            if (currentMonth === 1) {
                setCurrentMonth(12);
                setCurrentYear(currentYear - 1);
            } else {
                setCurrentMonth(currentMonth - 1);
            }
        } else {
            if (currentMonth === 12) {
                setCurrentMonth(1);
                setCurrentYear(currentYear + 1);
            } else {
                setCurrentMonth(currentMonth + 1);
            }
        }
    };


// We have an issue with the subs—there’s no way to connect it to the authentication—because
//  the schedule page is anonymous and doesn’t have a token. 
// We need to think of a solution; for now, it’s in the backlog.

//     const { data: subscriptionData } = useSubscription(EVENT_UPDATED_SUBSCRIPTION, {
//     variables: { userId: inviteData?.getInviteByToken.invitedBy.id },
//     onSubscriptionData: ({ subscriptionData }) => {
//         if (subscriptionData.data) {
//             console.log('Event updated:', subscriptionData.data);
//             refetchAvailableDates();
//             refetchASlots();
//         }
//     },
// });

    const [scheduleEvent] = useMutation(SCHEDULE_EVENT);
    const [rescheduleEvent] = useMutation(RESCHEDULE_EVENT);
    const [cancelEvent] = useMutation(CANCEL_EVENT);

    const handleCancelEvent = async () => {
        try {
            await cancelEvent({
                variables: {
                    eventId: scheduledEvent.id,
                },
            }).then(() => {
                setSuccessMessage(true);
                setConfirmationModal(false);
                refetchInvite();
                refetchASlots()
                setScheduledEvent(null);
            });
        } catch (err) {
            console.error('Error canceling event:', err);
        }
    };


    const handleScheduleConfirm = async (applicantInfo) => {
        if (!openReschedule) {
        try {
            await scheduleEvent({
                variables: {
                    input: {                      
                    hostId: inviteData.getInviteByToken.invitedBy.id,
                    guestId: inviteData.getInviteByToken.invitedUser.id,
                    date: selectedSlot.date,
                    startTime: selectedSlot.startTime,
                    endTime: selectedSlot.endTime,
                    },
                },
            }).then(() => {
                setSuccessMessage(true);
                setConfirmationModal(false);
                refetchInvite();
                refetchASlots()
            });
        } catch (err) {
            console.error('Error scheduling event:', err);
        }
    } else {
        try {
            await rescheduleEvent({
                variables: {
                    input: {
                    eventId: scheduledEvent.id,
                    hostId: inviteData.getInviteByToken.invitedBy.id,
                    guestId: inviteData.getInviteByToken.invitedUser.id,
                    date: selectedSlot.date,
                    startTime: selectedSlot.startTime,
                    endTime: selectedSlot.endTime,
                    },
                },
            }).then(() => {
                setSuccessMessage(true);
                setConfirmationModal(false);
                refetchInvite();
                refetchASlots()
            });
        } catch (err) {
            console.error('Error rescheduling event:', err);
        }
    }

    };

    const handleTimeZoneChange = (event) => {
        setTimeZoneView(event.target.value);
    };

    return (
        <div className="p-4 sm:p-6 max-w-4xl mx-auto bg-gray-50 shadow-md rounded-lg">
            <header className="text-center mb-8">
                <img src={logo} alt="logo" className="w-25 mx-auto" />
                <h1 className="text-2xl sm:text-3xl font-bold text-blue-600">Schedule with {hostInfo?.name}</h1>
                {hostInfo?.timeZone && (
                    <div className="mt-4">
                        <p className="text-gray-700">
                            All times shown in {timeZoneView === 'host' ? `${hostInfo.name}'s time zone (${hostInfo.timeZone})` : 'your local time'}.
                        </p>
                        <select
                            value={timeZoneView}
                            onChange={handleTimeZoneChange}
                            className="mt-2 p-2 border rounded-md bg-white shadow-sm focus:ring focus:ring-blue-300 w-full sm:w-auto"
                        >
                            <option value="host">Host Time Zone</option>
                            <option value="local">Your Local Time Zone</option>
                        </select>
                    </div>
                )}
            </header>

            {scheduledEvent && !openReschedule ? (
                <div className="bg-white p-6 shadow rounded-lg">
                    <h2 className="text-xl font-semibold text-blue-600 mb-4">Your Scheduled {hostInfo.invitationType==='phoneScreen'?'Phone Interview':'Interview'}</h2>
                    <p><strong>Date:</strong> {format(new Date(scheduledEvent.date), 'MMM d, yyyy')}</p>
                    <p><strong>Time:</strong> {scheduledEvent.startTime} - {scheduledEvent.endTime}</p>
                    <p><strong>Host:</strong> {hostInfo?.name}</p>
                    <div className="mt-4">
                        <button className="p-2 bg-red-500 text-white rounded-lg shadow-md" onClick={handleCancelEvent}>Cancel</button>
                        <button className="p-2 bg-blue-500 text-white rounded-lg shadow-md ml-4" onClick={()=>setOpenReschedule(true)}>Reschedule</button>
                    </div>
                </div>
            ) : (
                <div>
                     {successMessage && (
                <SuccessMessage
                    message={`Your ${hostInfo.invitationType==='phoneScreen'?'Phone Interview':'Interview'} on ${format(selectedDate, 'MMM d, yyyy')} at ${selectedSlot.startTime} - ${selectedSlot.endTime} (${timeZoneView === 'host' ? selectedSlot.hostTime : selectedSlot.applicantTime}) is confirmed.`}
                />
            )}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <aside className="bg-white p-4 shadow rounded-lg">
                    <h2 className="text-lg sm:text-xl font-semibold text-blue-500 mb-4">Select a Date</h2>
                    <div className="flex flex-col gap-2 items-center mb-4">
                        <div className="flex items-center justify-center gap-4 w-full">
                            <button
                                onClick={() => handleMonthChange('prev')}
                                className={`p-2 px-4 rounded-lg shadow-md ${
                                    currentMonth === new Date().getMonth() + 1 && currentYear === new Date().getFullYear()
                                        ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                                }`}
                                disabled={currentMonth === new Date().getMonth() + 1 && currentYear === new Date().getFullYear()}
                            >
                                &lt; Prev
                            </button>
                            <h2 className="text-lg font-bold text-gray-800">
                                {format(new Date(currentYear, currentMonth - 1), 'MMMM yyyy')}
                            </h2>
                            <button
                                onClick={() => handleMonthChange('next')}
                                className="p-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg shadow-md"
                            >
                                Next &gt;
                            </button>
                        </div>
                        </div>
                        <div className="grid grid-flow-col auto-cols-max gap-3 overflow-x-auto sm:grid-cols-1 sm:grid-rows-4">
    {availableDates.map((date, index) => (
        <button
            key={index}
            onClick={() => handleDateChange(new Date(date))}
            className={`p-0 w-20 sm:w-24 text-center border rounded-lg font-medium text-sm transition-all ${
                isSameDay(new Date(date), selectedDate)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 hover:bg-blue-100'
            }`}
        >
            {format(new Date(date), 'EEE')}<br />{format(new Date(date), 'dd')}
        </button>
    ))}
</div>
                    </aside>

                    <section className="col-span-2 bg-white p-4 sm:p-6 shadow rounded-lg">
                        <h2 className="text-lg sm:text-xl font-semibold text-blue-500 mb-4">Available Time Slots</h2>
                        {isLoadingSlots ? (
                            <p className="text-gray-500">Loading slots...</p>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {availableSlots.map((slot) => (
                                    <button
                                        key={slot.startTime}
                                        onClick={() => handleSlotSelect(slot)}
                                        className="p-4 border rounded-lg bg-green-200 hover:bg-green-300 transition-all text-sm font-medium"
                                    >
                                        {timeZoneView === 'host' ? slot.hostTime : slot.applicantTime}<br />
                                        <span className="text-gray-600">{slot.startTime} - {slot.endTime}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </section>
                </div>
            </div>
            )}

            {confirmationModal && (
                <Modal
                    title="Confirm Your Booking"
                    onClose={() => setConfirmationModal(false)}
                >
                    {!openReschedule && (
                        <>
                    <p className="text-gray-700 mb-4">
                        {hostInfo.invitedUserName}, you are scheduling {hostInfo.invitationType==='phoneScreen'?'a phone interview':'an interview'} with {hostInfo.name} for the position of {hostInfo.invitedPosition} at {hostInfo.programName}.
                    </p>
                    <p className="text-gray-700 mb-4">
                        {selectedSlot.startTime} - {selectedSlot.endTime} ({timeZoneView === 'host' ? selectedSlot.hostTime : selectedSlot.applicantTime}) on {format(selectedDate, 'MMM d, yyyy')}.
                    </p>
                    </>
                    )}
                    {openReschedule && (
                        <>
                    <p className="text-gray-700 mb-4">
                        {hostInfo.invitedUserName}, you are rescheduling {hostInfo.invitationType==='phoneScreen'?'a phone interview':'an interview'} with {hostInfo.name} for the position of {hostInfo.invitedPosition} at {hostInfo.programName}.
                    </p>
                    <p className="text-gray-700 mb-4">
                        Original time: {scheduledEvent.startTime} - {scheduledEvent.endTime} ({timeZoneView === 'host' ? scheduledEvent.hostTime : scheduledEvent.applicantTime}) on {format(new Date(scheduledEvent.date), 'MMM d, yyyy')}.
                    </p>
                    <p className="text-gray-700 mb-4">
                        New time: {selectedSlot.startTime} - {selectedSlot.endTime} ({timeZoneView === 'host' ? selectedSlot.hostTime : selectedSlot.applicantTime}) on {format(selectedDate, 'MMM d, yyyy')}.
                    </p>
                    </>
                    )}
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const applicantInfo = {
                                name: formData.get('name'),
                                email: formData.get('email'),
                            };
                            handleScheduleConfirm(applicantInfo);
                        }}
                    >
                        <button
                            type="submit"
                            className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
                        >
                            Confirm Booking
                        </button>
                    </form>
                </Modal>
            )}

           
        </div>
    );
};

export default SchedulingScreen;
