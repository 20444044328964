import { gql } from "@apollo/client";

export const GET_CAREER_FAIRS = gql`
  query GetCareerFairs($programId: ID!) {
    getCareerFairs(programId: $programId) {
    id
    programId
    name
    date
    location
    description
    formLink
    createdAt
    qrCode
    isVirtual
    virtualEventLink
    eventTime
    }
  }
`;