import { gql } from '@apollo/client';

export const GET_SCORE_BY_APPLICANTID = gql`
query GetScoreByApplicantId($applicantId: ID!) {
    getScoreByApplicantId(applicantId: $applicantId) {
            id
            applicantId
            uploaderId
            stage
            usmleStep1Score
            comlexLevel1Score
            usmleStep2Score
            usmleStep3Score
            comlexLevel2Score
            previousUsmleComlexFails
            usmleComlexDescription
            usmleDocs
            comlexDocs
            scoresVerified
            requestedAt
            submittedAt
            usmleScoresVerified
            comlexScoresVerified
            medicalSchoolEmail
            mspeReqEmailAdd
        }
    }
`

export const getApplicantCountByInstitutionId = gql`
    query getApplicantCountByInstitutionId($institutionId: ID!, $search: SearchInput) {
        getApplicantCountByInstitutionId(institutionId:$institutionId, search:$search)
    }
`


export const getApplicantsByInstitutionId = gql`
    query getApplicantsByInstitutionId($institutionId: ID!, $search: SearchInput, $first: Int, $limit: Int, $sort: SortInput) {
        getApplicantsByInstitutionId(institutionId:$institutionId, search:$search, first:$first, limit:$limit, sort:$sort) {
            id
            firstName
            lastName
            email
            isVerified 
            status
            profilePhotoUrl
            createdAt
            graduationYear
            graduationYearUpdated
            applicationInformation {
                nrmpId
                workAuthorization
                gender
                otherRaceEthnicity
                otherWorkAuthorization
                partnerName
                dateOfBirth
                homeTown
                homeAddress
                ethnicity
                coupleMatch
                medicalSchoolName
                aoaStatus
                goldHumanism
                firstAuthorPublications
                peerReviewedPublications
                posterPresentations
                podiumPresentations
                aamc
                previousLastName
                prefferedName
                linkedinLink
                pronouns
                usmleId
                nbomeId
                aoaId
                permanentAddress
                licensureRevoked
                licensureRevokedDetails
                militaryObligation
                militaryObligationDetails
                otherServiceObligations
                medicalTrainingInterrupted
                medicalTrainingInterruptedDetails
                sigmaPhi
                malpractice
                awardsAndHonors
                isLanguages
                fluentLanguages
                misdemeanorOrFelony
                misdemeanorOrFelonyDetails
                higherEducation {
                    institution
                    degree
                    date
                }
                coreClerkshipType
                otherCkMethod
                coreClerkshipGrades {
                    clerkshipEm
                    clerkshipFm
                    im
                    neurology
                    obgyn
                    pediatrics
                    psychiatry
                    surgery
                }

                awayRotations {
                    institution
                    degree
                    dateCompleted
                }
                nonCoreClerkshipGrades {
                    course
                    grade
                }
            }
            lors {
                id
                recommenderEmail
                recommenderName
                stage
            }
            mspe {
                id
                stage
                docUrl
            }
            transcript {
                id
                stage
                docUrl
            }
            scores {
                id
                stage
                usmleStep1Score
                comlexLevel1Score
                usmleStep2Score
                usmleStep3Score
                comlexLevel2Score
                mspeReqEmailAdd
            }
            ecfmgStatusReport {
                id
                stage
                docUrl
            }
        }
    }

`;

export const getAllLorsByUserIdQuery = gql`
    query getAllLorsByUserId($applicantId: ID!){ 
        getAllLorsByUserId(applicantId: $applicantId) {
            id
            recommenderEmail
            recommenderName
            stage
            applicationId
            isSlor
            
        }
    }
`

export const getApplicationsByApplicantIdQuery = gql`
    query getApplicationsByApplicantId($applicantId: ID!) {
        getApplicationsByApplicantId(applicantId: $applicantId) {
            id
            programId {
                id
                name

            }
            specialtyId {
                id
                name
            }
            createdAt
            applicationStatus
            personalStatement
            isSignal
        }
    }
`

