import { gql } from "@apollo/client";

export const CREATE_RUBRIC_TEMPLATE = gql`
  mutation CreateRubricTemplate($programId: ID!, $input: RubricTemplateInput!) {
    createRubricTemplate(programId: $programId, input: $input) {
      id
      rubricTemplate{
        id
        label
        inputType
        isPreInterview
        maxScale
        lowestScaleText
        highestScaleText
        dropdownOptions {
          option
          score
        }
        text
        order
      }
    }
  }
`;

export const UPDATE_RUBRIC_TEMPLATE = gql`
  mutation UpdateRubricTemplate($programId: ID!, $rubricTemplateId: ID!, $input: RubricTemplateInput!) {
    updateRubricTemplate(programId: $programId, rubricTemplateId: $rubricTemplateId, input: $input) {
      id
    }
  }
`;

export const DELETE_RUBRIC_TEMPLATE = gql`
  mutation DeleteRubricTemplate($programId: ID!, $rubricTemplateId: ID!) {
    deleteRubricTemplate(programId: $programId, rubricTemplateId: $rubricTemplateId) {
      id
      rubricTemplate{
        id
        label
        inputType
        isPreInterview
        maxScale
        lowestScaleText
        highestScaleText
        dropdownOptions {
          option
          score
        }
        text
        order
    }
    }
  }
`;


export const createGroupMutation = gql`
  mutation createGroup($input: CreateGroupInput!){
    createGroup(input: $input) {
      id
    }
  }
`

export const deleteGroupMutation = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`

export const updateGroupMutation = gql`
  mutation updateGroup($id: ID!, $input: UpdateGroupInput!) {
    updateGroup(id: $id, input: $input) {
      id
      name
      description
      membersIds
      applicationIds
      createdAt
    }
  }
`

export const updateGroupsApplicationIds = gql`
  mutation updateGroupsApplicationIds($groupIds: [ID!], $applicationIds: [ID!]) {
    updateGroupsApplicationIds(groupIds: $groupIds, applicationIds:$applicationIds) {
      id
      name
      description
      membersIds
      applicationIds
      createdAt
    }
  }
`

export const rejectApplicationMutation = gql`
  mutation rejectApplication($applicationId: ID!, $reviewerId: ID!) {
    rejectApplication(applicationId: $applicationId, reviewerId: $reviewerId) {
      id
    }
  }
`



export const UPDATE_SCREENED_DATA = gql`
    mutation UpdateScreenedData($programId: ID!, $input: ScreenedDataInput!) {
        updateScreenedData(programId: $programId, input: $input) {
            id
            screenedData {
                sections {
                    personalInfo
                    residencyApplication
                    personalStatement
                    documents
                    lors
                }
                personalInfo {
                    profilePicture
                    firstName
                    lastName
                    email
                    phoneNumber
                    genderIdentity
                    nrmpId
                    aamcId
                    hometown
                    raceEthnicity
                    homeAddress
                    workAuthorization
                    preventResponsibilities
                    elaborateResponsibilities
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    couplesMatch
                    partnerField
                    previouslyApplied
                    malpracticeLawsuit
                    elaborateMalpractice
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    militaryObligation
                    otherServiceObligations
                    medicalTrainingInterrupted
                    sigmaPhi
                }
                residencyApplication {
                    medicalSchoolType
                    medicalSchoolName
                    fluencyInLanguagesBesidesEnglish
                    otherLanguagesFluentIn
                    otherDegreesInstitutionsDates
                    aoaStatus
                    ghhsStatus
                    medicalSchoolAwardsHonors
                    coreClerkshipsGraded
                    specifyClerkshipDetails
                    clerkshipGrades
                    awayRotations
                    nonCoreClerkshipGradedCourses
                    impactfulResearchPublications
                    additionalResearchBibliography
                    firstAuthorPublications
                    totalPeerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    meaningfulExperiences
                    significantWorkExperiences
                    shortQuestions
                    hobbiesInterests
                }
            }
        }
    }
`;

export const updateApplicationArchivedMutation = gql`
    mutation updateApplicationArchived($applicationId: ID!, $archived: Boolean) {
      updateApplicationArchived(applicationId: $applicationId, archived: $archived) {
        id
      }
    }
`

export const updateUserWatchApplicationMutation = gql`
  mutation updateUserWatchApplication($applicationId: ID!, $userId: ID!) {
    updateUserWatchApplication(applicationId: $applicationId, userId: $userId) {
      id
    }
  }
`
export const createFilter = gql`
    mutation createFilter($programId:ID! $input: FilterInput!) {
        createFilter(programId:$programId, input: $input) {
            filterName
        }
    }
`

export const removeFilter = gql`
    mutation removeFilter($programId: ID!, $filterId: ID!) {
        removeFilter(programId: $programId, filterId: $filterId) {
          id
        }
    }
`

export const updateSavedEditView = gql`
    mutation updateSavedEditView($userId: ID!, $view: [String]) {
        updateSavedEditView(userId: $userId, view: $view) {
            id
        }
    }
`

export const lockRubricsMutation = gql`
  mutation lockRubrics($programId: ID!) {
    lockRubrics(programId: $programId) {
      id
      isRubricLocked
    }
  }
`

export const updateDropdownOptions = gql`
mutation updateDropdownOptions($programId: ID!, $dropdownLabel: String, $newOptions: String) {
  updateDropdownOptions(programId: $programId, dropdownLabel: $dropdownLabel, newOptions: $newOptions) {
    id
    rubricTemplate{
      id
      label
      inputType
      isPreInterview
      maxScale
      lowestScaleText
      highestScaleText
      dropdownOptions {
          option
          score
      }
      text
      order
      weight
    }
  }
}
`

export const updateRubricsWeight = gql`
mutation updateRubricsWeight($programId: ID!, $input: [RubricsWeightInput]) {
  updateRubricsWeight(programId: $programId, input: $input) {
    id
    rubricTemplate{
      id
      label
      inputType
      isPreInterview
      maxScale
      lowestScaleText
      highestScaleText
      dropdownOptions {
          option
          score
      }
      text
      order
      weight
    }
  }
}
`

export const removeApplicationFromGroupMutation = gql`
mutation removeApplicationFromGroup($groupId: ID!, $applicationId: ID!) {
  removeApplicationFromGroup(groupId: $groupId, applicationId: $applicationId) {
    id
    name
    description
    membersIds
    applicationIds
    createdAt
  }
}
`

export const removeAllApplicationsFromGroupMutation = gql`
mutation removeAllApplicationsFromGroup($groupId: ID!) {
  removeAllApplicationsFromGroup(groupId: $groupId) {
    id
    name
    description
    membersIds
    applicationIds
    createdAt
  }
}
`

export const inviteUserToApply = gql`
mutation inviteUserToApply($applicationId: ID!) {
  inviteUserToApply(applicationId: $applicationId)
}
`

export const updateApplicationStatus = gql`
mutation updateApplicationStatus($applicationId: ID!,$userId:ID, $status: String!) {
  updateApplicationStatus(applicationId: $applicationId, userId:$userId ,status: $status)
}
`