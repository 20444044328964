import React, { useRef } from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
  } from 'react-share';

const SMShareModal = ({ isOpen, onClose, body, virtualEventLink }) => {



    return (
        <div
            className="modal-backdrop"
            style={{
                zIndex: '25',
                display: isOpen ? 'flex' : 'none',
                flexDirection: 'row',
            }}
        >
            <div
                className="add-field-modal"
                style={{ height: '60vh', overflow: 'unset', width: '50vw', maxHeight: '100vh' }}
            >
                <div className="faculty-home-body" style={{ gap: '0', textAlign: 'center' }}>
                  
                <div
                    className="add-field-modal-footer"
                    style={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginTop: '5vh',
                    }}
                >
                    <FacebookShareButton
                        url={virtualEventLink}
                        quote={body}
                        hashtag="#virtualEvent"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <LinkedinShareButton
                        url={virtualEventLink}
                        title={body}
                        summary="Check out this virtual event!"
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <TwitterShareButton
                        url={virtualEventLink}
                        title={body}
                        hashtags={['#virtualEvent']}
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>
                    <button className="green-button" onClick={onClose}>
                        Close
                    </button>
                  
                </div>
            </div>
        </div>
    );
};

export default SMShareModal;
