import { gql } from "@apollo/client";

export const getProgramById = gql`
    query getProgramById($id: ID!) {
        getProgramById(id: $id) {
            id
            name
            description
             defaultLegalDisclaimers
            defaultEqualEmploymentOpportunityStatement
            isRubricLocked
            rubricTemplate{
                id
                label
                inputType
                isPreInterview
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                weight
            }

            completedReviewsSettings {
                title
                name
                highestRank
                rubricsOrder {
                    label
                    result
                    type
                }
            }

            screenedData {
                sections {
                    personalInfo
                    residencyApplication
                    personalStatement
                    documents
                    lors
                }
                personalInfo {
                    profilePicture
                    firstName
                    lastName
                    email
                    phoneNumber
                    genderIdentity
                    nrmpId
                    aamcId
                    hometown
                    raceEthnicity
                    homeAddress
                    workAuthorization
                    preventResponsibilities
                    elaborateResponsibilities
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    couplesMatch
                    partnerField
                    previouslyApplied
                    malpracticeLawsuit
                    elaborateMalpractice
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    militaryObligation
                    otherServiceObligations
                    medicalTrainingInterrupted
                    sigmaPhi
                }
                residencyApplication {
                    medicalSchoolType
                    medicalSchoolName
                    fluencyInLanguagesBesidesEnglish
                    otherLanguagesFluentIn
                    otherDegreesInstitutionsDates
                    aoaStatus
                    ghhsStatus
                    medicalSchoolAwardsHonors
                    coreClerkshipsGraded
                    specifyClerkshipDetails
                    clerkshipGrades
                    awayRotations
                    nonCoreClerkshipGradedCourses
                    impactfulResearchPublications
                    additionalResearchBibliography
                    firstAuthorPublications
                    totalPeerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    meaningfulExperiences
                    significantWorkExperiences
                    shortQuestions
                    hobbiesInterests
                }
            }
        }
    }
`;

export const calculateResultObjectQuery = gql`
query calculateResultObject($applicationsIds: [ID!]) {
    calculateResultObject(applicationsIds: $applicationsIds)
}
`
export const getCompletedReviewsCountQuery = gql`
    query getCompletedReviewsCount($userId: ID, $programId: ID!, $limit: Int, $offset: Int, $filter: FilterInput, $sort: SortInput) {
        getCompletedReviewsCount(userId: $userId, programId: $programId, limit: $limit, offset: $offset, filter: $filter, sort: $sort) 
    }
`

export const getAllCompletedReviewsQuery = gql`
query getAllCompletedReviews($userId: ID, $programId: ID!, $limit: Int, $offset: Int, $filter: FilterInput, $sort: SortInput, $advancedSorting: String) {
    getAllCompletedReviews(userId: $userId, programId: $programId, limit: $limit, offset: $offset, filter: $filter, sort: $sort, advancedSorting: $advancedSorting) {
        id
        reviewerId {
            firstName
            lastName
            email 
            phone
            profilePhotoUrl
            applicationInformation {
                nrmpId
                workAuthorization
                gender
                otherRaceEthnicity
                otherWorkAuthorization
                partnerName
                dateOfBirth
                homeTown
                ethnicity
                coupleMatch
                medicalSchoolName
                aoaStatus
                goldHumanism
                firstAuthorPublications
                peerReviewedPublications
                posterPresentations
                podiumPresentations
                aamc
                previousLastName
                prefferedName
                linkedinLink
                pronouns
                usmleId
                nbomeId
                aoaId
                permanentAddress
                licensureRevoked
                licensureRevokedDetails
                militaryObligation
                militaryObligationDetails
                otherServiceObligations
                medicalTrainingInterrupted
                medicalTrainingInterruptedDetails
                sigmaPhi
                malpractice
                awardsAndHonors
                isLanguages
                misdemeanorOrFelony
                higherEducation {
                    institution
                    degree
                    date
                }
                coreClerkshipGrades {
                    clerkshipEm
                    clerkshipFm
                    im
                    neurology
                    obgyn
                    pediatrics
                    psychiatry
                    surgery
                }

                awayRotations {
                    institution
                    degree
                    dateCompleted
                }
                nonCoreClerkshipGrades {
                    course
                    grade
                }
            }
        }
        applicationId {
            id
            isSignal
            userId {
                id
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    dateOfBirth
                    homeTown
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    fluentLanguages
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipType
                    otherCkMethod
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }
    
                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            programId
            scoreId {
                usmleStep1Score
                comlexLevel1Score
                usmleStep2Score
                usmleStep3Score
                comlexLevel2Score
            }
            specialtyId
            masterApplicationId {
                previouslyAppliedToSpecialty
                applicationYear
                carryingOutResponsibilities
                researchExperience {
                    journal
                    title
                    authors
                    datePublished
                }
                workExperiences {
                    employer
                    jobTitle
                    startDate
                    endDate
                    mentorName
                    mentorEmail
                    description
                }
                meaningfulExperiences {
                    category
                    title
                    startDate
                    endDate
                    description
                }
                hobbiesAndInterests
                shortAnswerQuestions {
                    question
                    answer
                }
                personalStatement
            }
            applicationStatus
            reviewDetails
            documents
            lors
            personalStatement
            rubricsResult {
                textResult
                dropdownResult
                scaleResult
            }
            applicationRubrics {
                label
                inputType
                isPreInterview
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                result
            }
        }
        programId {
            id
            name
            description
            specialtyId
        }
        stage
        rubric {
            label
            inputType
            isPreInterview
            maxScale
            lowestScaleText
            highestScaleText
            dropdownOptions {
                option
                score
            }
            text
            order
            result

        }
        comment
        reviewedAt
    }
}
`;
export const getAllCompletedNotPaginationQuery = gql`
query getAllCompletedNotPagination($userId: ID, $programId: ID!, $filter: FilterInput, $sort: SortInput, $advancedSorting: String) {
    getAllCompletedNotPagination(userId: $userId, programId: $programId, filter: $filter, sort: $sort, advancedSorting: $advancedSorting) {
        id
        reviewerId {
            firstName
            lastName
            email 
            phone
            profilePhotoUrl
            applicationInformation {
                nrmpId
                workAuthorization
                gender
                otherRaceEthnicity
                otherWorkAuthorization
                partnerName
                dateOfBirth
                homeTown
                ethnicity
                coupleMatch
                medicalSchoolName
                aoaStatus
                goldHumanism
                firstAuthorPublications
                peerReviewedPublications
                posterPresentations
                podiumPresentations
                aamc
                previousLastName
                prefferedName
                linkedinLink
                pronouns
                usmleId
                nbomeId
                aoaId
                permanentAddress
                licensureRevoked
                licensureRevokedDetails
                militaryObligation
                militaryObligationDetails
                otherServiceObligations
                medicalTrainingInterrupted
                medicalTrainingInterruptedDetails
                sigmaPhi
                malpractice
                awardsAndHonors
                isLanguages
                misdemeanorOrFelony
                higherEducation {
                    institution
                    degree
                    date
                }
                coreClerkshipGrades {
                    clerkshipEm
                    clerkshipFm
                    im
                    neurology
                    obgyn
                    pediatrics
                    psychiatry
                    surgery
                }

                awayRotations {
                    institution
                    degree
                    dateCompleted
                }
                nonCoreClerkshipGrades {
                    course
                    grade
                }
            }
        }
        applicationId {
            id
            isSignal
            userId {
                id
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    dateOfBirth
                    homeTown
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    linkedinLink
                    prefferedName
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    fluentLanguages
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipType
                    otherCkMethod
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }
    
                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            programId
            scoreId {
                usmleStep1Score
                comlexLevel1Score
                usmleStep2Score
                usmleStep3Score
                comlexLevel2Score
            }
            specialtyId
            masterApplicationId {
                previouslyAppliedToSpecialty
                applicationYear
                carryingOutResponsibilities
                researchExperience {
                    journal
                    title
                    authors
                    datePublished
                }
                workExperiences {
                    employer
                    jobTitle
                    startDate
                    endDate
                    mentorName
                    mentorEmail
                    description
                }
                meaningfulExperiences {
                    category
                    title
                    startDate
                    endDate
                    description
                }
                hobbiesAndInterests
                shortAnswerQuestions {
                    question
                    answer
                }
                personalStatement
            }
            applicationStatus
            reviewDetails
            documents
            lors
            personalStatement
            rubricsResult {
                textResult
                dropdownResult
                scaleResult
            }
            applicationRubrics {
                label
                inputType
                isPreInterview
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                result
            }
        }
        programId {
            id
            name
            description
            specialtyId
        }
        stage
        rubric {
            label
            inputType
            isPreInterview
            maxScale
            lowestScaleText
            highestScaleText
            dropdownOptions {
                option
                score
            }
            text
            order
            result

        }
        comment
        reviewedAt
    }
}
`;

export const getReviewsByUserIdQuery = gql`
    query getReviewsByUserId($userId: ID!) {
        getReviewsByUserId(userId: $userId){
            id
            reviewerId {
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    dateOfBirth
                    homeTown
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    misdemeanorOrFelony
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }
    
                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            applicationId {
                id
                userId {
                    id
                    firstName
                    lastName
                    email 
                    phone
                    profilePhotoUrl
                    applicationInformation {
                        nrmpId
                        workAuthorization
                        gender
                        otherRaceEthnicity
                        otherWorkAuthorization
                        partnerName
                        dateOfBirth
                        homeTown
                        ethnicity
                        coupleMatch
                        medicalSchoolName
                        aoaStatus
                        goldHumanism
                        firstAuthorPublications
                        peerReviewedPublications
                        posterPresentations
                        podiumPresentations
                        aamc
                        previousLastName
                        prefferedName
                        linkedinLink
                        pronouns
                        usmleId
                        nbomeId
                        aoaId
                        permanentAddress
                        licensureRevoked
                        licensureRevokedDetails
                        militaryObligation
                        militaryObligationDetails
                        otherServiceObligations
                        medicalTrainingInterrupted
                        medicalTrainingInterruptedDetails
                        sigmaPhi
                        malpractice
                        awardsAndHonors
                        isLanguages
                        misdemeanorOrFelony
                        higherEducation {
                            institution
                            degree
                            date
                        }
                        coreClerkshipGrades {
                            clerkshipEm
                            clerkshipFm
                            im
                            neurology
                            obgyn
                            pediatrics
                            psychiatry
                            surgery
                        }
        
                        awayRotations {
                            institution
                            degree
                            dateCompleted
                        }
                        nonCoreClerkshipGrades {
                            course
                            grade
                        }
                    }
                }
                programId
                scoreId {
                    usmleStep1Score
                    comlexLevel1Score
                    usmleStep2Score
                    usmleStep3Score
                    comlexLevel2Score
                }
                specialtyId
                masterApplicationId {
                    previouslyAppliedToSpecialty
                    applicationYear
                    carryingOutResponsibilities
                    researchExperience {
                        journal
                        title
                        authors
                        datePublished
                    }
                    workExperiences {
                        employer
                        jobTitle
                        startDate
                        endDate
                        mentorName
                        mentorEmail
                        description
                    }
                    meaningfulExperiences {
                        category
                        title
                        startDate
                        endDate
                        description
                    }
                    hobbiesAndInterests
                    shortAnswerQuestions {
                        question
                        answer
                    }
                    personalStatement
                }
                applicationStatus
                reviewDetails
                documents
                lors
                personalStatement
                rubricsResult {
                    textResult
                    dropdownResult
                    scaleResult
                }
                applicationRubrics {
                    label
                    inputType
                    isPreInterview
                    maxScale
                    lowestScaleText
                    highestScaleText
                    dropdownOptions {
                        option
                        score
                    }
                    text
                    order
                    result
                }
            }
            programId {
                id
                name
                description
                specialtyId
            }
            stage
            rubric {
                label
            }
            comment
            reviewedAt
        } 
    }
`;

export const getReviewsByApplicationId = gql`
    query getReviewsByApplicationId($applicationId: ID!) {
        getReviewsByApplicationId(applicationId: $applicationId){
            id
            reviewerId {
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    dateOfBirth
                    homeTown
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    misdemeanorOrFelony
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }
    
                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            applicationId {
                id
                userId {
                    id
                    firstName
                    lastName
                    email 
                    phone
                    profilePhotoUrl
                    applicationInformation {
                        nrmpId
                        workAuthorization
                        gender
                        otherRaceEthnicity
                        otherWorkAuthorization
                        partnerName
                        dateOfBirth
                        homeTown
                        ethnicity
                        coupleMatch
                        medicalSchoolName
                        aoaStatus
                        goldHumanism
                        firstAuthorPublications
                        peerReviewedPublications
                        posterPresentations
                        podiumPresentations
                        aamc
                        previousLastName
                        prefferedName
                        linkedinLink
                        pronouns
                        usmleId
                        nbomeId
                        aoaId
                        permanentAddress
                        licensureRevoked
                        licensureRevokedDetails
                        militaryObligation
                        militaryObligationDetails
                        otherServiceObligations
                        medicalTrainingInterrupted
                        medicalTrainingInterruptedDetails
                        sigmaPhi
                        malpractice
                        awardsAndHonors
                        isLanguages
                        misdemeanorOrFelony
                        higherEducation {
                            institution
                            degree
                            date
                        }
                        coreClerkshipGrades {
                            clerkshipEm
                            clerkshipFm
                            im
                            neurology
                            obgyn
                            pediatrics
                            psychiatry
                            surgery
                        }
        
                        awayRotations {
                            institution
                            degree
                            dateCompleted
                        }
                        nonCoreClerkshipGrades {
                            course
                            grade
                        }
                    }
                }
                programId
                scoreId {
                    usmleStep1Score
                    comlexLevel1Score
                    usmleStep2Score
                    usmleStep3Score
                    comlexLevel2Score
                }
                specialtyId
                masterApplicationId {
                    previouslyAppliedToSpecialty
                    applicationYear
                    carryingOutResponsibilities
                    researchExperience {
                        journal
                        title
                        authors
                        datePublished
                    }
                    workExperiences {
                        employer
                        jobTitle
                        startDate
                        endDate
                        mentorName
                        mentorEmail
                        description
                    }
                    meaningfulExperiences {
                        category
                        title
                        startDate
                        endDate
                        description
                    }
                    hobbiesAndInterests
                    shortAnswerQuestions {
                        question
                        answer
                    }
                    personalStatement
                }
                applicationStatus
                reviewDetails
                documents
                lors
                personalStatement
                rubricsResult {
                    textResult
                    dropdownResult
                    scaleResult
                }
                applicationRubrics {
                    label
                    inputType
                    isPreInterview
                    maxScale
                    lowestScaleText
                    highestScaleText
                    dropdownOptions
                    {
                        option
                        score
                    }    
                    text
                    order
                    result
                }
            }
            programId {
                id
                name
                description
                specialtyId
            }
            stage
            rubric {
                label
                result
                inputType
            }
            comment
            reviewedAt
        } 
    }
`;



export const getGroupsByProgramIdQuery = gql`
    query getGroupsByProgramId($programId: ID, $filter: FilterInput) {
        getGroupsByProgramId(programId: $programId, filter: $filter) {
            id
            name
            description
            membersIds
            applicationIds
            createdAt
        }
    }
`
export const getAllCompletedReviewsForProgramQuery = gql`
    query getAllCompletedReviewsForProgram($programId: ID!) {
        getAllCompletedReviewsForProgram(programId: $programId) {
            applicationId
            firstName
            lastName
            email
            profilePhotoUrl
            specificReviews {
                reviewerFirstName
                reviewerLastName
                rubricLabel
                rubricResult
                rubricText
            }
            compositeScore
            averageScores{
                label
                average
            }
        }
    }
`