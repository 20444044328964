import React, { useState, useEffect,useContext } from 'react';
import './index.css';
import {useQuery, useMutation} from '@apollo/client';
import {createFilter, removeFilter} from '../graphql/mutations';
import {getProgramFiltersByProgramId, GET_ALL_CUSTOM_FIELDS_FROM_POSITIONS_BY_PROGRAM_ID} from '../graphql/queries';
import { UserContext } from '../../../../context/userContext';
import medicalSchools from '../../../common/listOfInstitutions';

const constantFieldsDefinitions = {
  'User.applicationInformation.aoaStatus': { type: "aoaType", display: "AOA Status" },
  'Score.comlexLevel1Score': { type: "string", display: "COMLEX Level 1 Score" },
  'Score.comlexLevel2Score': { type: "number", display: "COMLEX Level 2 Score" },
  'User.applicationInformation.coupleMatch': { type: "coupleMatchType", display: "Couple Match" },
  'User.applicationInformation.ethnicity': { type: "ethnicityType", display: "Ethnicity" },
  'User.applicationInformation.firstAuthorPublications': { type: "number", display: "First Author Publications" },
  'User.applicationInformation.gender': { type: "gender", display: "Gender"},
  'User.applicationInformation.goldHumanism': { type: "ghhsType", display: "GHHS Status" },
  'user.firstName': { type: "string", display: "First Name" },
  'user.applicationInformation.homeTown': { type: "string", display: "Hometown" },
  'User.applicationInformation.licensureRevoked': { type: "boolean", display: "Licensure Revoked" },
  'User.lastName': { type: "string", display: "Last Name" },
  'User.applicationInformation.linkedinLink': { type: "string", display: "LinkedIn Link" },
  'User.applicationInformation.medicalSchoolName': { type: "medicalSchoolType", display: "Medical School Name" },
  'medicalSchoolState': { type: "state", display: "Medical School State"},
  'User.applicationInformation.medicalSchoolType': { type: "medSchoolTypeType", display: "Medical School Type" },
  'User.applicationInformation.medicalTrainingInterrupted': { type: "boolean", display: "Medical Training Interrupted" },
  'User.applicationInformation.militaryObligation': { type: "boolean", display: "Military Obligation" },
  'User.applicationInformation.nrmpId': { type: "string", display: "NRMP ID" },
  'User.applicationInformation.otherServiceObligations': { type: "string", display: "Other Service Obligations" },
  'User.applicationInformation.peerReviewedPublications': { type: "number", display: "Peer Reviewed Publications" },
  'User.applicationInformation.podiumPresentations': { type: "number", display: "Podium Presentations" },
  'User.applicationInformation.posterPresentations': { type: "number", display: "Poster Presentations" },
  'masterApplication.previouslyAppliedToSpecialty': { type: "boolean", display: "Previously Applied" },
  'Score.previousUsmleComlexFails': { type: "boolean", display: "Previous USMLE COMLEX FAILS" },
  'region': { type: "region", display: "Region"},
  "reviewDetails.stage": { type: "reviewStatus", display: "Review Status" },
  'selectedTracks.trackName': { type: "array", display: "Selected Tracks"},
  'isSignal': { type: "boolean", display: "Signal" },
  'signalType': { type: "signalTypeType", display: "Signal Type" },
  'User.applicationInformation.sigmaPhi': { type: "boolean", display: "Sigma Sigma Phi Status" },
  'createdAt': { type: "string", display: "Submission Date" },
  'Score.usmleStep1Score': { type: "string", display: "USMLE Step 1 Score" },
  'Score.usmleStep2Score': { type: "number", display: "USMLE Step 2 Score" },
  'Score.usmleStep3Score': { type: "number", display: "USMLE Step 3 Score" },
  "User.applicationInformation.workAuthorization": { type: "waType", display: "Work Authorization" },
  "archived": { type: "boolean", display: "Archived" },
  "masterApplication.status": { type: "enum", display: "Status" },
};

const getConditionsForType = (type) => {
    switch (type) {
      case "string":
        return ["Equals", "Not equals", "Contains", "Starts with", "Ends with"];
      case "medicalSchoolType":
        return ["Equals", "Not equals"];
      case "medSchoolTypeType":
        return ["Equals", "Not equals"];
      case "aoaType":
        return ["Equals", "Not equals"];
        case  "gender":
        return ["Equals", "Not equals"];
      case "options":
        return ["Equals", "Not equals", "Contains"];
      case "region":
        return ["Equals", "Not equals"];
        case "state":
        return ["Equals", "Not equals"];
      case "number":
        return ["Equals", "Not equals", "Greater than", "Less than", "Between"];
      case "boolean":
        return ["Equals"];
      case "booleanString":
        return ["Equals"];
      case "enum":
        return ["Equals"];
      case "array":
        return ["Contains", "Does not contain"];
      case "ethnicityType":
        return ["Contains", "Does not contain"];
        case "signalTypeType":
        return ["Equals", "Not equals"];
        case "coupleMatchType":
        return ["Equals", "Not equals"];
        case "ghhsType":
        return ["Equals", "Not equals"];
        case "waType":
        return ["Equals", "Not equals"];
        case "reviewStatus":
        return ["Equals", "Not equals"];
      default:
        return [];
    }
};
  

  

const AdvancedFiltersModal = ({parentFilters, onFiltersApply, onFilterRemove, onClose, specificPositionData}) => {
    const { user } = useContext(UserContext);
    const programId = user.programId;

  const [filters, setFilters] = useState(parentFilters);
  const [savedFilters, setSavedFilters] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [createFilterMutation] = useMutation(createFilter);
  const [removeFilterMutation] = useMutation(removeFilter);
  const [customFields, setCustomFields] = useState([]);
  const [fieldDefinitions, setFieldDefinitions] = useState(constantFieldsDefinitions)


  const { loading: loadingCustomFields, error: errorCustomFields, data: positionsCustomFields, refetch: refetchCustomFields} = useQuery(GET_ALL_CUSTOM_FIELDS_FROM_POSITIONS_BY_PROGRAM_ID, {
    variables: { programId: programId }
  })

  const { loading, error, data, refetch } = useQuery(getProgramFiltersByProgramId, {
    variables: { programId: programId },
    });


    useEffect(() => {
        if (data) {
            console.log(data.getProgramFiltersByProgramId,'the saved filters')
            setSavedFilters(data.getProgramFiltersByProgramId);
        }
    }, [data]);

    useEffect(() => {
      if(!loadingCustomFields && !errorCustomFields && positionsCustomFields) {
          if(positionsCustomFields.getAllCustomFieldsFromPositionsByProgramId.length > 0) {
            const customFields = positionsCustomFields.getAllCustomFieldsFromPositionsByProgramId
            console.log("customFields =>", customFields)
            var fieldsToAdd = {}
            customFields.reduce((result, field) => {
              fieldsToAdd[`masterApplication.customField.${field}`] = { type: "string", display: field };
              return result;
            }, {});

            console.log("fieldsToAdd =>", fieldsToAdd)
            setFieldDefinitions(prevState => ({
              ...prevState,
              ...fieldsToAdd
            }));
          }
      }
  }, [loadingCustomFields, errorCustomFields, positionsCustomFields])

  useEffect(() => {
    if(specificPositionData) {
      console.log("specificPositionData in filter =>", specificPositionData)
      var fieldsToAdd = {}
      specificPositionData.minimumQualifications.forEach(qualification => {
        fieldsToAdd[`masterApplication.qualifications.${qualification}`] = { type: "boolean", display: qualification };
      })
      specificPositionData.preferredQualifications.forEach(qualification => {
        fieldsToAdd[`masterApplication.qualifications.${qualification}`] = { type: "boolean", display: qualification };
      })
      specificPositionData.certifications.forEach(certification => {
        fieldsToAdd[`masterApplication.certsAnswers.${certification}`] = { type: "boolean", display: certification };
      })
      setFieldDefinitions(prevState => ({
        ...prevState,
        ...fieldsToAdd
      }));

    }
  }, [specificPositionData])




    // useEffect(() => {
    //   setFilters(filters);
    // }, [filters]);


    const addFilter = () => {
      setFilters([...filters, { column: '', condition: '', value: '' }]); // Set a default value for 'value'
  };


  const updateFilter = (index, key, value) => {
    console.log('newFilters')
    console.log("key =>", key, "value =>", value);
    const newFilters = filters.map((filter, i) => {
      if (i === index) {
        return { ...filter, [key]: value };
      }
      return filter;
    });
    console.log('newFilters')
    setFilters(newFilters);
  };

  const deleteFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
    onFilterRemove(newFilters);
  };
  const deleteAllFilters = () => {
    setFilters([]);
    onFilterRemove([]);
  };

  const saveFilters = () => {
    // setSavedFilters();
    createFilterMutation({
        variables: { programId: programId, input: { filterName: filterName, filterConditions: filters } },
        refetchQueries: [{ query: getProgramFiltersByProgramId, variables: { programId: programId } }]
      });
    setFilterName('');
  };

  const loadFilters = (filterConditions) => {
    // map the filter conditions to the filters state
    setFilters(filterConditions);
  };

  const deleteSavedFilter = (name) => {
    removeFilterMutation({
        variables: { programId: programId, filterId: name.id },
        refetchQueries: [{ query: getProgramFiltersByProgramId, variables: { programId: programId } }]
      });
  };

  const applyFilters = async () => {
    // go through the filters before applying them, for each one, if the type is number, make sure you pasrseInt it.
      filters.forEach(filter => {
        if (fieldDefinitions[filter.column].type === "number") {
          // filter.value = parseInt(filter.value);
          filter.value = filter.value.toString(); 
        }
      });
        onFiltersApply(filters);
    }

  return (
    <div className="filters-container">
      <div style={{float:'right', cursor: 'pointer'}} onClick={onClose}>X</div>
      <h3 style={{marginBottom:'3vh'}}>Advanced Filters</h3>
      {filters.map((filter, index) => (
        <div key={index} className="filter-row">
          <select value={filter.column} onChange={(e) => updateFilter(index, 'column', e.target.value)} className="dropdown">
            <option value="">Select Column</option>
            {Object.keys(fieldDefinitions).map(key => (
              <option key={key} value={key}>{fieldDefinitions[key].display}</option>
            ))}
          </select>
          <select value={filter.condition} onChange={(e) => updateFilter(index, 'condition', e.target.value)} disabled={!filter.column} className="dropdown">
          <option value="">Select Condition</option>
          {filter.column && getConditionsForType(fieldDefinitions[filter.column].type).map(cond => (
            <option key={cond} value={cond}>{cond}</option>
          ))}
          </select>
          {
            console.log("filter in render =>", filter, fieldDefinitions[filter.column])
          }
{
  filter.column && fieldDefinitions[filter.column].type === "boolean" ? 
  <select value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
    <option key="yesOption" value={true}>Yes</option>
    <option key="noOption" value={false}>No</option>
  </select>
  :   
  filter.column && fieldDefinitions[filter.column].type === "reviewStatus" ? 
  <select value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
    <option key="Pending" value='Pending'>Pending</option>
    <option key="Completed" value='Completed'>Completed</option>
  </select>
  :   
  filter.column && fieldDefinitions[filter.column].display === "Selected Tracks" ? 
  <select value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
    <option key="Categorical" value={"Categorical"}>Categorical</option>
              <option key="Advanced" value={"Advanced"}>Advanced</option>
              <option key="Physician Only" value={"Physician Only"}>Physician Only</option>
              <option key="Critical Care" value={"Critical Care"}>Critical Care</option>
              <option key="Research" value={"Research"}>Research</option>
              <option key="Peds/Anesthesiology" value={"Peds/Anesthesiology"}>Peds/Anesthesiology</option>
  </select>
  : filter.column && fieldDefinitions[filter.column].type === "region" ? 
  <select value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
                  <option key="Northeast" value={"Northeast"}>Northeast</option>
              <option key="South" value={"South"}>South</option>
              <option key="Midwest" value={"Midwest"}>Midwest</option>
              <option key="West" value={"West"}>West</option>
  </select>
  :
  filter.column && fieldDefinitions[filter.column].type === "waType" ? 
  <select value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown" style={{width:'13vw'}}>
    <option key="" value={""}>Select</option>
    <option value="U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)">U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)</option>
          <option value="Green Card Applicant Undergoing Adjustment of Status (with EAD)">Green Card Applicant Undergoing Adjustment of Status (with EAD)</option>
          <option value="DACA Recipient (Deferred Action for Childhood Arrivals)">DACA Recipient (Deferred Action for Childhood Arrivals)</option>
          <option value="E-2 Treaty Investor, Including Spouse and Children (with EAD)">E-2 Treaty Investor, Including Spouse and Children (with EAD)</option>
          <option value="Holder of Employment Authorization Document (EAD)">Holder of Employment Authorization Document (EAD)</option>
          <option value="F-1 Academic Student (with EAD, OPT)">F-1 Academic Student (with EAD, OPT)</option>
          <option value="H-1 Temporary Worker">H-1 Temporary Worker</option>
          <option value="H-1B Specialized Occupation, Department of Defense Worker, etc.">H-1B Specialized Occupation, Department of Defense Worker, etc.</option>
          <option value="H-2B Temporary Worker (Skilled or Unskilled)">H-2B Temporary Worker (Skilled or Unskilled)</option>
          <option value="H-4 Spouse or Child of H-1, H-2, or H-3 Visa Holder (with EAD)">H-4 Spouse or Child of H-1, H-2, or H-3 Visa Holder (with EAD)</option>
          <option value="J-1 Exchange Visitor">J-1 Exchange Visitor</option>
          <option value="J-2 Spouse or Child of J-1 Visa Holder (with EAD)">J-2 Spouse or Child of J-1 Visa Holder (with EAD)</option>
          <option value="L-2 Dependent of Intra-Company Transferee (with EAD)">L-2 Dependent of Intra-Company Transferee (with EAD)</option>
          <option value="O-1 Individual with Extraordinary Ability in Sciences, Arts, Education, Business, or Athletics">O-1 Individual with Extraordinary Ability in Sciences, Arts, Education, Business, or Athletics</option>
          <option value="TN NAFTA Trade Visa (Canadians and Mexicans)">TN NAFTA Trade Visa (Canadians and Mexicans)</option>
          <option value="Other">Other</option>
  </select>
  :
  filter.column && fieldDefinitions[filter.column].type === "state" ? 
  <select value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
   <option key="Alabama" value={"Alabama"}>Alabama</option>
<option key="Alaska" value={"Alaska"}>Alaska</option>
<option key="Arizona" value={"Arizona"}>Arizona</option>
<option key="Arkansas" value={"Arkansas"}>Arkansas</option>
<option key="California" value={"California"}>California</option>
<option key="Colorado" value={"Colorado"}>Colorado</option>
<option key="Connecticut" value={"Connecticut"}>Connecticut</option>
<option key="Delaware" value={"Delaware"}>Delaware</option>
<option key="Florida" value={"Florida"}>Florida</option>
<option key="Georgia" value={"Georgia"}>Georgia</option>
<option key="Hawaii" value={"Hawaii"}>Hawaii</option>
<option key="Idaho" value={"Idaho"}>Idaho</option>
<option key="Illinois" value={"Illinois"}>Illinois</option>
<option key="Indiana" value={"Indiana"}>Indiana</option>
<option key="Iowa" value={"Iowa"}>Iowa</option>
<option key="Kansas" value={"Kansas"}>Kansas</option>
<option key="Kentucky" value={"Kentucky"}>Kentucky</option>
<option key="Louisiana" value={"Louisiana"}>Louisiana</option>
<option key="Maine" value={"Maine"}>Maine</option>
<option key="Maryland" value={"Maryland"}>Maryland</option>
<option key="Massachusetts" value={"Massachusetts"}>Massachusetts</option>
<option key="Michigan" value={"Michigan"}>Michigan</option>
<option key="Minnesota" value={"Minnesota"}>Minnesota</option>
<option key="Mississippi" value={"Mississippi"}>Mississippi</option>
<option key="Missouri" value={"Missouri"}>Missouri</option>
<option key="Montana" value={"Montana"}>Montana</option>
<option key="Nebraska" value={"Nebraska"}>Nebraska</option>
<option key="Nevada" value={"Nevada"}>Nevada</option>
<option key="New Hampshire" value={"New Hampshire"}>New Hampshire</option>
<option key="New Jersey" value={"New Jersey"}>New Jersey</option>
<option key="New Mexico" value={"New Mexico"}>New Mexico</option>
<option key="New York" value={"New York"}>New York</option>
<option key="North Carolina" value={"North Carolina"}>North Carolina</option>
<option key="North Dakota" value={"North Dakota"}>North Dakota</option>
<option key="Ohio" value={"Ohio"}>Ohio</option>
<option key="Oklahoma" value={"Oklahoma"}>Oklahoma</option>
<option key="Oregon" value={"Oregon"}>Oregon</option>
<option key="Pennsylvania" value={"Pennsylvania"}>Pennsylvania</option>
<option key="Rhode Island" value={"Rhode Island"}>Rhode Island</option>
<option key="South Carolina" value={"South Carolina"}>South Carolina</option>
<option key="South Dakota" value={"South Dakota"}>South Dakota</option>
<option key="Tennessee" value={"Tennessee"}>Tennessee</option>
<option key="Texas" value={"Texas"}>Texas</option>
<option key="Utah" value={"Utah"}>Utah</option>
<option key="Vermont" value={"Vermont"}>Vermont</option>
<option key="Virginia" value={"Virginia"}>Virginia</option>
<option key="Washington" value={"Washington"}>Washington</option>
<option key="West Virginia" value={"West Virginia"}>West Virginia</option>
<option key="Wisconsin" value={"Wisconsin"}>Wisconsin</option>
<option key="Wyoming" value={"Wyoming"}>Wyoming</option>
  </select>
  :
  filter.column && fieldDefinitions[filter.column].type === "medicalSchoolType" ?
  <select style={{width:'100%'}} value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
    {medicalSchools.sort().map(school => (
      <option key={school} value={school}>{school}</option>
    ))}
    </select>
  :
  filter.column && fieldDefinitions[filter.column].type=== 'gender'?
  <select value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
      <option value="woman">Woman</option>
      <option value="man">Man</option>
      <option value="transWoman">Transgender Woman</option>
      <option value="transMan">Transgender Man</option>
      <option value="nonBinary">Gender Nonbinary</option>
      <option value="notListed">Gender Not Listed</option>
      <option value="noResponse">Prefer Not To Respond</option>
  </select>
    
  :
  filter.column && fieldDefinitions[filter.column].type=== 'medSchoolTypeType'?
  <select style={{width:'100%'}} value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
      <option value="International Medical School">International Medical School</option>
      <option value="US Allopathic Medical School (MD)">US Allopathic Medical School (MD)</option>
      <option value="US Osteopathic Medical School (DO)">US Osteopathic Medical School (DO)</option>
  </select>
   :
   filter.column && fieldDefinitions[filter.column].type=== 'signalTypeType'?
   <select style={{width:'100%'}} value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
       <option value="Gold">Gold</option>
       <option value="Silver">Silver</option>
   </select>
  :
  filter.column && fieldDefinitions[filter.column].type=== 'aoaType'?
  <select style={{width:'100%'}} value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
     <option value="Inducted">Inducted</option>
        <option value="Not Inducted">Not Inducted</option>
        <option value="Inductions have not yet taken place at my school">Inductions have not yet taken place at my school</option>
        <option value="My school does not have a chapter">My school does not have a chapter</option>
  </select>
   :
  filter.column && fieldDefinitions[filter.column].type=== 'ghhsType'?
  <select style={{width:'100%'}} value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option value="">Select...</option>
          <option value="Inducted">Inducted</option>
          <option value="Not Inducted">Not Inducted</option>
          <option value="Inductions have not yet taken place at my school">Inductions have not yet taken place at my school</option>
          <option value="My school does not have a chapter">My school does not have a chapter</option>
  </select>
   :
   filter.column && fieldDefinitions[filter.column].type=== 'coupleMatchType'?
   <select style={{width:'100%'}} value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
      <option value="yes">Yes</option>
      <option value="no">No</option>
   </select>
  :
  filter.column && fieldDefinitions[filter.column].type=== 'ethnicityType'?
  <select style={{width:'100%'}} value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} className="dropdown">
    <option key="" value={""}>Select</option>
    <option value="Prefer not to respond">Prefer not to respond</option>
    <option value="American Indian or Alaska Native">American Indian or Alaska Native</option>
    <option value="Asian">Asian</option>
    <option value="Black or African American">Black or African American</option>
    <option value="Hispanic or Latino">Hispanic or Latino</option>
    <option value="Native Hawaiian or Other Pacific Islander">Native Hawaiian or Other Pacific Islander</option>
    <option value="White">White</option>
    <option value="Other">Other</option>
  </select>
  :
  <input type="text" value={filter.value} onChange={(e) => updateFilter(index, 'value', e.target.value)} disabled={!filter.condition} className="input-field" />
  
 
}
          <button onClick={() => deleteFilter(index)} className="button-delete">Delete</button>
        </div>
      ))}
      <button style={{marginBottom:'1vh'}} onClick={addFilter} className="button-add">+ Add Filter</button>
      <button style={{marginBottom:'1vh', marginLeft:'2vw'}} onClick={deleteAllFilters} className="button-delete">Remove All</button>
      <div className='filters-modal-footer' style={{display:'flex',justifyContent:'space-between'}}>
      <div className="filter-name-row" style={{width:'34vw', marginBottom:'0'}}>
        <input type="text" placeholder="Filter Name" value={filterName} onChange={(e) => setFilterName(e.target.value)} className="input-field" />
        <button onClick={saveFilters} disabled={!filterName} className="button-save">Save Filters</button>
      </div>

        <button onClick={applyFilters} className='green-button'>Apply Filters</button>
        </div>
        <div style={{height:'30vh', overflowY:'scroll'}}>
        {savedFilters.map(savedFilter => (
          <div key={savedFilter.id} className="saved-filter-row" style={{marginTop:'2vh'}}>
            <div style={{display: 'flex',gap: '1vw', }}>
            <button style={{padding:'.5vh 1vw'}} onClick={() => loadFilters(savedFilter.filterConditions)} className="button-load">Load</button>
            <button style={{padding:'.5vh 1vw'}} onClick={() => deleteSavedFilter(savedFilter)} className="button-delete">Delete</button>
          </div>
          <span className="filter-name" style={{marginLeft:'1vw'}}>{savedFilter.filterName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdvancedFiltersModal;
