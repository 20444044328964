import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../styles/progress.css";
import calendarIcon from "../../../../../assets/imgs/calendarIcon.svg";
import personIcon from "../../../../../assets/imgs/personIcon.svg";
import calendarWithADayIcon from "../../../../../assets/imgs/calendarWithADayIcon.svg";
import toTheSideArrow from "../../../../../assets/imgs/toTheSideArrow.svg";
import { useInterviewSchedule } from '../interviewScheduleContext';
import InterviewScheduleTable from "../interviewScheduleTable";
import {AddMinutes, sanitizeTimeFormat} from "../dayCustomize/addMinutes";
import {useMutation} from "@apollo/client";


const InterviewBuilderProgress = ({ className = "" }) => {
  const { selectedDates, setSelectedDates,
    page,setPage,
    step,setStep,
    startTime, setStartTime,
    interviewLength, setInterviewLength,
    breakTime, setBreakTime,
    slots, setSlots,
    interviewersPerInterviewee, setInterviewersPerInterviewee,
    numberOfSlots, setNumberOfSlots,
    dayType, setDayType,
    isLocked, setIsLocked,
    numOfInterviewers, setNumOfInterviewers,
    } = useInterviewSchedule();
    
    

    const step2Data =[
          {
            // remove the AM or PM from the startTime
              // time: startTime.replace(/\s*(AM|PM)\s*/i, ''),
              time: startTime,
              R1: '',
              R2: '',
              R3: '',
              R4: '',
              R5: '',
              R6: ''
          }
      ];
  
  const step2Columns =  [
          {
              Header: '',
              accessor: 'time', // Accessor is the "key" in the data
          },
          {
              Header: '',
              accessor: 'R1',
          },
          {
              Header: '',
              accessor: 'R2',
          },
          {
              Header: '',
              accessor: 'R3',
          },
          {
              Header: '',
              accessor: 'R4',
          },
          {
              Header: '',
              accessor: 'R5',
          },
          {
              Header: '',
              accessor: 'R6',
          }
      ];

  const generateStep3Columns = (interviewersCount) => {
    const columns = [
      {
        Header: '',
        accessor: 'time', // Always include the time column
      }
    ];
  
    // Dynamically add "R" columns based on interviewersPerInterviewee
    for (let i = 1; i <= interviewersCount; i++) {
      columns.push({
        Header: `R${i}`,
        accessor: `R${i}`, // Accessor names are dynamically generated (R1, R2, R3, ...)
      });
    }
  
    return columns;
  };
  const step3Columns = generateStep3Columns(numOfInterviewers);

  const generateStep4Data = (startTime, interviewLength, interviewersCount) => {
    const data = [];
    // let currentTime = startTime.replace(/\s*(AM|PM)\s*/i, '')
    let currentTime = startTime
  
    for (let i = 0; i < 10; i++) {
      const row = { time: currentTime };
  
      // Generate R keys based on interviewersCount (which is step3Columns.length - 1)
      for (let j = 1; j <= interviewersCount; j++) {
        row[`R${j}`] = ''; // Initialize R keys with empty string
      }
  
      data.push(row);
      currentTime = AddMinutes(currentTime, interviewLength+breakTime); // Increment time for the next row

      }
  
    return data;
  };

  const generateStep6Data = (startTime, interviewLength, interviewersCount, slots) => {
    const data = [];
    let currentTime = startTime;
    let currentIndex = 0;
  
    while (currentIndex < 10) { // Assuming you want to generate data for 10 slots by default
      let slotFound = slots.find(slot => 
        currentTime >= slot.startTime && currentTime < slot.endTime
      );
  
      if (slotFound) {
        // If the current time is within a special slot, add that slot to the data
        data.push({
          isFullWidth: true, // This will be used to span the row across all columns
          cellColor: slotFound.itemColor, // Use the color specified in the slot
          fontStyle: { fontWeight: "bold",
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '18px',
          letterSpacing: '0.51em',
          textAlign: 'center',
          textTransform: 'uppercase'
         }, // Example style, adjust as needed
          fullWidthContent: slotFound.item, // The content of the slot
          name: currentTime, // Keeping the first column with the start time
        });
        // Skip to the end time of the slot
        currentTime = sanitizeTimeFormat(slotFound.endTime);
        currentIndex++; // Move to the next index
      } else {
        // Generate a regular time slot
        const row = { time: currentTime };
        for (let j = 1; j <= interviewersCount; j++) {
          row[`R${j}`] = ''; // Initialize R keys with empty string
        }
        data.push(row);
        console.log(`Adding minutes to: ${currentTime}, ${interviewLength + breakTime}`);
        currentTime = AddMinutes(currentTime, interviewLength + breakTime); // Increment time for the next row
        currentIndex++;
      }
    }
  
    return data;
  };

  const step4Data = generateStep4Data(startTime, interviewLength, step3Columns.length - 1);
  const step6Data = generateStep6Data(startTime, interviewLength, step3Columns.length - 1, slots);

  return (
    <>
      {(step === 0||step ===1) && (
       <div className={`interview-day-content ${className}`}>
      <div className="interview-day-content-child" />
      <img
        className="interview-day-content-item"
        loading="lazy"
        alt=""
        src="/vector-241.svg"
      />
      <div className="frame-parent6">
        <div className="frame-wrapper1">
          <div className="vector-parent">
            <img
              className="frame-item"
              loading="lazy"
              alt=""
              src="/vector-235.svg"
            />
            <div className="vector-wrapper">
              <img
                className="frame-inner"
                loading="lazy"
                alt=""
                src="/vector-235.svg"
              />
            </div>
            <div className="vector-wrapper">
              <img
                className="frame-inner"
                loading="lazy"
                alt=""
                src="/vector-235.svg"
              />
            </div>
            <div className="vector-frame">
              <img
                className="frame-inner"
                loading="lazy"
                alt=""
                src="/vector-235.svg"
              />
            </div>
            <div className="vector-frame">
              <img
                className="frame-inner"
                loading="lazy"
                alt=""
                src="/vector-235.svg"
              />
            </div>
            <img className="frame-item" alt="" src="/vector-235.svg" />
          </div>
        </div>
        <img
          className="interview-day-calendar-child"
          loading="lazy"
          alt=""
          src="/vector-242.svg"
        />
      </div>
      <div className="interview-day-calendar">
        <img
          className="interview-day-calendar-child"
          loading="lazy"
          alt=""
          src="/vector-241.svg"
        />
        <div className="calendar-content">
          <div className="calendar-main">
            <div className="calendar-days">
              <img
                className="calendar-days-child"
                loading="lazy"
                alt=""
                src="/vector-241.svg"
              />
              <div className="calendar-day-item-parent">
                <img
                  className="interview-day-calendar-child"
                  loading="lazy"
                  alt=""
                  src="/vector-241.svg"
                />
                <div className="calendar-event-labels">
                  <div className="calendar-event-label-item-wrapper">
                    <img
                      className="interview-day-calendar-child"
                      loading="lazy"
                      alt=""
                      src="/vector-241.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="vector-group">
                <img
                  className="interview-day-calendar-child"
                  loading="lazy"
                  alt=""
                  src="/vector-241.svg"
                />
                <div className="calendar-event-labels">
                  <div className="vector-wrapper2">
                    <img
                      className="interview-day-calendar-child"
                      loading="lazy"
                      alt=""
                      src="/vector-241.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="calendar-planning">
            <div className="calendar-icon-area">
              <img
                className="calendar-icon"
                loading="lazy"
                alt=""
                
                src={calendarIcon}
              />
              <div className="calendar-planning-description">
                <div className="plan-your-day-container">
                  <p className="plan-your-day">
                    Select a date to view your schedule.
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      </div>
      )}
       {step === 2 && (
       <>
        <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
        <InterviewScheduleTable columns={step2Columns} data={step2Data}/>
      
      </>
      )}
       {step === 3 && (
        <>
        <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
        <InterviewScheduleTable columns={step3Columns} data={step2Data}/>
      
        </>
      )}
       {step === 4 && (
        <>
        <separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
        <InterviewScheduleTable columns={step3Columns} data={step4Data}/>
      
        </>
      )}
      {(step === 5 || step ===6 || step ===7 || step ===8 || step ===9) && (
<>
<separator style={{width:'100%', border:'0.5px solid #CBCBCB'}}/>
<InterviewScheduleTable columns={step3Columns} data={step6Data}/>

</>
      )}
    </>
  );
};


export default InterviewBuilderProgress;
