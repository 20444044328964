import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  DISCONNECT_GOOGLE_CALENDAR,
  DISCONNECT_MICROSOFT_CALENDAR,
  GET_GOOGLE_AUTH_URL,
  GET_MICROSOFT_AUTH_URL,
} from './graphql/mutations';
import { GET_CONNECTED_CALENDARS } from './graphql/queries';
import 'tailwindcss/tailwind.css';
import outlookCalIcon from '../../../../assets/imgs/outlookCalIcon.png';
import googleCalIcon from '../../../../assets/imgs/googleCalIcon.png';
import { UserContext } from '../../../../context/userContext';

const CalendarIntegration = () => {
  const user = useContext(UserContext);
  console.log('user =>', user.user.userId);
  const [connectedCalendars, setConnectedCalendars] = useState([]);
  const { data, loading, refetch } = useQuery(GET_CONNECTED_CALENDARS, {
    variables: { userId: user.user.userId },
  });

  const [getGoogleAuthUrl] = useMutation(GET_GOOGLE_AUTH_URL);
  const [getMicrosoftAuthUrl] = useMutation(GET_MICROSOFT_AUTH_URL);

  const [disconnectGoogleCalendar] = useMutation(DISCONNECT_GOOGLE_CALENDAR);
  const [disconnectMicrosoftCalendar] = useMutation(DISCONNECT_MICROSOFT_CALENDAR);

  // Fetch connected calendars on load
  useEffect(() => {
    if (data) {
      setConnectedCalendars(data.getConnectedCalendars);
    }
  }, [data]);

  const handleConnectGoogle = async () => {
    try {
      const { data } = await getGoogleAuthUrl();
      if (data.getGoogleAuthUrl) {
        window.location.href = data.getGoogleAuthUrl;
      }
    } catch (error) {
      console.error('Error fetching Google Auth URL:', error);
    }
  };

  const handleConnectMicrosoft = async () => {
    try {
      const { data } = await getMicrosoftAuthUrl();
      if (data.getMicrosoftAuthUrl) {
        window.location.href = data.getMicrosoftAuthUrl;
      }
    } catch (error) {
      console.error('Error fetching Microsoft Auth URL:', error);
    }
  };

  const handleDisconnect = async (calendarName) => {
    try {
      if (calendarName === 'Google Calendar') {
        await disconnectGoogleCalendar();
      } else if (calendarName === 'Microsoft Calendar') {
        await disconnectMicrosoftCalendar();
      }
      refetch(); // Refresh connected calendars
    } catch (error) {
      console.error(`Error disconnecting ${calendarName}:`, error);
    }
  };

  const renderCalendarList = () => {
    const calendars = [
      { name: 'Google Calendar', icon: googleCalIcon },
      { name: 'Microsoft Calendar', icon: outlookCalIcon },
    ];

    return calendars.map((calendar) => {
      const isConnected = connectedCalendars.includes(calendar.name);

      return (
        <div
          key={calendar.name}
          className="flex items-center justify-between bg-white shadow-sm p-4 mb-3 rounded-lg border border-gray-200"
        >
          <div className="flex items-center space-x-3">
            <img src={calendar.icon} alt={calendar.name} className="w-6 h-6" />
            <span className="font-medium text-gray-700">{calendar.name}</span>
          </div>
          <div>
            {isConnected ? (
              <button
                className="text-sm bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600"
                onClick={() => handleDisconnect(calendar.name)}
              >
                Disconnect
              </button>
            ) : (
              <button
                className="text-sm bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600"
                onClick={() =>
                  calendar.name === 'Google Calendar' ? handleConnectGoogle() : handleConnectMicrosoft()
                }
              >
                Connect
              </button>
            )}
          </div>
        </div>
      );
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-3xl mx-auto mt-10 px-5">
      <h1 className="text-2xl font-semibold text-gray-800 mb-5">Calendar Integrations</h1>
      <p className="text-gray-600 mb-8">
        Connect your external calendars to automatically block out busy times and streamline your scheduling process.
      </p>
      <div>{renderCalendarList()}</div>
    </div>
  );
};

export default CalendarIntegration;
