import { gql } from '@apollo/client';

export const getProgramById = gql`
    query getProgramById($id: ID!) {
        getProgramById(id: $id) {
            id
            name
            description
            url
            specialtyId
            isRubricLocked
             defaultLegalDisclaimers
            defaultEqualEmploymentOpportunityStatement
            rubricTemplate{
                id
                label
                inputType
                maxScale
                isPreInterview
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                weight
            }
            screenedData {
                sections {
                    personalInfo
                    residencyApplication
                    personalStatement
                    documents
                    lors
                }
                personalInfo {
                    profilePicture
                    firstName
                    lastName
                    email
                    phoneNumber
                    genderIdentity
                    nrmpId
                    aamcId
                    hometown
                    raceEthnicity
                    homeAddress
                    workAuthorization
                    preventResponsibilities
                    elaborateResponsibilities
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    couplesMatch
                    partnerField
                    previouslyApplied
                    malpracticeLawsuit
                    elaborateMalpractice
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    militaryObligation
                    otherServiceObligations
                    medicalTrainingInterrupted
                    sigmaPhi
                    dateOfBirth
                }
                residencyApplication {
                    medicalSchoolType
                    medicalSchoolName
                    fluencyInLanguagesBesidesEnglish
                    otherLanguagesFluentIn
                    otherDegreesInstitutionsDates
                    aoaStatus
                    ghhsStatus
                    medicalSchoolAwardsHonors
                    coreClerkshipsGraded
                    specifyClerkshipDetails
                    clerkshipGrades
                    awayRotations
                    nonCoreClerkshipGradedCourses
                    impactfulResearchPublications
                    additionalResearchBibliography
                    firstAuthorPublications
                    totalPeerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    meaningfulExperiences
                    significantWorkExperiences
                    shortQuestions
                    hobbiesInterests
                }
            }
        }
    }
`;
export const getUsersQuery = gql`
    query getProgramMembersByProgramId($programId: ID!) {
        getProgramMembersByProgramId(programId: $programId) {
            userId {
                id
                firstName
                lastName
                email
                isPending
                institutionId
                institution
              }
            roles
        }
    }

`


export const getUserById = gql`
    query getUserById($id: ID!){
        getUserById(id: $id) {
            id
            email
            firstName
            lastName
            phone
            token
            verificationToken
            userType
            isVerified
            status
            programId        
            profilePhotoUrl
            institution
            institutionId
            isPending
            isRecommender
        }
    }
`

export const getUserWithAccountUsersById = gql`
    query getUserWithAccountUsersById($userId: ID!) {
        getUserWithAccountUsersById(userId: $userId) {
            id
            email
            firstName
            lastName
            phone
            token
            userId
            verificationToken
            userType
            isVerified
            status
            programId        
            profilePhotoUrl
            institution
            institutionId
            isPending
            isRecommender
            userPremissions
            accountAccessIds {
                id
                email
                firstName
                lastName
                phone
                token
                userId
                verificationToken
                userType
                isVerified
                status
                programId        
                profilePhotoUrl
                institution
                institutionId
                isPending
                isRecommender
                userPremissions  
            }
        }
    }
`