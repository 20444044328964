import React,{useState, useContext, useEffect, useRef} from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AddFieldModal from "./addFieldModal";
import CategoriesValuesModal from "./categoriesValuesModal";
import RubricItem from "./rubricItem";
import "./index.css";
import { useMutation, useQuery } from '@apollo/client';
import {DELETE_RUBRIC_TEMPLATE, CREATE_RUBRIC_TEMPLATE, lockRubricsMutation, updateRubricsWeight, updateDropdownOptions} from '../graphql/mutations';
import { getRubricsAnsweredQuery } from '../graphql/queries';
import {ProgramIdContext} from '../../progIdContext'
import Spinner from "../../../common/spinner";
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import RubricsPDF from "../../../pdf/rubricsPdf";
import unlockedIcon from '../../../../assets/imgs/unlockedIcon.svg';
import lockedIcon from '../../../../assets/imgs/lockedIcon.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Rubric = ({programRubricsOriginal, isRubricLocked}) => {
    const programId = useContext(ProgramIdContext);
    const [isRubricLockedState, setIsRubricLockedState] = useState(isRubricLocked);
    const [programRubrics, setProgramRubrics] = useState(programRubricsOriginal);
    const [activeTab, setActiveTab] = useState("screening");
    const [isLockModalOpen, setIsLockModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
    

    const [createRubricTemplate, { data:newRubric }] = useMutation(CREATE_RUBRIC_TEMPLATE,{
        onCompleted: (data) => {
            // Assuming the mutation returns the updated list of rubrics
            const rubricsToUpdate = data.createRubricTemplate.rubricTemplate.filter(elem => elem.isPreInterview === (activeTab === 'screening'))
            setRubrics(rubricsToUpdate);
            toast.success('Rubric Added Successfully');
            // filter so it will show only the rubrics for the active tab
        },
        onError: (error) => {
         toast.error('Error Adding Rubric',error);
        }
    });

    const [deleteRubricTemplate, { data: deleteData }] = useMutation(DELETE_RUBRIC_TEMPLATE, {
        onCompleted: (data) => {
            const rubricsToUpdate = data.deleteRubricTemplate.rubricTemplate.filter(elem => elem.isPreInterview === (activeTab === 'screening'))
            // Assuming the mutation returns the updated list of rubrics
            setRubrics(rubricsToUpdate);
            toast.success('Rubric Deleted Successfully');
        },
        onError: (error) => {
            toast.error('Error Deleting Rubric',error);
        }
    });

    const [lockRubrics, { data: lockData }] = useMutation(lockRubricsMutation, {
        onCompleted: (data) => {
            setIsRubricLockedState(data.lockRubrics.isRubricLocked);
            setIsLoading(false);
            setIsLockModalOpen(false);
            toast.success('Rubric Locked Successfully');
        },
        onError: (error) => {
            setIsLockModalOpen(false);
            toast.error('Error Locking Rubric',error);
        }
    });


    const [setRubricsWeight] = useMutation(updateRubricsWeight, {
        onCompleted: (data) => {
            const rubricsToUpdate = data.updateRubricsWeight.rubricTemplate.filter(elem => elem.isPreInterview === (activeTab === 'screening'))
            setRubrics(rubricsToUpdate);
            setIsLoading(false);
            handleCloseCategroriesModal();
            toast.success('Rubric Weights Updated Successfully');
        },
        onError: (error) => {
            toast.error('Error Updating Rubric Weights',error);
            setIsLoading(false);
        }
    });

    const [setDropdownOptions] = useMutation(updateDropdownOptions, {
        onCompleted: (data) => {
            console.log("data =>", data)
            const rubricsToUpdate = data.updateDropdownOptions.rubricTemplate.filter(elem => elem.isPreInterview === (activeTab === 'screening'))
            setRubrics(rubricsToUpdate);
            setIsLoading(false);
            toast.success('Rubric Categories Updated Successfully');
            // handleCloseCategroriesModal();
        },
        onError: (error) => {
            toast.error('Error Updating Rubric Categories',error);
            setIsLoading(false);
        }
    })


    const { data: rubricsData, error: robricsError, isLoading: robricsLoading } = useQuery(getRubricsAnsweredQuery, {
        variables: {
            programId
        },
    });



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rubrics, setRubrics] = useState(programRubrics.filter(elem => elem.isPreInterview === (activeTab === 'screening')));
    const [rubricsExists, setRubricsExists] = useState([]);
    const [rubricsNames, setRubricsNames] = useState([])

    const componentRef = useRef();


    useEffect(() => {
        if(programRubrics !== null && programRubrics !== undefined) {
            const names = programRubrics.reduce((acc, item) => {
                if (item.label) acc.push(item.label);
                return acc;
            }, []);
            console.log("names =>", names)
            setRubricsNames(names)
        }
    }, [programRubrics])



    if (robricsLoading) return <Spinner />;
    if (robricsError) return <p>Error :(</p>;
    else {
        console.log("rubricsData =>", rubricsData)
        if(rubricsData) {
            let rubricsFromQuery = rubricsData.getRubricsAnswered
            console.log("rubricsFromQuery =>", rubricsFromQuery)
            if(JSON.stringify(rubricsFromQuery) !== JSON.stringify(rubricsExists)) {
                setRubricsExists(rubricsFromQuery)
            }
        }
       
    }

    const sendRubricsWeightToMutation = (rubrics) => {
        setIsLoading(true);
        // set the rubrics in the right format:
        //    rubricId: ID
    // weight: Int
        const rubricsToSend = rubrics.map(rubric => {
            return {
                rubricId: rubric.id,
                weight: rubric.weight
            }
        }
        );
        setRubricsWeight({ variables: { programId: programId, input:rubricsToSend } });
    }



    const addRubric = (newRubric) => {
     createRubricTemplate({ variables: { programId: programId, input: newRubric } });
    };

    const updateRubric = (updatedRubric) => {
        const updatedRubrics = rubrics.map(rubric => {
            if (rubric.id === updatedRubric.id) {
                return updatedRubric;
            }
            return rubric;
        });
        setRubrics(updatedRubrics);
    }

    const deleteRubric = (_id) => {
        deleteRubricTemplate({ variables: { programId: programId, rubricTemplateId: _id } });

    }
   
    const moveRubric = (dragIndex, hoverIndex) => {
        const dragItem = rubrics[dragIndex];
        const newRubrics = [...rubrics];
        newRubrics.splice(dragIndex, 1);
        newRubrics.splice(hoverIndex, 0, dragItem);
    
        // Update the order based on the new index
        const updatedRubrics = newRubrics.map((rubric, index) => {
            return { ...rubric, order: index + 1 };
        });
        console.log(updatedRubrics);
    
        setRubrics(updatedRubrics);
    };


    const checkIfCanEdit = (rubric) => {
        if(isRubricLockedState) {
            return true;
        }
        let result = rubricsExists.includes(rubric.label)
        console.log("rubric =>", rubric, rubricsExists, result)
        return result
    }

    const checkIfCanUnlock = () => {
     if (rubricsExists.length > 0) {
         return true;
     }
    }

    const createRubricsPDF = async () => {
        const pages = Array.from(document.querySelectorAll('.a4-rubrics-container'))
        const canvases = await Promise.all(pages.map(page => {
            return html2canvas(page, {
                scale: 2, // Increase scale for better resolution
                useCORS: true,
                logging: true,
                scrollX: 0,
                scrollY: 0,
        })
        }))

        const pdf = new jsPDF('p', 'mm', 'a4')
        canvases.forEach((canvas, index) => {
            const imgData = canvas.toDataURL('image/JPEG', .5);
            
            // Skip this canvas if there's no image data
            if (imgData === 'data:,') {
                console.error('No image data in canvas');
                return;
            }
    
            const imgWidth = 190; // A4 width in mm
            const pageHeight = 340; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            
            if (index > 0) {
                pdf.addPage();
            }
        
            pdf.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight);
        });

        pdf.save("rubrics.pdf");

    }

    const exportRubricsToPDF = () => {
        const input = componentRef.current;
        const originalHeight = input.style.height;
        input.style.height = `${input.scrollHeight}px`;
    
        const opt = {
            margin: [10, 10, 10, 10], // Top, Right, Bottom, Left in mm
            filename: 'evaluationForm.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        };
    
        // Create a new jsPDF instance and add a title on the first page
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.setFontSize(20);
        pdf.text('EDIT EVALUATION FORM', 0, 20); // Title at top-left corner (10mm from top and left)
    
        // Continue with html2pdf to add the rest of the content
        html2pdf()
            .set({
                ...opt,
                jsPDF: pdf,
            })
            .from(input)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
                // The PDF will have the title on the first page and then the content
                pdf.save(opt.filename);
            })
            .finally(() => {
                // Revert the element's height back to its original value
                input.style.height = originalHeight;
            });
    };
    

const handleLockRubric = async() => {

    setIsLoading(true);
    lockRubrics({ variables: { programId: programId } });
    
}

const handleCloseCategroriesModal = () => {
    setIsCategoriesModalOpen(false);
};

const onUpdateDropdownOptions = (newOptions, selectedDropdown) => {
    console.log("onUpdateOptions", newOptions, selectedDropdown)
    setDropdownOptions({
        variables: {
            programId, 
            dropdownLabel: selectedDropdown.label,
            newOptions: JSON.stringify(newOptions)
        }
    })
}



 
    return (
        <div className='faculty-home-container'>
                      {isLockModalOpen && (
                 <div className='modal-backdrop'>
                     <div className='add-graduation-modal' style={{minHeight:'30vh'}}>
                        <ToastContainer
                        theme="colored"
                        />
                        <div className='modal-body' style={{marginBottom:'3vh'}}>
                            <p>
                            You are locking your evaluation form. Until you begin assigning reviewers, you may unlock and edit your evaluation forms.<strong> Once you have begun assigning reviewers, you will not be able to edit or remove items from your evaluation forms.</strong> Are you sure that you want to proceed?
                     </p>
                     </div>
                     {isLoading && 
                     <div className='modal-footer' style={{display:'flex' , gap:'2vw', alignSelf:'center' }}>
                        <Spinner />
                        </div>}
                     {!isLoading && (
                         
                         <div className='modal-footer' style={{display:'flex' , gap:'2vw', alignSelf:'center' }}>
                            <button className='green-button' onClick={handleLockRubric}>Lock Evaluation Forms</button>
                             <button className='green-button' onClick={() => setIsLockModalOpen(false)}>Cancel</button>
                    </div>
                            )}

                        </div>
                        </div>
                        )}

                        {isCategoriesModalOpen && (
                            <div className='modal-backdrop'>
                                <CategoriesValuesModal 
                                isOpen={isCategoriesModalOpen}
                                onClose={handleCloseCategroriesModal}
                                 rubrics={rubrics}
                                 activeTab={activeTab}
                                 onUpdateWeight={sendRubricsWeightToMutation}
                                  />
                            </div>
                        )}
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
  
            <div className="faculty-home-header">
                <h2 className="faculty-home-header-title">EDIT EVALUATION FORMS</h2>
                <p style={{textAlign:'justify'}} >Here you can create your program's customized evaluation form both for screening and interviewing applicants. Please read the following instructions carefully. To ensure that all applicants are reviewed with the same screening evaluation form, you must lock your evaluation form prior to assigning reviews on the 'Manage Reviews' page. Once you have begun assigning reviewers, you will no longer be able to remove or edit items in your screening evaluation form. Your interview evaluation form can be edited until you begin interviewing. </p>
                    {/* <seperator className="faculty-home-header-seperator"/> */}

                <div className="tabs-container">
                <div className="tabs-header" style={{flexDirection:'row-reverse', marginTop:'3vh'}}>
                <div className="buttons-row-rubric" style={{display:'flex', width:'45vw', gap:'2vw', marginBottom:'2vh', marginLeft:'auto'}}>
                {isRubricLockedState &&(
                    <button 
                    disabled={checkIfCanUnlock} onClick={handleLockRubric}
                     className="green-button"
                     style={{background:checkIfCanUnlock?'gray':'', cursor:checkIfCanUnlock?'not-allowed':''}}
                     >Locked
                     <img src={lockedIcon} alt="locked"  style={{width: '30px', height: '30px', marginRight: '10px'}}/> 
                     </button>
                     )}
                     {!isRubricLockedState &&(
                            <button className="green-button" onClick={() => setIsLockModalOpen(true)}>Unlocked
                            <img src={unlockedIcon} alt="unlocked" style={{width: '30px', height: '30px', marginRight: '10px'}}/>
                        </button>
                        )}
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={createRubricsPDF}><button className="green-button">Download Evaluation Forms</button></div>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={()=>setIsCategoriesModalOpen(!isCategoriesModalOpen)}><button className="green-button">Categories Weight</button></div>
                </div>
                <button
                    className={`tab ${activeTab === "interview" ? "active" : ""}`}
                    onClick={() => { setActiveTab("interview");  setRubrics(programRubrics.filter(elem => elem.isPreInterview === false ))}}
                    >
                    Interview Evaluation Form
                    </button>
                    <button
                    className={`tab ${activeTab === "screening" ? "active" : ""}`}
                    onClick={() => {setActiveTab("screening"); setRubrics(programRubrics.filter(elem => elem.isPreInterview === true ))}}
                    >
                    Screening Evaluation Form
                    </button>
                </div>
                {/* <div className="tabs-content">
                    {activeTab === "screening" ? (
                    <div className="tab-content">Content for Screening Rubric</div>
                    ) : (
                    <div className="tab-content">Content for Interview Rubric</div>
                    )}
                </div> */}
                </div>
            </div>
            
            
            <div ref={componentRef} style={{ height: '90vh', overflow: 'scroll'}}>
                <DndProvider backend={HTML5Backend}>
                <div className="faculty-home-body">

                {rubrics && rubrics.length > 0 && (
                    [...rubrics].sort((a, b) => a.order - b.order).map((rubric) => (
                        <RubricItem key={rubric._id} rubric={rubric} deleteRubric={deleteRubric} isRubricLocked={isRubricLocked} updateRubric={updateRubric} moveRubric={moveRubric} index={rubric.order-1} canEditDelete={checkIfCanEdit(rubric)} rubricsNames={rubricsNames} onUpdateDropdownOptions={onUpdateDropdownOptions}/>
                    ))
                )}

                {rubrics && rubrics.length === 0 &&(
                    <p>Your Evaluation Form Is Empty</p>
                )}


                </div>
                <div className="faculty-home-card-footer" style={{textAlign:'center', display:'flex', justifyContent:'center'}}>
                {/* <button className={checkIfCanEdit?"green-button":"green-button disabled"} disabled={checkIfCanEdit} onClick={() => setIsModalOpen(true)}>Add New Field</button> */}
                {(!isRubricLockedState||activeTab==='interview')&&<button className="green-button" onClick={() => setIsModalOpen(true)}>Add New Field</button>}
                <AddFieldModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}  onAddRubric={addRubric} lastRubricOrder={rubrics.length===0?0:rubrics[rubrics.length - 1].order} activeTab={activeTab}/>
                </div>
                </DndProvider>
            </div>
            {
                rubrics && 
                <div style={{ width: '235mm', height: '340mm', overflow: 'hidden', position: 'absolute', left: '-15000px'}}>
                {/* <div style={{ width: '235mm', height: '340mm'}}> */}
                    <RubricsPDF rubrics={programRubrics} />
                </div>
            }
            </div>
    );
}

export default Rubric;

