import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import './index.css'; // or your CSS file

// Sub-components
import LeftNavBar from './facultyComponents/LeftNavBar';
import FacultyHome from './facultyComponents/home';
import WorkFlows from './facultyComponents/workFlows';
import Rubric from './facultyComponents/rubric';
import CustomizeApplication from './facultyComponents/customizeApplication';
import MyTeam from './facultyComponents/myTeam';
import Recommendations from './facultyComponents/recommendations';
import Settings from './facultyComponents/settings';
import AccountAccess from './facultyComponents/accountAccess';
import ManageReviews from './facultyComponents/manageReviews';
import InterviewManager from './facultyComponents/interviewManager';
import Positions from './facultyComponents/positions';
import Applications from './facultyComponents/applications';
import Groups from './facultyComponents/groups';
import CompletedReviews from './facultyComponents/completedReviews';
import Tutorials from './facultyComponents/tutorials';
import AdminUsers from './facultyComponents/adminPortal/users';
import AdminSpecialties from './facultyComponents/adminPortal/specialties';
import AdminPrograms from './facultyComponents/adminPortal/programs';
import AdminMasterApplications from './facultyComponents/adminPortal/applications';
import AdminLors from './facultyComponents/adminPortal/lors';
import AdminDocuments from './facultyComponents/adminPortal/documents';
import AdminAnalytics from './facultyComponents/adminPortal/analytics';
import AdminInstitutions from './facultyComponents/adminPortal/institutions';
import AdminErasUpload from './facultyComponents/adminPortal/erasUpload';
import ContactUs from '../contactUs';
import Spinner from '../common/spinner';
import ProgramSettings from './facultyComponents/settings/programSettings';
import Communication from './facultyComponents/emailTemplate';
import Fairs from './facultyComponents/fairs';
import Leads from './facultyComponents/leads';

// Context & GraphQL
import { useQuery } from '@apollo/client';
import { getProgramById } from './facultyComponents/graphql/queries';
import { ProgramIdContext } from './progIdContext';
import { UserContext } from '../../context/userContext';

// Utility
const calculatePreSeason = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    const currentDate = new Date();
    const preSeasonDate = new Date('2024-09-16');
    return currentDate < preSeasonDate;
  }
  return false;
};

function Faculty() {
  const { user } = useContext(UserContext);
  const programId = user.programId;

  // GraphQL query
  const { loading, error, data, refetch } = useQuery(getProgramById, {
    variables: { id: programId },
  });

  // Early returns for loading/error
  if (loading) return <Spinner />;
  if (error) return <p>Error :(</p>;

  const isPreSeason = calculatePreSeason();

  return (
    <ProgramIdContext.Provider value={programId}>

        {/* LeftNavBar is always rendered; it uses NavLink/Link for navigation */}
        <LeftNavBar 
          userRoles={user.userPremissions} 
          isPreSeason={isPreSeason}
        />

        <main style={{ paddingLeft: '4vw' }}>
          <Routes>
            {/* Home */}
            <Route path="/" element={<FacultyHome />} />

            {/* Example routes */}
            <Route
              path="/positions"
              element={<Positions refetch={refetch} />}
            />
            <Route
              path="/applications"
              element={<Applications refetchProgram={refetch} />}
            />
            <Route
              path="/leads"
              element={<Leads refetchProgram={refetch} />}
            />
            <Route
              path="/fairs"
              element={<Fairs refetch={refetch} />}
            />
            <Route
              path="/interviewManager"
              element={<InterviewManager />}
            />
            <Route
              path="/emailTemplates"
              element={<Communication refetch={refetch} />}
            />

            {/* Example Admin sub-routes */}
            <Route path="/adminUsers" element={<AdminUsers />} />
            <Route path="/adminSpecialties" element={<AdminSpecialties />} />
            <Route path="/adminPrograms" element={<AdminPrograms />} />
            <Route path="/adminMasterApplications" element={<AdminMasterApplications />} />
            <Route path="/adminLors" element={<AdminLors />} />
            <Route path="/adminDocuments" element={<AdminDocuments />} />
            <Route path="/adminAnalytics" element={<AdminAnalytics />} />
            <Route path="/adminInstitutions" element={<AdminInstitutions />} />
            <Route path="/adminEras" element={<AdminErasUpload />} />

            {/* Additional routes */}
            <Route
              path="/evaluation-forms"
              element={
                <Rubric
                  programRubricsOriginal={data.getProgramById.rubricTemplate}
                  isRubricLocked={data.getProgramById.isRubricLocked}
                  refetchProgram={refetch}
                />
              }
            />
            <Route
              path="/customizeApplication"
              element={
                <CustomizeApplication 
                  screenedData={data.getProgramById.screenedData} 
                />
              }
            />
            <Route path="/myTeam" element={<MyTeam />} />
            <Route path="/recommendations" element={<Recommendations />} />
            <Route path="/contactUs" element={<ContactUs isNested user={user} programData={data} />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/Company-Settings" element={<ProgramSettings />} />
            <Route 
              path="/accountAccess" 
              element={<AccountAccess />} 
            />
            <Route
              path="/manageReviews"
              element={
                <ManageReviews 
                  isRubricLocked={data.getProgramById.isRubricLocked} 
                />
              }
            />
            <Route path="/groups" element={<Groups refetchProgram={refetch} />} />
            <Route path="/completedReviews" element={<CompletedReviews />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/workFlows" element={<WorkFlows refetch={refetch} />} />
          </Routes>
        </main>

    </ProgramIdContext.Provider>
  );
}

export default Faculty;
