import React, { useState, useEffect, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import allApplicationsIcon from '../../../../assets/imgs/allApplicationsIcon.svg'
import groupsIcon from '../../../../assets/imgs/groupsIcon.svg'
import individualApplicantsIcon from '../../../../assets/imgs/individualApplicants.svg'
import ReviewersTable from './reviewersTable';
import {tableColumns,
        summaryTableColumns,
        groupTableColumns,
        applicationsTableColumns,} from './tableProps';
import { useQuery,useMutation } from '@apollo/client';
import {GET_ALL_APPLICATIONS_FOR_ASSINGMENT} from './graphql/queries'
import {getGroupsByProgramIdQuery} from '../graphql/queries'
import {CREATE_REVIEWS} from './graphql/mutations'
import { ProgramIdContext } from '../../progIdContext';
import Spinner from '../../../common/spinner';
import ReviewerCard from './reviewerCard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const AssignReviewsModal = ({ isOpen, onClose, onAddUser, reviewers, refetchReviewerApplications, refetchReviewers }) => {
 const [step, setStep] = useState(1);
 const [step1Value, setStep1Value] = useState('');
 const [selectedReviewers, setSelectedReviewers] = useState([]);
 const [selectedGroups, setSelectedGroups] = useState([]);
 const [selectedRows, setSelectedRows] = useState([]);
 const [selectedApplications, setSelectedApplications] = useState([]);
 const [summaryTableData, setSummaryTableData] = useState([]);
 const [reviewFrequency, setReviewFrequency] = useState(0);
 const [applicationsTableData, setApplicationsTableData] = useState([])
 const [unassignedApplicationsTableData, setUnassignedApplicationsTableData] = useState([])
 const [groupsTableData, setGroupsTableData] = useState([])
 const [isEnoughReviewers, setIsEnoughReviewers] = useState(true);
 const [minimalReviewers, setMinimalReviewers] = useState(0);
 const programId = useContext(ProgramIdContext);
const [isLoading, setIsLoading] = useState(false);


 const [createReviews, { createReviewsData, createReviewsLoading, createReviewsError }] = useMutation(CREATE_REVIEWS);

 const { loading, error, data } = useQuery(GET_ALL_APPLICATIONS_FOR_ASSINGMENT, {
    variables: { programId: programId }, 
});

const { loadingGroups, errorGroups, dataGroups } = useQuery(getGroupsByProgramIdQuery, {
    variables: { programId: programId, filter: {field: 'name', value: null} },
    dataGroup: data,
    onCompleted: (data) => {
        // clean the data format
        const cleanedData = data.getGroupsByProgramId.map(group => {
            return {
                id: group.id,
                name: group.name,
                numApplications: group.applicationIds.length,
                applicationsIds: group.applicationIds
            }
        }
        );
        setGroupsTableData(cleanedData);

    },
    onError: (error) => {
        console.log('Query error:', error);
    },
});

useEffect(() => {
    if (!loading && !error && data) {

        const cleanedData = data.getAllApplicationsForAssingment.map(app => {
            return {
                id: app.id,
                name: app.firstName+' '+app.lastName,
                firstName: app.firstName,
                lastName: app.lastName,
            }
        }
        );
        cleanedData.sort((a, b) => a.lastName.localeCompare(b.lastName));
        setApplicationsTableData(cleanedData);

        const unassignedData = data.getAllApplicationsForAssingment.filter(app => app.reviewDetails.length===0).map(app => {
            return {
                id: app.id,
                name: app.firstName+' '+app.lastName,
                firstName: app.firstName,
                lastName: app.lastName,
                reviewDetails: app.reviewDetails
            }
        }
        );
        console.log('unassignedData', unassignedData);
    }
}, [loading, error, data]);


useEffect(() => {
    console.log('Selected Applications', selectedApplications);
}, [selectedApplications]);

 useEffect(() => {
    prepareSummaryTableData();
    if(selectedReviewers.length ===1){
        setReviewFrequency(1);
    }

}, [selectedReviewers]);



useEffect(() => {
  
        const reviewPerReviewer = Math.floor((selectedApplications.length / selectedReviewers.length)*reviewFrequency);
        const remainder = selectedApplications.length % selectedReviewers.length;
        let quotient = reviewPerReviewer;

        let data = [];
        summaryTableData.sort((a, b) => a.status - b.status);

        summaryTableData.forEach((reviewer, index) => {
            let assignedReviews = quotient;
            if (index < remainder) {
                assignedReviews += 1;
            }
            data.push({
                id: reviewer.id,
                name: reviewer.name,
                status: reviewer.status,
                toBeAssigned: assignedReviews
            });
        });

       
        console.log('im testing the data',data);
        setSummaryTableData(data);
}
 ,[reviewFrequency]);



 const handleClose = () => {
    if (step === 1) {
        setStep(1);
        setStep1Value('');
        onClose();
    }
    if (step !== 1) {

    if (step1Value === 'all' && step === 3 ) {
        setSelectedReviewers([]);
        setMinimalReviewers(0);
        setIsEnoughReviewers(true);
    }
     if (step1Value === 'allUnassigend' && step === 3 ) {
        setSelectedReviewers([]);
        setMinimalReviewers(0);
        setIsEnoughReviewers(true);
    }
    if (step1Value ==='groups'){
        if (step === 3) {
            setSelectedGroups([]);
        }
        if (step === 4  ) {
            setSelectedReviewers([]);
            setMinimalReviewers(0);
            setIsEnoughReviewers(true);
        }
    }
    if (step1Value ==='individual'){
        if (step === 3) {
            setSelectedApplications([]);
        }
        if (step === 4 ) {
            setSelectedReviewers([]);
            setMinimalReviewers(0);
            setIsEnoughReviewers(true);
        }
    }
    setStep(step-1);
}
 }

const handleProgress = () => {

 if (step1Value === 'all') {
if (step === 1) {
    setStep(step+1);
}

if (step === 2) {
    setSelectedApplications(applicationsTableData)
    setSelectedReviewers([...selectedReviewers, ...selectedRows]);
    console.log("update selected reviewers =>", [...selectedReviewers, ...selectedRows])
    if(selectedReviewers.length ===1){
        setReviewFrequency(1);
    }else{
        setSelectedRows([]);
        setStep(step+1);
    }
}
if (step1Value === 'all' && step === 3 && isEnoughReviewers) {
    setIsLoading(true);
    setAssignments();
    // onClose();
    setSelectedReviewers([]);
    setSelectedApplications([]);
    setMinimalReviewers(0);

}
    }


 if (step1Value === 'allUnassigned') {
if (step === 1) {
    setStep(step+1);
}

if (step === 2) {
    setSelectedApplications(applicationsTableData)
    setSelectedReviewers([...selectedReviewers, ...selectedRows]);
    if(selectedReviewers.length ===1){
        setReviewFrequency(1);
    }else{
        setSelectedRows([]);
        setStep(step+1);
    }
}
if (step1Value === 'allUnassigned' && step === 3 && isEnoughReviewers) {
    setIsLoading(true);
    setAssignments();
    // onClose();
    setSelectedReviewers([]);
    setSelectedApplications([]);
    setMinimalReviewers(0);

}
    }

if (step1Value === 'groups' ) {
    if (step === 1) {
        setStep(step+1);
    }
    if (step === 2) {
    // take the different applicationsIds arrays from the selected groups and combine them into one array
    const applicationsIds = selectedRows.map(row => row.original.applicationsIds).flat()
    // now I need you to put the array of ids, as an array of objects, with the id key
    const applicationsIdsObjects = applicationsIds.map(id => ({ id: id }));

    // flatten the array of applicationsIds
    // setSelectedApplications(applicationsIds);
    setSelectedApplications(applicationsIdsObjects);
    setSelectedRows([]);
    setStep(step+1);
    }
    if (step === 3) {
    setSelectedReviewers([...selectedReviewers, ...selectedRows]);
    if(selectedReviewers.length ===1){
        setReviewFrequency(1);
    }else{
        setSelectedRows([]);
        setStep(step+1);
    }

    }
    if (step === 4 && isEnoughReviewers) {
        setIsLoading(true);
        setAssignments();
        // onClose();
        setSelectedReviewers([]);
        setSelectedApplications([]);
        setMinimalReviewers(0);
    }

}
if (step1Value === 'individual' ) {
    if (step === 1) {
        setStep(step+1);
    }
    if (step === 2) {
    const selectedRowsForUpdate = selectedRows.map(row => row.original);
    setSelectedApplications([...selectedApplications, ...selectedRowsForUpdate]);

    setSelectedRows([]);
    setStep(step+1);
    }
    if (step === 3) {
        setSelectedReviewers([...selectedReviewers, ...selectedRows]);
        if(selectedReviewers.length ===1){
            setReviewFrequency(1);
        }else{
            setSelectedRows([]);
            setStep(step+1);
        }
    }
    if (step === 4 && isEnoughReviewers) {
        setIsLoading(true);
        setAssignments();
        // onClose();
        setSelectedReviewers([]);
        setSelectedApplications([]);
        setMinimalReviewers(0);
    }
}
}


const updateSelectedRows = (row) => {
    console.log("row, selectedRows =>", row, selectedRows)
    var selectedCpy = [...selectedRows]
    const idx = selectedCpy.findIndex(elem => elem._id.toString() === row._id.toString())
    console.log("idx =>", idx)
    if(idx > -1) {
        // element already selected - remove it
        selectedCpy.splice(idx, 1);
        console.log("element removed from selected rows =>", selectedCpy)
        setSelectedRows(selectedCpy)
    } else {
        // element not exists - add it 
        selectedCpy.push(row)
        console.log("element added to selected rows =>", selectedCpy)
        setSelectedRows(selectedCpy)
    }
}

const prepareSummaryTableData = () => {
    // Map over selectedReviewers to create new objects
    let data = selectedReviewers.map(reviewer => ({
        id: reviewer._id,
        name: reviewer.name,
        status: reviewer.totalReviews,
        toBeAssigned: 0 // Initialize toBeAssigned to 0 or any default value
    }));

    // Sort the data array by status
    data.sort((a, b) => a.status - b.status);

    // Set the new data to summaryTableData state
    setSummaryTableData(data);
}

const setAssignments = () => {
    let assignments = [];
    let assignmentSet = new Set(); // To keep track of assignments

    // Create arrays for applicationIds and reviewerIds, each repeated by review frequency
    let applicationIds = selectedApplications.map(app => app.id);
    let reviewerIds = selectedReviewers.map(reviewer => reviewer.original._id);

console.log(applicationIds, 'applicationIds');
console.log(reviewerIds, 'reviewerIds');

    // Calculate the total number of reviews needed
    let totalReviewsNeeded = applicationIds.length * reviewFrequency;

    // Shuffle the applicationIds to ensure randomness
    applicationIds = applicationIds.sort(() => Math.random() - 0.5);

    if (reviewFrequency === reviewerIds.length) {
        // If the review frequency is equal to the number of reviewers, assign each reviewer to each application
        for (let i = 0; i < reviewerIds.length; i++) {
            for (let applicationId of applicationIds) {
            let assignment = {
                reviewerId: reviewerIds[i],
                applicationId: applicationId,
                programId: programId,
                stage: 'Pending'
            };

            // Convert assignment to a string to be able to store it in a Set
            let assignmentStr = JSON.stringify(assignment);

            // If this assignment has not been made before, add it to the assignments array and the Set
            if (!assignmentSet.has(assignmentStr)) {
                assignments.push(assignment);
                assignmentSet.add(assignmentStr);
            }
        }
    }
    }else{

    // Assign applications to reviewers
    for (let i = 0; i < totalReviewsNeeded; i++) {
        let applicationIndex = i % applicationIds.length;
        let reviewerIndex = i % reviewerIds.length;

        let assignment = {
            reviewerId: reviewerIds[reviewerIndex],
            applicationId: applicationIds[applicationIndex],
            programId: programId,
            stage: 'Pending'
        };

        // Convert assignment to a string to be able to store it in a Set
        let assignmentStr = JSON.stringify(assignment);

        // If this assignment has not been made before, add it to the assignments array and the Set
        if (!assignmentSet.has(assignmentStr)) {
            assignments.push(assignment);
            assignmentSet.add(assignmentStr);
        }
    }
}
    // Log the assignments for debugging
    console.log(assignments, 'assignments');

    // Create reviews using the assignments
    createReviews({ variables: { input: { Reviews: assignments } } }).then(res => {
        setIsLoading(true);
        refetchReviewerApplications();
        refetchReviewers();
        setIsLoading(false);
        onClose();
        toast.success('Reviews assigned successfully!');
    }).catch(err => {
        setIsLoading(false);
        toast.error('Failed to assign reviews');
    }
    );
}

return (

<div className="modal-backdrop">
{isLoading&&
<Spinner/>}
{!isLoading&&
<div className="assign-reviews-modal" style={{minHeight:'75vh', placeContent:'space-evenly', display:'flex', flexDirection:'column'}}>
    <ToastContainer
        theme="colored"
    />

    <div className='add-field-modal-header'>
    {step===1&&<h2>HOW WOULD YOU LIKE TO ASSIGN APPLICANTS?</h2>}
    {((step===2&&step1Value==='all')||(step===3&&step1Value!=='all'))&&<h2>Select Reviewers</h2>}
    {step===2&&step1Value==='groups'&&<h2>SELECT FOLDERS</h2>}
    {step===2&&step1Value==='individual'&&<h2>SELECT APPLICANTS</h2>}
    {((step===3&&step1Value==='all')||step === 4 && step1Value!=='all')&&<h2>REVIEW FREQUENCY</h2>}


    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>
    {/* Step 1 */}
    {step === 1 && (
    <div className="assign-reviews-modal-first-step" style={{display:'flex', gap:'2vw',marginTop:'4vh'}}>

        <div className={step1Value==='all'?"assign-reviews-modal-icon-cont-active":"assign-reviews-modal-icon-cont"} onClick={() => setStep1Value('all')}>
            {/* <img src={allApplicationsIcon} alt="All Applications Icon" className="step-icon"/> */}
            <h3>All Applicants</h3>
        </div>
        <div className={step1Value==='groups'?"assign-reviews-modal-icon-cont-active":"assign-reviews-modal-icon-cont"} onClick={() => setStep1Value('groups')}>
            {/* <img src={groupsIcon} alt="Groups Icon" className="step-icon"/> */}
            <h3>Folders</h3>
        </div>
        <div className={step1Value==='individual'?"assign-reviews-modal-icon-cont-active":"assign-reviews-modal-icon-cont"}  onClick={() => setStep1Value('individual')}>
            {/* <img src={individualApplicantsIcon} alt="Individual Applicants Icon" className="step-icon"/> */}
            <h3>Individual Applicants</h3>
        </div>
        {/* <div className={step1Value==='allUnassigned'?"assign-reviews-modal-icon-cont-active":"assign-reviews-modal-icon-cont"} onClick={() => setStep1Value('allUnassigned')}>
            <img src={allApplicationsIcon} alt="All Applications Icon" className="step-icon"/>
            <h3 style={{textAlign:'center'}}>All Unassigned Applications</h3>
        </div> */}
    </div>
    )}
    {/* All Applicants rendering */}
    {step === 2 && step1Value==='all'&&(
        <div className="assign-reviews-modal-second-step reviewers-container">
            {
                reviewers.map(row => (
                    <ReviewerCard reviewer={row} selectedRows={selectedRows} updateSelectedRows={updateSelectedRows}/>
                ))
            }
            {/* <ReviewersTable columns={tableColumns} data={reviewers} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/> */}
        </div>
    )}

    {step === 3 && step1Value==='all'&&(
        <div className="assign-reviews-modal-second-step">
            {selectedReviewers.length > 1 && (
                <div style={{display:'flex',flexDirection:'column'}}>
                <label>How many times would you like each application to be reviewed</label>

                    <select style={{
                                  width: '100%',
                                  height: '8vh',
                                  boxSizing: 'border-box',
                                  color: '#414141',
                                  background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                                  border: '.1vw solid #dbdbdb',
                                  borderRadius: '8px',
                                  outline: 'none',
                                  padding: '1vh 2vw',  // Note: last padding value used, overriding previous
                                  fontFamily: 'Montserrat',
                                  fontSize: '3vh',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '2vh',
                                  zIndex:'5'
                                }}
                                onChange={(e) =>  setReviewFrequency(+e.target.value)}>
 <option value="">Select a number</option>
    {[...Array(selectedReviewers.length).keys()].map((value) => 
        <option key={value + 1} value={value + 1}>{value + 1}</option>
    )}
</select>
</div>
            )}
            {!isEnoughReviewers && 
    <div style={{fontWeight:'500', color:'red', fontFamily:'Montserrat', textAlign:'center'}}>
        You need at least {minimalReviewers} reviewers to review {selectedApplications.length} applications without any duplicates. Please add more reviewers, decrease the review frequency, or remove some applications.
    </div>
}
        <ReviewersTable columns={summaryTableColumns} data={summaryTableData}/>
    </div>
    )}

    {/* Group Assignment rendering */}

    {step === 2 && step1Value==='groups'&&(
        <div className="assign-reviews-modal-second-step">
            {loadingGroups && <Spinner />}
            <ReviewersTable columns={groupTableColumns} data={groupsTableData} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
        </div>
    )}
    {step === 3 && step1Value==='groups'&&(
         <div className="assign-reviews-modal-second-step">
         <ReviewersTable columns={tableColumns} data={reviewers} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
     </div>
        )}
     {step === 4 && step1Value==='groups'&&(
        <div className="assign-reviews-modal-second-step">
                     {selectedReviewers.length > 1 && (
                <div style={{display:'flex',flexDirection:'column'}}>
                <label>How many times would you like each application to be reviewed</label>
                <select style={{
                                  width: '100%',
                                  height: '8vh',
                                  boxSizing: 'border-box',
                                  color: '#414141',
                                  background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                                  border: '.1vw solid #dbdbdb',
                                  borderRadius: '8px',
                                  outline: 'none',
                                  padding: '1vh 2vw',  // Note: last padding value used, overriding previous
                                  fontFamily: 'Montserrat',
                                  fontSize: '3vh',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '2vh',
                                  zIndex:'5'
                                }} 
                                onChange={(e) =>  setReviewFrequency(+e.target.value)}>
 <option value="">Select a number</option>
    {[...Array(selectedReviewers.length).keys()].map((value) => 
        <option key={value + 1} value={value + 1}>{value + 1}</option>
    )}
</select>
</div>
            )}
            {!isEnoughReviewers && 
    <div style={{fontWeight:'500', color:'red', fontFamily:'Montserrat', textAlign:'center'}}>
        You need at least {minimalReviewers} reviewers to review {selectedApplications.length} applications without any duplicates. Please add more reviewers, decrease the review frequency, or remove some applications.
    </div>
}
        <ReviewersTable columns={summaryTableColumns} data={summaryTableData}/>
    </div>
        )}


        {/* Individual Applications Assignment rendering */}

        {step === 2 && step1Value==='individual'&&(
        <div className="assign-reviews-modal-second-step">
            <ReviewersTable columns={applicationsTableColumns} data={applicationsTableData} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
        </div>
    )}
        {step === 3 && step1Value==='individual'&&(
        <div className="assign-reviews-modal-second-step" style={{overflowY:'scroll', maxHeight:'69vh'}}>
            <ReviewersTable columns={tableColumns} data={reviewers} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
        </div>
    )}

{step === 4 && step1Value==='individual'&&(
        <div className="assign-reviews-modal-second-step">
                                      {selectedReviewers.length > 1 && (
                <div style={{display:'flex',flexDirection:'column'}}>
                <label>How many times would you like each application to be reviewed</label>
                <select style={{
                                  width: '100%',
                                  height: '8vh',
                                  boxSizing: 'border-box',
                                  color: '#414141',
                                  background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
                                  border: '.1vw solid #dbdbdb',
                                  borderRadius: '8px',
                                  outline: 'none',
                                  padding: '1vh 2vw',  // Note: last padding value used, overriding previous
                                  fontFamily: 'Montserrat',
                                  fontSize: '3vh',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '2vh',
                                  zIndex:'5'
                    }} 
                    onChange={(e) => {setReviewFrequency(Number(e.target.value))}}>
                    {/* // onChange={(e) => {console.log(Number(e.target.value))}}> */}
                        <option value="">Select a number</option>
                        {[...Array(selectedReviewers.length).keys()].map((value) => 
                            <option key={value + 1} value={value + 1}>{value + 1}</option>
                        )}
                    </select>
</div>
            )}
{!isEnoughReviewers && 
    <div style={{fontWeight:'500', color:'red', fontFamily:'Montserrat', textAlign:'center'}}>
        You need at least {minimalReviewers} reviewers to review {selectedApplications.length} applications without any duplicates. Please add more reviewers, decrease the review frequency, or remove some applications.
    </div>
}
        <ReviewersTable columns={summaryTableColumns} data={summaryTableData}/>
    </div>
        )}


    <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw', marginTop:'3vh'}}>
                    <button type="button" className='white-button' onClick={handleClose} >{step===1?'Cancel':'Back'}</button>
                    <button disabled={step1Value===''} type="button" className='gradient-button' onClick={()=>handleProgress()}>
                    {step === 1 ? 'Next' : ((step === 3 && step1Value === 'all') || (step === 4 && step1Value !== 'all')) ? 'Assign' : 'Next'}
                    </button>
                    </div>

</div>
}
</div>
)
};

export default AssignReviewsModal;