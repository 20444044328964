import React, { useState, useContext } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { CREATE_MANUAL_APPLICATION } from './graphql/mutations';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddressAutocomplete from '../../../common/addressAutoComplete';


const AddApplicantModal = ({ isOpen, onClose, selectedPosition, programId ,refetchApplications, statusRefetchCount}) => {
  const [isEdit, setIsEdit] = useState(true); 
  

  const [createApplication] = useMutation(CREATE_MANUAL_APPLICATION);
  


  let initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
    genderIdentity: '',
    homeAddress: '',
    workAuthorization: '',
    otherWorkAuthorization: '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    dateOfBirth: Yup.date().required('Date of Birth is required'),
    genderIdentity: Yup.string().required('Gender is required'),
    homeAddress: Yup.string().required('Address is required'),
    workAuthorization: Yup.string().required('Work Authorization is required'),
    otherWorkAuthorization: Yup.string().when('workAuthorization', {
      is: 'Other',
      then: Yup.string().required('Other Work Authorization is required'),
    }),
  });

  const handleSubmit = (values, { setSubmitting }) => {


    
    createApplication({
        variables: {
            input: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phoneNumber,
                dateOfBirth: values.dateOfBirth,
                gender: values.genderIdentity,
                homeAddress: values.homeAddress,
                workAuthorization: values.workAuthorization,
                positionId: selectedPosition,
                programId: programId
            }
        }
    })
    .then((res) => {
      toast.success('Application successfully created');
      refetchApplications();
      statusRefetchCount();
      setSubmitting(false);
      onClose();
    })
    .catch((err) => {
      toast.error('Error creating application');
      setSubmitting(false);
    }
    );
    
  };


  const handleExit = () => {
    initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        genderIdentity: '',
        homeAddress: '',
        workAuthorization: '',
        otherWorkAuthorization: '',
      };
    
    onClose();
  }



  return (
    <div className="modal-backdrop" style={{ zIndex: '25' }}>
      <div className="add-field-modal" style={{ height: '70vh', overflow: 'unset', width:'65vw' }}>
      <ToastContainer 
                    theme= "colored"
                  />
        <div className="add-field-modal-header">
          <h2>Add Applicant</h2>
          <img src={logo} alt="Logo" className="logo" style={{ width: '4vw' }} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isValid, isSubmitting,dirty }) => (
            <Form>
                <div className='form-modal-body' style={{marginBottom:'3vh', overflowY:'auto', height:'45vh'}}>

              {/* First Name */}
              <div style={{display:'flex', gap:'1vw'}}>
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="firstName" className="applicant-form-field-label">
                  First Name <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="firstName"
                  placeholder="First Name"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="firstName" component="div" />
                </div>
              </div>

              {/* Last Name */}
              <div className="application-form-field-group">
                <label htmlFor="lastName" className="applicant-form-field-label">
                  Last Name <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="lastName"
                  placeholder="Last Name"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="lastName" component="div" />
                </div>
              </div>
              </div>


              <div style={{display:'flex', gap:'1vw'}}>
              {/* Email */}
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="email" className="applicant-form-field-label">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="email" component="div" />
                </div>
              </div>

              {/* Phone Number */}
              <div className="application-form-field-group">
                <label htmlFor="phoneNumber" className="applicant-form-field-label">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="phoneNumber" component="div" />
                </div>
              </div>

              </div>

              <div style={{display:'flex', gap:'1vw'}}>
              {/* Date of Birth */}
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="dateOfBirth" className="applicant-form-field-label">
                  Date of Birth <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="dateOfBirth"
                  placeholder="MM/DD/YYYY"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="dateOfBirth" component="div" />
                </div>
              </div>

              {/* Gender */}
              <div className="application-form-field-group">
                <label htmlFor="genderIdentity" className="applicant-form-field-label">
                  Gender <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  as="select"
                  name="genderIdentity"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                >
                  <option value="">Select Gender</option>
                  <option value="woman">Woman</option>
                  <option value="man">Man</option>
                  <option value="transWoman">Transgender Woman</option>
                  <option value="transMan">Transgender Man</option>
                  <option value="nonBinary">Gender Nonbinary</option>
                  <option value="notListed">Gender Not Listed</option>
                  <option value="noResponse">Prefer Not To Respond</option>
                </Field>
                <div className="application-form-error-message">
                  <ErrorMessage name="genderIdentity" component="div" />
                </div>
              </div>

              </div>

              {/* Address */}
              <div className="application-form-field-group" style={{width:'60vw'}}>
                <label htmlFor="homeAddress" className="applicant-form-field-label">
                  Address <span style={{ color: 'red' }}>*</span>
                </label>
                {/* <Field
                  name="homeAddress"
                  placeholder="Mailing Address"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                  style={{ width: '56.5vw' }}
                /> */}
                <AddressAutocomplete />
                <div className="application-form-error-message">
                  <ErrorMessage name="homeAddress" component="div" />
                </div>
              </div>

              {/* Work Authorization */}
              <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
  <label className='applicant-form-field-label'>Work Authorization (U.S.)<span style={{ color: 'red' }}>*</span></label>
        <Field as="select" name="workAuthorization"className='application-form-standard-input one' onChange={(event) => setFieldValue("workAuthorization", event.target.value)} disabled={!isEdit} style={{ marginBottom: '1vh'}}>
          <option value="">Select Work Authorization</option>
          <option value="U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)">U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)</option>
          <option value="Green Card Applicant Undergoing Adjustment of Status (with EAD)">Green Card Applicant Undergoing Adjustment of Status (with EAD)</option>
          <option value="DACA Recipient (Deferred Action for Childhood Arrivals)">DACA Recipient (Deferred Action for Childhood Arrivals)</option>
          <option value="E-2 Treaty Investor, Including Spouse and Children (with EAD)">E-2 Treaty Investor, Including Spouse and Children (with EAD)</option>
          <option value="Holder of Employment Authorization Document (EAD)">Holder of Employment Authorization Document (EAD)</option>
          <option value="F-1 Academic Student (with EAD, OPT)">F-1 Academic Student (with EAD, OPT)</option>
          <option value="H-1 Temporary Worker">H-1 Temporary Worker</option>
          <option value="H-1B Specialized Occupation, Department of Defense Worker, etc.">H-1B Specialized Occupation, Department of Defense Worker, etc.</option>
          <option value="H-2B Temporary Worker (Skilled or Unskilled)">H-2B Temporary Worker (Skilled or Unskilled)</option>
          <option value="H-4 Spouse or Child of H-1, H-2, or H-3 Visa Holder (with EAD)">H-4 Spouse or Child of H-1, H-2, or H-3 Visa Holder (with EAD)</option>
          <option value="J-1 Exchange Visitor">J-1 Exchange Visitor</option>
          <option value="J-2 Spouse or Child of J-1 Visa Holder (with EAD)">J-2 Spouse or Child of J-1 Visa Holder (with EAD)</option>
          <option value="L-2 Dependent of Intra-Company Transferee (with EAD)">L-2 Dependent of Intra-Company Transferee (with EAD)</option>
          <option value="O-1 Individual with Extraordinary Ability in Sciences, Arts, Education, Business, or Athletics">O-1 Individual with Extraordinary Ability in Sciences, Arts, Education, Business, or Athletics</option>
          <option value="TN NAFTA Trade Visa (Canadians and Mexicans)">TN NAFTA Trade Visa (Canadians and Mexicans)</option>
          {/* <option value="Other">Other</option> */}
          </Field>
          <div className='application-form-error-message'> 
          <ErrorMessage name="workAuthorization" component="div" />
          </div>
          </div>
          {
            values.workAuthorization === "Other" &&
            <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
            <label htmlFor="otherWorkAuthorization" className='applicant-form-field-label'>Other Work Authorization <span style={{ color: 'red' }}>*</span></label>
            <Field name="otherWorkAuthorization" placeholder="Other Work Authorization" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
            <div className='application-form-error-message'> 
            <ErrorMessage name="otherWorkAuthorization" component="div" />
            </div>
            </div>
          }
          </div>
            
            <div className='buttons-row' style={{marginBottom:'3vh', display:'flex', gap:'2vw', justifyContent:'center'}}>
              {/* Submit Button */}
              <button type="submit" className="green-button" disabled={!isValid || isSubmitting ||!dirty}>
                Save Applicant
              </button>
              <button  
              className="green-button" 
              style={{width:'12vw', background:'white', color:'#145388', border:'1px solid #145388'}}
              onClick={handleExit}
              >
                Cancel
              </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddApplicantModal;
