import React, { useState,useContext, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormContext } from '../ApplicationForm';
import uploadFile from '../../../../assets/imgs/uploadFile.svg';
import { Tooltip } from 'reactstrap';
import infoIcon from '../../../../assets/imgs/infoIcon.svg';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg';
import Spinner from '../../../common/spinner';
import logo from '../../../../assets/imgs/logo.svg'
import Select from 'react-select';
import { UserContext } from '../../../../context/userContext';
import packageInfo from '../../../../../package.json';




const ApplicationFormStep1 = ({ validateForm, isValid, step, isNested, applicationData, applicant, updateUserData, updatePhase, phases, viewOnly }) => {
  const { values, setFieldValue, setStep, handleSubmit, isSubmitting } = useContext(FormContext);
  const [isEdit, setIsEdit] = useState(!viewOnly ? phases.firstPhase? false : true : false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [tooltipPhone, setTooltipPhoneOpen] = useState(false);
  const [usmleTooltip, setUsmleTooltip] = useState(false);
  const [mailingTooltip,setMailingTooltip] = useState(false);
  const [permanentTooltip,setPermanentTooltip] = useState(false);
  const togglePermanentTooltip = () => setPermanentTooltip(!permanentTooltip);
  const toggleMailingTooltip = () => setMailingTooltip(!mailingTooltip);
  const toggleUsmleTooltip = () => setUsmleTooltip(!usmleTooltip);
  const togglePhoneTooltip = () => setTooltipPhoneOpen(!tooltipPhone);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmImageModal, setConfirmImageModal] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [finishSave, setFinishSave] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const {user} = useContext(UserContext);
  console.log('medSchoolType:', user.schoolType);



  const raceEthnicityOptions = [
    { id: "preferNotToRespond", label: "Prefer not to respond", value:"Prefer not to respond" },
    { id: "americanIndianOrAlaskaNative", label: "American Indian or Alaska Native", value:"American Indian or Alaska Native" },
    { id: "asian", label: "Asian", value:"Asian" },
    { id: "blackOrAfricanAmerican", label: "Black or African American", value:"Black or African American" },
    { id: "hispanicOrLatino", label: "Hispanic or Latino", value:"Hispanic or Latino" },
    { id: "nativeHawaiianOrOtherPacificIslander", label: "Native Hawaiian or Other Pacific Islander", value:"Native Hawaiian or Other Pacific Islander" },
    { id: "white", label: "White", value:"White" },
    { id: "other", label: "Other", value:"Other" }
  ]



const handleImageChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  }
};

useEffect(() => {
  let timer;
  if (finishSave) {
    timer = setTimeout(() => {
      setFinishSave(false);
    }, 5000); // 5 seconds
  }

  // Cleanup timer
  return () => clearTimeout(timer);
}, [finishSave]);

const handleCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};

const handleUpload = async () => {
  setIsLoading(true);
  const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
  const formData = new FormData();
  formData.append('image', croppedBlob); // Append the cropped image blob
  formData.append('loginToken', localStorage.getItem('login_token'));

  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload_profile`, {
    method: 'POST',
    headers: { 'App-Version': packageInfo.version },
    body: formData
  });
  const data = await response.json();
  console.log('Image uploaded successfully:', data.imageUrl);
  setCroppedImage(null); // Clear the cropping state after upload

  // Update the applicant profile photo URL in the parent component
  updateUserData({ ...values, profilePhotoUrl: data.imageUrl }, 1);

  // Set imageSrc to the new image URL
  setImageSrc(data.imageUrl);
  setIsLoading(false);
  setConfirmImageModal(true)
};

const handleCancelCrop = () => {
  setImageSrc(null);
  setCrop({ x: 0, y: 0 });
  setZoom(1);
  setCroppedAreaPixels(null);
  setCroppedImage(null);
};


const onChecked = (selectedOptions) => {
  let newChecked = selectedOptions.map(option => option.value);

  // Check if "Prefer not to respond" is selected
  if (newChecked.includes("Prefer not to respond")) {
    newChecked = ["Prefer not to respond"]; // Only "Prefer not to respond" should be selected
  } else {
    // Remove "Prefer not to respond" if other options are selected
    const preferNotToRespondIndex = newChecked.indexOf("Prefer not to respond");
    if (preferNotToRespondIndex !== -1) {
      newChecked.splice(preferNotToRespondIndex, 1);
    }
  }

  setFieldValue("raceEthnicity", newChecked); // Update Formik's state
};


  const checkDisabled = (values, isSubmitting) => {
    return isSubmitting || !values.firstName || !values.lastName || !values.email || !values.phoneNumber ||
    (values.dateOfBirth && !/^\d{2}\/\d{2}\/\d{4}$/.test(values.dateOfBirth)) 
    || !values.homeAddress ||  !values.workAuthorization || !values.linkedinLink ||
    !values.misdemeanorOrFelony  || (values.misdemeanorOrFelony === 'yes' && !values.misdemeanorOrFelonyDetails)
  }


  const handleSave = async () => {
    if (saveError !== null) {
      setSaveError(null); 
    } 
    setIsSaveLoading(true)
    try {
      await updateUserData(values, 1);
      setIsSaveLoading(false);
      setFinishSave(true); // Indicate success
    } catch (errorMessage) {
      setIsSaveLoading(false);
      setSaveError(errorMessage); // Indicate failure
    }
  }
  


  return(
  <>
  {
    !viewOnly &&
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', width: '94%', flexDirection: 'column'}}>
      {finishSave && <div className="floating-btn" style={{ fontFamily: 'Montserrat', color: "#145388", marginBottom: '2vh', marginTop: '-4vh'}}>Application saved!</div>}
      {saveError && <div className="floating-btn" style={{ color:'red',fontFamily: 'Montserrat', marginBottom: '2vh', marginTop: '-4vh'}}>Not Saved. please try again.</div>}
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '15%', flexDirection: 'row'}}>
      {!isSaveLoading&&<button className='white-button floating-btn'  style={{width: '10vw'}} disabled={!isEdit} onClick={ () => handleSave()}>Save</button>}
    { isSaveLoading && <div className='floating-btn' style={{ width: '20px', top:'7vh', right:'10vw'}}><Spinner/></div>}
      </div>
    </div>
      

  }
     
      <div className='step1-upper-container' style={{marginBottom:'0'}}>
        <div style={{marginTop:'3vh', marginRight:'1vw', marginBottom:'0'}}>
        {isLoading ? (
          <Spinner/>
) : (
  imageSrc ? (
    <div>
      <div className="crop-container">
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={handleCropComplete}
        />
      </div>
      <div className="crop-buttons">
        <button onClick={handleUpload} style={{ fontFamily: 'Montserrat'}}>Upload Image</button>
        <button onClick={handleCancelCrop} style={{ fontFamily: 'Montserrat'}}>Cancel</button>
      </div>
    </div>
  ) : (
    <>
      <img id="previewImage" style={{margin:'0'}} src={applicant.profilePhotoUrl ? applicant.profilePhotoUrl : "https://via.placeholder.com/282"} alt="profile" className="settings-profile-image"/>   
      {
        !viewOnly &&
        <label htmlFor="imageInput" style={{cursor:'pointer',
    position: 'relative',
    top: '-14%',
    left: '84%',
    display:'flex',
    width:'4vw'}}>
        <img src={uploadFile}></img>
      </label>}
      <input type="file" style={{display:'none'}} id="imageInput" accept="image/*" onChange={handleImageChange}/>
    </>
  )
)}
        </div>
      <div className='step1-upper-right-container'>
      <div className='step1-upper-right-inner-container'>
      <div className='application-form-field-group'>
      <label htmlFor="firstName" className='applicant-form-field-label'>First Name <span style={{ color: 'red' }}>*</span></label>
      <Field name="firstName" placeholder="" className='application-form-standard-input' disabled={!isEdit}/>
      <div className='application-form-error-message'> 
    <ErrorMessage name="firstName" component="div" />
    </div>
    </div>

    <div className='application-form-field-group'>
    <label htmlFor="lastName" className='applicant-form-field-label'>Last Name <span style={{ color: 'red' }}>*</span></label>
    <Field name="lastName" placeholder="Last Name" className='application-form-standard-input' disabled={!isEdit} />
    <div className='application-form-error-message'> 
    <ErrorMessage name="lastName" component="div" />
    </div>
      </div>
      </div>

      <div className='step1-upper-right-inner-container'>
      <div className='application-form-field-group'>
    <label htmlFor="email" className='applicant-form-field-label'>Email <span style={{ color: 'red' }}>*</span></label>
    <Field name="email" type="email" placeholder="Email" className='application-form-standard-input' disabled={!isEdit}/>
    <div className='application-form-error-message'> 
    <ErrorMessage name="email" component="div" />
    </div>
      </div>

      <div className='application-form-field-group'>
    <label style={{ display: "flex"}} htmlFor="phoneNumber" className='applicant-form-field-label'>Phone Number  <span style={{ color: 'red' }}> *</span></label>
    {/* <div id="TooltipExample2" onMouseOver={togglePhoneTooltip} onMouseOut={togglePhoneTooltip} style={{ marginLeft: '0.4vw'}}>
      <img src={infoIcon} style={{width:'1vw', paddingTop:'1vh'}}/>
    </div>
    <Tooltip placement="bottom" isOpen={tooltipPhone} target="TooltipExample2" toggle={togglePhoneTooltip}
      style={{borderRadius:'4px', border:'solid #145388 .1vh',fontFamily:'Montserrat',zIndex:'2', backgroundColor:'white', color:'#145388', fontSize:'2vh', width:'20vw', textAlign:'center', padding:'2vh'}}
    >
      Changing your phone number will also update the number used for logging into the app. This can only be done from the 'Settings' screen.
    </Tooltip> */}
    <Field name="phoneNumber" disabled={true} placeholder="Phone Number" className='application-form-standard-input' />
    <div className='application-form-error-message'> 
    <ErrorMessage name="phoneNumber" component="div" />
    </div>
      </div>
      </div>

      <div className='step1-upper-right-inner-container'>
      <div className='application-form-field-group' style={{marginBottom:'1.5vh', width:'50%'}}>
  <label htmlFor="dateOfBirth" className='applicant-form-field-label'>Date of Birth <span style={{ color: 'red' }}>*</span></label>
    <Field name="dateOfBirth" placeholder="MM/DD/YYYY" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
    <div className='application-form-error-message'> 
    <ErrorMessage name="dateOfBirth" component="div" />
    </div>
    </div>

      <div className='application-form-field-group' style={{width:'50%'}}>
      <label htmlFor="gender" className='applicant-form-field-label'>Gender <span style={{ color: 'red' }}>*</span></label>
    <Field as="select" style={{width:'unset'}} name="genderIdentity" className='application-form-standard-input three' onChange={(event) => setFieldValue("genderIdentity", event.target.value)} disabled={!isEdit}>
      <option>Gender</option>
      <option value="Woman">Woman</option>
      <option value="Man">Man</option>
      <option value="transWoman">Transgender Woman</option>
      <option value="transMan">Transgender Man</option>
      <option value="nonBinary">Gender Nonbinary</option>
      <option value="notListed">Gender Not Listed</option>
      <option value="noResponse">Prefer Not To Respond</option>
    </Field>
    <div className='application-form-error-message'> 
    <ErrorMessage name="genderIdentity" component="div" />
    </div>
      </div>


      </div>
  </div>
  </div>
  <div>

  {/* <div className='step1-upper-right-inner-container' style={{ justifyContent:'space-between'}}>  
    <div className='application-form-field-group single' style={{marginBottom:'1.5vh', width:'100%'}}>
    <label className='applicant-form-field-label' style={{width:'70%'}}>Race/Ethnicity <span style={{ color: 'red' }}>*</span></label>
    <Select 
      isMulti={true}
      name="ethnicitySelection"
      options={raceEthnicityOptions}
      isDisabled={!isEdit}
      onChange={ option => {
        onChecked(option)
        // setFieldValue("raceEthnicity", option.value)
      }}
      value={values.raceEthnicity.map(value =>
        raceEthnicityOptions.find(option => option.value === value) || { label: value, value }
      )}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: '10'
          }),
        control: (provided) => ({
          ...provided,
          width: '100%',
          // height: '9vh',
          boxSizing: 'border-box',
          color: '#414141',
          background: 'linear-gradient(0deg, #f2f2f2, #f2f2f2), #fff',
          border: '1px solid #dbdbdb',
          borderRadius: '8px',
          outline: 'none',
          fontFamily: 'Montserrat',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '30px',
          zIndex:'10',
          position:'relative',
          overflowY: 'scroll'
          
        }),
        option: (provided) => ({
          ...provided,
          zIndex:'10',
          position:'relative'
          
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '8vh'
        })
      }}
    />

  <div className='application-form-error-message'> 
  <ErrorMessage name="raceEthnicity" component="div" />
  </div>
  </div>
  {
    values.raceEthnicity.includes("Other") &&
    <div className='application-form-field-group single' style={{marginBottom:'1.5vh', width:'50%'}}>
    <label htmlFor="otherRaceEthnicity" className='applicant-form-field-label'>Other Race/Ethnicity <span style={{ color: 'red' }}>*</span></label>
    <Field name="otherRaceEthnicity" placeholder="Other Race/Ethnicity" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
    <div className='application-form-error-message'> 
    <ErrorMessage name="otherRaceEthnicity" component="div" />
    </div>
    </div>
  }
  </div> */}

  <div className='step1-upper-right-inner-container' style={{ justifyContent:'space-between'}}>

    {/* <div className='application-form-field-group single' style={{marginBottom:'1.5vh', width:'33%'}}>
    <label htmlFor="prefferedName" className='applicant-form-field-label'>Preferred Name</label>
      <Field name="prefferedName" placeholder="" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
      <div className='application-form-error-message'> 
      <ErrorMessage name="prefferedName" component="div" />
      </div>
    </div> */}

  {/* <div className='application-form-field-group single' style={{marginBottom:'1.5vh', width:'33%'}}>
  <label htmlFor="previousLastName" className='applicant-form-field-label'>Previous Last Name</label>
    <Field name="previousLastName" placeholder="" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
  </div> */}

  

  {/* <div className='application-form-field-group single' style={{marginBottom:'1.5vh', width:'33%'}}>
  <label htmlFor="pronouns" className='applicant-form-field-label'>Pronouns</label>
    <Field name="pronouns" placeholder="" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
  </div> */}
  </div>



{/* 
    <div className='application-form-field-group single' style={{marginBottom:'1.5vh'}}>
  <label htmlFor="hometown" className='applicant-form-field-label'>Hometown <span style={{ color: 'red' }}>*</span></label>
    <Field name="hometown" placeholder="City, State, Country" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
    <div className='application-form-error-message'> 
    <ErrorMessage name="hometown" component="div" />
    </div>
    </div> */}
 <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
    <label htmlFor="linkedinLink" className='applicant-form-field-label' style={{display:'flex'}}>LinkedIn Profile Link <span style={{ color: 'red' }}>*</span>
    </label>
    <Field name="linkedinLink" placeholder="LinkedIn Link" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
    <div className='application-form-error-message'> 
    <ErrorMessage name="linkedinLink" component="div" />
    </div>
    </div>

 

    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
    <label htmlFor="homeAddress" className='applicant-form-field-label' style={{display:'flex'}}>Address <span style={{ color: 'red' }}>*</span>
    </label>
    <Field name="homeAddress" placeholder="Mailing Address" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
    <div className='application-form-error-message'> 
    <ErrorMessage name="homeAddress" component="div" />
    </div>
    </div>


    <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
  <label className='applicant-form-field-label'>Work Authorization (U.S.)<span style={{ color: 'red' }}>*</span></label>
        <Field as="select" name="workAuthorization"className='application-form-standard-input one' onChange={(event) => setFieldValue("workAuthorization", event.target.value)} disabled={!isEdit} style={{ marginBottom: '1vh'}}>
          <option value="">Select Work Authorization</option>
          <option value="U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)">U.S. Citizen, National, or Legal Permanent Resident (e.g., Refugee, Asylee)</option>
          <option value="Green Card Applicant Undergoing Adjustment of Status (with EAD)">Green Card Applicant Undergoing Adjustment of Status (with EAD)</option>
          <option value="DACA Recipient (Deferred Action for Childhood Arrivals)">DACA Recipient (Deferred Action for Childhood Arrivals)</option>
          <option value="E-2 Treaty Investor, Including Spouse and Children (with EAD)">E-2 Treaty Investor, Including Spouse and Children (with EAD)</option>
          <option value="Holder of Employment Authorization Document (EAD)">Holder of Employment Authorization Document (EAD)</option>
          <option value="F-1 Academic Student (with EAD, OPT)">F-1 Academic Student (with EAD, OPT)</option>
          <option value="H-1 Temporary Worker">H-1 Temporary Worker</option>
          <option value="H-1B Specialized Occupation, Department of Defense Worker, etc.">H-1B Specialized Occupation, Department of Defense Worker, etc.</option>
          <option value="H-2B Temporary Worker (Skilled or Unskilled)">H-2B Temporary Worker (Skilled or Unskilled)</option>
          <option value="H-4 Spouse or Child of H-1, H-2, or H-3 Visa Holder (with EAD)">H-4 Spouse or Child of H-1, H-2, or H-3 Visa Holder (with EAD)</option>
          <option value="J-1 Exchange Visitor">J-1 Exchange Visitor</option>
          <option value="J-2 Spouse or Child of J-1 Visa Holder (with EAD)">J-2 Spouse or Child of J-1 Visa Holder (with EAD)</option>
          <option value="L-2 Dependent of Intra-Company Transferee (with EAD)">L-2 Dependent of Intra-Company Transferee (with EAD)</option>
          <option value="O-1 Individual with Extraordinary Ability in Sciences, Arts, Education, Business, or Athletics">O-1 Individual with Extraordinary Ability in Sciences, Arts, Education, Business, or Athletics</option>
          <option value="TN NAFTA Trade Visa (Canadians and Mexicans)">TN NAFTA Trade Visa (Canadians and Mexicans)</option>
          <option value="Other">Other</option>
          </Field>
          <div className='application-form-error-message'> 
          <ErrorMessage name="workAuthorization" component="div" />
          </div>
          </div>
          {
            values.workAuthorization === "Other" &&
            <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
            <label htmlFor="otherWorkAuthorization" className='applicant-form-field-label'>Other Work Authorization <span style={{ color: 'red' }}>*</span></label>
            <Field name="otherWorkAuthorization" placeholder="Other Work Authorization" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
            <div className='application-form-error-message'> 
            <ErrorMessage name="otherWorkAuthorization" component="div" />
            </div>
            </div>
          }



        {/* <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
          <label htmlFor="otherServiceObligations" className='applicant-form-field-label'>Other service obligations <span style={{ color: 'red' }}>*</span></label>
            <Field name="otherServiceObligations" placeholder="" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
            <div className='application-form-error-message'> 
            <ErrorMessage name="otherServiceObligations" component="div" />
          </div>
        </div> */}


        

          <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
          <label className='applicant-form-field-label'>Have you ever been convicted of a misdemeanor or felony? <span style={{ color: 'red' }}>*</span></label>
          <div role="group" aria-labelledby="radio-group" className='application-form-group-radio'>
            <label>
              <Field className="application-form-radiobox" style={{display:'unset', marginRight:'.4vw'}} type="radio" name="misdemeanorOrFelony" value="yes"  disabled={!isEdit}/> Yes
            </label>
            <label>
              <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="misdemeanorOrFelony" value="no" disabled={!isEdit}/> No
            </label>
          </div>
          <div className='application-form-error-message'>
          <ErrorMessage name="misdemeanorOrFelony" component="div" />
          </div>
          </div>
          
          {
            values.misdemeanorOrFelony === 'yes' && (
            <div className='application-form-field-group' style={{marginBottom:'3vh'}}>
            <label className='applicant-form-field-label'>Please elaborate <span style={{ color: 'red' }}>*</span></label>
            <Field name="misdemeanorOrFelonyDetails" className='application-form-standard-input one' disabled={!isEdit}/>
            <div className='application-form-error-message'> 
            <ErrorMessage name="misdemeanorOrFelonyDetails" component="div" />
            </div>
            </div>
          )}
{/* 

        <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
        <label className='applicant-form-field-label'>Have you ever been involved in a malpractice lawsuit? <span style={{ color: 'red' }}>*</span></label>
        <div role="group" aria-labelledby="radio-group" className='application-form-group-radio'>
          <label>
            <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="malpracticeLawsuit" value="yes" disabled={!isEdit}/> Yes
          </label>
          <label>
            <Field style={{display:'unset', marginRight:'.4vw'}} type="radio" name="malpracticeLawsuit" value="no" disabled={!isEdit}/> No
          </label>
        </div>
</div> */}



{values.malpracticeLawsuit === 'yes' && (
  <div className='application-form-field-group'>
    <label className='applicant-form-field-label'>Please elaborate <span style={{ color: 'red' }}>*</span></label>
    <Field name="elaboration" className='application-form-standard-input one' disabled={!isEdit}/>
    <div className='application-form-error-message'> 
      <ErrorMessage name="elaboration" component="div" />
    </div>
  </div>
)}
{
  confirmImageModal &&
  <div className="modal-backdrop">
    <div className="add-field-modal">
      <div className='add-field-modal-header'>
        <h2>Image Upload</h2>
      <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
      </div>
      <label>Image uploaded successfully.</label>
      <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', gap:'2vw', marginTop: '2vh'}}>
        <button className='green-button' onClick={() => setConfirmImageModal(prevState => !prevState)}>Close</button>
      </div>
    </div>
  </div>




}
{!isNested && isEdit && (
  <button className="mark-as-complete"
  disabled={checkDisabled(values, isSubmitting)}
  onClick={(event) => {
    
     event.preventDefault();
     // Call the handleSubmit which should be handling Formik's submitForm
     handleSave();
     updatePhase("firstPhase")
     // Optionally move to the next step after submission
     setStep(prevStep => prevStep + 1);
    }}>
    Next
  </button>
)}

{!isNested && !isEdit&& (
  <button className="white-button"
  style={{width:'100%'}}
  onClick={() => {
 setIsEdit(true)
 updatePhase("firstPhase")
    }}>
    Reopen Task
  </button>
)}




</div>
</>
)
}

export default ApplicationFormStep1;