import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './index.css'; // Assuming styles are in Home.css
import LeftNavBar from './applicantComponents/LeftNavBar';
import ApplicantHome from './applicantComponents/Home';
import ApplicationManager from './applicantComponents/ApplicationManager';
import Documents from './documents/Documents';
import Archive from './archive/Archive';
import Settings from './settings/Settings';
import Lor from './lors';
import ContactUs from '../contactUs';
import InterviewManager from './interviewManager';
import logo from '../../assets/imgs/logoWhite.svg';

import { getApplicantByIdQuery, getAllMasterApplicationsByUserIdQuery, getApplicationsByApplicantIdQuery, GET_SCORE_BY_APPLICANTID } from './applicantComponents/graphql/queries';

import client from '../../config/apolloClient';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/userContext';

function removeTypename(obj) {
  if (obj === null || obj === undefined) {
      return obj;
  }

  if (Array.isArray(obj)) {
      return obj.map(removeTypename);
  }

  if (typeof obj === 'object') {
      const newObj = {};
      for (let property in obj) {
          if (property !== '__typename') {
              newObj[property] = removeTypename(obj[property]);
          }
      }
      return newObj;
  }

  return obj;
}

function Applicant() {
  const { user } = useContext(UserContext);
  const location = useLocation();
        const { positionId, routeDirectlyToForm } = location.state || {};
  console.log("user in applicant main =>", user)
  console.log('routeDirectlyToForm in applicant main =>', routeDirectlyToForm)
  console.log('positionId in applicant main =>', positionId)
  
  const [page, setPage] = useState('home');
  const [applicant, setApplicant] = useState(null)
  const [selectedApplication, setSelectedApplication] = useState(null)
  const [applicationData, setApplicationData] = useState({})
  const [masterApplications, setMasterApplications] = useState(null)
  const [phases, setPhases] = useState(null)
  const [score, setScore] = useState(null)
  const [applications, setApplications] = useState(null)
  const [applicationDataTitle, setApplicationDataTitle] = useState(null)
  const [applicationLoading, setApplicationLoading] = useState(false)
  


  useEffect(() => {
    console.log("selectedApplication changed!", selectedApplication)
    if(selectedApplication !== null ){
      setApplicationData(selectedApplication) // Set specialty data
      const phases = {
          firstPhase: selectedApplication.firstPhase,
          secondPhase: selectedApplication.secondPhase,
          thirdPhase: selectedApplication.thirdPhase,
          fourthPhase: selectedApplication.fourthPhase,
          fifthPhase: selectedApplication.fifthPhase,
          sixthPhase: selectedApplication.sixthPhase,
          seventhPhase: selectedApplication.seventhPhase
      }
      setPhases(phases)

  }
  }, [ selectedApplication ])

  useEffect(() => {
    if(applicationData !== undefined && applicationData !== null) {
      console.log('applicationData in the ApplicationManager thingi', applicationData, applicationData.title, applicationDataTitle)
      if(applicationData.title !== undefined && applicationData.title !== null) {
        setApplicationDataTitle(applicationData.title)
    }
    }
   
  }, 
  [applicationData])

  const resetApplication = () => {
    setSelectedApplication(null)
    setApplicationData(null)
    getAllMasterApplications()
  }
  
  const selectApplication = (specialty) => {
    console.log("Specialty in applicant =>", specialty)
    setSelectedApplication(specialty)
  }

  const getApplicant = () => {
    setApplicationLoading(true)
    client.query({
      query: getApplicantByIdQuery,
      variables: {
        id: user.userId
      },
      fetchPolicy: 'network-only'
    })
    .then(res => {
      let applicant = res.data.getApplicantByIdQuery
      const cleanData = removeTypename(applicant)
      setApplicant(cleanData)
      setApplicationLoading(false)
    })
  }


  const refreshApplicant = (refreshedData) => {
    setApplicationLoading(true)
    const cleanData = removeTypename(refreshedData)
    setApplicant(cleanData)
    setApplicationLoading(false)
  }

  const getUserScore = () => {
    client.query({
      query: GET_SCORE_BY_APPLICANTID,
      variables: {
        applicantId: user.userId
      }
    })
    .then(res => {
        const score = res.data.getScoreByApplicantId
        console.log("score =>", score)
        setScore(score)
    })
  }

  const updatePhases = (newPhases) => {
    setPhases(newPhases);
  }


  const getApplicationsByApplicantId = () => {
    setApplicationLoading(true)
    client.query({
        query: getApplicationsByApplicantIdQuery,
        variables: {
            applicantId: user.userId
        },
        fetchPolicy: 'network-only'
    })
    .then(res => {
        const applications = res.data.getApplicationsByApplicantId
        console.log("applications =>", applications)
        setApplications(applications)
        setApplicationLoading(false)
    })
}


  const getAllMasterApplications = () => {
    setApplicationLoading(true)
    client.query({
      query: getAllMasterApplicationsByUserIdQuery,
      variables: {
          userId: user.userId
      },
      fetchPolicy: 'network-only'
  })
  .then(res => {
      const masterApplications = res.data.getAllMasterApplicationsByUserId
      // clean up the data from __typename
      let cleanData =[]
      masterApplications.forEach(application => {
          removeTypename(application);
          cleanData.push(application)
      })
      setMasterApplications(cleanData)

      // update selected application
      console.log("applicationData =>", applicationData)
      if(applicationData && applicationData.id) {
          let selectedIdx = cleanData.findIndex(elem => elem.id.toString() === applicationData.id.toString())
          console.log("selectedIdx =>", selectedIdx)
          if(selectedIdx > -1) {
              let modifiedApplication = cleanData[selectedIdx]
              console.log("modifiedApplication =>", modifiedApplication)
              setSelectedApplication(modifiedApplication) // Set specialty data
              const phases = {
                  firstPhase: modifiedApplication.firstPhase,
                  secondPhase: modifiedApplication.secondPhase,
                  thirdPhase: modifiedApplication.thirdPhase,
                  fourthPhase: modifiedApplication.fourthPhase,
                  fifthPhase: modifiedApplication.fifthPhase,
                  sixthPhase: modifiedApplication.sixthPhase,
                  seventhPhase: modifiedApplication.specialtyId==="662a26f3e8233c2be04275b7"?true: modifiedApplication.seventhPhase,
              }
              setPhases(phases)
              calculateApplicationProgress(modifiedApplication)
              setApplicationLoading(false)
              
            
          }
      }
  
     
  })
  }



  const calculateDaysLeft = (date) => {
    let target = new Date(parseInt(date))
    console.log("date =>", target)
     // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the target date and the current date
    const difference = target.getTime() - currentDate.getTime();

    // Convert the difference from milliseconds to days
    let daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));

    if(daysLeft < 0) daysLeft = 0

    return daysLeft;
}

const CheckStatus = (application) => {
    let conditionsResult = application.firstPhase === true && application.secondPhase  === true && application.thirdPhase === true && application.fourthPhase === true && application.fifthPhase  === true && application.sixthPhase  === true
    console.log("conditionsResult =>", conditionsResult)
    if(conditionsResult === false) return "Open"
    return "Closed"
}

const calculateApplicationProgress = (application) => {
    let partWeight = parseFloat(100 / 7)
    console.log("application in cal =>", application)
    let progress = 0
    if(application.firstPhase) progress += partWeight
    if(application.secondPhase) progress += partWeight
    if(application.thirdPhase) progress += partWeight
    if(application.fourthPhase) progress += partWeight
    if(application.fifthPhase) progress += partWeight
    if(application.sixthPhase) progress += partWeight
    if(application.seventhPhase) progress += partWeight
    console.log("partWeight =>", partWeight)
    console.log("progress =>", progress)
    return progress
}


  useEffect(() => {
    getApplicant()
    getAllMasterApplications()
    getUserScore()
    getApplicationsByApplicantId()
  }, [])


  return (
    <>
    <header className="faculty-header" style={{background: '#2a93d5', display:'flex', justifyContent:'center', position:'fixed', top:'0vh', width:'100%', zIndex:'10'}}>
    <img src={logo} alt="logo" style={{  width:'6vw' }} />
    </header>
    <main className="container mx-auto px-6 py-4" style={{paddingTop:'0', paddingBottom:'0', paddingRight:'0', maxWidth: '1500px'}}>
        <LeftNavBar  page={page} setPage={setPage} applicant={applicant} resetApplication={resetApplication}/>
        
        {page==='home'&&(<ApplicantHome applicant={applicant} setPage={setPage} setSelectedApplication={selectApplication} getAllMasterApplications={getAllMasterApplications} getApplicant={getApplicant}/>)}
        {page==='appManager'&&(<ApplicationManager refetchUser={getApplicant} applicant={applicant} setPage={setPage} selectedApplication={selectedApplication} getApplicant={getApplicant} masterApplications={masterApplications} calculateDaysLeft={calculateDaysLeft} CheckStatus={CheckStatus} calculateApplicationProgress={calculateApplicationProgress} setApplicationData={setApplicationData} applicationDataTitle={applicationDataTitle} applicationData={applicationData} setPhases={setPhases} score={score} phases={phases} applications={applications} getAllMasterApplications={getAllMasterApplications}  updatePhases={updatePhases} applicationLoading={applicationLoading} refreshApplicant={refreshApplicant} routeDirectlyToForm={routeDirectlyToForm}  positionId={positionId}/>)}
        {page==='documents'&&(<Documents applicant={applicant}/>)}
        {page==='archive'&&(<Archive applicant={applicant}/>)}
        {page==='settings'&&(<Settings applicant={applicant} getApplicant={getApplicant}/>)}
        {page==='lors'&&(<Lor applicant={applicant}/>)}
        {page==='interviewManager'&&(<InterviewManager applicant={applicant}/>)}
        {page==='help'&&(<ContactUs isNested={true} user={user}/>)}
    </main>
    </>
  );
}

export default Applicant;