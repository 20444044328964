import React, { useState, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg'
import { useMutation } from '@apollo/client';
import { UPDATE_CAREER_FAIR } from './graphql/mutations';

function formatStringDate(timestamp) {
    const date = new Date(parseInt(timestamp, 10));
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}

const UpdateRoleModal = ({ isOpen, onClose, user, onEditUser }) => {
    const [workflowData, setWorkflowData] = useState({
        programId: user.programId,
        name: user.name,
        description: user.description,
        date: formatStringDate(user.date),
        location: user.location,
        isVirtual: user.isVirtual,
        virtualEventLink: user.virtualEventLink,
        eventTime: user.eventTime,
    }); 
    const [dateError, setDateError] = useState('');
 

    const [updateCareerFair, { data, loading, error }] = useMutation(UPDATE_CAREER_FAIR);


    if (!isOpen) return null;


    const formatDate = (value) => {
        // Remove all non-digit characters
        const cleaned = value.replace(/\D+/g, '');
        
        // Format the cleaned value
        const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
        if (!match) return value;
        
        const [, month, day, year] = match;
        let formattedDate = '';
        if (month) formattedDate += month.length === 2 ? month + '/' : month;
        if (day) formattedDate += day.length === 2 ? day + '/' : day;
        if (year) formattedDate += year;
        
        return formattedDate;
      };

    const handleDateChange = (e) => {
        const formattedDate = formatDate(e.target.value);
        setWorkflowData(prevData => ({
          ...prevData,
          date: formattedDate
        }));
      
        // Simple validation to check if the date is in MM/DD/YYYY format
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(formattedDate)) {
          setDateError('');
        } else {
          setDateError('Date must be in MM/DD/YYYY format');
        }
    };

    const formatTime = (value) => {
        // Remove all non-digit characters
        const cleaned = value.replace(/\D+/g, '');
      
        // Use a simple slice approach for hours (0–2 digits) and minutes (next 0–2 digits)
        const hoursPart = cleaned.slice(0, 2);
        const minutesPart = cleaned.slice(2, 4);
      
        // Validate hours
        let hoursNum = hoursPart === '' ? '' : parseInt(hoursPart, 10);
        // Constrain hours to 0–23
        if (!isNaN(hoursNum) && hoursNum > 23) {
          hoursNum = 23;
        }
      
        // Validate minutes
        let minutesNum = minutesPart === '' ? '' : parseInt(minutesPart, 10);
        // Constrain minutes to 0–59
        if (!isNaN(minutesNum) && minutesNum > 59) {
          minutesNum = 59;
        }
      
        // Convert back to strings (only if they’re not empty)
        const hoursStr =
          hoursPart === ''
            ? ''
            : hoursNum.toString().padStart(hoursPart.length, '0');
      
        // For the minutes, only pad if the user has typed 2 digits
        let minutesStr = '';
        if (minutesPart) {
          // If the user typed exactly 1 digit, let it stay as is
          if (minutesPart.length === 1) {
            minutesStr = minutesPart; 
          } else {
            // If the user typed 2 digits, pad and constrain
            minutesStr = minutesNum.toString().padStart(2, '0');
          }
        }
      
        // Assemble final output
        let formattedTime = hoursStr;
        if (minutesStr !== '') {
          formattedTime += ':' + minutesStr;
        }
      
        // Limit to 5 characters (e.g. "HH:MM")
        return formattedTime.slice(0, 5);
      };

      
    const handleTimeChange = (e) => {
        const formattedTime = formatTime(e.target.value);
        setWorkflowData(prevData => ({
            ...prevData,
            eventTime: formattedTime
        }));
    };
    


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setWorkflowData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleRadioChange = (value) => {
        console.log('Radio changed to', value);
        setWorkflowData((prevData) => ({
        ...prevData,
        isVirtual: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    try {
        const result = await updateCareerFair({ variables: { id: user.id, input: workflowData } });
        console.log('Mutation result:', result);

        onEditUser()
        onClose();
    } catch (err) {
        console.error('Error executing mutation:', err);
    }
        // Handle form submission logic here
        console.log('Workflow Data:', workflowData);
        onEditUser();
        onClose();
    };

return (

<div className="modal-backdrop">
<div className="add-field-modal">
    <div className='add-field-modal-header'>
    <h2>UPDATE EXISTING JOB FAIR</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>

    <form onSubmit={handleSubmit}>
    <div
        className="form-group"
        style={{ maxHeight: "66vh", overflowY: "scroll" }}
    >

<label>Virtual Event?</label>
<div style={{display:'flex', gap:'1vw'}}>
<div onClick={()=>handleRadioChange(true)}>
<input
          type="radio"
          name="isVirtualTrue"
          checked={workflowData.isVirtual === true}
          onChange={() => handleRadioChange(true)}
        />
<label>Yes</label>
</div>

<div onClick={()=> handleRadioChange(false)}>
<input
          type="radio"
          name="isVirtual1"
          checked={workflowData.isVirtual === false}
          onChange={() => handleRadioChange(false)}
        />
<label>No</label>
</div>
</div>


              <label>Event Name</label>
              <input
                type="text"
                name="name"
                value={workflowData.name}
                onChange={handleChange}
                className="border rounded p-2 w-full mb-4"
              />
            
              <label>Description</label>
               <input
                type="text"
                name="description"
                value={workflowData.description}
                onChange={handleChange}
                className="border rounded p-2 w-full mb-4"
              />
            
                <input
                type="text"
                name="date"
                value={workflowData.date}
                onChange={handleDateChange}
                placeholder='MM/DD/YYYY'
                className={`border rounded p-2 w-full ${dateError === '' ? 'mb-4' : 'mb-0'}`}
                />

                {dateError && <div style={{color:'red', fontFamily:'Montserrat', fontWeight:'500'}} className="error-message">{dateError}</div>}

                {!workflowData.isVirtual && (
                <>
                <label>Location</label>
                <input
                type="text"
                name="location"
                value={workflowData.location}
                onChange={handleChange}
                className="border rounded p-2 w-full mb-4"
                />
                </>
                )}
                  {workflowData.isVirtual && (
                <>
                <label>Event Time</label>
                <input
                type="text"
                name="eventTime"
                value={workflowData.eventTime}
                onChange={handleTimeChange} // Use the new handleTimeChange function
                placeholder="HH:MM"
                className="border rounded p-2 w-full mb-4"
                />
                </>
                )}




            </div>
            <div
              className="button-container"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "2vw",
              }}
            >
              <button
                type="submit"
                className="gradient-button"
                style={{ fontWeight: "500" }}
              >
                Update Fair
              </button>

              <button type="button" className="white-button" onClick={onClose}>
                Cancel
              </button>
            </div>
    </form>
    
</div>
</div>
)
};

export default UpdateRoleModal;