import React, { useContext, useEffect, useState } from "react";
import "./styles/leftNavBar.css";
import navBarLogo from "../../../assets/imgs/logo.svg";
import { NavLink, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/userContext';
import packageInfo from '../../../../package.json';

const LeftNavBar = ({ userRoles, isPreSeason }) => {
  const [adminMenuOpen, setIsAdminMenuOpen] = useState(false);
  const { user, topUser, setUser, setTopUser } = useContext(UserContext);
  const [initials, setInitials] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user.firstName && user.lastName) {
      setInitials(user.firstName.charAt(0) + user.lastName.charAt(0));
    }
  }, [user]);

  // If user is only a "Recommender", navigate them directly to /recommendations
  useEffect(() => {
    if (userRoles.includes('Recommender') && userRoles.length === 1) {
      navigate('/recommendations');
    }
  }, [userRoles, navigate]);

  const handleLogout = () => {
    localStorage.removeItem('login_token');
    navigate('/');
  };

  // Example: if you still need a custom function for account access toggles
  const accountAccessClicked = () => {
    if (topUser !== null && topUser !== undefined) {
      setUser(topUser);
      setTopUser(null);
      navigate('/'); // or wherever you want to redirect after "back to my account"
    } else {
      navigate('/accountAccess');
    }
  };

  return (
    <div className="left-navBar-container" style={{ overflowY: 'auto' }}>
      <div className="left-navBar-header">
        <div>
          {/* Logo */}
          <img
            className="left-navBar-profile-photo"
            src={navBarLogo}
            alt="profile"
            style={{ background: 'white', borderRadius: '0', width: '13.15vw', padding:'1vw' }}
          />
        </div>
        <div>
          <h2 className="left-navBar-header-profile-name">
            {user.firstName} {user.lastName}
          </h2>
        </div>
      </div>

      <ul>
        {/* Example NavLink usage for Home */}
        <li>
          <NavLink 
            to="/faculty" 
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
            end
          >
            Home
          </NavLink>
        </li>

        {/* Positions */}
        <li>
          <NavLink
            to="/faculty/positions"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Positions
          </NavLink>
        </li>

        {/* Applications */}
        <li>
          <NavLink
            to="/faculty/applications"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Applications
          </NavLink>
        </li>

        {/* Leads */}
        <li>
          <NavLink
            to="/faculty/leads"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Leads
          </NavLink>
        </li>

        {/* Fairs */}
        <li>
          <NavLink
            to="/faculty/fairs"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Job Fairs
          </NavLink>
        </li>

        {/* Interview Manager */}
        <li>
          <NavLink
            to="/faculty/interviewManager"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Scheduler
          </NavLink>
        </li>

        {/* Communications */}
        <li>
          <NavLink
            to="/faculty/emailTemplates"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Communications
          </NavLink>
        </li>
      </ul>

      <separator className="left-navBar-separator" />

      {/* Admin Menu */}
      <ul>
        {user.userType === 'admin' && (
          <>
            <li
              className={`left-navBar-item ${adminMenuOpen ? 'active' : ''}`}
              onClick={() => setIsAdminMenuOpen((prevState) => !prevState)}
            >
              Admin
            </li>
            {adminMenuOpen && (
              <div style={{ marginLeft: '1vw' }}>
                <NavLink
                  to="/adminUsers"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  Users
                </NavLink>
                <NavLink
                  to="/adminSpecialties"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  Specialties
                </NavLink>
                <NavLink
                  to="/adminPrograms"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  Programs
                </NavLink>
                <NavLink
                  to="/adminMasterApplications"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  Applications
                </NavLink>
                <NavLink
                  to="/adminLors"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  LoRs
                </NavLink>
                <NavLink
                  to="/adminDocuments"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  Documents
                </NavLink>
                <NavLink
                  to="/adminEras"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  ERAS Upload
                </NavLink>
                <NavLink
                  to="/adminInstitutions"
                  className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
                >
                  Institutions
                </NavLink>
              </div>
            )}
          </>
        )}

        {/* User Settings */}
        <li>
          <NavLink
            to="/faculty/settings"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            User Settings
          </NavLink>
        </li>

        {/* Company Settings */}
        <li>
          <NavLink
            to="/faculty/Company-Settings"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Company Settings
          </NavLink>
        </li>

        {/* Customer Support */}
        <li>
          <NavLink
            to="/faculty/contactUs"
            className={({ isActive }) => `left-navBar-item ${isActive ? 'active' : ''}`}
          >
            Customer Support
          </NavLink>
        </li>

        {/* If you need custom logic for account access */}
        {/* 
        <li
          className={`left-navBar-item ${(someCondition) ? 'active' : ''}`} 
          onClick={accountAccessClicked}
        >
          {topUser ? "Back to my account" : "Account Access"}
        </li> 
        */}
        
        {/* Logout */}
        <li className="left-navBar-item" onClick={handleLogout}>
          Log Out
        </li>
      </ul>

      <div className="left-navBar-footer" style={{ marginTop: '0', marginLeft: '.1vw' }}>
        <p className="left-navBar-footer-text">{packageInfo.version}</p>
        <p className="left-navBar-footer-text">
          {new Date().getFullYear()} DDH | All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default LeftNavBar;
