import React from "react";
import './paginationComponent.css';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pages = [];

  console.log('totalPages in Pagingatin compoent', totalPages);

  // Calculate the start and end page numbers
  let startPage = Math.max(currentPage - 2, 1);
  let endPage = Math.min(currentPage + 2, totalPages);

  // Ensure that there are always 4 page numbers displayed if possible
  if (currentPage <= 2) {
    endPage = Math.min(4, totalPages);
  } else if (currentPage >= totalPages - 1) {
    startPage = Math.max(totalPages - 3, 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="pagination" style={{ marginLeft: '-10vw'}}>
      {
        currentPage > 1 &&
        <button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </button>
      }
    
      
      {pages.map(page => (
        <button
          key={page}
          className={page === currentPage ? 'active' : ''}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}

      {
        currentPage < totalPages &&
        <button
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </button>
      }
      
    
    </div>
  );
}

export default Pagination;
