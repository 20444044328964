import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles/imHeader.css";
import calendarIcon from "../../../../assets/imgs/calendarIcon.svg";
import calendarIconGray from "../../../../assets/imgs/calendarIconGray.svg";
import personIcon from "../../../../assets/imgs/personIcon.svg";
import personIconColor from "../../../../assets/imgs/personIconColor.svg";
import calendarWithADayIcon from "../../../../assets/imgs/calendarWithADayIcon.svg";
import calendarWithADayIconColor from "../../../../assets/imgs/calendarWithADayIconColor.svg";
import emailTemplatesIcon from "../../../../assets/imgs/emailTemplatesIcon.svg";
import emailTemplatesIconColor from "../../../../assets/imgs/emailTemplatesIconColor.svg";


const IMHeader = ({ className = "", setPage, page}) => {
  

  const selectedHeaderStyle ={
    fontWeight: "600",
    borderBottom: "2px solid #145388",
    cursor: 'default',
    color:'black'
  }
  const unselectedHeaderStyle ={
    cursor: "pointer",
    color: "#707070",
    fontWeight: "300",
  }


  return (
    <div className={`content-header-parent ${className}`}>
      <div className="content-header">
        <h2 className="heading-1">SCHEDULER</h2>
        <div className="description-1-wrapper">
          {/* <div className="description-1">
            Click on a Date to Start Planning Intevirviews.
          </div> */}
        </div>
        <div className="content-icons">
          <div className="content-icon-set-parent">
            <div className="content-icon-set">
            <nav className={`frame-nav ${className}`}>
 <div className="frame-parent3">
        <div className="iontoday-outline-wrapper">
          <img
            className="iontoday-outline-icon"
            loading="lazy"
            alt=""
            src={page === 'customize' ? calendarIcon : calendarIconGray}
          />
        </div>
        <div className="description-2"
         style={page === 'customize' ? selectedHeaderStyle : unselectedHeaderStyle}
        onClick={()=>setPage('customize')}
        >Calendar</div>
      </div>
       {/* <div className="frame-parent4">
        <div className="iontoday-outline-wrapper">
          <img
            className="iontoday-outline-icon"
            loading="lazy"
            alt=""
            src={page === 'invite' ? personIconColor : personIcon}
            />
        </div>
        <a className="description-21"
         onClick={()=>setPage('invite')}
         style={page==='invite'?selectedHeaderStyle : unselectedHeaderStyle}>Invite Applicants</a>
      </div>
      <div className="frame-parent4">
        <div className="iontoday-outline-wrapper">
          <img
            className="iontoday-outline-icon"
            loading="lazy"
            alt=""
            src={page === 'plan' ? calendarWithADayIconColor : calendarWithADayIcon}
          />
        </div>
        <a className="description-21" onClick={()=>setPage('plan')}
        style={page==='plan'?selectedHeaderStyle : unselectedHeaderStyle}
        >Plan interview Day</a>
      </div> */}
      {/* <div className="frame-parent4">
        <div className="iontoday-outline-wrapper">
          <img
            className="iontoday-outline-icon"
            loading="lazy"
            alt=""
            src={page === 'emailTemplates' ? emailTemplatesIconColor : emailTemplatesIcon}
          />
        </div>
        <a className="description-21" onClick={()=>setPage('emailTemplates')}
        style={page==='emailTemplates'?selectedHeaderStyle : unselectedHeaderStyle}
        >Email Templates</a>
      </div> */}




<div className="frame-parent4">
        <div className="iontoday-outline-wrapper">
          <img
            className="iontoday-outline-icon"
            loading="lazy"
            alt=""
            src={page === 'availabilities' ? emailTemplatesIconColor : emailTemplatesIcon}
          />
        </div>
        <a className="description-21" onClick={()=>setPage('availabilities')}
        style={page==='availabilities'?selectedHeaderStyle : unselectedHeaderStyle}
        >Availabilities</a>
      </div>


      <div className="frame-parent4">
        <div className="iontoday-outline-wrapper">
          <img
            className="iontoday-outline-icon"
            loading="lazy"
            alt=""
            src={page === 'extCal' ? emailTemplatesIconColor : emailTemplatesIcon}
          />
        </div>
        <a className="description-21" onClick={()=>setPage('extCal')}
        style={page==='extCal'?selectedHeaderStyle : unselectedHeaderStyle}
        >Integrate Your Calendar</a>
      </div>


    </nav>
            </div>
            <div className="content-navigation">
              <div className="content-navigation-left-parent">
                <img
                  className="content-navigation-left"
                  loading="lazy"
                  alt=""
                  src="/vector-232.svg"
                />
                <img
                  className="content-navigation-right"
                  loading="lazy"
                  alt=""
                  src="/vector-233.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default IMHeader;
