import { gql } from "@apollo/client";

export const GET_CAREER_FAIR_BY_ID = gql`
 query getCareerFairByLink($link: String!) {
    getCareerFairByLink(link: $link) {
      id
      programId
      name
      date
      location
      description
      formLink
      qrCode
      isVirtual
      virtualEventLink
      eventTime
    }
     }
`;