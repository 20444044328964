import React,{useState,useContext, useEffect} from "react";
import "./index.css";
// import Card from "../../../../components/common/card"
import { updateUserMutation,
       updateUserPasswordMutation,
       revokeUserMutation,
       addUserToAccountAccessMutation,
       updateLegalDisclaimersMutation
     } from './graphql/mutations';
import { getUserById, getUserWithAccountUsersById, getUsersQuery } from './graphql/queries';
import {useQuery, useMutation} from '@apollo/client';
import client from '../../../../config/apolloClient';
import { UserContext } from '../../../../context/userContext';
import AccountsTable from './accountsTable';
import { useNavigate } from 'react-router-dom';
import Spinner from "../../../common/spinner";
import Select from 'react-select';
import { ProgramIdContext } from '../../progIdContext';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import packageInfo from '../../../../../package.json';
import { Card, CardBody, CardImg, Collapse } from "reactstrap";
import toggleArrowUpIcon from "../../../../assets/imgs/toggleArrowUpIcon.svg";
import toggleArrowIcon from "../../../../assets/imgs/toggleArrowIcon.svg";
import MyTeam from "../myTeam/myTeamSettings";
import { getProgramById } from  './graphql/queries';
import CustomizeApplicationSettings from "../customizeApplication/customizeSettings";
import RubricSettings from "../rubric/rubricSettings";
import ResponseModal from "../../../common/resModal";
import ProgramDetails from "./programDetails";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProgramSettings = ({ setPage }) => {
    const { user, setUser, setTopUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [updateLegalDiscalimers] = useMutation(updateLegalDisclaimersMutation);


    const programId = useContext(ProgramIdContext);
    const [accordion, setAccordion] = useState([false, false, false, false, false, false])
    const [fromEmail, setFromEmail] = useState('')
    const [equalEmploymentOpportunityStatement, setEqualEmploymentOpportunityStatement] = useState('');
    const [legalDisclaimers, setLegalDisclaimers] = useState('');
    const [initialLegalDisclaimers, setInitialLegalDisclaimers] = useState('');
    const [initialEqualEmploymentOpportunityStatement, setInitialEqualEmploymentOpportunityStatement] = useState('');
    const [isTextareaFocused1, setIsTextareaFocused1] = useState(false);
    const [isTextareaFocused2, setIsTextareaFocused2] = useState(false);
    const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);


    const { loading, error, data, refetch } = useQuery(getProgramById, {
        variables: { id: programId },
    });

    useEffect(() => {
        if (data) {
            const { defaultLegalDisclaimers, defaultEqualEmploymentOpportunityStatement } = data.getProgramById;
            setLegalDisclaimers(defaultLegalDisclaimers);
            setEqualEmploymentOpportunityStatement(defaultEqualEmploymentOpportunityStatement);
            setInitialLegalDisclaimers(defaultLegalDisclaimers);
            setInitialEqualEmploymentOpportunityStatement(defaultEqualEmploymentOpportunityStatement);
        }
    }, [data, loading, error])

    if (loading) return <Spinner />;
    if (error) return <p>Error :(</p>;

    const toggleAccordion = tab => {
        console.log("toggleAccordion clicked =>", tab)
        const prevState = [...accordion];
        console.log("prevState =>", prevState)
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        console.log("newState =>", state)

        setAccordion(state)
    };

    const verifyEmail = async () => {
        console.log("email to verify =>", fromEmail)
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({
                verificationEmail: fromEmail,
                programId
            }),
        });
        
    }

    const saveLegalDisclaimers = async () => {
        updateLegalDiscalimers({
            variables: {
                programId,
                input:{
                defaultLegalDisclaimers: legalDisclaimers,
                defaultEqualEmploymentOpportunityStatement: equalEmploymentOpportunityStatement
            }
            }
        }).then(res => {
            toast.success('Disclaimers were updated successfully');
            setEqualEmploymentOpportunityStatement(res.data.updateLegalDisclaimers.defaultEqualEmploymentOpportunityStatement);
            setLegalDisclaimers(res.data.updateLegalDisclaimers.defaultLegalDisclaimers);
            setInitialLegalDisclaimers(res.data.updateLegalDisclaimers.defaultLegalDisclaimers);
            setInitialEqualEmploymentOpportunityStatement(res.data.updateLegalDisclaimers.defaultEqualEmploymentOpportunityStatement);
            setIsResponseModalOpen(true);
        }).catch(err => {
            toast.error('Error updating disclaimers', err);
        }
        )
    }



    return (
        <div className='applicant-home-container'>
            <ToastContainer 
            theme="colored"
            />
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="applicant-home-header">
                <h2 className="applicant-home-header-title">COMPANY SETTINGS</h2>
                <seperator className="applicant-home-header-seperator"/>
            </div>
            {isResponseModalOpen && (
                <ResponseModal 
                    onClose={() => setIsResponseModalOpen(false)} 
                    body="Disclaimers were updated successfully" 
                />
            )}

            {/* screened */}
{/* 
            <div xxs="12" md="12">
                <Card className="toggle-card" style={accordion[0] ? { height: '80vh', overflow: 'scroll'}: {}}>
                <div className="card-toggle-title">
                    <label>Customize Screened Items</label>
                    <img 
                    onClick={() => toggleAccordion(0)}
                    src={accordion[0] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[0]}>
                        <CustomizeApplicationSettings  screenedData={data.getProgramById.screenedData}/>
                    </Collapse>
                </Card>
            </div> */}



            <div xxs="12" md="12">
                <Card className="toggle-card" style={accordion[1] ? { height: '80vh', overflow: 'scroll'}: {}}>
                <div className="card-toggle-title">
                    <label>Manage Users</label>
                    <img 
                    onClick={() => toggleAccordion(1)}
                    src={accordion[0] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[1]} >
                        <MyTeam />
                    </Collapse>
                </Card>
            </div>
            <div xxs="12" md="12">
                <Card className="toggle-card">
                <div className="card-toggle-title">
                    <label>Manage Evaluation Forms</label>
                    <img 
                    onClick={() => toggleAccordion(2)}
                    src={accordion[0] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[2]}>
                        <RubricSettings programRubricsOriginal={data.getProgramById.rubricTemplate} isRubricLocked={data.getProgramById.isRubricLocked} refetchProgram={refetch}/>
                    </Collapse>
                </Card>
            </div>

            {/* customize Email Sender */}
{/* 
            <div xxs="12" md="12">
                <Card className="toggle-card" style={accordion[3] ? { height: '80vh', overflow: 'scroll'}: {}}>
                <div className="card-toggle-title">
                    <label>Customize Email Sender</label>
                    <img 
                    onClick={() => toggleAccordion(3)}
                    src={accordion[3] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[3]}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <label>From</label>
                            <div style={{ marginBottom: '2vh'}}>
                                <input
                                className="applicant-top-bar-search-input" 
                                style={{width:'30vw',height:'4.7vh',alignSelf:'center'}}
                                type="text" value={fromEmail} onChange={(e) => setFromEmail(e.target.value)}/>
                            </div>
                        </div>
                        <button className="green-button" onClick={verifyEmail}>Verify Email</button>
                    </Collapse>
                </Card>
            </div>
             */}



                <div xxs="12" md="12">
                <Card className="toggle-card" style={accordion[4] ? { height: '80vh', overflow: 'scroll'}: {}}>
                <div className="card-toggle-title">
                    <label>Manage Legal Disclaimers</label>
                    <img 
                    onClick={() => toggleAccordion(4)}
                    src={accordion[4] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[4]} >
                        <div style={{marginTop:'2vh'}}>
                        <h3> Legal Disclaimers</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', gap:'2vw', marginBottom:'3vh'}}>
                        <textarea
                             style={{
                                width: '70vw',
                                height: '20vh',
                                alignSelf: 'center',
                                cursor: isTextareaFocused1 ? 'text' : 'pointer'
                            }}
                            value={legalDisclaimers}
                            onChange={(e) => setLegalDisclaimers(e.target.value)}
                            onBlur={(e) => {
                                console.log("this is here =>", e.target.value);
                                setIsTextareaFocused1(false);
                            }}
                            onFocus={() => setIsTextareaFocused1(true)}
                        />
                        <button 
                        className="green-button"
                         style={{alignSelf:'center', marginTop:'2vh'}}
                         disabled={legalDisclaimers === initialLegalDisclaimers}
                         onClick={saveLegalDisclaimers}
                         >Save</button>
                        </div>

                        <h3> Equal Employment Opportunity Statement</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', gap:'2vw'}}>
                            <textarea
                             style={{
                                width: '70vw',
                                height: '20vh',
                                alignSelf: 'center',
                                cursor: isTextareaFocused2 ? 'text' : 'pointer',
                            }}
                            value={equalEmploymentOpportunityStatement}
                            onChange={(e) => setEqualEmploymentOpportunityStatement(e.target.value)}
                            onBlur={(e) => {
                                console.log("this is here =>", e.target.value);
                                setIsTextareaFocused2(false);
                            }}
                            onFocus={() => setIsTextareaFocused2(true)}
                        />
                         <button 
                        className="green-button"
                         style={{alignSelf:'center', marginTop:'2vh'}}
                         disabled={equalEmploymentOpportunityStatement === initialEqualEmploymentOpportunityStatement}
                         onClick={saveLegalDisclaimers}
                         >Save</button>
                        </div>

                        </div>



                    </Collapse>
                </Card>
            </div>


            <div xxs="12" md="12">
                <Card className="toggle-card" style={accordion[5] ? { height: '80vh', overflow: 'scroll'}: {}}>
                <div className="card-toggle-title">
                    <label>Manage Company Details</label>
                    <img 
                    onClick={() => toggleAccordion(5)}
                    src={accordion[5] ? toggleArrowUpIcon : toggleArrowIcon}
                    alt="toggle-icon" className="toggle-icon" />
                </div>
                
                
                    <Collapse isOpen={accordion[5]} >
                        <div style={{marginTop:'2vh'}}>
                        <ProgramDetails programData={data.getProgramById} refetch={refetch}/>
                        </div>



                    </Collapse>
                </Card>
            </div>

        </div>
    )

}

export default ProgramSettings;