import { gql } from '@apollo/client';

export const updateUserSuspendMutation = gql`
  mutation updateUserSuspend($id: ID, $suspend: Boolean) {
    updateUserSuspend(id: $id, suspend:$suspend) {
      id
    }
  }
`


export const updateUserMutation = gql`
    mutation updateUser($id: ID!, $input: UpdateUserInput! ){
        updateUser(id: $id, input: $input) {
            id
        }
    }
`

export const REMOVE_PROGRAM_MEMBER = gql`
mutation RemoveProgramMember($programId: ID!, $userId: ID!) {
  removeProgramMember(programId: $programId, userId: $userId) {
    userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
  }
}
`;

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: ID!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
        id
    }
  }
`;

export const CREATE_SCORE = gql`
  mutation CreateScore($input: ScoreInput!) {
    createScore(input: $input) {
      id
      applicantId
      uploaderId
      stage
      usmleStep1Score
      comlexLevel1Score
      usmleStep2Score
      usmleStep3Score
      comlexLevel2Score
      previousUsmleComlexFails
      usmleComlexDescription
      usmleDocs
      comlexDocs
      scoresVerified
      requestedAt
      submittedAt
      usmleScoresVerified
      comlexScoresVerified
      medicalSchoolEmail
      mspeReqEmailAdd
    }
  }
`;

export const updateScoreMutation = gql`
  mutation updateScore($id: ID!, $input: ScoreInput!) {
    updateScore(id: $id, input: $input) {
      id
    }
  }
`

export const updateProgramUserMutation = gql`
  mutation updateProgramUser($userId: ID!, $programId:ID, $roles: [String]! ){
    updateProgramUser(userId: $userId, programId:$programId roles: $roles) {
        id
    }
  }
`

export const inviteUserMutation = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      userId{
        id
      }
    }
  }
`
export const inviteApplicantMutation = gql`
  mutation inviteApplicant($input: InviteApplicantInput!) {
    inviteApplicant(input: $input) {
        id
    }
  }
`

export const inviteUserToPortalMutation = gql`
  mutation inviteUserToPortal($email: String!, $institutionId: ID, $institution: String, $firstName: String, $lastName: String, $phone: String) {
    inviteUserToPortal(email: $email, institutionId: $institutionId, institution: $institution, firstName: $firstName, lastName: $lastName, phone: $phone) {
        id
    }
  }
`

export const updatePaymentDetailsMutation = gql`
  mutation updatePaymentDetails($masterApplicationId: ID, $userId: ID) {
    updatePaymentDetails(masterApplicationId: $masterApplicationId, userId: $userId) {
      id
    } 
  }
`

export const updateSpecialtyRequirementsMutation = gql`
  mutation updateSpecialtyRequirements($specialtyId: ID, $applicationRequirements: ApplicationRequirementsInput){
    updateSpecialtyRequirements(specialtyId: $specialtyId, applicationRequirements: $applicationRequirements) {
      id
    }
  }
`
export const updateSpecialtyMutation = gql`
  mutation updateSpecialty($id: ID!, $input: UpdateSpecialtyInput!) {
    updateSpecialty(id: $id, input: $input) {
      id
    }
  }
`

export const createSpecialtyMutation = gql`
  mutation createSpecialty($input: CreateSpecialtyInput!) {
    createSpecialty(input: $input) {
      id
    }
  }`


export const updateBypassSMSMutation = gql`
mutation updateBypassSMS($id: ID!, $bypassSMS: Boolean!, $permanentBypassSMS: Boolean!) {
  updateBypassSMS(id: $id, bypassSMS: $bypassSMS, permanentBypassSMS: $permanentBypassSMS) {
    id
  }
}
`

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;

export const CREATE_RUBRIC_TEMPLATE = gql`
  mutation CreateRubricTemplate($programId: ID!, $input: RubricTemplateInput!) {
    createRubricTemplate(programId: $programId, input: $input) {
      id
    }
  }
`;

export const UPDATE_RUBRIC_TEMPLATE = gql`
  mutation UpdateRubricTemplate($programId: ID!, $rubricTemplateId: ID!, $input: RubricTemplateInput!) {
    updateRubricTemplate(programId: $programId, rubricTemplateId: $rubricTemplateId, input: $input) {
      id
    }
  }
`;

export const DELETE_RUBRIC_TEMPLATE = gql`
  mutation DeleteRubricTemplate($programId: ID!, $rubricTemplateId: ID!) {
    deleteRubricTemplate(programId: $programId, rubricTemplateId: $rubricTemplateId) {
      id
      rubricTemplate{
        id
        label
        inputType
        isPreInterview
        maxScale
        lowestScaleText
        highestScaleText
        dropdownOptions {
          option
          score
        }
        text
        order
    }
    }
  }
`;

export const UPDATE_SCREENED_DATA = gql`
    mutation UpdateScreenedData($programId: ID!, $input: ScreenedDataInput!) {
        updateScreenedData(programId: $programId, input: $input) {
            id
            screenedData {
                sections {
                    personalInfo
                    residencyApplication
                    personalStatement
                    documents
                    lors
                }
                personalInfo {
                    profilePicture
                    firstName
                    lastName
                    email
                    phoneNumber
                    genderIdentity
                    nrmpId
                    aamcId
                    hometown
                    raceEthnicity
                    homeAddress
                    workAuthorization
                    preventResponsibilities
                    elaborateResponsibilities
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    couplesMatch
                    partnerField
                    previouslyApplied
                    malpracticeLawsuit
                    elaborateMalpractice
                    previousLastName
                    linkedinLink
                    prefferedName
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    militaryObligation
                    otherServiceObligations
                    medicalTrainingInterrupted
                    sigmaPhi
                }
                residencyApplication {
                    medicalSchoolType
                    medicalSchoolName
                    fluencyInLanguagesBesidesEnglish
                    otherLanguagesFluentIn
                    otherDegreesInstitutionsDates
                    aoaStatus
                    ghhsStatus
                    medicalSchoolAwardsHonors
                    coreClerkshipsGraded
                    specifyClerkshipDetails
                    clerkshipGrades
                    awayRotations
                    nonCoreClerkshipGradedCourses
                    impactfulResearchPublications
                    additionalResearchBibliography
                    firstAuthorPublications
                    totalPeerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    meaningfulExperiences
                    significantWorkExperiences
                    shortQuestions
                    hobbiesInterests
                }
            }
        }
    }
`;


export const createProgramMutation = gql`
    mutation createProgram($input: CreateProgramInput!) {
      createProgram(input: $input) {
        id
      }
    }
`

export const updateApplicantAccessMutation = gql`
  mutation updateApplicantAccess($id: ID!) {
    updateApplicantAccess(id: $id) {
      id
    }
  }
`

export const resetPasswordRequest = gql`
    mutation resetPasswordRequest($email: String!) {
        resetPasswordRequest(email: $email) {
            id
        }
    }
`

export const createInstitutionMutation = gql`
    mutation createInstitution($input: CreateInstitutionInput!) {
      createInstitution(input: $input) {
        id
      }
    }
`

export const updateInstitutionMutation = gql`
  mutation updateInstitution($id: ID!, $input: UpdateInstitutionInput!) {
    updateInstitution(id: $id, input: $input) {
      id
    }
  }
`

export const deleteInstitutionMutation = gql`
  mutation deleteInstitution($id: ID!) {
    deleteInstitution(id: $id ){
      id
    }
  }
`