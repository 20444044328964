import React, { useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { connectMicrosoftCalendar } from '../components/faculty/facultyComponents/interviewManager/graphql/mutations';
import { UserContext } from '../context/userContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MCalAuth = () => {
    const user = useContext(UserContext)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');


    const [handleGoogleOAuthCallback] = useMutation(connectMicrosoftCalendar);

    useEffect(() => {
        if (code) {
            handleGoogleOAuthCallback({
                variables: {
                    userId:user.user.userId,
                    authCode: code
                }
            }).then(res => {
                if (res.data.connectGoogleCalendar) {
                    toast.success("Calendar Integration Successful");
                    navigate('/faculty', { state: { page: 'interviewManager' } });
                }
            }).catch(err => {
                toast.error("Calendar Integration Failed");
            });
        }
    }, [code, handleGoogleOAuthCallback, navigate]);

    return (
        <div>
                  <ToastContainer 
                    theme= "colored"
                  />
            <h1>Calendar Integration</h1>
            <h2>Outlook Calendar</h2>
        </div>
    );
}

export default MCalAuth;