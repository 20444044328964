import React, { useState, createContext, useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './styles/applicationForm.css';
import formValidationSchema from './formValidation';
import ApplicationFormStep1 from './applicationFormSteps/step1';
import ApplicationFormStep2 from './applicationFormSteps/step2';
import ApplicationFormStep3 from './applicationFormSteps/step3';
import ApplicationFormStep4 from './applicationFormSteps/step4';
import ApplicationFormStep5 from './applicationFormSteps/step5';
import ApplicationFormStep6 from './applicationFormSteps/step6Modified';
import ApplicationFormStep7 from './applicationFormSteps/step7';
import PaymentView from './applicationFormSteps/paymentView';
import { getSpecialtyByIdQuery } from './graphql/queries';
import { updateUserMutation, updateMasterApplicationMutation, createScoreMutation, updateScoreMutation, updatePhaseMutation } from './graphql/mutations';
import client from '../../../config/apolloClient';
import greenCheck from '../../../assets/imgs/greenCheck.svg';
import { useQuery, useMutation } from '@apollo/client';
import { merge } from 'lodash';
import SaveFailModal from './applicationFormSteps/saveFailModal';
import Spinner from '../../common/spinner';


export const FormContext = createContext();

const ApplicationForm = ({ applicationData, applicant, score, setPage, setIsApplicationOpen, phases, applications, getApplicant, refetchApplications, refetchUser, updatePhases, selectedApplication, setApplicationData, refreshApplicant }) => {
  const [step, setStep] = useState(1);
  const [lorIdsToAdd, setLorIdsToAdd] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  // const [phasesData, setPhases] = useState(phases)
  const [shortQuestions, setShortQuestions] = useState(['','',''])
  const [specialty, setSpecialty] = useState(null)
  const [selectedLors, setSelectedLors] = useState([])
  const [loadingApplication, setLoadingApplication ] = useState(false)
  const [savingError, setSavingError] = useState(null)
  const [isSaveFailModalOpen, setIsSaveFailModalOpen] = useState(false)




  console.log('applicationData in da application form', applicationData)

  const [updateUser] = useMutation(updateUserMutation);
  const [updateMasterApplication] = useMutation(updateMasterApplicationMutation);

  const formikRef = useRef();
  const initialFormValues = 
  {
    // step 1
    photo: null,//applicant !== null && applicant !== undefined ? applicant.profilePhotoUrl : null,
    firstName: applicant !== null && applicant !== undefined ? applicant.firstName : '',
    lastName: applicant !== null && applicant !== undefined ? applicant.lastName : '',
    email: applicant !== null && applicant !== undefined ? applicant.email : '',
    phoneNumber: applicant !== null && applicant !== undefined ? applicant.phone : '',
    genderIdentity: applicant !== null && applicant !== undefined ? applicant.applicationInformation.gender: "",
    dateOfBirth: applicant !== null && applicant !== undefined ? applicant.applicationInformation.dateOfBirth: "",
    nrmpId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.nrmpId: "",
    aamcId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.aamc: "",
    hometown: applicant !== null && applicant !== undefined ? applicant.applicationInformation.homeTown: "",
    raceEthnicity: applicant !== null && applicant !== undefined ? applicant.applicationInformation.ethnicity: [],
    otherRaceEthnicity:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherRaceEthnicity: "",
    homeAddress: applicant !== null && applicant !== undefined ? applicant.applicationInformation.homeAddress: "",
    workAuthorization: applicant !== null && applicant !== undefined ? applicant.applicationInformation.workAuthorization: "",
    otherWorkAuthorization: applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherWorkAuthorization: "",
    partnerField: applicant !== null && applicant !== undefined ? applicant.applicationInformation.partnerFeild: "",
    partnerName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.partnerName: "",
    carryingOutResponsibilities: applicationData !== null && applicationData !== undefined ? applicationData.carryingOutResponsibilities === true ? "yes" : "no" : "",
    carryingOutResponsibilitiesElaboration: applicationData !== null && applicationData !== undefined ? applicationData.carryingOutResponsibilitiesElaboration : "",
    misdemeanorOrFelony: applicant !== null && applicant !== undefined ? applicant.applicationInformation.misdemeanorOrFelony === true ? "yes": "no": "",
    misdemeanorOrFelonyDetails: applicant !== null && applicant !== undefined ? applicant.applicationInformation.misdemeanorOrFelonyDetails: "",
    couplesMatch: applicant !== null && applicant !== undefined ? applicant.applicationInformation.coupleMatch: "",
    previouslyApplied: applicationData!== null && applicationData !== undefined ? applicationData.previouslyAppliedToSpecialty === true ? "yes": "no": "",
    applicationYear: applicationData!== null && applicationData !== undefined ? applicationData.applicationYear : "",
    malpracticeLawsuit:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.malpractice === true ? "yes": "no": "",
    previousLastName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.previousLastName: "",
    prefferedName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.prefferedName: "",
    linkedinLink: applicant !== null && applicant !== undefined ? applicant.applicationInformation.linkedinLink: "",
    pronouns: applicant !== null && applicant !== undefined ? applicant.applicationInformation.pronouns: "",
    usmleId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.usmleId: "",
    nbomeId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.nbomeId: "",
    aoaId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.aoaId: "",
    permanentAddress: applicant !== null && applicant !== undefined ? applicant.applicationInformation.permanentAddress: "",
    licensureRevoked:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.licensureRevoked === true ? "yes": "no": "",
    licensureRevokedDetails:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.licensureRevokedDetails: "",
    militaryObligation: applicant !== null && applicant !== undefined ? applicant.applicationInformation.militaryObligation === true ? "yes": "no": "",
    militaryObligationDetails: applicant !== null && applicant !== undefined ? applicant.applicationInformation.militaryObligationDetails: "",
    otherServiceObligations: applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherServiceObligations: "",
    medicalTrainingInterrupted: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalTrainingInterrupted === true ? "yes": "no": "",
    medicalTrainingInterruptedDetails: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalTrainingInterruptedDetails: "",
    sigmaPhi: applicant !== null && applicant !== undefined ? applicant.applicationInformation.sigmaPhi : "",

    // step 2
    medicalSchoolType: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalSchoolType : "",
    medicalSchoolName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalSchoolName: "",
    isLanguages: applicant !== null && applicant !== undefined ? applicant.applicationInformation.isLanguages === true ? "yes": "no" : "",
    fluentLanguages: applicant !== null && applicant !== undefined ? applicant.applicationInformation.fluentLanguages : [],
    higherEducation: applicant !== null && applicant !== undefined ? applicant.applicationInformation.higherEducation: [{ institution: '', degree: '', date: '' }],
    aoaStatus: applicant !== null && applicant !== undefined ? applicant.applicationInformation.aoaStatus : "",
    goldHumanism: applicant !== null && applicant !== undefined ? applicant.applicationInformation.goldHumanism : "",
    awardsAndHonors: applicant !== null && applicant !== undefined ? applicant.applicationInformation.awardsAndHonors : "",
    coreClerkshipType: applicant !== null && applicant !== undefined ? applicant.applicationInformation.coreClerkshipType : "",
    otherCkMethod: applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherCkMethod : "",
    coreClerkshipGrades: applicant !== null && applicant !== undefined ? applicant.applicationInformation.coreClerkshipGrades : { clerkshipEm: '', clerkshipFm: '', im: '', neurology: '', obgyn: '', pediatrics: '', psychiatry: '', surgery: '' },
    awayRotations: applicant !== null && applicant !== undefined ? applicant.applicationInformation.awayRotations :[{ institution: '', degree: '', dateCompleted: '' }], // Ensure this is an array
    nonCoreClerkshipGrades: applicant !== null && applicant !== undefined ?  applicant.applicationInformation.nonCoreClerkshipGrades :[{ course: '', grade: '' }], // Ensure this is an array
    firstAuthorPublications: applicant!== null && applicant !== undefined ? applicant.applicationInformation.firstAuthorPublications : "",
    peerReviewedPublications: applicant!== null && applicant !== undefined ? applicant.applicationInformation.peerReviewedPublications : "",
    posterPresentations: applicant!== null && applicant !== undefined ? applicant.applicationInformation.posterPresentations : "",
    podiumPresentations: applicant!== null && applicant !== undefined ? applicant.applicationInformation.podiumPresentations : "",
    researchExperience: applicationData !== null && applicationData !== undefined && (!applicationData.researchExperience || applicationData.researchExperience.length === 0) ? [] : applicationData.researchExperience,
    meaningfulExperiences: applicationData !== null && applicationData !== undefined && (!applicationData.meaningfulExperiences) ? [{ category: '', title:'', startDate:'', endDate:'', description: ''}] : applicationData.meaningfulExperiences,
    workExperiences: applicationData !== null && applicationData !== undefined ? applicationData.workExperiences : [{ employer: '', jobTitle: '', startDate: '', endDate: '', mentorName: '', mentorEmail: '', description: ''}],
    references: applicationData !== null && applicationData !== undefined && applicationData.references !== null && applicationData.references !== undefined ? applicationData.references : [],
    shortQ1: applicationData !== null && applicationData !== undefined && applicationData.shortAnswerQuestions !== null && applicationData.shortAnswerQuestions !== undefined && applicationData.shortAnswerQuestions.length>0  ? applicationData.shortAnswerQuestions[0].question : shortQuestions[0],
    shortQ2: applicationData !== null && applicationData !== undefined && applicationData.shortAnswerQuestions !== null && applicationData.shortAnswerQuestions !== undefined && applicationData.shortAnswerQuestions.length > 0 ? applicationData.shortAnswerQuestions[1].question : shortQuestions[1],
    shortQ3: applicationData !== null && applicationData !== undefined && applicationData.shortAnswerQuestions !== null && applicationData.shortAnswerQuestions !== undefined&& applicationData.shortAnswerQuestions.length > 0 ? applicationData.shortAnswerQuestions[2].question : shortQuestions[2],
    shortA1: applicationData && applicationData.shortAnswerQuestions && applicationData.shortAnswerQuestions[0] ? applicationData.shortAnswerQuestions[0].answer : '',
    shortA2: applicationData && applicationData.shortAnswerQuestions && applicationData.shortAnswerQuestions[1] ? applicationData.shortAnswerQuestions[1].answer : '',
    shortA3: applicationData && applicationData.shortAnswerQuestions && applicationData.shortAnswerQuestions[2] ? applicationData.shortAnswerQuestions[2].answer : '',
    hobbiesAndInterests: applicationData !== null && applicationData !== undefined ? applicationData.hobbiesAndInterests : "",
    qualifications: applicationData !== null && applicationData !== undefined ? removeTypename(applicationData.qualifications) : [],
    certsAnswers: applicationData !== null && applicationData !== undefined ?  removeTypename(applicationData.certsAnswers) : [],
    // step 3
    personalStatement:  applicationData!== null && applicationData !== undefined ? applicationData.personalStatement : "",
    // step 4
    usmleStep1Score: score !== null && score !== undefined ? score.usmleStep1Score : "",
    usmleStep2Score: score !== null && score !== undefined ? score.usmleStep2Score : "",
    usmleStep3Score: score !== null && score !== undefined ? score.usmleStep3Score : "",
    comlexLevel1Score: score !== null && score !== undefined ? score.comlexLevel1Score : "",
    comlexLevel2Score: score !== null && score !== undefined ? score.comlexLevel2Score : "",
    medicalSchoolEmail: score !== null && score !== undefined ? score.medicalSchoolEmail : "",
    mspeReqEmailAdd: score !== null && score !== undefined ? score.mspeReqEmailAdd : "",
  }

  useEffect(() => {
    if (formikRef.current) {
      console.log("in the useEffecct for applicationData", formikRef.current)
      // Reset the form with new initialValues
      const updatedValues = merge({}, formikRef.current.values, mapApplicationDataToFormValues(applicationData));
      formikRef.current.setValues(updatedValues);
    }
  }, [applicationData]); // Dependency array i  ncludes applicationData to trigger effect on change

  useEffect(() => {
    if (formikRef.current) {
      console.log("in the useEffecct for applicantData", formikRef.current)
      // Reset the form with new initialValues
      const updatedValues = merge({}, formikRef.current.values, mapApplicantDataToFormValues(applicant));
      formikRef.current.setValues(updatedValues);
    }
  }, [applicant]); // Dependency array includes applicationData to trigger effect on change



  // Helper function to map applicationData to form values
  function mapApplicationDataToFormValues(data) {
    return {
      carryingOutResponsibilities: applicationData !== null && applicationData !== undefined ? applicationData.carryingOutResponsibilities === true ? "yes" : "no" : "",
      carryingOutResponsibilitiesElaboration: applicationData !== null && applicationData !== undefined ? applicationData.carryingOutResponsibilitiesElaboration : "",
      previouslyApplied: applicationData!== null && applicationData !== undefined ? applicationData.previouslyAppliedToSpecialty === true ? "yes": "no": "",
      applicationYear: applicationData!== null && applicationData !== undefined ? applicationData.applicationYear : "",
      researchExperience: applicationData !== null && applicationData !== undefined && (!applicationData.researchExperience || applicationData.researchExperience.length === 0) ? [] : applicationData.researchExperience,
      meaningfulExperiences: applicationData !== null && applicationData !== undefined && (!applicationData.meaningfulExperiences) ? [{ category: '', title:'', startDate:'', endDate:'', description: ''}] : applicationData.meaningfulExperiences,
      workExperiences: applicationData !== null && applicationData !== undefined ? applicationData.workExperiences : [{ employer: '', jobTitle: '', startDate: '', endDate: '', mentorName: '', mentorEmail: '', description: ''}],
      references: applicationData !== null && applicationData !== undefined ? applicationData.references : [{name:'',  company:'', role:'', phone:'', email:''}],
      shortQ1: applicationData !== null && applicationData !== undefined && applicationData.shortAnswerQuestions !== null && applicationData.shortAnswerQuestions !== undefined && applicationData.shortAnswerQuestions.length>0 ? applicationData.shortAnswerQuestions[0].question : shortQuestions[0],
      shortQ2: applicationData !== null && applicationData !== undefined && applicationData.shortAnswerQuestions !== null && applicationData.shortAnswerQuestions !== undefined&& applicationData.shortAnswerQuestions.length>0 ? applicationData.shortAnswerQuestions[1].question : shortQuestions[1],
      shortQ3: applicationData !== null && applicationData !== undefined && applicationData.shortAnswerQuestions !== null && applicationData.shortAnswerQuestions !== undefined&& applicationData.shortAnswerQuestions.length>0 ? applicationData.shortAnswerQuestions[2].question : shortQuestions[2],
      shortA1: applicationData && applicationData.shortAnswerQuestions && applicationData.shortAnswerQuestions[0] ? applicationData.shortAnswerQuestions[0].answer : '',
      shortA2: applicationData && applicationData.shortAnswerQuestions && applicationData.shortAnswerQuestions[1] ? applicationData.shortAnswerQuestions[1].answer : '',
      shortA3: applicationData && applicationData.shortAnswerQuestions && applicationData.shortAnswerQuestions[2] ? applicationData.shortAnswerQuestions[2].answer : '',
      hobbiesAndInterests: applicationData !== null && applicationData !== undefined ? applicationData.hobbiesAndInterests : "",
      qualifications: applicationData !== null && applicationData !== undefined ? removeTypename(applicationData.qualifications) : [],
      certsAnswers: applicationData !== null && applicationData !== undefined ?  removeTypename(applicationData.certsAnswers) : [],
      // step 3
      personalStatement:  applicationData!== null && applicationData !== undefined ? applicationData.personalStatement : "",
      // step 4
      usmleStep1Score: score !== null && score !== undefined ? score.usmleStep1Score : "",
      usmleStep2Score: score !== null && score !== undefined ? score.usmleStep2Score : "",
      usmleStep3Score: score !== null && score !== undefined ? score.usmleStep3Score : "",
      comlexLevel1Score: score !== null && score !== undefined ? score.comlexLevel1Score : "",
      comlexLevel2Score: score !== null && score !== undefined ? score.comlexLevel2Score : "",
      medicalSchoolEmail: score !== null && score !== undefined ? score.medicalSchoolEmail : "",
      mspeReqEmailAdd: score !== null && score !== undefined ? score.mspeReqEmailAdd : "",

      positionId: applicationData !== null && applicationData !== undefined ? applicationData.positionId : "",
    };
  }

  function mapApplicantDataToFormValues(data)  {
    return {
      photo: null,//applicant !== null && applicant !== undefined ? applicant.profilePhotoUrl : null,
      firstName: applicant !== null && applicant !== undefined ? applicant.firstName : '',
      lastName: applicant !== null && applicant !== undefined ? applicant.lastName : '',
      email: applicant !== null && applicant !== undefined ? applicant.email : '',
      phoneNumber: applicant !== null && applicant !== undefined ? applicant.phone : '',
      genderIdentity: applicant !== null && applicant !== undefined ? applicant.applicationInformation.gender: "",
      dateOfBirth: applicant !== null && applicant !== undefined ? applicant.applicationInformation.dateOfBirth: "",
      nrmpId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.nrmpId: "",
      aamcId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.aamc: "",
      hometown: applicant !== null && applicant !== undefined ? applicant.applicationInformation.homeTown: "",
      raceEthnicity: applicant !== null && applicant !== undefined ? applicant.applicationInformation.ethnicity: [],
      otherRaceEthnicity:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherRaceEthnicity: "",
      homeAddress: applicant !== null && applicant !== undefined ? applicant.applicationInformation.homeAddress: "",
      workAuthorization: applicant !== null && applicant !== undefined ? applicant.applicationInformation.workAuthorization: "",
      otherWorkAuthorization: applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherWorkAuthorization: "",
      partnerField: applicant !== null && applicant !== undefined ? applicant.applicationInformation.partnerFeild: "",
      partnerName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.partnerName: "",
      misdemeanorOrFelony: applicant !== null && applicant !== undefined ? applicant.applicationInformation.misdemeanorOrFelony === true ? "yes": "no": "",
      misdemeanorOrFelonyDetails: applicant !== null && applicant !== undefined ? applicant.applicationInformation.misdemeanorOrFelonyDetails: "",
      couplesMatch: applicant !== null && applicant !== undefined ? applicant.applicationInformation.coupleMatch: "",
      malpracticeLawsuit:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.malpractice === true ? "yes": "no": "",
      previousLastName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.previousLastName: "",
      prefferedName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.prefferedName: "",
      linkedinLink: applicant !== null && applicant !== undefined ? applicant.applicationInformation.linkedinLink: "",
      pronouns: applicant !== null && applicant !== undefined ? applicant.applicationInformation.pronouns: "",
      usmleId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.usmleId: "",
      nbomeId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.nbomeId: "",
      aoaId: applicant !== null && applicant !== undefined ? applicant.applicationInformation.aoaId: "",
      permanentAddress: applicant !== null && applicant !== undefined ? applicant.applicationInformation.permanentAddress: "",
      licensureRevoked:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.licensureRevoked === true ? "yes": "no": "",
      licensureRevokedDetails:  applicant !== null && applicant !== undefined ? applicant.applicationInformation.licensureRevokedDetails: "",
      militaryObligation: applicant !== null && applicant !== undefined ? applicant.applicationInformation.militaryObligation === true ? "yes": "no": "",
      militaryObligationDetails: applicant !== null && applicant !== undefined ? applicant.applicationInformation.militaryObligationDetails: "",
      otherServiceObligations: applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherServiceObligations: "",
      medicalTrainingInterrupted: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalTrainingInterrupted === true ? "yes": "no": "",
      medicalTrainingInterruptedDetails: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalTrainingInterruptedDetails: "",
      sigmaPhi: applicant !== null && applicant !== undefined ? applicant.applicationInformation.sigmaPhi : "",
      medicalSchoolType: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalSchoolType : "",
      medicalSchoolName: applicant !== null && applicant !== undefined ? applicant.applicationInformation.medicalSchoolName: "",
      isLanguages: applicant !== null && applicant !== undefined ? applicant.applicationInformation.isLanguages === true ? "yes": "no" : "",
      fluentLanguages: applicant !== null && applicant !== undefined ? applicant.applicationInformation.fluentLanguages : [],
      higherEducation: applicant !== null && applicant !== undefined ? applicant.applicationInformation.higherEducation: [{ institution: '', degree: '', date: '' }],
      aoaStatus: applicant !== null && applicant !== undefined ? applicant.applicationInformation.aoaStatus : "",
      goldHumanism: applicant !== null && applicant !== undefined ? applicant.applicationInformation.goldHumanism : "",
      awardsAndHonors: applicant !== null && applicant !== undefined ? applicant.applicationInformation.awardsAndHonors : "",
      coreClerkshipType: applicant !== null && applicant !== undefined ? applicant.applicationInformation.coreClerkshipType : "",
      otherCkMethod: applicant !== null && applicant !== undefined ? applicant.applicationInformation.otherCkMethod : "",
      coreClerkshipGrades: applicant !== null && applicant !== undefined ? applicant.applicationInformation.coreClerkshipGrades : { clerkshipEm: '', clerkshipFm: '', im: '', neurology: '', obgyn: '', pediatrics: '', psychiatry: '', surgery: '' },
      awayRotations: applicant !== null && applicant !== undefined ? applicant.applicationInformation.awayRotations :[{ institution: '', degree: '', dateCompleted: '' }], // Ensure this is an array
      nonCoreClerkshipGrades: applicant !== null && applicant !== undefined ?  applicant.applicationInformation.nonCoreClerkshipGrades :[{ course: '', grade: '' }], // Ensure this is an array
      firstAuthorPublications: applicant!== null && applicant !== undefined ? applicant.applicationInformation.firstAuthorPublications : "",
      peerReviewedPublications: applicant!== null && applicant !== undefined ? applicant.applicationInformation.peerReviewedPublications : "",
      posterPresentations: applicant!== null && applicant !== undefined ? applicant.applicationInformation.posterPresentations : "",
      podiumPresentations: applicant!== null && applicant !== undefined ? applicant.applicationInformation.podiumPresentations : "",

    };
  }


  const { data: specialtyData, error: specialtyError, isLoading: specialtyLoading , refetch} = useQuery(getSpecialtyByIdQuery, {
    variables: {
        id: applicationData.specialtyId
    },
    fetchPolicy: "network-only"
  });

  

  useEffect(() => {
    if (specialtyLoading) {
      return; // Or set a loading state if necessary
    } else if (specialtyError) {
      console.error(specialtyError);
      return; // Handle the error appropriately
    } else if (specialtyData && specialtyData.getSpecialtyById) {
      console.log("specialtyData =>", specialtyData)
      let countRes = specialtyData.getSpecialtyById;
      if (JSON.stringify(specialty) !== JSON.stringify(countRes)) {
            setSpecialty(countRes);
            console.log("countRes =>", countRes);
            setShortQuestions(countRes.shortAnswerQuestions)
      }
    }
  }, [specialtyData, specialtyError, specialtyLoading, specialty, shortQuestions, setSpecialty]);

  useEffect(() => {
    console.log("shortQuestions changed!", shortQuestions)
  }, [shortQuestions])




  // const shortQuestions = [
  //   "Leadership often involves guiding and mentoring others to reach their full potential. Share an instance where you mentored a junior colleague and describe the impact you had on their development and growth.",
  //   "As a urology resident, you will inevitably encounter stressful and high-pressure situations. How do you typically manage your emotions and maintain composure during challenging medical cases or emergencies?",
  //   "Empathy is an essential aspect of patient care. Describe a time when you demonstrated exceptional empathy towards a patient or their family. How did your ability to connect emotionally with the patient positively impact their care?",
  // ];



  const updateSelectedPrograms = (selectedPrograms) => {
    console.log("selectedPrograms to update =>", selectedPrograms)
    setSelectedPrograms(selectedPrograms)
  }

  const updateApplicationData = (data, step) => {
    return new Promise((resolve, reject) => {
      setLoadingApplication(true)
    let masterApplicationToUpdate = {}
    const cleanedMeaningfulExperiences1 = data.meaningfulExperiences.map(({ __typename, ...rest }) => rest);
    const cleanedWorkExperience1 = data.workExperiences.map(({ __typename, ...rest }) => rest);
    const researchExperience = data.researchExperience.map(({ __typename, ...rest }) => rest);


    switch(step) {
      case 3:
        masterApplicationToUpdate = {
          personalStatement: data.personalStatement
        }

        const updateMasterApplicationPromiseStep3 = client.mutate({
          mutation: updateMasterApplicationMutation,
          variables: {
            id: applicationData.id,
            input: masterApplicationToUpdate
          },
          fetchPolicy: 'network-only'
        });
        
     
        // Use Promise.all to wait for both mutations to complete
        Promise.all([updateMasterApplicationPromiseStep3])
          .then((results) => {
            // results[0] is the response from updateMasterApplicationMutation
            // results[1] is the response from updateUserMutation
            // You can now update your state or UI based on the results
            const masterApplicationResponse = results[0];
            // Example: Update application data with the response
            setApplicationData(masterApplicationResponse.data.updateMasterApplication);
            setLoadingApplication(false)
            resolve(results);
          })
          .catch((error) => {
            setSavingError(error)
            setIsSaveFailModalOpen(true)
            reject(error)
          });

      break
    
  }
  })
  }

  function removeTypename(obj) {
    if (Array.isArray(obj)) {
        return obj.map(item => removeTypename(item)); // Recursively handle arrays
    } else if (typeof obj === 'object' && obj !== null) {
        const { __typename, ...rest } = obj; // Remove __typename from the current level
        // Recursively handle nested fields
        Object.keys(rest).forEach(key => {
            if (typeof rest[key] === 'object') {
                rest[key] = removeTypename(rest[key]); // Recursively remove __typename from nested objects
            }
        });
        return rest;
    }
    return obj;
}


  const updateUserData = (data, step) => {
    return new Promise((resolve, reject) => {
      setLoadingApplication(true)
    let userDataToUpdate = {}
    let masterApplicationToUpdate = {}
    const researchExperience = data.researchExperience.map(({ __typename, ...rest }) => rest);
    // for the higher education array, remove from all elements the isEditing key

    switch(step){
      case 1: 
      const cleanedHigherEducation1 = data.higherEducation.map(({ __typename, isEditable, ...rest }) => rest);
      const cleanedAwayRotation1 = data.awayRotations.map(({ __typename, isEditable, ...rest }) => rest);
      const cleanedNonCoreClerkshipGrades1 = data.nonCoreClerkshipGrades.map(({ __typename, isEditable, ...rest }) => rest);
      const cleanedMeaningfulExperiences1 = data.meaningfulExperiences.map(({ __typename, ...rest }) => rest);
      const cleanedWorkExperience1 = data.workExperiences.map(({ __typename, ...rest }) => rest);
 
        userDataToUpdate = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phoneNumber,
          applicationInformation: {
            previousLastName: data.previousLastName,
            prefferedName: data.prefferedName,
            linkedinLink: data.linkedinLink,
            pronouns: data.pronouns,
            usmleId: data.usmleId,
            nbomeId: data.nbomeId,
            aoaId: data.aoaId,
            permanentAddress: data.permanentAddress,
            licensureRevoked:  data.licensureRevoked === "" ? null : data.licensureRevoked === "yes" ? true : false,
            licensureRevokedDetails: data.licensureRevokedDetails,
            militaryObligation:  data.militaryObligation === "" ? null : data.militaryObligation === "yes" ? true : false,
            militaryObligationDetails: data.militaryObligationDetails,
            otherServiceObligations: data.otherServiceObligations,
            medicalTrainingInterrupted: data.medicalTrainingInterrupted === "" ? null : data.medicalTrainingInterrupted === "yes" ? true : false,
            medicalTrainingInterruptedDetails: data.medicalTrainingInterruptedDetails,
            sigmaPhi:  data.sigmaPhi,
            nrmpId: data.nrmpId,
            dateOfBirth: data.dateOfBirth,
            aamc: data.aamcId,
            homeTown: data.hometown,
            gender: data.genderIdentity,
            ethnicity: data.raceEthnicity,
            otherRaceEthnicity: data.otherRaceEthnicity,
            homeAddress: data.homeAddress,
            workAuthorization: data.workAuthorization,
            otherWorkAuthorization: data.otherWorkAuthorization,
            partnerFeild: data.partnerField,
            partnerName: data.partnerName,
            misdemeanorOrFelony: data.misdemeanorOrFelony === "" ? null : data.misdemeanorOrFelony === "yes" ? true : false,
            misdemeanorOrFelonyDetails: data.misdemeanorOrFelonyDetails,
            coupleMatch: data.couplesMatch === "" ? null : data.couplesMatch ,
            malpractice: data.malpractice === "" ? null : data.malpractice === "yes" ? true : false,
            medicalSchoolType: data.medicalSchoolType,
            medicalSchoolName: data.medicalSchoolName,
            isLanguages: data.isLanguages === "" ? null : data.isLanguages === "yes" ? true : false,
            fluentLanguages: data.fluentLanguages,
            higherEducation: cleanedHigherEducation1,
            aoaStatus: data.aoaStatus,
            goldHumanism: data.goldHumanism,
            awardsAndHonors: data.awardsAndHonors,
            coreClerkshipType: data.coreClerkshipType,
            otherCkMethod: data.otherCkMethod,
            coreClerkshipGrades: data.coreClerkshipGrades,
            awayRotations: cleanedAwayRotation1,
            nonCoreClerkshipGrades: cleanedNonCoreClerkshipGrades1,
            firstAuthorPublications: parseInt(data.firstAuthorPublications),
            peerReviewedPublications: parseInt(data.peerReviewedPublications),
            posterPresentations: parseInt(data.posterPresentations),
            podiumPresentations: parseInt(data.podiumPresentations)
          },
        }

        masterApplicationToUpdate = {
          carryingOutResponsibilities: data.carryingOutResponsibilities === "" ? null : data.carryingOutResponsibilities === "yes" ? true : false,
          carryingOutResponsibilitiesElaboration: data.carryingOutResponsibilitiesElaboration,
          previouslyAppliedToSpecialty: data.previouslyApplied === "" ? null : data.previouslyApplied === "yes" ? true : false,
          applicationYear: data.applicationYear,
          applicationCustomFields: data.applicationCustomFields,
          qualifications: removeTypename(data.qualifications),
          certsAnswers: removeTypename(data.certsAnswers)
        }

        const updateMasterApplicationPromiseStep1 = client.mutate({
          mutation: updateMasterApplicationMutation,
          variables: {
            id: applicationData.id,
            input: masterApplicationToUpdate
          },
          fetchPolicy: 'network-only'
        });
        
        const updateUserPromiseStep1 = client.mutate({
          mutation: updateUserMutation,
          variables: {
            id: applicant.id,
            input: userDataToUpdate
          },
          fetchPolicy: 'network-only'
        });
        
        // Use Promise.all to wait for both mutations to complete
        Promise.all([updateMasterApplicationPromiseStep1, updateUserPromiseStep1])
          .then((results) => {
            // results[0] is the response from updateMasterApplicationMutation
            // results[1] is the response from updateUserMutation
            // You can now update your state or UI based on the results
            const masterApplicationResponse = results[0];
            const userResponse = results[1];
            // Example: Update application data with the response
            setApplicationData(masterApplicationResponse.data.updateMasterApplication);
            refreshApplicant(userResponse.data.updateUser);
            setLoadingApplication(false)
            resolve(results);
          })
          .catch((error) => {
            // Handle any errors that occur during either mutation
            setSavingError(error)
            setIsSaveFailModalOpen(true)
            reject(error)
          });

      break
      case 5:

      setLoadingApplication(true)
        const cleanedReferences = data.references.map(({ __typename, isEditable, ...rest }) => rest);

        const applicationToUpdate ={
          references: cleanedReferences
        }

        const updateApplicationPromiseStep5 = client.mutate({
          mutation: updateMasterApplicationMutation,
          variables: {
            id: applicationData.id,
            input: applicationToUpdate
          },
          fetchPolicy: 'network-only'
        }).then((result) => {
          setApplicationData(result.data.updateMasterApplication);
          setLoadingApplication(false)
        }).catch((error) => {
          setSavingError(error)
          setIsSaveFailModalOpen(true)
          reject(error)
        }
        );
      break
      case 2:
        const cleanedHigherEducation = data.higherEducation.map(({ __typename, isEditable, ...rest }) => rest);
        const cleanedAwayRotation = data.awayRotations.map(({ __typename, isEditable, ...rest }) => rest);
        const cleanedNonCoreClerkshipGrades = data.nonCoreClerkshipGrades.map(({ __typename, isEditable, ...rest }) => rest);
        const cleanedMeaningfulExperiences = data.meaningfulExperiences.map(({ __typename, ...rest }) => rest);
        const cleanedWorkExperience = data.workExperiences.map(({ __typename, ...rest }) => rest);
        // const cleandCoreClerkshipGrades = data.coreClerkshipGrades.map(({ __typename, ...rest }) => rest);

        setLoadingApplication(true)

        userDataToUpdate = {
          applicationInformation: {
            previousLastName: data.previousLastName,
            prefferedName: data.prefferedName,
            pronouns: data.pronouns,
            usmleId: data.usmleId,
            nbomeId: data.nbomeId,
            aoaId: data.aoaId,
            linkedinLink: data.linkedinLink,
            permanentAddress: data.permanentAddress,
            licensureRevoked:  data.licensureRevoked === "" ? null : data.licensureRevoked === "yes" ? true : false,
            licensureRevokedDetails: data.licensureRevokedDetails,
            militaryObligation:  data.militaryObligation === "" ? null : data.militaryObligation === "yes" ? true : false,
            militaryObligationDetails: data.militaryObligationDetails,
            otherServiceObligations: data.otherServiceObligations,
            medicalTrainingInterrupted: data.medicalTrainingInterrupted === "" ? null : data.medicalTrainingInterrupted === "yes" ? true : false,
            medicalTrainingInterruptedDetails: data.medicalTrainingInterruptedDetails,
            sigmaPhi:  data.sigmaPhi,
            nrmpId: data.nrmpId,
            dateOfBirth: data.dateOfBirth,
            aamc: data.aamcId,
            homeTown: data.hometown,
            gender: data.genderIdentity,
            ethnicity: data.raceEthnicity,
            otherRaceEthnicity: data.otherRaceEthnicity,
            homeAddress: data.homeAddress,
            workAuthorization: data.workAuthorization,
            otherWorkAuthorization: data.otherWorkAuthorization,
            partnerFeild: data.partnerField,
            partnerName: data.partnerName,
            misdemeanorOrFelony: data.misdemeanorOrFelony === "" ? null : data.misdemeanorOrFelony === "yes" ? true : false,
            misdemeanorOrFelonyDetails: data.misdemeanorOrFelonyDetails,
            coupleMatch: data.couplesMatch === "" ? null : data.couplesMatch ,
            malpractice: data.malpractice === "" ? null : data.malpractice === "yes" ? true : false,
            medicalSchoolType: data.medicalSchoolType,
            medicalSchoolName: data.medicalSchoolName,
            isLanguages: data.isLanguages === "" ? null : data.isLanguages === "yes" ? true : false,
            fluentLanguages: data.fluentLanguages,
            higherEducation: cleanedHigherEducation,
            aoaStatus: data.aoaStatus,
            goldHumanism: data.goldHumanism,
            awardsAndHonors: data.awardsAndHonors,
            coreClerkshipType: data.coreClerkshipType,
            otherCkMethod: data.otherCkMethod,
            coreClerkshipGrades: data.coreClerkshipGrades,
            awayRotations: cleanedAwayRotation,
            nonCoreClerkshipGrades: cleanedNonCoreClerkshipGrades,
            firstAuthorPublications: parseInt(data.firstAuthorPublications),
            peerReviewedPublications: parseInt(data.peerReviewedPublications),
            posterPresentations: parseInt(data.posterPresentations),
            podiumPresentations: parseInt(data.podiumPresentations)
          }
        }

        masterApplicationToUpdate = {
          carryingOutResponsibilities: data.carryingOutResponsibilities === "" ? null : data.carryingOutResponsibilities === "yes" ? true : false,
          carryingOutResponsibilitiesElaboration: data.carryingOutResponsibilitiesElaboration,
          previouslyAppliedToSpecialty: data.previouslyApplied === "" ? null : data.previouslyApplied === "yes" ? true : false,
          applicationYear: data.applicationYear,
          researchExperience,
          meaningfulExperiences: cleanedMeaningfulExperiences,
          workExperiences: cleanedWorkExperience,
          shortAnswerQuestions: [
            { question: data.shortQ1, answer: data.shortA1 },
            { question: data.shortQ2, answer: data.shortA2 },
            { question: data.shortQ3, answer: data.shortA3 },
          ],
          hobbiesAndInterests: data.hobbiesAndInterests,
          applicationCustomFields: data.applicationCustomFields,
          qualifications: removeTypename(data.qualifications),
          certsAnswers: removeTypename(data.certsAnswers)
        }
        console.log("update user data =>", userDataToUpdate)
    
        const updateMasterApplicationPromise = client.mutate({
          mutation: updateMasterApplicationMutation,
          variables: {
            id: applicationData.id,
            input: masterApplicationToUpdate
          },
          fetchPolicy: 'network-only'
        });
        
        const updateUserPromise = client.mutate({
          mutation: updateUserMutation,
          variables: {
            id: applicant.id,
            input: userDataToUpdate
          },
          fetchPolicy: 'network-only'
        });
        
        // Use Promise.all to wait for both mutations to complete
        Promise.all([updateMasterApplicationPromise, updateUserPromise])
          .then((results) => {
            // results[0] is the response from updateMasterApplicationMutation
            // results[1] is the response from updateUserMutation
            // You can now update your state or UI based on the results
            const masterApplicationResponse = results[0];
            const userResponse = results[1];
            // Example: Update application data with the response
            setApplicationData(masterApplicationResponse.data.updateMasterApplication);
            refreshApplicant(userResponse.data.updateUser);
            setLoadingApplication(false)
            resolve(results);
          })
          .catch((error) => {
            setSavingError(error)
            setIsSaveFailModalOpen(true)
            reject(error)
          });

      break


      case 3:
        masterApplicationToUpdate = {
          personalStatement: data.personalStatement
        }

        const updateMasterApplicationPromiseStep3 = client.mutate({
          mutation: updateMasterApplicationMutation,
          variables: {
            id: applicationData.id,
            input: masterApplicationToUpdate
          },
          fetchPolicy: 'network-only'
        });
        
     
        // Use Promise.all to wait for both mutations to complete
        Promise.all([updateMasterApplicationPromiseStep3])
          .then((results) => {
            // results[0] is the response from updateMasterApplicationMutation
            // results[1] is the response from updateUserMutation
            // You can now update your state or UI based on the results
            const masterApplicationResponse = results[0];
            // Example: Update application data with the response
            setApplicationData(masterApplicationResponse.data.updateMasterApplication);
            setLoadingApplication(false)
            resolve(results);
          })
          .catch((error) => {
            setSavingError(error)
            setIsSaveFailModalOpen(true)
            reject(error)
          });

      break


      default:
      break
  }
});
};

  const updatePhase = (step) => {
    client.mutate({
      mutation: updatePhaseMutation,
      variables: {
        id: applicationData.id,
        phase: step,
      }
    }).then(res => {
      // setPhases(res.data.updatePhase)
      updatePhases(res.data.updatePhase);
      console.log("phases =>", res.data.updatePhase)
    }
    )
  }

  const updateLorInApplication = (lors) => {
    let lorToAdd = lors.filter(lor => lor.checked !== null && lor.checked !== undefined && lor.checked === true)
    let lorIds = lorToAdd.map(lor => lor.id)
    console.log("lorIds to add =>", lorIds)
    setLorIdsToAdd(lorIds)
  }

  useEffect(() => {
    console.log("selectedLors in form =>", selectedLors)
   
  }, [selectedLors, setSelectedLors]);

  const updateSelectedLors = (selected) => {
    console.log("selected =>", selected)
    setSelectedLors(selected)
  }

  const moveStepClicked = async (newStep, values) => {
    if (loadingApplication) {
      return;
    }
    const prevStep = step
    if (prevStep === 4) {
      setStep(newStep)
      return
    }
    if (prevStep === 5) {
      updateLorInApplication(selectedLors)
      setStep(newStep)
      return
    }
    if (prevStep === 6) {
      setStep(newStep)
      return
    }
    if (prevStep === 7) {
      setStep(newStep)
      return
    }
    if (prevStep === 8) {
      setStep(newStep)
      return
    }

    try {
      await updateUserData(values, prevStep)
      console.log('User data updated successfully, now checking for application data')
    setStep(newStep)
  } catch (error) {
    console.error("Error moving step", error)
  }finally {
    setLoadingApplication(false)
  }
}

  console.log("application data before render =>", applicationData)




  return (
    <Formik    
    validationSchema={formValidationSchema}
    initialValues={initialFormValues}
    onSubmit={(values, actions) => {
      console.log("Form submitted with values:", values);
      // other logic here
  }}
  innerRef={formikRef}
    
    >
      {({ handleSubmit, values, setFieldValue, submitForm, setFieldTouched,  errors, isValid, isSubmitting, validateForm }) => (
       <Form onSubmit={handleSubmit}
       
        style={{paddingRight:'3vw',paddingLeft:'2vw'}}>
                {/* {console.log("values statem =>", values.personalStatement, applicationData.personalStatement)} */}

          <div className="step-indicators-container"   style={{marginTop:'15vh' }}>  <div className="step-indicators-container">
                <div className={step === 1 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(1, values)}}>
                  {phases && phases.firstPhase && 
                    <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                  }
                  {!phases || !phases.firstPhase &&
                    <div className="step-indicator-number">1</div>
                  }
                    <div className="step-indicator-text">Personal</div>
                </div>
                <div className={step === 2 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(2, values)}}>
                {phases && phases.secondPhase && 
                    <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                  }
                  {!phases || !phases.secondPhase &&
                    <div className="step-indicator-number">2</div>
                  }
                    <div className="step-indicator-text">Professional</div>
                </div>
                <div className={step === 3 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(3,values)}}>
                {phases && phases.thirdPhase && 
                    <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                  }
                  {!phases || !phases.thirdPhase &&
                    <div className="step-indicator-number">3</div>
                  }
                    <div className="step-indicator-text">Cover Letter</div>
                </div>
                <div className={step === 4 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(4,values)}}>
                {phases && phases.fourthPhase && 
                    <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                  }
                  {!phases || !phases.fourthPhase &&
                    <div className="step-indicator-number">4</div>
                  }
                    <div className="step-indicator-text">CV</div>
                </div>
                <div className={step === 5 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(5,values)}}>
                    {phases && phases.fifthPhase && 
                    <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                  }
                  {!phases || !phases.fifthPhase &&
                    <div className="step-indicator-number">5</div>
                  }
                    <div className="step-indicator-text">References</div>
                </div>
                {/* <div className={step === 6 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(6,values)}}>
                {phases && phases.sixthPhase && 
                    <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                  }
                  {!phases || !phases.sixthPhase &&
                    <div className="step-indicator-number">6</div>
                  }
                    <div className="step-indicator-text">Program Selection</div>
                </div> */}
{/*                 
                <div className={step === 8 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(8,values)}}>
                
                {
                  applicationData && applicationData.paymentStatus === 'paid' &&
                  <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                }
                 {
                  applicationData && applicationData.paymentStatus !== 'paid' &&
                  <div className="step-indicator-number">7</div>
                }
                  <div className="step-indicator-text">Payment</div>

                </div> */}
                <div className={step === 7 ? "step-indicator-group" : "step-indicator-group-inactive"} style={{cursor: loadingApplication ? 'not-allowed' : 'pointer'}} onClick={()=>{moveStepClicked(7,values)}}>

                {phases && phases.seventhPhase && 
                    <div className="step-indicator-checkmark">
                  <img src={greenCheck} style={{width:'fit-content', maxWidth:'unset'}}/>
                  </div>
                  }
                  {!phases || !phases.seventhPhase &&
                    <div className="step-indicator-number">6</div>
                  }
                    <div className="step-indicator-text">Submit</div>
                </div>
            </div>
          </div>
          <FormContext.Provider value={{ values, setStep, setFieldValue, handleSubmit: submitForm, shortQuestions, applications }}>
            {(loadingApplication) && <Spinner />}
            {isSaveFailModalOpen && <SaveFailModal setIsSaveFailModalOpen={setIsSaveFailModalOpen} savingError={savingError}/>}
             
            {step === 1 && !loadingApplication  && <ApplicationFormStep1 viewOnly={false} savingError={savingError} validateForm={validateForm} isValid={isValid} applicant={applicant} applicationData={applicationData} updateUserData={updateUserData} isSubmitting={isSubmitting} updatePhase={updatePhase} phases={phases} />}
            {step === 2 && !loadingApplication  && <ApplicationFormStep2 viewOnly={false} savingError={savingError} validateForm={validateForm} setFieldTouched={setFieldTouched}  isValid={isValid} isNested={false} loadingApplication={loadingApplication} applicant={applicant} applicationData={applicationData} updateUserData={updateUserData} updatePhase={updatePhase} phases={phases}/>}
            {step === 3 && !loadingApplication  && <ApplicationFormStep3 viewOnly={false} savingError={savingError} isValid={isValid} applicationData={applicationData} updateUserData={updateUserData}  updatePhase={updatePhase} phases={phases}/>}
            {step === 4 && !loadingApplication  && <ApplicationFormStep4 viewOnly={false} setPage={setPage} isValid={isValid} applicant={applicant} applicationData={applicationData} updateUserData={updateUserData} score={score} updatePhase={updatePhase} phases={phases}/>}
            {step === 5 && !loadingApplication  && <ApplicationFormStep5 viewOnly={false} isValid={isValid} applicant={applicant} applicationData={applicationData} updatePhase={updatePhase} updateUserData={updateUserData} updateLorInApplication={updateLorInApplication} phases={phases} setSelectedLors={updateSelectedLors} selectedLors={selectedLors}/>}
            {step === 6 && !loadingApplication  && <ApplicationFormStep6 viewOnly={false} isValid={isValid} applicationData={applicationData} applicant={applicant} updateUserData={updateUserData} isSubmitting={isSubmitting} updateApplicationData={updateApplicationData} score={score}  updatePhase={updatePhase} lorIdsToAdd={lorIdsToAdd} finishApplication={false} selectedProgramsData={selectedPrograms} updateSelectedPrograms={updateSelectedPrograms} phases={phases} specialty={specialty}/>} 
            {step === 7 && !loadingApplication  && <ApplicationFormStep7 viewOnly={false} isValid={isValid} applicationData={applicationData} applicant={applicant} score={score} updatePhase={updatePhase} updateLorInApplication={updateLorInApplication}  lorIdsToAdd={lorIdsToAdd} selectedPrograms={selectedPrograms}  selectedProgramsData={selectedPrograms} updateSelectedPrograms={updateSelectedPrograms} setPage={setPage} setIsApplicationOpen={setIsApplicationOpen} phases={phases} setSelectedLors={setSelectedLors}  specialty={specialty}/>} 
            {/* {step === 8 && !loadingApplication  && <PaymentView applicationData={applicationData} applicant={applicant} score={score} updatePhase={updatePhase} updateLorInApplication={updateLorInApplication}  lorIdsToAdd={lorIdsToAdd} selectedPrograms={selectedPrograms}  selectedProgramsData={selectedPrograms} updateSelectedPrograms={updateSelectedPrograms} setPage={setPage} setIsApplicationOpen={setIsApplicationOpen} refetchApplications={refetchApplications}/>}  */}
            
          </FormContext.Provider>
        </Form>
      )}
    </Formik>
  );
};

export default ApplicationForm;
