import React,{ useState,useRef, useEffect, useContext } from "react";
import "./index.css";
import backIcon from "../../../../assets/imgs/backIcon.svg"
import downloadIcon from "../../../../assets/imgs/download.svg"
import ApplicationViewNavBar from "./applicationViewNavBar";
import ApplicationViewMain from "./applicationViewMain";
import ApplicationViewRubric from "./applicationViewRubric";
import DownloadPDF from '../applications/downloadPDF';
import { UserContext } from '../../../../context/userContext';
import { ProgramIdContext } from '../../progIdContext';
import { useQuery, useMutation } from '@apollo/client';
import SharedComments from './sharedComments';
import { GET_REVIEWERS_APPLICATIONS_BY_PROGRAM } from './graphql/queries';
import dropdownArrowIcon from '../../../../assets/imgs/DropdownArrowIcon.svg'
import client from "../../../../config/apolloClient";
import { updateGroupsApplicationIds, rejectApplicationMutation } from '../graphql/mutations';
import { getGroupsByProgramIdQuery } from '../graphql/queries';
import GroupsDropdown from '../applications/groupsDropdown';
import packageInfo from '../../../../../package.json';
import Spinner from "../../../common/spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ApplicationView = ({application, applicationIdx,refetchGroups, refetchApplications, onClose, previousApplicationClicked, nextApplicationClicked, screenedData, applications, totalApplications,handleStatusChange, handleInviteToApply }) => {
    const [isApplicationOpen, setIsApplicationOpen] = useState(false)
    // const [applicationData, setApplicationData] = useState(application)
    const [currentSection, setCurrentSection] = useState(null); 
    const [selectedReviewerApplications, setSelectedReviewerApplications] = useState([])
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [groups, setGroups] = useState([]);
    const [limit, setLimit] = useState(100000);
    const [offset, setOffset] = useState(0);
    const [sort, setSort] = useState({ field: 'reviewerId', direction: 'desc'})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useContext(UserContext);
    const programId = useContext(ProgramIdContext);
    const sectionRefs = useRef({});
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isRejectLoading, setIsRejectLoading] = useState(false);


    // rejectApplicationMutation
    const [rejectApplication] = useMutation(rejectApplicationMutation, {
        onCompleted: () => {
            setIsRejectModalOpen(false);
            setIsRejectLoading(false);
            if (applicationIdx !== totalApplications - 1) {
                nextApplicationClicked();
                }
            refetchReviewerApplications();
            refetch();
        },
        onError: (error) => {
            console.error("Error rejecting application:", error);
            setIsRejectLoading(false);
        }
    });

    const handleNavClick = (sectionId) => {
        sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
    };

    const {loading: loadingReviewerApplications, error: errorReviewerApplications, data: dataReviewerApplications , refetch: refetchReviewerApplications} = useQuery(GET_REVIEWERS_APPLICATIONS_BY_PROGRAM,{
        variables: { reviewerId: user.userId, programId:programId, offset, limit, sort},
        skip: !user.userId,
        fetchPolicy: 'network-only'
    });

    const { data: groupsData, error: groupsError, isLoading: groupsLoading , refetch} = useQuery(getGroupsByProgramIdQuery, {
        variables: {
            programId,
            filter: { field: 'name', value: null }
        },
        fetchPolicy:'network-only'
    });

    if(!groupsLoading&&groupsData&&groupsData.getGroupsByProgramId) {
        let groupsToDisplay = groupsData.getGroupsByProgramId
        if(JSON.stringify(groupsToDisplay) !== JSON.stringify(groups)) {
            console.log("groupsToDisplay =>", groupsToDisplay)
            setGroups(groupsToDisplay)
        }
    }

    useEffect(() => {
      if (!loadingReviewerApplications && !errorReviewerApplications && dataReviewerApplications) {
  
          const sortedApplications = [...dataReviewerApplications.getReviewersApplicationsByProgram].sort((a, b) => a.lastName.localeCompare(b.lastName));
            console.log("sortedApplications =>", sortedApplications)
          setSelectedReviewerApplications(sortedApplications);
      }
  }, [loadingReviewerApplications, errorReviewerApplications, dataReviewerApplications]);
  

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setCurrentSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.12 }
        );
    
        Object.values(sectionRefs.current).forEach((section) => {
            if (section instanceof Element) {
                observer.observe(section);
            }
        });
    
        return () => {
            Object.values(sectionRefs.current).forEach((section) => {
                if (section instanceof Element) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    

    // const handleApplyClick = (selectedGroups) => {
    //     const selectedIds = [application.id]
    //     const checkedGroupIds = selectedGroups.map(group => group.id); // Get the ids of the checked groups
    //     const updatedGroups = groups.map(group => {
    //         // Check if the group's id is in the checkedGroupIds array
    //         if (checkedGroupIds.includes(group.id)) {
    //             // Create a new group object instead of modifying the existing one
    //             const newGroup = { ...group };
    //             if (newGroup.applicationIds.some(id => selectedIds.includes(id))) {
    //                 newGroup.applicationIds = newGroup.applicationIds.filter(id => !selectedIds.includes(id));
    //             } else {
    //                 newGroup.applicationIds = [...newGroup.applicationIds, ...selectedIds];
    //             }
    //             return newGroup;
    //         }
    //         return group;
    //     });

    //     client.mutate({
    //         mutation: updateGroupsApplicationIds,
    //         variables: {
    //             groupIds: checkedGroupIds,
    //             applicationIds: selectedIds,
    //         }
    //     })


    //     setGroups(updatedGroups);
    //     setIsModalOpen(false);
    // };

    
    const handleApplyClick = async (updatedGroups) => {
        // Assuming updatedGroup is a single group object with updated applicationIds
        try {
            console.log("updatedGroups =>", updatedGroups)
            await Promise.all(updatedGroups.map(async updatedGroup => {
                const response = await client.mutate({
                    mutation: updateGroupsApplicationIds,
                    variables: {
                        groupIds: [updatedGroup.id],
                        applicationIds: updatedGroup.applicationIds,
                    }
                });
        
                const updatedGroupFromResponse = response.data.updateGroupsApplicationIds;
        
                // Update the groupsState by merging the updated group
                setGroups(prevGroups => prevGroups.map(group =>
                    group.id === updatedGroupFromResponse.id ? updatedGroupFromResponse : group
                ));
        
            }))

         
        } catch (err) {
            toast.error('Error updating groups',err);
        }
    };
    

    const createPdfInBackEnd = async () => {
        setIsFileDownloading(true);
        const dataToSend = [];
            dataToSend.push({
                applicationId: application.id,
                fileName: `${application.lastName}_${application.firstName}_${application.aamc}_application`,
                token:localStorage.getItem('login_token')
            })
            console.log('data to send', dataToSend)

        // fetch(`${process.env.REACT_APP_SERVER_URL}/generate-pdf`, {
            fetch(`https://pdfbecreator.accessgme.com/generate-pdf`, {
        // fetch(`https://centralapppdfcreator.azurewebsites.net/api/caPDFCreator?code=lg46yvyp1WP_UzYoI0m4FmzJn988i0dwxmbfyuYPbnpOAzFu698m-Q%3D%3D`, {
            
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'App-Version': packageInfo.version
            },
            body: JSON.stringify({
                applications: dataToSend
            })
        })
        .then(response => response.json())
        .then(json => {
            const pdfUrl = json.body.url; // Extract the URL from the response
            window.open(pdfUrl, '_blank');
            setIsFileDownloading(false);
        })
        
        .catch(error =>{
            console.error('Error:', error)
            setIsFileDownloading(false);
        } );
    }

    const handleRejectClick = () => {
        isRejectModalOpen ? setIsRejectModalOpen(false) : setIsRejectModalOpen(true);
    }

    const handleRejectApplication = () => {
        console.log(application.id);
        rejectApplication({ variables: { applicationId: application.id, reviewerId:user.userId } })
            .then(() => {
                refetchApplications();
                refetchGroups();
                toast.success('Application rejected successfully');
            })
            .catch(error => {
                // console.error('Error rejecting application:', error);
                toast.error('Error rejecting application');
            });
    };



return (

<div className='faculty-home-container' style={{overflowX:'scroll'}}>
    <ToastContainer
    theme="colored"
    />

{isRejectModalOpen && (
                 <div className='modal-backdrop' style={{zIndex:'9999'}}>
                     <div className='add-graduation-modal' style={{minHeight:'30vh'}}>
                        <div className='modal-body' style={{marginBottom:'3vh'}}>
                            <p>
                            <strong>You are about to reject {application.firstName} {application.lastName}'s application.</strong> <br/>
                            This will mark the application as reviewed, and will remove the application from all active groups and reviews assigned. 
                            <br/>
                            <br/>
                           Are you sure you want to proceed?
                     </p>
                     </div>
                     {isRejectLoading && 
                     <div className='modal-footer' style={{display:'flex' , gap:'2vw', alignSelf:'center' }}>
                        <Spinner />
                        </div>}
                     {!isRejectLoading && (
                         
                         <div className='modal-footer' style={{display:'flex' , gap:'2vw', alignSelf:'center' }}>
                            <button className='green-button'  style={{background:'#d66262'}} onClick={handleRejectApplication}>Reject Application</button>
                             <button className='green-button' onClick={() => setIsRejectModalOpen(false)}>Cancel</button>
                    </div>
                            )}

                        </div>
                        </div>
                        )}
            
            <ellipse className='ellipseDesignTop1' />
            <ellipse className='ellipseDesignTop2' />
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            <div className="faculty-home-header" style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
            <div style={{display:'flex', flexDirection:'row', gap:'1vw', marginBottom:'2vh'}}>
            <button  className="green-button" style={{background:'white', color:'#145388', border:'solid 1px #145388', marginBottom:'1vh'}} onClick={onClose}><img src={backIcon}/> Return</button>
            {!isFileDownloading&&(<button  className="green-button" style={{background:'white', color:'#145388', border:'solid 1px #145388', marginBottom:'1vh'}} onClick={createPdfInBackEnd}><img src={downloadIcon}/> Download PDF</button>)}
            {isFileDownloading&&(<Spinner/>)}
            
            {/* <button className="green-button" style={{background:'white', color:'#145388', border:'solid 1px #145388', marginBottom:'1vh'}} onClick={() => setIsModalOpen(!isModalOpen)}>Folders <img src={dropdownArrowIcon}/> </button> */}
            </div>
            {
                        console.log("calcAppIdx =>", applicationIdx, totalApplications)
            }
            {
                isModalOpen &&  <GroupsDropdown isOpen={isModalOpen} groups={groups} selectedGroups={checkedGroups} setSelectedGroups={setCheckedGroups} onApply={handleApplyClick} selectedRows={[application]} finishMutation={() => refetch()} setIsOpen={setIsModalOpen} from={"applicationView"}/>

            }
            {
                  screenedData !== null && screenedData !== undefined && screenedData.personalInfo !== null && screenedData.personalInfo !== undefined &&
                  screenedData.personalInfo.firstName !== null && screenedData.personalInfo.firstName !== undefined && screenedData.personalInfo.firstName === true &&
                  screenedData.personalInfo.lastName !== null && screenedData.personalInfo.lastName !== undefined && screenedData.personalInfo.lastName === true &&
                  <div style={{display:'flex', justifyContent:'center', width:'85vw'}}>
                  {/* <h2 className="faculty-home-header-title" style={{marginBottom:'0'}}>{application.firstName} {application.lastName} </h2> */}
                  <div style={{display:'flex',flexDirection:'row', gap:'1vw'}}>
                  {  applicationIdx > 0 &&   <button className="green-button" onClick={previousApplicationClicked}>Previous Applicant</button>}
                   {/* {user.userPremissions.includes('PD') && !application.archived && <button className="green-button" onClick={handleRejectClick} style={{background:'#d66262'}}>Reject</button>} */}
                   {application.status !=='Rejected' && <button className="green-button" onClick={handleRejectClick} style={{background:'#d66262'}}>Reject</button>}

                    {application.status ==='New' &&(<button style={{background:'#30B776'}} className="green-button"  onClick={() => handleStatusChange([application], 'Review')}>Move to Review</button>)}
                    {application.status ==='Lead'&& (<button style={{background:'#30B776'}} className="green-button" onClick={() => handleInviteToApply([application])}>Invite to Apply</button>)}
                    {application.status ==='Review'&&(<button style={{background:'#30B776'}} className="green-button"  onClick={() => handleStatusChange([application], 'Phone Screen')}>Move to Phone Screen</button>)}
                    {application.status ==='Phone Screen'&&(<button style={{background:'#30B776'}} className="green-button" onClick={() => handleStatusChange([application], 'Interview')}>Move to Interview</button>)}
                    {application.status ==='Interview'&&(<button style={{background:'#30B776'}} className="green-button" onClick={() => handleStatusChange([application], 'Offer')}>Send an Offer</button>)}
                    {application.status ==='Offer'&&(<button style={{background:'#30B776'}} className="green-button" onClick={() => handleStatusChange([application], 'Accepted')}>Mark as Accepted</button>)}



                  { applicationIdx < totalApplications - 1 && <button className="green-button" onClick={nextApplicationClicked}>Next Applicant</button>}
                  </div>
                  </div>
            }
            
                <div className="top-buttons-row"  style={{display:'flex', flexDirection:'row', marginBottom:'2vh', gap:'1vw'}}>
                
                </div>
            
            
            
            <seperator className="faculty-home-header-seperator" style={{width:'114.5vw'}}/>
            </div>

            <div className="application-view-body">
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <ApplicationViewNavBar onNavClick={handleNavClick}  currentSection={currentSection} />
                    <ApplicationViewMain application={application} sectionRefs={sectionRefs}  screenedData={screenedData}/>
                </div>
                <div style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    <ApplicationViewRubric refetchGroups={refetchGroups} refetchApplications={refetchApplications} application={application} applicationIdx={applicationIdx} totalApplications={totalApplications} nextApplicationClicked={nextApplicationClicked} previousApplicationClicked={previousApplicationClicked} selectedReviewerApplications={selectedReviewerApplications}/>
                    <SharedComments applicationId={application.id} userId={user.userId}/>
                </div>
            </div>
</div>
)
}
export default ApplicationView;