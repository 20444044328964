import { gql } from '@apollo/client';

export const CREATE_LEAD = gql`
    mutation createLead($input: LeadInput!){
        createLead(input: $input) {
    programId
    CareerFairId
    sourcer
    recruiter
    notes
    usCitizen
    firstName
    lastName
    email
    phone
    interestAreas
    desiredHireDate
        }
    }
`
