import React, { useRef, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const libraries = ["places"]; // Required for Places API


const AddressAutocomplete = () => {
  const [address, setAddress] = useState("");
  const autocompleteRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries,
  });

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.formatted_address) {
      setAddress(place.formatted_address);
    }
  };

  React.useEffect(() => {
    if (isLoaded && !autocompleteRef.current) {
      const input = document.getElementById("autocomplete-input");
      autocompleteRef.current = new window.google.maps.places.Autocomplete(input, {
        types: ["address"],
      });

      autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
    }
  }, [isLoaded]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="application-form-field-group">
         
      <input
        id="autocomplete-input"
        type="text"
        placeholder="Enter address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        className="application-form-standard-input"
        style={{ width: "100%" }}
      />
      {/* <p>Selected Address: {address}</p> */}
    </div>
  );
};

export default AddressAutocomplete;
