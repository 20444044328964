const fieldMapping = {
    // "Position": "position",
    // "Department": "department",
    // "Pod": "pod",
    "Recruiter": "recruiter",
    "Sourcer": "sourcer",
    // "Source of Hire": "sourceOfHire",
    // "Hire Status": "hireStatus",
    "Career Fair": "careerFair",
    // "Date of Hire": "dateOfHire",
    "Date of Birth": "dateOfBirth",
    "Added on": "createdAt",
    "Notes": "notes",
    "Home Address": "homeAddress",
    "US Citizen": "usCitizen",
    "Submission Date":"createdAt",
    "First Name": "firstName",
    "Last Name": "lastName",
    "Email": "email",
    "Phone Number": "phone",
    "NRMP ID": "nrmpId",
    "Interest Areas": "interestAreas",
    "Desired Hire Date": "desiredHireDate",
    "Gender Identity": "gender",
    "Hometown": "hometown",
    "Race/Ethnicity": "ethnicity",
    "Couple's Match": "coupleMatch",
    "Medical School Name": "medicalSchoolName",
    "AOA Status": "aoaStatus",
    "Gold Humanism Honor Society Status": "goldHumanism",
    "First Author Publications": "firstAuthorPublications",
    "Peer-Reviewed Publications": "peerReviewedPublications",
    "Poster Presentations": "posterPresentations",
    "Podium Presentations": "podiumPresentations",
    "AAMC ID": "aamc",
    "Previous Applicant": "previouslyAppliedToSpeciality",
    "Signal": "isSignal",
    "Step 1": "usmleStep1Score",
    "Step 2": "usmleStep2Score",
    "Comlex 1": "comlexLevel1Score",
    "Comlex 2": "comlexLevel2Score",
    "Previous Last Name":"previousLastName",
    "Preffered Name": "prefferedName",
    "Pronouns": "pronouns",
    "USMLE Id": "usmleId",
    "NBOME Id": "nbomeId",
    "AOA Id": "aoaId",
    "Permanent Address": "permanentAddress",
    "Licensure Revoked": "licensureRevoked",
    "Licensure Revoked Details": "licensureRevokedDetails",
    "Military Obligation": "militaryObligation",
    "Military Obligation Details": "militaryObligationDetails",
    "Other Service Obligations": "otherServiceObligations",
    "Medical Training Interrupted": "medicalTrainingInterrupted",
    "Medical Training Interrupted Details": "medicalTrainingInterruptedDetails",
    "Sigma Sigma Phi": "sigmaPhi",
    "Region": "region",
    "Medical School State": "medicalSchoolState",
    "Signal Type": "signalType",
    "Work Authorization": "workAuthorization",
    "Selected Tracks": "selectedTracks",

};

export const fieldMappingForSortAndFilter = {
    "Submission Date":"createdAt",
    "firstName": "user.firstName",
    "Last Name": "lastName",
    "Email": "email",
    "Phone Number": "phone",
    "NRMP ID": "nrmpId",
    "US Citizen": "workAuthorization"
}


export default fieldMapping;