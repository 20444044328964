// import React from 'react';
// // import logo from '../../assets/imgs/logo.svg';
// import logo from '../../assets/imgs/valor_logo.svg';
// import './styles/spinner.css';

// export default function Spinner() {
//     return (
//         <div className="spinner">
//             <div className="spinner-container">
//                 <img src={logo} className='spinner-img' alt="Loading..." />
//                 <div className="spinnerBorder" />
//             </div>
//         </div>
//     );
// }


// import React from "react";
// import { motion } from "framer-motion";
// import './styles/spinner.css';

// const Spinner = () => {
//     return (
//                 <div className="spinner">
//             <div className="spinner-container">
//        <motion.svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 188 159"
//       style={{ width: "150px", height: "150px" }}
//     >
//       {/* Left Arm */}
//       <motion.path
//         d="M132.4,49c6.5-6.5,13.5-12.4,21.1-17.5,2.7-1.8,5.8-2.5,9-3,1-.1,1.9-.4,2.9-.2.9.2,1.4.7,1.6,1.7.2,1.5.2,2.9.1,4.3-.2,1.8-1.4,3.2-2.6,4.5-2.3,2.4-5.3,3.8-7.8,5.9-3.3,2.7-6.5,5.5-9.6,8.3-3.5,3.8-7.2,7.2-10.5,10.9-6.7,7.3-12.4,15.3-17.3,23.9-4.4,7.5-7.7,15.6-10.2,24-.5,1.5-1,3-1.6,4.8-.9-1.2-.7-2.4-1-3.5-1.3-4.3-2.4-8.6-3.6-12.8-.5-1.8-1-3.6-1.8-5.2-.3-.5-.2-1,0-1.5,2.1-4.2,4.2-8.5,6.4-12.7,1.8-3.4,4.1-6.5,6.2-9.7,5.4-8.2,12-15.3,18.9-22.2h-.2Z"
//         fill="#273b89"
//         initial={{ rotate: -15, originX: "35%", originY: "50%" }}
//         animate={{ rotate: 0 }}
//         transition={{
//           type: "spring",
//           stiffness: 40,
//           damping: 12,
//           duration: 1.5,
//           repeat: Infinity,
//           repeatType: "reverse",
//           delay: 0.2,
//         }}
//       />
      

//       {/* Head */}
//       <motion.path
//         d="M80.8,45.3c1.3-5.3,4.7-8.8,10.1-10.1,6.1-1.5,12.3.5,15.9,5.3,4.2,5.6,3.7,13.6-1.4,18.3-4.6,4.3-10.1,5.7-16.2,3.5-2.6-.9-4.7-2.8-6.4-5-2.5-3.8-3-7.9-2-12h0Z"
//         fill="#273b89"
//       />

//       {/* Right Arm */}
//       <motion.path
//        d="M35.9,29.3c3.1,2,6,4.3,8.8,6.6,6.4,5.3,12.5,11,18.2,17,4.5,4.6,8.6,9.5,12.3,14.8,5.1,7.4,9.6,15,12.8,23.4,3.4,8.8,5.9,17.8,8.1,27,1.5,6.2,1.9,12.6,1.2,19,0,.9-.4,1.7-.6,2.6-1.1,3.9-2.3,7.8-3.1,11.8-.1.8-.4,1.6-1.4,2-.8-1.5-1.2-3.2-1.6-4.8-.6-2.9-1.7-5.7-2.4-8.5-1-4.1-1.9-8.2-2.3-12.5-.7-6.9-2.8-13.4-5.2-19.8-2.7-7.4-5.6-14.7-9.7-21.5-3.9-6.5-8.1-12.7-13-18.5-.9-1.1-1.8-2.3-2.7-3.5-5.3-6.5-11.3-12.2-17.4-17.7-3.7-3.3-7.7-6.4-11.7-9.3-2.7-1.9-3.3-5-2-8.1.4-.9,1.3-1.6,2.3-1.6s2-.3,3-.2"
//         fill="#273b89"
//         initial={{ rotate: 15, originX: "65%", originY: "50%" }}
//         animate={{ rotate: 0 }}
//         transition={{
//           type: "spring",
//           stiffness: 40,
//           damping: 12,
//           duration: 1.5,
//           repeat: Infinity,
//           repeatType: "reverse",
//           delay: 0.2,
//         }}
//       />
//     </motion.svg>
//     </div>
//     </div>
//       );
//     };
    

// export default Spinner;



import React from 'react';
import './styles/spinner.css';

const Spinner = () => {
  return (
    <div className="wave-loader">
      <span className="wave-letter A">G</span>
      <span className="wave-letter A">o</span>
      <span className="wave-letter A">t</span>
      <span className="wave-letter A">t</span>
      <span className="wave-letter B">H</span>
      <span className="wave-letter B">i</span>
      <span className="wave-letter B">r</span>
      <span className="wave-letter B">e</span>
    </div>
  );
};

export default Spinner;
