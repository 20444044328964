import {React, useContext, useState, useEffect} from 'react';
import './styles/facultyHomeCard.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';




    // modcked bar data:

   

    const tableData = [
        { position: 'Position 1', progress: '50%', analytics: 'Good' },
        { position: 'Position 2', progress: '70%', analytics: 'Excellent' },
        { position: 'Position 3', progress: '30%', analytics: 'Average' },
    ];


const FacultyHomeCard = ({card, setPage, calculations}) => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Candidates',
                data: [],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    });

    useEffect(() => {
        if(calculations) {
            console.log("calculations =>", calculations)
            setChartData({
                labels: calculations.positionNames,
                datasets: [{
                    label: 'Candidates',
                    data: calculations.positionApplicationsCount,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                    ],
                    borderWidth: 1,
                }],
            })
        }
     
    }, [calculations])

    return (
<div className="faculty-home-card">
{card === 'applicantsOverview' && (

<div className="faculty-home-card-header">
    <h2 className="faculty-home-card-header-title">Metrics</h2>
    <seperator className="faculty-home-header-seperator"/>
    <div className="faculty-home-card-body">

    <div className="data-visualization" >
                    <h3>Hiring Pipeline Overview</h3>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <Bar 
        data={chartData} 
        width={400}  // Set the desired width
        height={200} // Set the desired height
    />
                    {/* <table>
                        <thead>
                            <tr>
                                <th>Position</th>
                                <th>Progress</th>
                                <th>Analytics</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.position}</td>
                                    <td>{row.progress}</td>
                                    <td>{row.analytics}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table> */}
                    </div>
                </div>
    </div>

    {/* <div className="faculty-home-card-footer">
        <p>Stay up to date on your applicant statistics here. For more specific information, view or review all applications using the menu on the left.</p>
        </div> */}

</div>
)}
      
        {card === 'applicationCustomization' && (
            <>
                <div className="faculty-home-card-header" style={{display:'flex',flexDirection:'column'}}>
                    <h2 className="faculty-home-card-header-title">Application Customization</h2>
                    <seperator className="faculty-home-header-seperator"/>
                    <div className="faculty-home-card-footer">
                        <p>Utilize this feature to customize your application experience. Here, you can edit the evaluation forms that your team is utilizing for review, customize your view of the application, and PDF download settings!</p>
                        </div>
    
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:'1vh'}}> 
                <button className='green-background-button' style={{marginRight:'.5vw'}} onClick={() => window.location.href='/faculty/evaluation-forms'}>Edit Evaluation Forms</button>
                {/* <button className='green-background-button' onClick={() => setPage('customizeApplication')}>Edit Screened Items</button> */}
                
                </div>
                </>
        )}

{card === 'myTeam' && (
    <>
                <div className="faculty-home-card-header" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <h2 className="faculty-home-card-header-title">My Team</h2>
                    {/* <button className='view-team-button' onClick={() => setPage('myTeam')}>View Team</button> */}
                    </div>
                    <seperator className="faculty-home-header-seperator"/>


                    <div className="faculty-home-card-footer">
                        <p>Manage your review team here. Invite your reviewers, recommenders, and coordinators. Anyone invited to your team will become a Verified User.</p>
                        </div>
                        <button className='green-background-button' onClick={() => window.location.href='/faculty/myTeam'}>Edit My Team</button>
                        </>

        )}





</div>
    )};

export default FacultyHomeCard;