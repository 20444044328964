import React, { useState, useContext, useEffect } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_LEAD } from './graphql/mutations';
import {GET_CAREER_FAIRS,
  GET_PROGRAM_USERS_BY_PROGRAM_ID,
} from './graphql/queries';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddressAutocomplete from '../../../common/addressAutoComplete';



const AddLeadModal = ({ isOpen, onClose, selectedPosition, programId ,refetchApplications, statusRefetchCount}) => {
  const [isEdit, setIsEdit] = useState(true); 
  const [careerFairs, setCareerFairs] = useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const [sourcers, setSourcers] = useState([]);
  const [triedLead, setTriedLead] = useState(false);
  

  const [createApplication] = useMutation(CREATE_LEAD);
  const { data: careerFairsData } = useQuery(GET_CAREER_FAIRS, {
    variables: { programId },
  });
  const { data: programUsersData } = useQuery(GET_PROGRAM_USERS_BY_PROGRAM_ID, {
    variables: { programId },
  });

  useEffect(() => {
    if (careerFairsData) {
      setCareerFairs(careerFairsData.getCareerFairs);
    }
  }, [careerFairsData]);

  useEffect(() => {
    if (programUsersData) {
      const sourcerData = programUsersData.getProgramMembersByProgramId.filter(
        (user) => user.roles.includes('HRS')
      );
      console.log('Sourcer Data:', sourcerData);
      const recruiterData = programUsersData.getProgramMembersByProgramId.filter(
        (user) => user.roles.includes('HRR')
      );
      console.log('Recruiter Data:', recruiterData);
      setSourcers(sourcerData);
      setRecruiters(recruiterData);
    }
  }, [programUsersData]);




  let initialValues = {
    programId: programId,
    careerFairId: '', // ID To handleeeeeeeee
    sourcer: '', // ID To handle
    recruiter: '', // ID To handle
    notes: '',
    usCitizen: false,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    genderIdentity: '',
    homeAddress: '',
  }; 

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone Number is required'),
    dateOfBirth: Yup.date().required('Date of Birth is required'),
    genderIdentity: Yup.string().required('Gender is required'),
    homeAddress: Yup.string().required('Address is required'),
    workAuthorization: Yup.string().required('Work Authorization is required'),
    otherWorkAuthorization: Yup.string().when('workAuthorization', {
      is: 'Other',
      then: Yup.string().required('Other Work Authorization is required'),
    }),
  });

  const handleSubmit = (values, { setSubmitting }) => {


    
    createApplication({
        variables: {
            input: {
                programId: programId,
                CareerFairId: values.careerFairId,
                sourcer: values.sourcer,
                recruiter: values.recruiter,
                notes: values.notes,
                usCitizen: values.usCitizen==='true'?true:false,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                dateOfBirth: values.dateOfBirth,
                gender: values.genderIdentity,
                homeAddress: values.homeAddress,
            }
        }
    })
    .then((res) => {
        toast.success('Lead Created Successfully')
        refetchApplications();
        statusRefetchCount();
        setSubmitting(false);
        onClose();
    })
    .catch((err) => {
        toast.error('Failed to Create Lead')
        setSubmitting(false);
    })
  };


  const handleExit = () => {
    initialValues = {
      careerFairId: '', // ID To handleeeeeeeee
      sourcer: '', // ID To handle
      recruiter: '', // ID To handle
      notes: '',
      usCitizen: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dateOfBirth: '',
      genderIdentity: '',
      homeAddress: '',
      };
    
    onClose();
  }



  return (
    <div className="modal-backdrop" style={{ zIndex: '25' }}>
      <div className="add-field-modal" style={{ height: '70vh', overflow: 'unset', width:'65vw' }}>
        <ToastContainer
        theme='colored'
        />
        <div className="add-field-modal-header">
          <h2>Add Lead</h2>
          <img src={logo} alt="Logo" className="logo" style={{ width: '4vw' }} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
                <div className='form-modal-body' style={{marginBottom:'3vh', overflowY:'auto', height:'45vh'}}>

              {/* First Name */}
              <div style={{display:'flex', gap:'1vw'}}>
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="firstName" className="applicant-form-field-label">
                  First Name <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="firstName"
                  placeholder="First Name"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="firstName" component="div" />
                </div>
              </div>

              {/* Last Name */}
              <div className="application-form-field-group">
                <label htmlFor="lastName" className="applicant-form-field-label">
                  Last Name <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="lastName"
                  placeholder="Last Name"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="lastName" component="div" />
                </div>
              </div>
              </div>


              <div style={{display:'flex', gap:'1vw'}}>
              {/* Email */}
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="email" className="applicant-form-field-label">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="email" component="div" />
                </div>
              </div>

              {/* Phone Number */}
              <div className="application-form-field-group">
                <label htmlFor="phone" className="applicant-form-field-label">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="phone"
                  placeholder="Phone Number"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="phone" component="div" />
                </div>
              </div>

              </div>



              <div style={{display:'flex', gap:'1vw'}}>
              {/* Date of Birth */}
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="dateOfBirth" className="applicant-form-field-label">
                  Date of Birth <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="dateOfBirth"
                  placeholder="MM/DD/YYYY"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                />
                <div className="application-form-error-message">
                  <ErrorMessage name="dateOfBirth" component="div" />
                </div>
              </div>

              {/* Gender */}
              <div className="application-form-field-group">
                <label htmlFor="genderIdentity" className="applicant-form-field-label">
                  Gender <span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  as="select"
                  name="genderIdentity"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                >
                  <option value="">Select Gender</option>
                  <option value="woman">Woman</option>
                  <option value="man">Man</option>
                  <option value="transWoman">Transgender Woman</option>
                  <option value="transMan">Transgender Man</option>
                  <option value="nonBinary">Gender Nonbinary</option>
                  <option value="notListed">Gender Not Listed</option>
                  <option value="noResponse">Prefer Not To Respond</option>
                </Field>
                <div className="application-form-error-message">
                  <ErrorMessage name="genderIdentity" component="div" />
                </div>
              </div>

              </div>



              <div style={{display:'flex', gap:'1vw'}}>
              {/* Recruiter */}
              <div className="application-form-field-group" style={{width:'30vw'}}>
                <label htmlFor="dateOfBirth" className="applicant-form-field-label">
                  Recruiter 
                </label>
                <Field
                  as="select"
                  name="recruiter"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                >
                  <option value="">Select Recruiter</option>
                  {recruiters.map((recruiter) => (
                    <option value={recruiter.userId.id}>
                      {recruiter.userId.firstName} {recruiter.userId.lastName}
                    </option>
                  ))}
                </Field>
               
              </div>

              {/* sourcer */}
              <div className="application-form-field-group">
                <label htmlFor="genderIdentity" className="applicant-form-field-label">
                Sourcer 
                </label>
                <Field
                  as="select"
                  name="sourcer"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                >
                  <option value="">Select Sourcer</option>
                  {sourcers.map((sourcer) => (
                    <option value={sourcer.userId.id}>
                      {sourcer.userId.firstName} {sourcer.userId.lastName}
                    </option>
                  ))}
                </Field>
               
              </div>

              </div>


                  {/* Career Fair */}
                  <div className="application-form-field-group" style={{width:'60vw'}}>
                <label htmlFor="careerFairId" className="applicant-form-field-label">
                  Career Fair
                </label>
                <Field
                  as="select"
                  name="careerFairId"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                  style={{ width: '56.5vw' }}
                >
                  <option value="">Select Career Fair</option>
                  {careerFairs.map((careerFair) => (
                    <option value={careerFair.id}>
                      {careerFair.name}
                    </option>
                  ))}
                </Field>
              </div>



              {/* Address */}
              <div className="application-form-field-group" style={{width:'60vw'}}>
                <label htmlFor="homeAddress" className="applicant-form-field-label">
                  Address <span style={{ color: 'red' }}>*</span>
                </label>
                {/* <Field
                  name="homeAddress"
                  placeholder="Mailing Address"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                  style={{ width: '56.5vw' }}
                /> */}
                <AddressAutocomplete/>
                <div className="application-form-error-message">
                  <ErrorMessage name="homeAddress" component="div" />
                </div>
              </div>

              {/* Work Authorization */}
              <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
  <label className='applicant-form-field-label'>U.S. Citizen?<span style={{ color: 'red' }}>*</span></label>
        <Field as="select" name="workAuthorization"className='application-form-standard-input one' onChange={(event) => setFieldValue("workAuthorization", event.target.value)} disabled={!isEdit} style={{ marginBottom: '1vh'}}>
          <option value="">Select Work Authorization</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
         
          {/* <option value="Other">Other</option> */}
          </Field>
          <div className='application-form-error-message'> 
          <ErrorMessage name="workAuthorization" component="div" />
          </div>
          </div>
          {
            values.workAuthorization === "Other" &&
            <div className='application-form-field-group single' style={{marginBottom:'3vh'}}>
            <label htmlFor="otherWorkAuthorization" className='applicant-form-field-label'>Other Work Authorization <span style={{ color: 'red' }}>*</span></label>
            <Field name="otherWorkAuthorization" placeholder="Other Work Authorization" className='application-form-standard-input one' disabled={!isEdit} style={{ marginBottom: '1vh'}}/>
            <div className='application-form-error-message'> 
            <ErrorMessage name="otherWorkAuthorization" component="div" />
            </div>
            </div>
          }

            {/* notes */}
            <div className="application-form-field-group" style={{width:'60vw'}}>
                <label htmlFor="notes" className="applicant-form-field-label">
                  Notes
                </label>
                <Field
                  as="textarea" // Changed to textarea
                  name="notes"
                  placeholder="Notes"
                  className="application-form-standard-input"
                  disabled={!isEdit}
                  style={{ width: '56.5vw', height: '40vh' }}
                />
              </div>

          </div>

        


            
            <div className='buttons-row' style={{marginBottom:'3vh', display:'flex', gap:'2vw', justifyContent:'center'}}>
              {/* Submit Button */}
              <button type="submit" className="green-button">
                Save Lead
              </button>
              <button  
              className="green-button" 
              style={{width:'12vw', background:'white', color:'#145388', border:'1px solid #145388'}}
              onClick={handleExit}
              >
                Cancel
              </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddLeadModal;
