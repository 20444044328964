import React, { useEffect, useState } from 'react';
import logo from '../../../../assets/imgs/logo.svg';
import { useMutation, useQuery } from '@apollo/client';
import { LIST_POSITIONS_NAMES } from './graphql/queries';
import { CREATE_APPLICATIONS_FROM_LEADS } from './graphql/mutations';
import Spinner from "../../../common/spinner";
import Select from 'react-select';


const ExportLeadsModal = ({ isOpen, onClose, programId, leads }) => {

    const [uploadStatus, setUploadStatus] = useState('');
    const [positions, setPositions] = useState([]);
    const [positionId, setPositionId] = useState('');
    const [successfulUploads, setSuccessfulUploads] = useState(0);


    const { data: positionsData } = useQuery(LIST_POSITIONS_NAMES, {
        variables: { programId },
    });

    useEffect(() => {
        if (positionsData) {
            setPositions(positionsData.listPositions);
        }
    }
    , [positionsData]);

    const [createApplicationsFromLeads] = useMutation(CREATE_APPLICATIONS_FROM_LEADS);

    const handleExport = async () => {
        try {
            await createApplicationsFromLeads({
                variables: {
                    leadsIds: leads.map((lead) => lead.id),
                    positionId,
                },
            }).then((mutationData) => {
                setSuccessfulUploads(mutationData.data.createApplicationsFromLeads);
                setUploadStatus('Success');
            }
            );
        } catch (error) {
            setUploadStatus('Error');
        }
    }


  return (
    <div className="modal-backdrop" style={{ zIndex: '25' }}>
      <div className="add-field-modal" style={{ height: '50vh', overflow: 'unset', width:'65vw', maxHeight:'100vh' }}>
        <div className="add-field-modal-header">
          <h2>Export Leads to ATS</h2>
          <img src={logo} alt="Logo" className="logo" style={{ width: '10vw' }} />
        </div>
        
        <div className="faculty-home-body" style={{gap:'0'}}>
        {uploadStatus === '' && (
          <div>
            <p>Here you can export leads to a specific position, and then invite them to apply to that position</p>
            <p>Please select a position to export the selected {leads.length>0?leads.length:''} leads to </p>
           


          <Select
            options={positions.map((position) => ({ value: position.id, label: position.jobTitle }))}
            onChange={(selectedOption) => setPositionId(selectedOption.value)}
            placeholder="Select a position"
          />
<div style={{display:'flex', width:'50%', justifyContent:'space-between', justifySelf:'center', marginTop:'2vh'}}>

 <button className='green-button' style={{background:'white', color:'#145388', border:'solid 1px #145388'}} onClick={onClose}>Cancel</button>
  <button className='green-button' disabled={positionId===''} onClick={handleExport}>Export to ATS</button>
  </div>
 </div>

          )}







            {uploadStatus === 'Success' && (
              <div>
                <p>{successfulUploads} out of {leads.length} were exported successfully</p>
                <button className='green-button' onClick={onClose}>Close</button>
              </div>
            )}

            {uploadStatus === 'Error' && (
              <div>
                <p>Something went wrong!</p>
                <button className='green-button' onClick={()=>setUploadStatus('')}>Try Again</button>
                <button className='green-button' onClick={onClose}>Close</button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ExportLeadsModal;
