import { gql } from "@apollo/client";

export const getProgramById = gql`
    query getProgramById($id: ID!) {
        getProgramById(id: $id) {
            id
            name
            description
            isRubricLocked
             defaultLegalDisclaimers
            defaultEqualEmploymentOpportunityStatement
            rubricTemplate{
                id
                label
                inputType
                isPreInterview
                isLocked
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
            }
            screenedData {
                sections {
                    personalInfo
                    residencyApplication
                    personalStatement
                    documents
                    lors
                }
                personalInfo {
                    profilePicture
                    firstName
                    lastName
                    email
                    phoneNumber
                    genderIdentity
                    nrmpId
                    aamcId
                    hometown
                    raceEthnicity
                    homeAddress
                    workAuthorization
                    preventResponsibilities
                    elaborateResponsibilities
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    couplesMatch
                    partnerField
                    previouslyApplied
                    malpracticeLawsuit
                    elaborateMalpractice
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    militaryObligation
                    otherServiceObligations
                    medicalTrainingInterrupted
                    sigmaPhi
                }
                residencyApplication {
                    medicalSchoolType
                    medicalSchoolName
                    fluencyInLanguagesBesidesEnglish
                    otherLanguagesFluentIn
                    otherDegreesInstitutionsDates
                    aoaStatus
                    ghhsStatus
                    medicalSchoolAwardsHonors
                    coreClerkshipsGraded
                    specifyClerkshipDetails
                    clerkshipGrades
                    awayRotations
                    nonCoreClerkshipGradedCourses
                    impactfulResearchPublications
                    additionalResearchBibliography
                    firstAuthorPublications
                    totalPeerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    meaningfulExperiences
                    significantWorkExperiences
                    shortQuestions
                    hobbiesInterests
                }
            }
        }
    }
`;


export const getPopulatedDatesForMonth = gql`
    query getPopulatedDatesForMonth($hostId: ID!, $month: Int!, $year: Int!) {
        getPopulatedDatesForMonth(hostId: $hostId, month: $month, year: $year) {
             id
             guest {
             name
             email
             }
            date
            startTime
            endTime
            status
    }
    }
`;

export const getProgramPopulatedDatesQuery = gql`
    query getProgramPopulatedDates($programId: ID!) {
        getProgramPopulatedDates(programId: $programId) {
            date
            status
        }
    }
`;

export const getSpecificProgramPopulatedDateQuery = gql`
    query getSpecificProgramPopulatedDate($programId: ID!, $date: String!) {
        getSpecificProgramPopulatedDate(programId: $programId, date: $date) {
            date
            status
            isRegularDay
            startTime
            totalAvailableSlots{
                level
                slotsNumber
            }
            currentAvailableSlots{
                level
                slotsNumber
            }
            breakTime
            interviewLength
            slots{
                startTime
                endTime
                item
                itemColor
            }
            interviewersPerInterviewee
            numOfInterviewers
        }
    }
`;

export const specificEmailTemplateByProgramQuery = gql`
  query getSpecificEmailTemplateByProgram($programId: ID!, $emailName: String!) {
    getSpecificEmailTemplateByProgram(programId: $programId, emailName: $emailName) {
      _id
      programId
      name
      emailSubject
      emailBody
      attachments
    }
  }
`;

export const getProgramEmailTemplatesQuery = gql`
    query getProgramEmailTemplates($programId: ID!) {
        getProgramEmailTemplates(programId: $programId) {
        _id
        programId
        name
        emailSubject
        emailBody
        attachments
        }
    }
    `;

export const getAllSelectedApplicationsByProgramQuery = gql`
    query getAllSelectedApplicationsByProgram($programId: ID!, $search: String, $category: String, $categoryType: String) {
        getAllSelectedApplicationsByProgram(programId: $programId, search: $search, category: $category, categoryType: $categoryType) {
            id
            profilePhotoUrl
            firstName
            lastName
            institution
            nrmpId
            invitationStatus
            interviewDate
        }
    }
`;

export const getGroupsByProgramId = gql`
    query getGroupsByProgramId($programId: ID!) {
        getGroupsByProgramId(programId: $programId) {
            id
            name
        }
    }
`;

export const getApplicationByIdQuery = gql`
    query getApplicationById($id: ID!){
        getApplicationById(id: $id) {
            id
            signalEssay
            watchUsersIds
            createdAt
            applicationRubrics {
                label
                inputType
                maxScale
                lowestScaleText
                highestScaleText
                dropdownOptions {
                    option
                    score
                }
                text
                order
                result 
            }
            userId {
                _id
                firstName
                lastName
                email 
                phone
                profilePhotoUrl
                graduationYear
                graduationYearUpdated
                mspe {
                    docUrl
                }
                transcript {
                    docUrl
                }
                applicationInformation {
                    nrmpId
                    workAuthorization
                    gender
                    otherRaceEthnicity
                    otherWorkAuthorization
                    partnerName
                    dateOfBirth
                    homeTown
                    homeAddress
                    ethnicity
                    coupleMatch
                    medicalSchoolName
                    aoaStatus
                    goldHumanism
                    firstAuthorPublications
                    peerReviewedPublications
                    posterPresentations
                    podiumPresentations
                    aamc
                    previousLastName
                    prefferedName
                    linkedinLink
                    pronouns
                    usmleId
                    nbomeId
                    aoaId
                    permanentAddress
                    licensureRevoked
                    licensureRevokedDetails
                    militaryObligation
                    militaryObligationDetails
                    otherServiceObligations
                    medicalTrainingInterrupted
                    medicalTrainingInterruptedDetails
                    sigmaPhi
                    malpractice
                    awardsAndHonors
                    isLanguages
                    fluentLanguages
                    misdemeanorOrFelony
                    misdemeanorOrFelonyDetails
                    higherEducation {
                        institution
                        degree
                        date
                    }
                    coreClerkshipType
                    otherCkMethod
                    coreClerkshipGrades {
                        clerkshipEm
                        clerkshipFm
                        im
                        neurology
                        obgyn
                        pediatrics
                        psychiatry
                        surgery
                    }

                    awayRotations {
                        institution
                        degree
                        dateCompleted
                    }
                    nonCoreClerkshipGrades {
                        course
                        grade
                    }
                }
            }
            isSignal
            selectedTracks {
                trackName
            }
            scoreId {
                usmleStep1Score
                comlexLevel1Score
                usmleStep2Score
                usmleStep3Score
                comlexLevel2Score
                previousUsmleComlexFails
            }
            masterApplicationId {
                previouslyAppliedToSpecialty
                applicationYear
                carryingOutResponsibilities
                carryingOutResponsibilitiesElaboration
                researchExperience {
                    category
                    title
                    authors
                    journal
                    datePublished
                    pubMedId
                    isFirstAuthor
                    significantResearchExperience
                    meeting
                    meetingDate
                    order
                }
                workExperiences {
                    employer
                    jobTitle
                    startDate
                    endDate
                    mentorName
                    mentorEmail
                    description
                }
                meaningfulExperiences {
                    category
                    title
                    startDate
                    endDate
                    description
                }
                hobbiesAndInterests
                shortAnswerQuestions {
                    question
                    answer
                }
                personalStatement
            }
        }
    }
`;

export const getScheduledApplicantsByProgramAndDateQuery = gql`
    query getScheduledApplicantsByProgramAndDate($programId: ID!, $date: String!) {
        getScheduledApplicantsByProgramAndDate(programId: $programId, date: $date) {
            id
            profilePhotoUrl
            firstName
            lastName
            institution
            nrmpId
            invitationStatus
            interviewDate
        }
    }
`;

export const getWaitlistApplicantsByProgramAndDateQuery = gql`
    query getWaitlistApplicantsByProgramAndDate($programId: ID!, $date: String!) {
        getWaitlistApplicantsByProgramAndDate(programId: $programId, date: $date) {
            id
            profilePhotoUrl
            firstName
            lastName
            institution
            nrmpId
            invitationStatus
            interviewDate
        }
    }
`;

export const GET_TIMEZONE = gql`
  query getTimeZone($userId: ID!) {
    getTimeZone(userId: $userId) 
  }
`;

export const GET_AVAILABILITY = gql`
query getAvailability($userId: ID!) {
  getAvailability(userId: $userId) {
    dayOfWeek
    startTime
    endTime
    slotDuration
  }
}
`;

export const GET_CONNECTED_CALENDARS = gql`
  query GetConnectedCalendars($userId: ID!) {
    getConnectedCalendars(userId: $userId) 
  }
`;