import {gql} from '@apollo/client';

export const SCHEDULE_EVENT = gql`
    mutation scheduleEvent($input: ScheduleEventInput!) {
        scheduleEvent(input: $input) 
    }
`;

export const RESCHEDULE_EVENT = gql`
    mutation rescheduleEvent($input: ScheduleEventInput!) {
        rescheduleEvent(input: $input) 
    }
`;

export const CANCEL_EVENT = gql`
    mutation cancelEvent($eventId: ID!) {
        cancelEvent(eventId: $eventId) 
    }
`;