
import React, { useState, useEffect,useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {gql} from '@apollo/client';
import {UserContext} from '../../../../context/userContext';
import 'tailwindcss/tailwind.css';
import {SET_AVAILABILITY, SET_TIMEZONE} from './graphql/mutations';
import { GET_AVAILABILITY, GET_TIMEZONE } from './graphql/queries';

const timeZones = [
  "UTC",
  "PST",
  "EST",
  "CST",
  "MST",
  "GMT",
  "CET",
];
const daysOfWeek = [
    { label: "Sun", value: 0 },
    { label: "Mon", value: 1 },
    { label: "Tue", value: 2 },
    { label: "Wed", value: 3 },
    { label: "Thu", value: 4 },
    { label: "Fri", value: 5 },
    { label: "Sat", value: 6 },
  ];
  
  
const AvailabilitySetup = ({ userId }) => {
    const user = useContext(UserContext);
    console.log("user=>", user)
    const [availability, setAvailability] = useState([]);
    const [timeZone, setTimeZone] = useState('');
    const [isSaving, setIsSaving] = useState(false);
  
    const { data: availabilityData, loading: loadingAvailability, error: availabilityError } = useQuery(GET_AVAILABILITY, {
        variables: { userId: user.user.userId },
        onCompleted: (data) => {
          const availabilityMap = {};
      
          data.getAvailability.forEach((item) => {
            const key = `${item.startTime}-${item.endTime}-${item.slotDuration}`;
            if (!availabilityMap[key]) {
              availabilityMap[key] = {
                daysOfWeek: [],
                startTime: item.startTime,
                endTime: item.endTime,
                slotDuration: item.slotDuration,
              };
            }
            availabilityMap[key].daysOfWeek.push(item.dayOfWeek);
          });
      
          const initialAvailability = Object.values(availabilityMap);
          setAvailability(initialAvailability);
        },
      });
  
    const { data: timeZoneData, loading: loadingTimeZone, error: timeZoneError } = useQuery(GET_TIMEZONE, {
      variables: { userId:user.user.userId },
      onCompleted: (data) => {
        setTimeZone(data.getTimeZone?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone);
      },
    });
  
    const [setAvailabilityMutation] = useMutation(SET_AVAILABILITY);
    const [setTimeZoneMutation] = useMutation(SET_TIMEZONE);
  
    const handleAddBlock = () => {
      setAvailability((prev) => [...prev, { daysOfWeek: [], startTime: '00:00', endTime: '00:00', slotDuration: 15 }]);
    };
  
    const handleDayToggle = (index, day) => {
      const newBlocks = [...availability];
      const days = newBlocks[index].daysOfWeek;
      if (days.includes(day)) {
        newBlocks[index].daysOfWeek = days.filter((d) => d !== day);
      } else {
        newBlocks[index].daysOfWeek = [...days, day];
      }
      setAvailability(newBlocks);
    };
  
    const handleTimeChange = (index, field, value) => {
        const newBlocks = [...availability];
        
        if (field === 'endTime') {
          const startTime = newBlocks[index].startTime;
          if (startTime && value < startTime) {
            alert('End time cannot be earlier than start time.');
            return; // Prevent the update
          }
        }
        
        // Store the time in 24-hour format directly
        newBlocks[index][field] = value;
        setAvailability(newBlocks);
      };
  
    const handleRemoveBlock = (index) => {
      setAvailability((prev) => prev.filter((_, i) => i !== index));
    };
  
    const handleSave = async () => {
        // Validation check
        for (const block of availability) {
          if (
            block.daysOfWeek.length === 0 ||
            !block.startTime ||
            !block.endTime ||
            !block.slotDuration
          ) {
            alert('Please ensure all fields are filled for each availability block.');
            return; // Prevent save if validation fails
          }
        }
      
        setIsSaving(true);
        try {
          const expandedAvailability = availability.flatMap((block) =>
            block.daysOfWeek.map((day) => ({
              dayOfWeek: day,
              startTime: block.startTime,
              endTime: block.endTime,
              slotDuration: block.slotDuration,
            }))
          );
          await setAvailabilityMutation({
            variables: {
              userId: user.user.userId,
              availability: expandedAvailability,
            },
          });
          alert('Availability saved successfully!');
        } catch (error) {
          console.error(error);
          alert('Error saving availability.');
        } finally {
          setIsSaving(false);
        }
      };

    const handleTimeZoneChange = async (timeZone) => {
        setTimeZone(timeZone);
        try {
            await setTimeZoneMutation({
                variables: {
                    userId:user.user.userId,
                    timeZone,
                },
            }).then((res) => {
                console.log("res=>", res)
                alert('Time zone saved successfully!');
            });
        } catch (error) {
            console.error(error);
            alert('Error saving time zone.');
        }
    };
  
    if (loadingAvailability || loadingTimeZone) return <div className="p-6">Loading...</div>;
    if (availabilityError || timeZoneError) return <div className="p-6 text-red-500">Error loading data</div>;
  
    return (
      <div className="p-6 max-w-3xl mx-auto bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Set Your Availability</h1>
  
        <div className="mb-4">
        <label className="block font-semibold mb-2">Time Zone <span className="text-red-500">*</span></label>
          <select
            value={timeZone}
            onChange={(e) => handleTimeZoneChange((e.target.value))}
            className="w-full p-2 border rounded-md shadow-sm"
          >
            {timeZones.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
  
        {availability.map((block, index) => (
          <div key={index} className="mb-4 border p-4 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <label className="font-semibold">Block {index + 1} <span className="text-red-500">*</span></label>
              <button
                onClick={() => handleRemoveBlock(index)}
                className="text-red-500 font-semibold"
              >
                Remove
              </button>
            </div>
  
            <div className="mb-4 flex space-x-2">
              {daysOfWeek.map((day) => (
                <button
                  key={day.value}
                  onClick={() => handleDayToggle(index, day.value)}
                  className={`px-3 py-1 border rounded-md ${
                    block.daysOfWeek.includes(day.value)
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200'
                  }`}
                >
                  {day.label}
                </button>
              ))}
            </div>
  
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-2">
            <div>
            <label className="block font-semibold mb-1">Start Time <span className="text-red-500">*</span></label>
              <input
                type="time"
                value={block.startTime} // Ensure this is bound to the correct state
                onChange={(e) => handleTimeChange(index, 'startTime', e.target.value)}
                className="w-full p-2 border rounded-md shadow-sm"
                />
            </div>

            <div>
            <label className="block font-semibold mb-1">End Time <span className="text-red-500">*</span></label>
              <input
               type="time"
               value={block.endTime} // Ensure this is bound to the correct state
               onChange={(e) => handleTimeChange(index, 'endTime', e.target.value)}
               className="w-full p-2 border rounded-md shadow-sm"
              />
            </div>

            <div>
            <label className="block font-semibold mb-1">Slot Duration (Minutes) <span className="text-red-500">*</span></label>
              <select
                value={block.slotDuration}
                onChange={(e) => {
                  const newBlocks = [...availability];
                  newBlocks[index].slotDuration = Number(e.target.value);
                  setAvailability(newBlocks);
                }}
                className="w-full p-2 border rounded-md shadow-sm"
              >
                {[15, 30, 45, 60].map((duration) => (
                  <option key={duration} value={duration}>
                    {duration} min
                  </option>
                ))}
              </select>
            </div>
          </div>
          </div>
        ))}
  
        <button
          onClick={handleAddBlock}
          className="w-full bg-blue-500 text-white p-2 rounded-md shadow-sm hover:bg-blue-600 mb-4"
        >
          Add Another Block
        </button>
  
        <button
            onClick={handleSave}
            className={`w-full p-2 rounded-md shadow-sm ${isSaving ? 'bg-gray-400' : 'bg-green-500 hover:bg-green-600 text-white'}`}
            disabled={isSaving}
            >
            {isSaving ? 'Saving...' : 'Save Availability'}
            </button>
      </div>
    );
  };
  
  export default AvailabilitySetup;
  