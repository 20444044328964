import React, { useState,useRef,useEffect, useContext } from 'react';
import AddNewGroupModal from '../groups/addNewGroupModal';
import { ProgramIdContext } from '../../progIdContext';
import client from '../../../../config/apolloClient';
import { getProgramByIdMembers } from "../graphql/queries";
import { createGroupMutation } from "../graphql/mutations";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const GroupsDropdown = ({ isOpen, groups, selectedGroups, setSelectedGroups, onApply, selectedRows, finishMutation, setIsOpen, from }) => {
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [programMembers, setProgramMembers] = useState([])
    const [succeed, setSucceed] = useState(false);
    const checkboxRefs = useRef([]);
    const dropdownRef = useRef(null);


    const programId = useContext(ProgramIdContext);

    useEffect(() => {
        console.log("checkedGroups changed!", checkedGroups)
    }, [checkedGroups])

   

    useEffect(() => {
        if(isOpen === true) {
            if(selectedRows && selectedRows.length > 0) {
                console.log("groups in dropdown =>", groups)
                console.log("selectedRows in dropdown =>", selectedRows)
                // update selected row

                setCheckedGroups(groups.filter(group => getCheckboxState(group) === 'checked'));
                checkboxRefs.current.forEach((ref, index) => {
                    if (ref) {
                        ref.indeterminate = getCheckboxState(groups[index]) === 'indeterminate';
                    }
                });
            }
        }
       
    }, [isOpen])

    useEffect(() => {
        getProgramMembers()
    }, [])

    const getProgramMembers = () => {
        try {
        client.query({
            query: getProgramByIdMembers,
            variables: {
                id: programId
            }
        })
        .then(res => {
            let program = res.data.getProgramByIdMembers
            console.log("program members =>", program)
            setProgramMembers(program.members)
        })
    }catch(err) {
        console.log("error =>", err)
    }
    }

    const createGroup = (name, description, selectedMembers) => {
        const membersIds = selectedMembers.map(member => member.value);
        console.log("inside create group =>", name, description, selectedMembers)
        client.mutate({
            mutation: createGroupMutation,
            variables: {
                input: {
                    programId,
                    name,
                    description,
                    membersIds
                }
            }
        })
        .then(res => {
            toast.success('Group created successfully!');
            finishMutation()
        }).catch(err => {
            toast.error('Failed to create group', err);
        }
        )
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            console.log("clicked outside!")
          // Close the dropdown
          setIsOpen(false);
        }
      };

    const handleCheckboxChange = (group) => {
        setCheckedGroups(prev => {
          const isAlreadyChecked = prev.filter(elem => elem.id.toString() === group.id.toString()).length > 0;
          console.log("isAlreadyChecked =>", isAlreadyChecked)
          if (isAlreadyChecked) {
            const selectedIds = selectedRows.map(row => row.id);
            const checkedGroup = checkedGroups.filter(elem => elem.id.toString() === group.id.toString()).length > 0 ? checkedGroups.filter(elem => elem.id.toString() === group.id.toString())[0]: null
            const groupIds = checkedGroup !== null ? checkedGroup.applicationIds : group.applicationIds;
            const allChecked = groupIds.length === 0 ? false : selectedIds.every(id =>  groupIds.includes(id));
            const someChecked = selectedIds.some(id => groupIds.includes(id));
            console.log("result in click =>", allChecked, someChecked)
            if(allChecked) {
                // remove the application id from the group
                const idsToRemove = selectedRows.map(elem => elem.id)
                console.log("ids to remove =>", idsToRemove);
                const modifiedGroup = {
                    ...(checkedGroup !== null ? checkedGroup: group),
                    applicationIds: group.applicationIds.filter(id => !idsToRemove.includes(id)) // Remove the ids from the applicationIds array
                };

                console.log("modifiedGroup after removal =>", prev.map(g => g.id.toString() === modifiedGroup.id.toString() ? modifiedGroup : g));

                return prev.map(g => g.id.toString() === modifiedGroup.id.toString() ? modifiedGroup : g);
            } else if(someChecked){
                console.log("some checked!, update all other selected!")
                const existingIds = group.applicationIds
                const ids = selectedRows.map(elem => elem.id)
                const newIds = ids.filter(id => !existingIds.includes(id));
                console.log("newIds =>", newIds)
                var modifiedGroup = {
                    ...group,
                    applicationIds: [...group.applicationIds, ...newIds] // Spread the existing applicationIds and add new ids
                };
                
                console.log("checkedGroups =>",prev.map(g => g.id.toString() === modifiedGroup.id.toString() ? modifiedGroup : g));


                
                // Update the state or return the new array with the modified group
                return prev.map(g => g.id.toString() === modifiedGroup.id.toString() ? modifiedGroup : g);
            }

          } else {
            console.log("new groups =>", [...prev, group])
            //   add the applications to application ids 
            const checkedGroup = checkedGroups.filter(elem => elem.id.toString() === group.id.toString()).length > 0 ? checkedGroups.filter(elem => elem.id.toString() === group.id.toString())[0]: null
            const existingIds = checkedGroup !== null ? checkedGroup.applicationIds : group.applicationIds
            const ids = selectedRows.map(elem => elem.id)
            const newIds = ids.filter(id => !existingIds.includes(id));

            console.log("ids to add =>", ids)
            var modifiedGroup = {
                ...group,
                applicationIds: [...group.applicationIds, ...newIds] // Spread the existing applicationIds and add new ids
            };
            
            console.log("modifiedGroup =>", modifiedGroup, group);
            
            // Update the state or return the new array with the modified group
            return [...prev, modifiedGroup];
          }
        });
      };
    
      const getCheckboxState = (group) => {
        const selectedIds = selectedRows.map(row => row.id);
        const groupIds = group.applicationIds;
        const allChecked = groupIds.length === 0 ? false : groupIds.every(id => selectedIds.includes(id));
        const someChecked = groupIds.some(id => selectedIds.includes(id));
        console.log("someChecked =>", someChecked)
        console.log("allChecked =>", allChecked)
        if (allChecked) {
            return 'checked';
        } else if (someChecked) {
            return 'indeterminate';
        } else {
            return '';
        }
    };


    const applyChanges = () => {
        console.log("checkedGroups in apply =>", checkedGroups)
        setSucceed(true)
        onApply(checkedGroups)
        setTimeout(() => {
            setSucceed(false)
        }, 3000)
    }

    const toggleModalView = () => {
        setIsModalOpen(!isModalOpen)
    }

    const generateOptions = (programMembers) => {
        let options = []
    
        programMembers.forEach(member => {
            options.push({ label: `${member.userId.firstName} ${member.userId.lastName}`, value: member.userId.id })
        })
        return options
    }
    const onAddGroup = (newGroup) => {
        console.log("newGroup =>", newGroup)
        // setGroups([...groups, newGroup]);
    };

    

    useEffect(() => {
        setCheckedGroups(groups.filter(group => getCheckboxState(group) === 'checked'));
        checkboxRefs.current.forEach((ref, index) => {
            if (ref) {
                ref.indeterminate = getCheckboxState(groups[index]) === 'indeterminate';
            }
        });
    }, [selectedRows, groups]);

    useEffect(() => {
        // Bind the event listener if dropdown is open
        if (isOpen) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        // Clean up the event listener on component unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isOpen]);
    
    return isOpen ? (
        <div className={from === "manage" ? 'groups-dropdown-manage' : from === 'applicationView' ? 'groups-dropdown-view' : 'groups-dropdown'} ref={dropdownRef}>
            <ToastContainer
                theme="colored"
            />
            { succeed && <label style={{color: '#145388', marginBottom: '5vh'}}>Folders updated!</label>}
            <div style={{overflowY:'scroll',maxHeight:'50vh'}}>
            {groups.map((group, index) => (
                <div style={{padding: '1vh', textAlign:'left'}} key={group.name}>
                   <input 
                        style={{ marginRight: '.5vw' }}
                        type="checkbox" 
                        checked={
                            checkedGroups && checkedGroups.length > 0 ?
                            // checkedGroups.every(elem => elem.id.toString() === group.id.toString()) &&
                            selectedRows.some(row => (checkedGroups.filter(elem => elem.id.toString() === group.id.toString()).length > 0 ? checkedGroups.filter(elem => elem.id.toString() === group.id.toString())[0] : group).applicationIds.includes(row.id.toString()))
                            :
                            // group.applicationIds.every(elem => elem.toString() === group.id.toString()) &&
                            selectedRows.some(row => group.applicationIds.includes(row.id.toString()))

                        }
                        onChange={() => handleCheckboxChange(group)} 
                        ref={el => {
                            const selectedIds = selectedRows.map(row => row.id);
                            const checkedGroup = checkedGroups && checkedGroups.filter(elem => elem.id.toString() === group.id.toString()).length > 0 ? checkedGroups.filter(elem => elem.id.toString() === group.id.toString())[0] : null
                            console.log("checkedGroup =>", checkedGroup)
                            const groupIds = checkedGroup === null ? group.applicationIds : checkedGroup.applicationIds;

                            console.log("selectedIds, groupIds =>", group.id, selectedIds, groupIds)
                            const allChecked = groupIds.length === 0 ? false : selectedIds.every(id => {
                                console.log("id to check =>", group.id, selectedIds, id, selectedIds.includes(id))
                                return groupIds.includes(id)});
                                const someChecked = selectedIds.some(id => groupIds.includes(id));
                         
                            console.log("allChecked inside input =>", group.id, allChecked, someChecked)

                            if (el) {
                                el.indeterminate = someChecked && !allChecked; // set indeterminate if some but not all are checked
                            }
                            checkboxRefs.current[index] = el;
                        }}
                    />
                    {group.name}
                </div>
            ))}
            </div>
            <label className='add-group' style={{marginTop:'2vh', color:'#145388', fontWeight:'500', width:'100%', display:'flex', justifyContent:'center'}} onClick={toggleModalView}>+ Add Folder</label>
            <button onClick={applyChanges}>Apply</button>
            <AddNewGroupModal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false);}} onAddGroup={onAddGroup} onEditGroup={() => {}} groupToEdit={null} programMembers={programMembers} programMembersOptions={generateOptions(programMembers)} createGroup={createGroup}/>

        </div>
    ) : null;
};


export default GroupsDropdown;