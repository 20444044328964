import React, { useRef } from 'react';
import { saveAs } from 'file-saver';

const QRModal = ({ isOpen, onClose, body, qrCodeObj }) => {
    // console.log("qrCodeObj =>", qrCodeObj.qrCode);
    const qrCode = qrCodeObj.qrCode;
    const canvasRef = useRef(null);
    // console.log("qrCode =>", qrCode);

    const downloadPng = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();

        if (!qrCode) {
            console.error("QR Code is not available.");
            return;
        }

        // Encode the SVG string as a Data URL
        const svgDataUrl = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(qrCode)))}`;

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            // Convert canvas to PNG and trigger download
            canvas.toBlob((blob) => {
                saveAs(blob, 'QRCode.png');
            }, 'image/png');
        };

        img.onerror = (err) => {
            console.error("Error loading SVG as image:", err);
        };

        img.src = svgDataUrl;
    };

    return (
        <div
            className="modal-backdrop"
            style={{
                zIndex: '25',
                display: isOpen ? 'flex' : 'none',
                flexDirection: 'row',
            }}
        >
            <div
                className="add-field-modal"
                style={{ height: '60vh', overflow: 'unset', width: '50vw', maxHeight: '100vh' }}
            >
                <div className="faculty-home-body" style={{ gap: '0', textAlign: 'center' }}>
                    {/* Render the QR Code SVG */}
                    {qrCode ? (
                     <div
                     dangerouslySetInnerHTML={{ __html: qrCode }}
                     style={{ width: '200px', height: '200px', margin: '0 auto' }}
                 />
                    ) : (
                        <p style={{ fontWeight: '500', fontSize: '18px' }}>{body || 'QR Code Loading...'}</p>
                    )}
                </div>
                <div
                    className="add-field-modal-footer"
                    style={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginTop: '5vh',
                    }}
                >
                    <button className="green-button" onClick={onClose}>
                        Close
                    </button>
                    {/* Button to download the SVG as PNG */}
                    {qrCode && (
                        <button
                            className="green-button"
                            onClick={downloadPng}
                            style={{ marginLeft: '1vw' }}
                        >
                            Download
                        </button>
                    )}
                </div>
                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
        </div>
    );
};

export default QRModal;
