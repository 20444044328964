import {gql} from '@apollo/client';

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: ID!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
        id
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      userId {
        id
        firstName
        lastName
        email
        isPending
      }
      roles
    }
  }
`;

export const DELETE_FAIR = gql`
mutation deleteCareerFair($id: ID!) {
  deleteCareerFair(id: $id)
}
`;

export const UPDATE_CAREER_FAIR = gql`
mutation UpdateCareerFair($id: ID!, $input: CareerFairInput!) {
  updateCareerFair(id: $id, input: $input) {
    id
  }
}
`;

export const CREATE_CAREER_FAIR = gql`
mutation CreateCareerFair($input: CareerFairInput!) {
  createCareerFair(input: $input) {
    id
    programId
    name
    date
    location
    description
    formLink
    qrCode
    isVirtual
    virtualEventLink
    eventTime
  }
}
`;