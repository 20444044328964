import React, {useState} from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import magGlass from '../../../../assets/imgs/magGlass.svg';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
  
    return (
      <span className='rt-search-bar'>
        <img src={magGlass} alt='magnifying glass' />
        <input
        style={{width:'30vw',height:'4.7vh',alignSelf:'center'}}
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`Search ${count} records...`}
        />
      </span>
    );
  }


const TeamMembersTable = ({columns, data, sortData}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
      } = useTable({ columns, data }, useGlobalFilter, useSortBy);
    

      const sortClicked = (direction, column) => {
        console.log("sorting =>", column.id, direction)
        sortData(column.id, direction)
      }

      return (
          <table className='table-programs-list' {...getTableProps()}>
          <thead className='tHead-programs-list'>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.filter(column => (column.Header !== 'isPending'&&column.Header!=='id' )).map(column => (
                                <th style={{ lineHeight: '4vh'}} className='table-header-title' {...column.getHeaderProps(column.getSortByToggleProps())} onClick={(e) => {
                                  column.getSortByToggleProps().onClick(e);
                                  sortClicked(column.isSortedDesc, column);
                              }}>
                                {
                                  column.Header !== 'Action' && column.Header !== 'Roles' &&
                                  <span>
                                  {console.log("column.isSorted=>", column, column.isSorted)}
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? ' 🔼'
                                      : ' 🔽'
                                    : ''}
                                </span>
                                }
                                  {column.render('Header')}
                                </th>
                ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const rowStyle = row.values.isPending === true ? {background: 'gainsboro', fontStyle:'italic', fontWeight:'bold'} : {};
                return (
                  <tr className='programs-list-table-row table-row-height' {...row.getRowProps()} style={rowStyle}>
                      {row.cells.filter(cell => (cell.column.Header !== 'isPending'&&cell.column.Header!=='id')).map(cell => (
                          <td className='programs-list-table-row-text' {...cell.getCellProps()} style={{ maxWidth: '30vw', overFlowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                          >{cell.render('Cell')}</td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
      );
  }
  
    
    export default TeamMembersTable;


