export const occupations = [
    "Accountants and Auditors",
    "Actors",
    "Actuaries",
    "Acupuncturists",
    "Acute Care Nurses",
    "Adapted Physical Education Specialists",
    "Adhesive Bonding Machine Operators and Tenders",
    "Administrative Law Judges, Adjudicators, and Hearing Officers",
    "Administrative Services Managers",
    "Adult Basic Education, Adult Secondary Education, and English as a Second Language Instructors",
    "Advanced Practice Psychiatric Nurses",
    "Advertising and Promotions Managers",
    "Advertising Sales Agents",
    "Aerospace Engineering and Operations Technologists and Technicians",
    "Aerospace Engineers",
    "Agents and Business Managers of Artists, Performers, and Athletes",
    "Agricultural Engineers",
    "Agricultural Equipment Operators",
    "Agricultural Inspectors",
    "Agricultural Sciences Teachers, Postsecondary",
    "Agricultural Technicians",
    "Agricultural Workers, All Other",
    "Air Crew Members",
    "Air Crew Officers",
    "Air Traffic Controllers",
    "Aircraft Cargo Handling Supervisors",
    "Aircraft Launch and Recovery Officers",
    "Aircraft Launch and Recovery Specialists",
    "Aircraft Mechanics and Service Technicians",
    "Aircraft Service Attendants",
    "Aircraft Structure, Surfaces, Rigging, and Systems Assemblers",
    "Airfield Operations Specialists",
    "Airline Pilots, Copilots, and Flight Engineers",
    "Allergists and Immunologists",
    "Ambulance Drivers and Attendants, Except Emergency Medical Technicians",
    "Amusement and Recreation Attendants",
    "Anesthesiologist Assistants",
    "Anesthesiologists",
    "Animal Breeders",
    "Animal Caretakers",
    "Animal Control Workers",
    "Animal Scientists",
    "Animal Trainers",
    "Anthropologists and Archeologists",
    "Anthropology and Archeology Teachers, Postsecondary",
    "Appraisers and Assessors of Real Estate",
    "Appraisers of Personal and Business Property",
    "Arbitrators, Mediators, and Conciliators",
    "Architects, Except Landscape and Naval",
    "Architectural and Civil Drafters",
    "Architectural and Engineering Managers",
    "Architecture Teachers, Postsecondary",
    "Archivists",
    "Area, Ethnic, and Cultural Studies Teachers, Postsecondary",
    "Armored Assault Vehicle Crew Members",
    "Armored Assault Vehicle Officers",
    "Art Directors",
    "Art Therapists",
    "Art, Drama, and Music Teachers, Postsecondary",
    "Artillery and Missile Crew Members",
    "Artillery and Missile Officers",
    "Artists and Related Workers, All Other",
    "Assemblers and Fabricators, All Other",
    "Astronomers",
    "Athletes and Sports Competitors",
    "Athletic Trainers",
    "Atmospheric and Space Scientists",
    "Atmospheric, Earth, Marine, and Space Sciences Teachers, Postsecondary",
    "Audio and Video Technicians",
    "Audiologists",
    "Audiovisual Equipment Installers and Repairers",
    "Automotive and Watercraft Service Attendants",
    "Automotive Body and Related Repairers",
    "Automotive Engineering Technicians",
    "Automotive Engineers",
    "Automotive Glass Installers and Repairers",
    "Automotive Service Technicians and Mechanics",
    "Aviation Inspectors",
    "Avionics Technicians",
    "Baggage Porters and Bellhops",
    "Bailiffs",
    "Bakers",
    "Barbers",
    "Baristas",
    "Bartenders",
    "Bicycle Repairers",
    "Bill and Account Collectors",
    "Billing and Posting Clerks",
    "Biochemists and Biophysicists",
    "Bioengineers and Biomedical Engineers",
    "Biofuels Processing Technicians",
    "Biofuels Production Managers",
    "Biofuels/Biodiesel Technology and Product Development Managers",
    "Bioinformatics Scientists",
    "Bioinformatics Technicians",
    "Biological Science Teachers, Postsecondary",
    "Biological Scientists, All Other",
    "Biological Technicians",
    "Biologists",
    "Biomass Plant Technicians",
    "Biomass Power Plant Managers",
    "Biostatisticians",
    "Blockchain Engineers",
    "Boilermakers",
    "Bookkeeping, Accounting, and Auditing Clerks",
    "Brickmasons and Blockmasons",
    "Bridge and Lock Tenders",
    "Broadcast Announcers and Radio Disc Jockeys",
    "Broadcast Technicians",
    "Brokerage Clerks",
    "Brownfield Redevelopment Specialists and Site Managers",
    "Budget Analysts",
    "Building Cleaning Workers, All Other",
    "Bus and Truck Mechanics and Diesel Engine Specialists",
    "Bus Drivers, School",
    "Bus Drivers, Transit and Intercity",
    "Business Continuity Planners",
    "Business Intelligence Analysts",
    "Business Operations Specialists, All Other",
    "Business Teachers, Postsecondary",
    "Butchers and Meat Cutters",
    "Buyers and Purchasing Agents, Farm Products",
    "Cabinetmakers and Bench Carpenters",
    "Calibration Technologists and Technicians",
    "Camera and Photographic Equipment Repairers",
    "Camera Operators, Television, Video, and Film",
    "Captains, Mates, and Pilots of Water Vessels",
    "Cardiologists",
    "Cardiovascular Technologists and Technicians",
    "Career/Technical Education Teachers, Middle School",
    "Career/Technical Education Teachers, Postsecondary",
    "Career/Technical Education Teachers, Secondary School",
    "Cargo and Freight Agents",
    "Carpenters",
    "Carpet Installers",
    "Cartographers and Photogrammetrists",
    "Cashiers",
    "Cement Masons and Concrete Finishers",
    "Chefs and Head Cooks",
    "Chemical Engineers",
    "Chemical Equipment Operators and Tenders",
    "Chemical Plant and System Operators",
    "Chemical Technicians",
    "Chemistry Teachers, Postsecondary",
    "Chemists",
    "Chief Executives",
    "Chief Sustainability Officers",
    "Child, Family, and School Social Workers",
    "Childcare Workers",
    "Chiropractors",
    "Choreographers",
    "Civil Engineering Technologists and Technicians",
    "Civil Engineers",
    "Claims Adjusters, Examiners, and Investigators",
    "Cleaners of Vehicles and Equipment",
    "Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders",
    "Clergy",
    "Climate Change Policy Analysts",
    "Clinical and Counseling Psychologists",
    "Clinical Data Managers",
    "Clinical Neuropsychologists",
    "Clinical Nurse Specialists",
    "Clinical Research Coordinators",
    "Coaches and Scouts",
    "Coating, Painting, and Spraying Machine Setters, Operators, and Tenders",
    "Coil Winders, Tapers, and Finishers",
    "Coin, Vending, and Amusement Machine Servicers and Repairers",
    "Command and Control Center Officers",
    "Command and Control Center Specialists",
    "Commercial and Industrial Designers",
    "Commercial Divers",
    "Commercial Pilots",
    "Communications Equipment Operators, All Other",
    "Communications Teachers, Postsecondary",
    "Community and Social Service Specialists, All Other",
    "Community Health Workers",
    "Compensation and Benefits Managers",
    "Compensation, Benefits, and Job Analysis Specialists",
    "Compliance Managers",
    "Compliance Officers",
    "Computer and Information Research Scientists",
    "Computer and Information Systems Managers",
    "Computer Hardware Engineers",
    "Computer Network Architects",
    "Computer Network Support Specialists",
    "Computer Numerically Controlled Tool Operators",
    "Computer Numerically Controlled Tool Programmers",
    "Computer Occupations, All Other",
    "Computer Programmers",
    "Computer Science Teachers, Postsecondary",
    "Computer Systems Analysts",
    "Computer Systems Engineers/Architects",
    "Computer User Support Specialists",
    "Computer, Automated Teller, and Office Machine Repairers",
    "Concierges",
    "Conservation Scientists",
    "Construction and Building Inspectors",
    "Construction and Related Workers, All Other",
    "Construction Laborers",
    "Construction Managers",
    "Continuous Mining Machine Operators",
    "Control and Valve Installers and Repairers, Except Mechanical Door",
    "Conveyor Operators and Tenders",
    "Cooks, All Other",
    "Cooks, Fast Food",
    "Cooks, Institution and Cafeteria",
    "Cooks, Private Household",
    "Cooks, Restaurant",
    "Cooks, Short Order",
    "Cooling and Freezing Equipment Operators and Tenders",
    "Coroners",
    "Correctional Officers and Jailers",
    "Correspondence Clerks",
    "Cost Estimators",
    "Costume Attendants",
    "Counselors, All Other",
    "Counter and Rental Clerks",
    "Couriers and Messengers",
    "Court Reporters and Simultaneous Captioners",
    "Court, Municipal, and License Clerks",
    "Craft Artists",
    "Crane and Tower Operators",
    "Credit Analysts",
    "Credit Authorizers, Checkers, and Clerks",
    "Credit Counselors",
    "Crematory Operators",
    "Criminal Justice and Law Enforcement Teachers, Postsecondary",
    "Critical Care Nurses",
    "Crossing Guards and Flaggers",
    "Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders",
    "Curators",
    "Customer Service Representatives",
    "Customs and Border Protection Officers",
    "Customs Brokers",
    "Cutters and Trimmers, Hand",
    "Cutting and Slicing Machine Setters, Operators, and Tenders",
    "Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic",
    "Cytogenetic Technologists",
    "Cytotechnologists",
    "Dancers",
    "Data Entry Keyers",
    "Data Scientists",
    "Data Warehousing Specialists",
    "Database Administrators",
    "Database Architects",
    "Demonstrators and Product Promoters",
    "Dental Assistants",
    "Dental Hygienists",
    "Dental Laboratory Technicians",
    "Dentists, All Other Specialists",
    "Dentists, General",
    "Dermatologists",
    "Derrick Operators, Oil and Gas",
    "Designers, All Other",
    "Desktop Publishers",
    "Detectives and Criminal Investigators",
    "Diagnostic Medical Sonographers",
    "Dietetic Technicians",
    "Dietitians and Nutritionists",
    "Digital Forensics Analysts",
    "Dining Room and Cafeteria Attendants and Bartender Helpers",
    "Directors, Religious Activities and Education",
    "Disc Jockeys, Except Radio",
    "Dishwashers",
    "Dispatchers, Except Police, Fire, and Ambulance",
    "Document Management Specialists",
    "Door-to-Door Sales Workers, News and Street Vendors, and Related Workers",
    "Drafters, All Other",
    "Dredge Operators",
    "Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
    "Driver/Sales Workers",
    "Drywall and Ceiling Tile Installers",
    "Earth Drillers, Except Oil and Gas",
    "Economics Teachers, Postsecondary",
    "Economists",
    "Editors",
    "Education Administrators, All Other",
    "Education Administrators, Kindergarten through Secondary",
    "Education Administrators, Postsecondary",
    "Education and Childcare Administrators, Preschool and Daycare",
    "Education Teachers, Postsecondary",
    "Educational Instruction and Library Workers, All Other",
    "Educational, Guidance, and Career Counselors and Advisors",
    "Electric Motor, Power Tool, and Related Repairers",
    "Electrical and Electronic Engineering Technologists and Technicians",
    "Electrical and Electronic Equipment Assemblers",
    "Electrical and Electronics Drafters",
    "Electrical and Electronics Installers and Repairers, Transportation Equipment",
    "Electrical and Electronics Repairers, Commercial and Industrial Equipment",
    "Electrical and Electronics Repairers, Powerhouse, Substation, and Relay",
    "Electrical Engineers",
    "Electrical Power-Line Installers and Repairers",
    "Electricians",
    "Electro-Mechanical and Mechatronics Technologists and Technicians",
    "Electromechanical Equipment Assemblers",
    "Electronic Equipment Installers and Repairers, Motor Vehicles",
    "Electronics Engineers, Except Computer",
    "Elementary School Teachers, Except Special Education",
    "Elevator and Escalator Installers and Repairers",
    "Eligibility Interviewers, Government Programs",
    "Embalmers",
    "Emergency Management Directors",
    "Emergency Medical Technicians",
    "Emergency Medicine Physicians",
    "Endoscopy Technicians",
    "Energy Auditors",
    "Energy Engineers, Except Wind and Solar",
    "Engine and Other Machine Assemblers",
    "Engineering Teachers, Postsecondary",
    "Engineering Technologists and Technicians, Except Drafters, All Other",
    "Engineers, All Other",
    "English Language and Literature Teachers, Postsecondary",
    "Entertainers and Performers, Sports and Related Workers, All Other",
    "Entertainment and Recreation Managers, Except Gambling",
    "Entertainment Attendants and Related Workers, All Other",
    "Environmental Compliance Inspectors",
    "Environmental Economists",
    "Environmental Engineering Technologists and Technicians",
    "Environmental Engineers",
    "Environmental Restoration Planners",
    "Environmental Science and Protection Technicians, Including Health",
    "Environmental Science Teachers, Postsecondary",
    "Environmental Scientists and Specialists, Including Health",
    "Epidemiologists",
    "Equal Opportunity Representatives and Officers",
    "Etchers and Engravers",
    "Excavating and Loading Machine and Dragline Operators, Surface Mining",
    "Executive Secretaries and Executive Administrative Assistants",
    "Exercise Physiologists",
    "Exercise Trainers and Group Fitness Instructors",
    "Explosives Workers, Ordnance Handling Experts, and Blasters",
    "Extraction Workers, All Other",
    "Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic",
    "Extruding and Forming Machine Setters, Operators, and Tenders, Synthetic and Glass Fibers",
    "Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders",
    "Fabric and Apparel Patternmakers",
    "Facilities Managers",
    "Fallers",
    "Family and Consumer Sciences Teachers, Postsecondary",
    "Family Medicine Physicians",
    "Farm and Home Management Educators",
    "Farm Equipment Mechanics and Service Technicians",
    "Farm Labor Contractors",
    "Farmers, Ranchers, and Other Agricultural Managers",
    "Farmworkers and Laborers, Crop, Nursery, and Greenhouse",
    "Farmworkers, Farm, Ranch, and Aquacultural Animals",
    "Fashion Designers",
    "Fast Food and Counter Workers",
    "Fence Erectors",
    "Fiberglass Laminators and Fabricators",
    "File Clerks",
    "Film and Video Editors",
    "Financial and Investment Analysts",
    "Financial Clerks, All Other",
    "Financial Examiners",
    "Financial Managers",
    "Financial Quantitative Analysts",
    "Financial Risk Specialists",
    "Financial Specialists, All Other",
    "Fine Artists, Including Painters, Sculptors, and Illustrators",
    "Fire Inspectors and Investigators",
    "Fire-Prevention and Protection Engineers",
    "Firefighters",
    "First-Line Supervisors of Air Crew Members",
    "First-Line Supervisors of All Other Tactical Operations Specialists",
    "First-Line Supervisors of Construction Trades and Extraction Workers",
    "First-Line Supervisors of Correctional Officers",
    "First-Line Supervisors of Entertainment and Recreation Workers, Except Gambling Services",
    "First-Line Supervisors of Farming, Fishing, and Forestry Workers",
    "First-Line Supervisors of Firefighting and Prevention Workers",
    "First-Line Supervisors of Food Preparation and Serving Workers",
    "First-Line Supervisors of Gambling Services Workers",
    "First-Line Supervisors of Helpers, Laborers, and Material Movers, Hand",
    "First-Line Supervisors of Housekeeping and Janitorial Workers",
    "First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers",
    "First-Line Supervisors of Material-Moving Machine and Vehicle Operators",
    "First-Line Supervisors of Mechanics, Installers, and Repairers",
    "First-Line Supervisors of Non-Retail Sales Workers",
    "First-Line Supervisors of Office and Administrative Support Workers",
    "First-Line Supervisors of Passenger Attendants",
    "First-Line Supervisors of Personal Service Workers",
    "First-Line Supervisors of Police and Detectives",
    "First-Line Supervisors of Production and Operating Workers",
    "First-Line Supervisors of Protective Service Workers, All Other",
    "First-Line Supervisors of Retail Sales Workers",
    "First-Line Supervisors of Security Workers",
    "First-Line Supervisors of Transportation Workers, All Other",
    "First-Line Supervisors of Weapons Specialists/Crew Members",
    "Fish and Game Wardens",
    "Fishing and Hunting Workers",
    "Fitness and Wellness Coordinators",
    "Flight Attendants",
    "Floor Layers, Except Carpet, Wood, and Hard Tiles",
    "Floor Sanders and Finishers",
    "Floral Designers",
    "Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders",
    "Food Batchmakers",
    "Food Cooking Machine Operators and Tenders",
    "Food Preparation and Serving Related Workers, All Other",
    "Food Preparation Workers",
    "Food Processing Workers, All Other",
    "Food Science Technicians",
    "Food Scientists and Technologists",
    "Food Servers, Nonrestaurant",
    "Food Service Managers",
    "Foreign Language and Literature Teachers, Postsecondary",
    "Forensic Science Technicians",
    "Forest and Conservation Technicians",
    "Forest and Conservation Workers",
    "Forest Fire Inspectors and Prevention Specialists",
    "Foresters",
    "Forestry and Conservation Science Teachers, Postsecondary",
    "Forging Machine Setters, Operators, and Tenders, Metal and Plastic",
    "Foundry Mold and Coremakers",
    "Fraud Examiners, Investigators and Analysts",
    "Freight Forwarders",
    "Fuel Cell Engineers",
    "Fundraisers",
    "Fundraising Managers",
    "Funeral Attendants",
    "Funeral Home Managers",
    "Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders",
    "Furniture Finishers",
    "Gambling and Sports Book Writers and Runners",
    "Gambling Cage Workers",
    "Gambling Change Persons and Booth Cashiers",
    "Gambling Dealers",
    "Gambling Managers",
    "Gambling Service Workers, All Other",
    "Gambling Surveillance Officers and Gambling Investigators",
    "Gas Compressor and Gas Pumping Station Operators",
    "Gas Plant Operators",
    "Gem and Diamond Workers",
    "General and Operations Managers",
    "General Internal Medicine Physicians",
    "Genetic Counselors",
    "Geneticists",
    "Geodetic Surveyors",
    "Geographers",
    "Geographic Information Systems Technologists and Technicians",
    "Geography Teachers, Postsecondary",
    "Geological Technicians, Except Hydrologic Technicians",
    "Geoscientists, Except Hydrologists and Geographers",
    "Geothermal Production Managers",
    "Geothermal Technicians",
    "Glass Blowers, Molders, Benders, and Finishers",
    "Glaziers",
    "Government Property Inspectors and Investigators",
    "Graders and Sorters, Agricultural Products",
    "Graphic Designers",
    "Grinding and Polishing Workers, Hand",
    "Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
    "Grounds Maintenance Workers, All Other",
    "Hairdressers, Hairstylists, and Cosmetologists",
    "Hazardous Materials Removal Workers",
    "Health and Safety Engineers, Except Mining Safety Engineers and Inspectors",
    "Health Education Specialists",
    "Health Informatics Specialists",
    "Health Information Technologists and Medical Registrars",
    "Health Specialties Teachers, Postsecondary",
    "Health Technologists and Technicians, All Other",
    "Healthcare Diagnosing or Treating Practitioners, All Other",
    "Healthcare Practitioners and Technical Workers, All Other",
    "Healthcare Social Workers",
    "Healthcare Support Workers, All Other",
    "Hearing Aid Specialists",
    "Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic",
    "Heating, Air Conditioning, and Refrigeration Mechanics and Installers",
    "Heavy and Tractor-Trailer Truck Drivers",
    "Helpers, Construction Trades, All Other",
    "Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters",
    "Helpers--Carpenters",
    "Helpers--Electricians",
    "Helpers--Extraction Workers",
    "Helpers--Installation, Maintenance, and Repair Workers",
    "Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons",
    "Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters",
    "Helpers--Production Workers",
    "Helpers--Roofers",
    "Highway Maintenance Workers",
    "Histology Technicians",
    "Historians",
    "History Teachers, Postsecondary",
    "Histotechnologists",
    "Hoist and Winch Operators",
    "Home Appliance Repairers",
    "Home Health Aides",
    "Hospitalists",
    "Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop",
    "Hotel, Motel, and Resort Desk Clerks",
    "Human Factors Engineers and Ergonomists",
    "Human Resources Assistants, Except Payroll and Timekeeping",
    "Human Resources Managers",
    "Human Resources Specialists",
    "Hydroelectric Plant Technicians",
    "Hydroelectric Production Managers",
    "Hydrologic Technicians",
    "Hydrologists",
    "Industrial Ecologists",
    "Industrial Engineering Technologists and Technicians",
    "Industrial Engineers",
    "Industrial Machinery Mechanics",
    "Industrial Production Managers",
    "Industrial Truck and Tractor Operators",
    "Industrial-Organizational Psychologists",
    "Infantry",
    "Infantry Officers",
    "Information and Record Clerks, All Other",
    "Information Security Analysts",
    "Information Security Engineers",
    "Information Technology Project Managers",
    "Inspectors, Testers, Sorters, Samplers, and Weighers",
    "Installation, Maintenance, and Repair Workers, All Other",
    "Instructional Coordinators",
    "Insulation Workers, Floor, Ceiling, and Wall",
    "Insulation Workers, Mechanical",
    "Insurance Appraisers, Auto Damage",
    "Insurance Claims and Policy Processing Clerks",
    "Insurance Sales Agents",
    "Insurance Underwriters",
    "Intelligence Analysts",
    "Interior Designers",
    "Interpreters and Translators",
    "Interviewers, Except Eligibility and Loan",
    "Investment Fund Managers",
    "Janitors and Cleaners, Except Maids and Housekeeping Cleaners",
    "Jewelers and Precious Stone and Metal Workers",
    "Judges, Magistrate Judges, and Magistrates",
    "Judicial Law Clerks",
    "Kindergarten Teachers, Except Special Education",
    "Labor Relations Specialists",
    "Laborers and Freight, Stock, and Material Movers, Hand",
    "Landscape Architects",
    "Landscaping and Groundskeeping Workers",
    "Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
    "Laundry and Dry-Cleaning Workers",
    "Law Teachers, Postsecondary",
    "Lawyers",
    "Layout Workers, Metal and Plastic",
    "Legal Secretaries and Administrative Assistants",
    "Legal Support Workers, All Other",
    "Legislators",
    "Librarians and Media Collections Specialists",
    "Library Assistants, Clerical",
    "Library Science Teachers, Postsecondary",
    "Library Technicians",
    "Licensed Practical and Licensed Vocational Nurses",
    "Life Scientists, All Other",
    "Life, Physical, and Social Science Technicians, All Other",
    "Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers",
    "Light Truck Drivers",
    "Lighting Technicians",
    "Loading and Moving Machine Operators, Underground Mining",
    "Loan Interviewers and Clerks",
    "Loan Officers",
    "Locker Room, Coatroom, and Dressing Room Attendants",
    "Locksmiths and Safe Repairers",
    "Locomotive Engineers",
    "Lodging Managers",
    "Log Graders and Scalers",
    "Logging Equipment Operators",
    "Logging Workers, All Other",
    "Logisticians",
    "Logistics Analysts",
    "Logistics Engineers",
    "Loss Prevention Managers",
    "Low Vision Therapists, Orientation and Mobility Specialists, and Vision Rehabilitation Therapists",
    "Machine Feeders and Offbearers",
    "Machinists",
    "Magnetic Resonance Imaging Technologists",
    "Maids and Housekeeping Cleaners",
    "Mail Clerks and Mail Machine Operators, Except Postal Service",
    "Maintenance and Repair Workers, General",
    "Maintenance Workers, Machinery",
    "Makeup Artists, Theatrical and Performance",
    "Management Analysts",
    "Managers, All Other",
    "Manicurists and Pedicurists",
    "Manufactured Building and Mobile Home Installers",
    "Manufacturing Engineers",
    "Marine Engineers and Naval Architects",
    "Market Research Analysts and Marketing Specialists",
    "Marketing Managers",
    "Marriage and Family Therapists",
    "Massage Therapists",
    "Material Moving Workers, All Other",
    "Materials Engineers",
    "Materials Scientists",
    "Mathematical Science Occupations, All Other",
    "Mathematical Science Teachers, Postsecondary",
    "Mathematicians",
    "Meat, Poultry, and Fish Cutters and Trimmers",
    "Mechanical Door Repairers",
    "Mechanical Drafters",
    "Mechanical Engineering Technologists and Technicians",
    "Mechanical Engineers",
    "Mechatronics Engineers",
    "Media and Communication Equipment Workers, All Other",
    "Media and Communication Workers, All Other",
    "Media Programming Directors",
    "Media Technical Directors/Managers",
    "Medical and Clinical Laboratory Technicians",
    "Medical and Clinical Laboratory Technologists",
    "Medical and Health Services Managers",
    "Medical Appliance Technicians",
    "Medical Assistants",
    "Medical Dosimetrists",
    "Medical Equipment Preparers",
    "Medical Equipment Repairers",
    "Medical Records Specialists",
    "Medical Scientists, Except Epidemiologists",
    "Medical Secretaries and Administrative Assistants",
    "Medical Transcriptionists",
    "Meeting, Convention, and Event Planners",
    "Mental Health and Substance Abuse Social Workers",
    "Mental Health Counselors",
    "Merchandise Displayers and Window Trimmers",
    "Metal Workers and Plastic Workers, All Other",
    "Metal-Refining Furnace Operators and Tenders",
    "Meter Readers, Utilities",
    "Microbiologists",
    "Microsystems Engineers",
    "Middle School Teachers, Except Special and Career/Technical Education",
    "Midwives",
    "Military Enlisted Tactical Operations and Air/Weapons Specialists and Crew Members, All Other",
    "Military Officer Special and Tactical Operations Leaders, All Other",
    "Milling and Planing Machine Setters, Operators, and Tenders, Metal and Plastic",
    "Millwrights",
    "Mining and Geological Engineers, Including Mining Safety Engineers",
    "Mixing and Blending Machine Setters, Operators, and Tenders",
    "Mobile Heavy Equipment Mechanics, Except Engines",
    "Model Makers, Metal and Plastic",
    "Model Makers, Wood",
    "Models",
    "Molders, Shapers, and Casters, Except Metal and Plastic",
    "Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic",
    "Molecular and Cellular Biologists",
    "Morticians, Undertakers, and Funeral Arrangers",
    "Motion Picture Projectionists",
    "Motor Vehicle Operators, All Other",
    "Motorboat Mechanics and Service Technicians",
    "Motorboat Operators",
    "Motorcycle Mechanics",
    "Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
    "Museum Technicians and Conservators",
    "Music Directors and Composers",
    "Music Therapists",
    "Musical Instrument Repairers and Tuners",
    "Musicians and Singers",
    "Nannies",
    "Nanosystems Engineers",
    "Nanotechnology Engineering Technologists and Technicians",
    "Natural Sciences Managers",
    "Naturopathic Physicians",
    "Network and Computer Systems Administrators",
    "Neurodiagnostic Technologists",
    "Neurologists",
    "Neuropsychologists",
    "New Accounts Clerks",
    "News Analysts, Reporters, and Journalists",
    "Non-Destructive Testing Specialists",
    "Nuclear Engineers",
    "Nuclear Medicine Technologists",
    "Nuclear Monitoring Technicians",
    "Nuclear Power Reactor Operators",
    "Nuclear Technicians",
    "Nurse Anesthetists",
    "Nurse Midwives",
    "Nurse Practitioners",
    "Nursing Assistants",
    "Nursing Instructors and Teachers, Postsecondary",
    "Obstetricians and Gynecologists",
    "Occupational Health and Safety Specialists",
    "Occupational Health and Safety Technicians",
    "Occupational Therapists",
    "Occupational Therapy Aides",
    "Occupational Therapy Assistants",
    "Office and Administrative Support Workers, All Other",
    "Office Clerks, General",
    "Office Machine Operators, Except Computer",
    "Online Merchants",
    "Operating Engineers and Other Construction Equipment Operators",
    "Operations Research Analysts",
    "Ophthalmic Laboratory Technicians",
    "Ophthalmic Medical Technicians",
    "Ophthalmic Medical Technologists",
    "Ophthalmologists, Except Pediatric",
    "Opticians, Dispensing",
    "Optometrists",
    "Oral and Maxillofacial Surgeons",
    "Order Clerks",
    "Orderlies",
    "Orthodontists",
    "Orthopedic Surgeons, Except Pediatric",
    "Orthoptists",
    "Orthotists and Prosthetists",
    "Outdoor Power Equipment and Other Small Engine Mechanics",
    "Packaging and Filling Machine Operators and Tenders",
    "Packers and Packagers, Hand",
    "Painters, Construction and Maintenance",
    "Painting, Coating, and Decorating Workers",
    "Paper Goods Machine Setters, Operators, and Tenders",
    "Paperhangers",
    "Paralegals and Legal Assistants",
    "Paramedics",
    "Park Naturalists",
    "Parking Attendants",
    "Parking Enforcement Workers",
    "Parts Salespersons",
    "Passenger Attendants",
    "Patient Representatives",
    "Patternmakers, Metal and Plastic",
    "Patternmakers, Wood",
    "Paving, Surfacing, and Tamping Equipment Operators",
    "Payroll and Timekeeping Clerks",
    "Pediatric Surgeons",
    "Pediatricians, General",
    "Penetration Testers",
    "Personal Care Aides",
    "Personal Care and Service Workers, All Other",
    "Personal Financial Advisors",
    "Personal Service Managers, All Other",
    "Pest Control Workers",
    "Pesticide Handlers, Sprayers, and Applicators, Vegetation",
    "Petroleum Engineers",
    "Petroleum Pump System Operators, Refinery Operators, and Gaugers",
    "Pharmacists",
    "Pharmacy Aides",
    "Pharmacy Technicians",
    "Philosophy and Religion Teachers, Postsecondary",
    "Phlebotomists",
    "Photographers",
    "Photographic Process Workers and Processing Machine Operators",
    "Photonics Engineers",
    "Photonics Technicians",
    "Physical Medicine and Rehabilitation Physicians",
    "Physical Scientists, All Other",
    "Physical Therapist Aides",
    "Physical Therapist Assistants",
    "Physical Therapists",
    "Physician Assistants",
    "Physicians, All Other",
    "Physicians, Pathologists",
    "Physicists",
    "Physics Teachers, Postsecondary",
    "Pile Driver Operators",
    "Pipelayers",
    "Plant and System Operators, All Other",
    "Plasterers and Stucco Masons",
    "Plating Machine Setters, Operators, and Tenders, Metal and Plastic",
    "Plumbers, Pipefitters, and Steamfitters",
    "Podiatrists",
    "Poets, Lyricists and Creative Writers",
    "Police and Sheriff's Patrol Officers",
    "Police Identification and Records Officers",
    "Political Science Teachers, Postsecondary",
    "Political Scientists",
    "Postal Service Clerks",
    "Postal Service Mail Carriers",
    "Postal Service Mail Sorters, Processors, and Processing Machine Operators",
    "Postmasters and Mail Superintendents",
    "Postsecondary Teachers, All Other",
    "Potters, Manufacturing",
    "Pourers and Casters, Metal",
    "Power Distributors and Dispatchers",
    "Power Plant Operators",
    "Precision Agriculture Technicians",
    "Precision Instrument and Equipment Repairers, All Other",
    "Prepress Technicians and Workers",
    "Preschool Teachers, Except Special Education",
    "Pressers, Textile, Garment, and Related Materials",
    "Preventive Medicine Physicians",
    "Print Binding and Finishing Workers",
    "Printing Press Operators",
    "Private Detectives and Investigators",
    "Probation Officers and Correctional Treatment Specialists",
    "Procurement Clerks",
    "Producers and Directors",
    "Production Workers, All Other",
    "Production, Planning, and Expediting Clerks",
    "Project Management Specialists",
    "Proofreaders and Copy Markers",
    "Property, Real Estate, and Community Association Managers",
    "Prosthodontists",
    "Protective Service Workers, All Other",
    "Psychiatric Aides",
    "Psychiatric Technicians",
    "Psychiatrists",
    "Psychologists, All Other",
    "Psychology Teachers, Postsecondary",
    "Public Relations Managers",
    "Public Relations Specialists",
    "Public Safety Telecommunicators",
    "Pump Operators, Except Wellhead Pumpers",
    "Purchasing Agents, Except Wholesale, Retail, and Farm Products",
    "Purchasing Managers",
    "Quality Control Analysts",
    "Quality Control Systems Managers",
    "Radiation Therapists",
    "Radio Frequency Identification Device Specialists",
    "Radio, Cellular, and Tower Equipment Installers and Repairers",
    "Radiologic Technologists and Technicians",
    "Radiologists",
    "Rail Car Repairers",
    "Rail Transportation Workers, All Other",
    "Rail Yard Engineers, Dinkey Operators, and Hostlers",
    "Rail-Track Laying and Maintenance Equipment Operators",
    "Railroad Brake, Signal, and Switch Operators and Locomotive Firers",
    "Railroad Conductors and Yardmasters",
    "Range Managers",
    "Real Estate Brokers",
    "Real Estate Sales Agents",
    "Receptionists and Information Clerks",
    "Recreation and Fitness Studies Teachers, Postsecondary",
    "Recreation Workers",
    "Recreational Therapists",
    "Recreational Vehicle Service Technicians",
    "Recycling and Reclamation Workers",
    "Recycling Coordinators",
    "Refractory Materials Repairers, Except Brickmasons",
    "Refuse and Recyclable Material Collectors",
    "Registered Nurses",
    "Regulatory Affairs Managers",
    "Regulatory Affairs Specialists",
    "Rehabilitation Counselors",
    "Reinforcing Iron and Rebar Workers",
    "Religious Workers, All Other",
    "Remote Sensing Scientists and Technologists",
    "Remote Sensing Technicians",
    "Reservation and Transportation Ticket Agents and Travel Clerks",
    "Residential Advisors",
    "Respiratory Therapists",
    "Retail Loss Prevention Specialists",
    "Retail Salespersons",
    "Riggers",
    "Robotics Engineers",
    "Robotics Technicians",
    "Rock Splitters, Quarry",
    "Rolling Machine Setters, Operators, and Tenders, Metal and Plastic",
    "Roof Bolters, Mining",
    "Roofers",
    "Rotary Drill Operators, Oil and Gas",
    "Roustabouts, Oil and Gas",
    "Sailors and Marine Oilers",
    "Sales and Related Workers, All Other",
    "Sales Engineers",
    "Sales Managers",
    "Sales Representatives of Services, Except Advertising, Insurance, Financial Services, and Travel",
    "Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products",
    "Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products",
    "Sawing Machine Setters, Operators, and Tenders, Wood",
    "School Bus Monitors",
    "School Psychologists",
    "Search Marketing Strategists",
    "Secondary School Teachers, Except Special and Career/Technical Education",
    "Secretaries and Administrative Assistants, Except Legal, Medical, and Executive",
    "Securities, Commodities, and Financial Services Sales Agents",
    "Security and Fire Alarm Systems Installers",
    "Security Guards",
    "Security Management Specialists",
    "Security Managers",
    "Segmental Pavers",
    "Self-Enrichment Teachers",
    "Semiconductor Processing Technicians",
    "Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders",
    "Septic Tank Servicers and Sewer Pipe Cleaners",
    "Service Unit Operators, Oil and Gas",
    "Set and Exhibit Designers",
    "Sewers, Hand",
    "Sewing Machine Operators",
    "Shampooers",
    "Sheet Metal Workers",
    "Ship Engineers",
    "Shipping, Receiving, and Inventory Clerks",
    "Shoe and Leather Workers and Repairers",
    "Shoe Machine Operators and Tenders",
    "Shuttle Drivers and Chauffeurs",
    "Signal and Track Switch Repairers",
    "Skincare Specialists",
    "Slaughterers and Meat Packers",
    "Social and Community Service Managers",
    "Social and Human Service Assistants",
    "Social Science Research Assistants",
    "Social Sciences Teachers, Postsecondary, All Other",
    "Social Scientists and Related Workers, All Other",
    "Social Work Teachers, Postsecondary",
    "Social Workers, All Other",
    "Sociologists",
    "Sociology Teachers, Postsecondary",
    "Software Developers",
    "Software Quality Assurance Analysts and Testers",
    "Soil and Plant Scientists",
    "Solar Energy Installation Managers",
    "Solar Energy Systems Engineers",
    "Solar Photovoltaic Installers",
    "Solar Sales Representatives and Assessors",
    "Solar Thermal Installers and Technicians",
    "Sound Engineering Technicians",
    "Spa Managers",
    "Special Education Teachers, All Other",
    "Special Education Teachers, Elementary School",
    "Special Education Teachers, Kindergarten",
    "Special Education Teachers, Middle School",
    "Special Education Teachers, Preschool",
    "Special Education Teachers, Secondary School",
    "Special Effects Artists and Animators",
    "Special Forces",
    "Special Forces Officers",
    "Speech-Language Pathologists",
    "Speech-Language Pathology Assistants",
    "Sports Medicine Physicians",
    "Stationary Engineers and Boiler Operators",
    "Statistical Assistants",
    "Statisticians",
    "Stockers and Order Fillers",
    "Stone Cutters and Carvers, Manufacturing",
    "Stonemasons",
    "Structural Iron and Steel Workers",
    "Structural Metal Fabricators and Fitters",
    "Substance Abuse and Behavioral Disorder Counselors",
    "Substitute Teachers, Short-Term",
    "Subway and Streetcar Operators",
    "Supply Chain Managers",
    "Surgeons, All Other",
    "Surgical Assistants",
    "Surgical Technologists",
    "Survey Researchers",
    "Surveying and Mapping Technicians",
    "Surveyors",
    "Sustainability Specialists",
    "Switchboard Operators, Including Answering Service",
    "Tailors, Dressmakers, and Custom Sewers",
    "Talent Directors",
    "Tank Car, Truck, and Ship Loaders",
    "Tapers",
    "Tax Examiners and Collectors, and Revenue Agents",
    "Tax Preparers",
    "Taxi Drivers",
    "Teachers and Instructors, All Other",
    "Teaching Assistants, All Other",
    "Teaching Assistants, Postsecondary",
    "Teaching Assistants, Preschool, Elementary, Middle, and Secondary School, Except Special Education",
    "Teaching Assistants, Special Education",
    "Team Assemblers",
    "Technical Writers",
    "Telecommunications Engineering Specialists",
    "Telecommunications Equipment Installers and Repairers, Except Line Installers",
    "Telecommunications Line Installers and Repairers",
    "Telemarketers",
    "Telephone Operators",
    "Tellers",
    "Terrazzo Workers and Finishers",
    "Textile Bleaching and Dyeing Machine Operators and Tenders",
    "Textile Cutting Machine Setters, Operators, and Tenders",
    "Textile Knitting and Weaving Machine Setters, Operators, and Tenders",
    "Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders",
    "Textile, Apparel, and Furnishings Workers, All Other",
    "Therapists, All Other",
    "Tile and Stone Setters",
    "Timing Device Assemblers and Adjusters",
    "Tire Builders",
    "Tire Repairers and Changers",
    "Title Examiners, Abstractors, and Searchers",
    "Tool and Die Makers",
    "Tool Grinders, Filers, and Sharpeners",
    "Tour Guides and Escorts",
    "Traffic Technicians",
    "Training and Development Managers",
    "Training and Development Specialists",
    "Transit and Railroad Police",
    "Transportation Engineers",
    "Transportation Inspectors",
    "Transportation Planners",
    "Transportation Security Screeners",
    "Transportation Vehicle, Equipment and Systems Inspectors, Except Aviation",
    "Transportation Workers, All Other",
    "Transportation, Storage, and Distribution Managers",
    "Travel Agents",
    "Travel Guides",
    "Treasurers and Controllers",
    "Tree Trimmers and Pruners",
    "Tutors",
    "Umpires, Referees, and Other Sports Officials",
    "Underground Mining Machine Operators, All Other",
    "Upholsterers",
    "Urban and Regional Planners",
    "Urologists",
    "Ushers, Lobby Attendants, and Ticket Takers",
    "Validation Engineers",
    "Veterinarians",
    "Veterinary Assistants and Laboratory Animal Caretakers",
    "Veterinary Technologists and Technicians",
    "Video Game Designers",
    "Waiters and Waitresses",
    "Watch and Clock Repairers",
    "Water and Wastewater Treatment Plant and System Operators",
    "Water Resource Specialists",
    "Water/Wastewater Engineers",
    "Weatherization Installers and Technicians",
    "Web Administrators",
    "Web and Digital Interface Designers",
    "Web Developers",
    "Weighers, Measurers, Checkers, and Samplers, Recordkeeping",
    "Welders, Cutters, Solderers, and Brazers",
    "Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders",
    "Wellhead Pumpers",
    "Wholesale and Retail Buyers, Except Farm Products",
    "Wind Energy Development Managers",
    "Wind Energy Engineers",
    "Wind Energy Operations Managers",
    "Wind Turbine Service Technicians",
    "Woodworkers, All Other",
    "Woodworking Machine Setters, Operators, and Tenders, Except Sawing",
    "Word Processors and Typists",
    "Writers and Authors",
    "Zoologists and Wildlife Biologists"
    ];