import { gql } from "@apollo/client";

export const updateApplicationRubricsMutation = gql`
mutation updateApplicationRubrics($reviewerId: ID!, $applicationId: ID!, $rubrics: [RubricInput], $rubricsResult: RubricResultInput, $postRubricsResult: RubricResultInput) {
    updateApplicationRubrics(reviewerId: $reviewerId , applicationId: $applicationId, rubrics: $rubrics, rubricsResult:$rubricsResult, postRubricsResult: $postRubricsResult) {
            id
        }
    }

`

export const updateApplicationRubricsAndAssignToReviewerMutation = gql`
mutation updateApplicationRubricsAndAssignReviewer($reviewerId: ID!, $applicationId: ID!, $rubrics: [RubricInput], $rubricsResult: RubricResultInput, $postRubricsResult: RubricResultInput) {
    updateApplicationRubricsAndAssignReviewer(reviewerId: $reviewerId , applicationId: $applicationId, rubrics: $rubrics, rubricsResult:$rubricsResult, postRubricsResult: $postRubricsResult) {
            id
        }
    }
`


export const addSharedNoteToApplicationMutation = gql`
    mutation addSharedNoteToApplication($applicationId: ID!, $note: String!, $userId: ID!, $timestamp: String) {
        addSharedNoteToApplication(applicationId: $applicationId, note: $note, userId: $userId, timestamp: $timestamp) {
            userId{
                id
            }
        }
    }
`

export const selectApplicationForInterviewMutation = gql`
  mutation selectApplicationForInterview($applicationId: ID!, $reviewerId: ID!) {
    selectApplicationForInterview(applicationId: $applicationId, reviewerId: $reviewerId) {
      id
    }
  }
`