import React,{useState, useContext} from "react";
import "./index.css";
import FacultyHomeCard from "./facultyHomeCard";
import { getHomeCalcsQuery } from './graphql/queries';
import { useQuery } from "@apollo/client"; 
import { ProgramIdContext } from '../../progIdContext';
import {UserContext} from '../../../../context/userContext';
import {getUserById} from './graphql/queries';
import Spinner from "../../../common/spinner";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FacultyHome = ({setPage}) => {
    const [isSpecialtyOpen, setIsSpecialtyOpen] = useState(false)
    const [specialtyData, setSpecialtyData] = useState({})
    const [calculations, setCalculations] = useState({})
    const programId = useContext(ProgramIdContext);
    const { user } = useContext(UserContext);
    console.log("user inside faculty home =>", user)
    const { data: userData, loading, error } = useQuery(getUserById, {
        variables: { id: user.userId },
    });






    const { data: calcs, error: calcsError, loading: calcsLoading } = useQuery(getHomeCalcsQuery, {
        variables: {
            userId: user.userId,
            programId
           
        },
        fetchPolicy: 'network-only'
    });

    if (calcsLoading) return <Spinner />;
    if (calcsError) return <p>Error :(</p>;
    else {
        const calcsData = calcs.getHomeCalcs
        if(JSON.stringify(calcsData) !== JSON.stringify(calculations)) {
            setCalculations(calcsData)
            console.log("calculations =>", calcsData)
        }
    }

    if(loading) return <Spinner />;
    if(error) return <p>Error :(</p>;
    else {
        const userFromData = userData.getUserById
        console.log("userFromData =>", userFromData)
    }




    // Mocked data for testing purposes
    const justSomeDesc ='Welcome to the Anesthesiology Smart Recruiting (ACA). The ACA is being piloted to explore a more cost-effective, learner-centric application. CentralApp has been used in plastic surgery for the past three years with nearly full specialty adoption and a projected 95% cost reduction to facultys. The programs listed below encourage you to complete the ACA in addition to ERAS. This pilot application is completely free to facultys.'
  
    const columns = [
        {
            Header: 'Program Name',
            accessor: 'programName',
        },
        // {
        //     Header: 'Location',
        //     accessor: 'location',
        // },
        {
            Header: 'Learn More',
            accessor: 'learnMore',
            Cell: ({ cell: { value } }) => (
              <a href={value} target="_blank" rel="noopener noreferrer" style={{color:'#145388',float:'right',marginRight:'6vw'}}>
                Learn More
              </a>
            ),
          },
    ]
    const data = [
        {
            programName: 'Program 1',
            location: 'Location 1',
            learnMore: 'https://walla.co.il',
        },
        {
            programName: 'Program 2',
            location: 'Location 2',
            learnMore: 'https://msn.com',
        },
        {
            programName: 'Program 3',
            location: 'Location 3',
            learnMore: 'https://nytimes.com',
        },
        {
            programName: 'Program 4',
            location: 'Location 4',
            learnMore: 'Learn More',
        },
        {
            programName: 'Program 5',
            location: 'Location 5',
            learnMore: 'Learn More',
        },
    ]




    return (
        <div className='faculty-home-container'>
            <ellipse className='ellipseDesignTop1' />
            {/* <ellipse className='ellipseDesignTop2' /> */}
            <ellipse className='ellipseDesignTop3' />
            <ellipse className='ellipseDesignTop4' />
            {/* <div className="faculty-home-header"> */}
                {/* <h2 className="faculty-home-header-title">WELCOME, {calculations.name}</h2> */}
                {/* <p className="faculty-home-header-subtitle"> Welcome to your Dashboard! Here you can access all submitted applications, view notifications on application, interview, and review updates, and customize your application and team!</p> */}
            {/* </div> */}
            {/* <seperator className="faculty-home-header-seperator"/> */}
            <div className="faculty-home-body">
                <FacultyHomeCard card='applicantsOverview' calculations={calculations}/>
                <div className="faculty-home-row" style={{display:'flex',flexDirection:'row', gap:'2vw', marginTop:'2vh'}}>
                <FacultyHomeCard card='applicationCustomization' setPage={setPage}/>
                <FacultyHomeCard card='myTeam' setPage={setPage}/>
                </div>

            </div>
        </div>
        
    );
}

export default FacultyHome;

