import React,{ useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { ProgramIdContext } from '../../progIdContext';
import { getProgramByIdQuery, getReviewByReviewerAndApplicationQuery } from './graphql/queries';
import client from '../../../../config/apolloClient';
import { updateApplicationRubricsMutation, selectApplicationForInterviewMutation, updateApplicationRubricsAndAssignToReviewerMutation } from './graphql/mutation';
import { UserContext } from '../../../../context/userContext'; 
import Spinner from '../../../common/spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ApplicationViewRubric = ({application, refetchApplications, nextApplicationClicked, previousApplicationClicked, selectedReviewerApplications, applicationIdx, totalApplications, refetchGroups}) => {
    const programId = useContext(ProgramIdContext);
    const [programObj, setProgramObj] = useState(null)
    const [sortedRubrics,setSortedRubrics] = useState([])
    const { user } = useContext(UserContext);
    const [success, setSuccess] = useState(false);
    const [isArchived, setIsArchived] = useState(application.archived)
    const [isRubricCompleted, setIsRubricCompleted] = useState(null)
    const [isMyApplication, setIsMyApplication] = useState(selectedReviewerApplications.some(elem => elem.id.toString() === application.id.toString()))
    const [isLoading, setIsLoading] = useState(true);
    const [allReviewsForReviewer, setAllReviewsForReviewer] = useState(selectedReviewerApplications)
    const [relevantReview, setRelevantReview] = useState(null)
    const [hasDataFetched, setHasDataFetched] = useState(false);
    const [saveError, setSaveError] = useState('');
    const [isSelectLoading, setIsSelectLoading] = useState(false);
    

    const [selectApplicationForInterview] = useMutation(selectApplicationForInterviewMutation, {
        onCompleted: () => {
            if (totalApplications > 1 && applicationIdx +1 !== totalApplications) {
            nextApplicationClicked();
            }
            refetchApplications();
            refetchGroups();
            setIsSelectLoading(false);
            toast.success('Application selected for interview');
        },
        onError: (error) => {
            toast.error(error.message);
            setSaveError(error.message);
            setIsSelectLoading(false);
            toast.error('Error selecting application for interview');
        }
    });


    const { data, error, loading:isQueryLoading , refetch} = useQuery(getReviewByReviewerAndApplicationQuery, {
        variables: {
            reviewerId: user.userId,
            applicationId: application.id
        },
        fetchPolicy: "network-only"
      });

      useEffect(() => {
        if(isArchived===true){
            setIsLoading(false)
        }
    }, [isArchived])

    useEffect(() => {
        console.log('Query Loading:', isQueryLoading, 'Error:', error, 'Data:', data);
        if (!isQueryLoading && !error && data) {
            console.log('Processing data:', data.getReviewByReviewerAndApplication);
            if (data.getReviewByReviewerAndApplication=== null) {
                console.log('No review found for reviewer and application');
                setRelevantReview(null);
                setIsLoading(false);
            } else {
                const stage = data.getReviewByReviewerAndApplication.stage;
                console.log('Review stage:', stage);
                setIsRubricCompleted(stage === 'Completed');
                setIsLoading(false);
                setRelevantReview(data.getReviewByReviewerAndApplication);
            }
        }
    }, [isQueryLoading, data, error]);


    useEffect(() => {
        console.log("selectedReviewerApplications =>", selectedReviewerApplications)
        let isReviewer = selectedReviewerApplications.some(elem => elem.id === application.id)
        console.log(isReviewer, 'isReviewer')
        console.log('application.id', application.id)
        setIsMyApplication(isReviewer)
    }, [allReviewsForReviewer, selectedReviewerApplications])

    useEffect(() => {
        console.log("inside useEffect")
        getProgram()
    }, [])



    useEffect(() => {
        if(programObj && relevantReview) {
             // order the rubrics by the order property
             const program = programObj
             let cpy = [...program.rubricTemplate]
             let sorted = cpy.sort((a, b) => a.order - b.order)
             // remove all the rubrics that have isPreInterview = false
             sorted = sorted.filter(elem => elem.isPreInterview === true)
 
             let modifiedRubrics = []
             if (relevantReview.rubric === null) {
                 setSortedRubrics(sorted)
                //  setIsLoading(false)
 
             }else{
 
             sorted.map(rubric => { 
                 console.log("rubric =>", rubric, application.applicationRubrics)
                 let filteredRubric = relevantReview.rubric.filter(elem => elem.label === rubric.label && elem.inputType === rubric.inputType)
                 if(filteredRubric.length === 0) {
                     modifiedRubrics.push({
                         ...rubric,
                         result: ""
                     })
                 } else {
                     filteredRubric = filteredRubric[0]
                     console.log("filteredRubric =>", filteredRubric)
                     modifiedRubrics.push({
                         ...rubric,
                         result: filteredRubric.result
                     })
                 } 
              }
             )
             setSortedRubrics(modifiedRubrics)
            //  setIsLoading(false)
             }
            }
        if (programObj && relevantReview === null) {
            const program = programObj
            let cpy = [...program.rubricTemplate]
            let sorted = cpy.sort((a, b) => a.order - b.order)
            // remove all the rubrics that have isPreInterview = false
            sorted = sorted.filter(elem => elem.isPreInterview === true)

            setSortedRubrics(sorted)
            // setIsLoading(false)
        }
    }, [programObj, relevantReview])
   
    const getProgram = () => {
        client.query({
            query: getProgramByIdQuery,
            variables: {
                id: programId
            },
            fetchPolicy: "network-only"
        })
        .then(res => {
            let program = res.data.getProgramById
            console.log("program from query => ", program)
            setProgramObj(program)
        })
    }

    // const RubricItem = React.memo(({rubric, index, updateRubricResult, rubricValue}) => {
    //     console.log("rubricValue =>", rubricValue)    
        
      
      

    //     return (
    //         <>
    //           <div className="rubric-item-container" style={{marginBottom:'3vh'}}>
    //             <div className="rubric-item-header">
    //              <h3>{rubric.label}</h3>
    //             </div>
                
    //             <div className="rubric-item-container" style={{display:'flex', flexDirection:'row'}}>
    //             <div className='rubric-item-handler'>
    //             </div>
                
    //             {rubric.inputType === 'text' && <input key={rubric.id} autoFocus="autoFocus" type='text' style={{width:'100%'}} value={rubricValue} className='application-form-standard-input' onChange={(event) => updateRubricResult(index, rubric, event.target.value)} />}
    //             {rubric.inputType === 'dropdown' && (
    //                 <select style={{width: '100%'}} className='application-form-standard-input' value={rubricValue}  onChange={(event) => updateRubricResult(index, rubric, event.target.value)}>
    //                     {rubric.dropdownOptions.map((option, index) => (
    //                         <option key={index} value={option}>{option}</option>
    //                     ))}
    //                 </select>
    //             )}
    
    //             {rubric.inputType === 'scale' && (
    //             <div style={{width: '100%'}}>
    //                 <label className='scale-label'>
    //                     <span>{rubric.lowestScaleText}</span>
    //                     <input type="range" min="1" max={rubric.maxScale} value={rubricValue} className='scale-input' onChange={(event) =>  updateRubricResult(index, rubric, event.target.value)}/>
    //                     <span>{rubric.highestScaleText}</span>
    //                 </label>
    //                 <div className='scale-numbers'>  
    //                     {Array.from({length: rubric.maxScale}, (_, i) => i + 1).map(number => (
    //                         <span key={number} style={{flex: 1, textAlign: 'center'}}>{number}</span>
    //                     ))}
    //                 </div>
    //             </div>
    //         )}
    
    //         </div>
            
    //         </div>
    //         </>
    
    //     )
    // }, (prevProps, nextProps) => {
    //     return prevProps.rubricValue === nextProps.rubricValue &&
    //            prevProps.rubric === nextProps.rubric; // Adapt based on actual usage
    // })

    const updateRubricResult = (index, rubric, selectedOption) => {
        let cleanedOption = selectedOption;
        if (rubric.inputType === 'scale') {
            cleanedOption = selectedOption.toString();
        } else if (rubric.inputType === 'dropdown') {
            // Ensure the option is stored as a string
            cleanedOption = JSON.stringify(selectedOption);
        }
    
        setSortedRubrics(prevRubrics => {
            return prevRubrics.map((item, idx) => {
                if (idx === index) {
                    return { ...item, result: cleanedOption };
                }
                return item;
            });
        });
    };

    const removeTypename = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeTypename(item)); // Recursively handle arrays
        } else if (typeof obj === 'object' && obj !== null) {
            const { __typename, ...rest } = obj; // Remove __typename from the current level
            // Recursively handle nested fields
            Object.keys(rest).forEach(key => {
                if (typeof rest[key] === 'object') {
                    rest[key] = removeTypename(rest[key]); // Recursively remove __typename from nested objects
                }
            });
            return rest;
        }
        return obj;
    };

    const saveRubricAndAssignToReviewer = () => {
        console.log("Save rubric", sortedRubrics)

        // preRubrics
        const preRubrics = sortedRubrics.filter(elem => elem.isPreInterview === true)
        const postRubrics = sortedRubrics.filter(elem => elem.isPreInterview === false)

        let textTypeFields = preRubrics.filter(elem => elem.inputType === "text")
        let dropdownTypeFields = preRubrics.filter(elem => elem.inputType === "dropdown")
        let scaleTypeFields = preRubrics.filter(elem => elem.inputType === "scale")

        let textTypeFieldsPost = postRubrics.filter(elem => elem.inputType === "text")
        let dropdownTypeFieldsPost = postRubrics.filter(elem => elem.inputType === "dropdown")
        let scaleTypeFieldsPost = postRubrics.filter(elem => elem.inputType === "scale")
        
        
         // calculate text
        let answeredCount = textTypeFields.filter(elem => elem.result !== undefined && elem.result !== null && elem.result !== "").length
        // calculate dropdown
        const allOptions = dropdownTypeFields.reduce((acc, item) => {
            return acc.concat(item.dropdownOptions);
        }, []);
        const distinctOptions = Array.from(new Set(allOptions))
        let optionsCount = {}
        distinctOptions.map(option => {
            let countRes = dropdownTypeFields.filter(elem => elem.result === option).length
            optionsCount[option] = countRes
        })
        // calculate scale
        let scoreSum = 0
        scaleTypeFields.map(scale => {
            scoreSum += parseInt(scale.result)
        })

        const scoreAvg = parseFloat(scoreSum / scaleTypeFields.length).toFixed(1)

        const result = {
            textResult: answeredCount,
            dropdownResult: JSON.stringify(optionsCount),
            scaleResult: scoreAvg
        }
        console.log("result of file =>", answeredCount, optionsCount, scoreAvg)


        // post rubrics
          // calculate text
          let answeredCountPost = textTypeFieldsPost.filter(elem => elem.result !== undefined && elem.result !== null && elem.result !== "").length
          
          // calculate dropdown
          const allOptionsPost = dropdownTypeFieldsPost.reduce((acc, item) => {
              return acc.concat(item.dropdownOptions);
          }, []);

          const distinctOptionsPost = Array.from(new Set(allOptions))
          let optionsCountPost = {}
          distinctOptionsPost.map(option => {
              let countRes = dropdownTypeFieldsPost.filter(elem => elem.result === option).length
              optionsCountPost[option] = countRes
          })
          // calculate scale
          let scoreSumPost = 0
          scaleTypeFieldsPost.map(scale => {
            scoreSumPost += parseInt(scale.result)
          })
  
          const scoreAvgPost = parseFloat(scoreSumPost / scaleTypeFieldsPost.length).toFixed(1)
  
          const resultPost = {
              textResult: answeredCountPost,
              dropdownResult: JSON.stringify(optionsCountPost),
              scaleResult: scoreAvgPost
          }
          console.log("result of file =>", answeredCount, optionsCount, scoreAvg)



          const cleanedRubrics = sortedRubrics.map(({ __typename, id, ...rest }) => {
           // Remove __typename from inner "dropdownOptions" if it exists
           if (rest.dropdownOptions) {
               rest.dropdownOptions = rest.dropdownOptions.map(option => removeTypename(option));
           }
           return removeTypename(rest); // Recursively remove __typename from the rest
       });


       client.mutate({
           mutation: updateApplicationRubricsAndAssignToReviewerMutation,
           variables: {
               reviewerId: user.userId,
               applicationId: application.id,
               rubrics: cleanedRubrics,
               rubricsResult: result,
               postRubricsResult: resultPost

           }
       })
       .then(res => {
           setSuccess(true)
           setTimeout(() => {
               setSuccess(false)
               setIsRubricCompleted(true)
           }, 3000);
       }).catch(err => {
           toast.error(err.message)
         })
      
    }

    const saveRubric = () => {
        console.log("Save rubric", sortedRubrics)
        
         // preRubrics
         const preRubrics = sortedRubrics.filter(elem => elem.isPreInterview === true)
         const postRubrics = sortedRubrics.filter(elem => elem.isPreInterview === false)
        
         let textTypeFields = preRubrics.filter(elem => elem.inputType === "text")
         let dropdownTypeFields = preRubrics.filter(elem => elem.inputType === "dropdown")
         let scaleTypeFields = preRubrics.filter(elem => elem.inputType === "scale")
         
         let textTypeFieldsPost = postRubrics.filter(elem => elem.inputType === "text")
         let dropdownTypeFieldsPost = postRubrics.filter(elem => elem.inputType === "dropdown")
         let scaleTypeFieldsPost = postRubrics.filter(elem => elem.inputType === "scale")
 
         // calculate text
         let answeredCount = textTypeFields.filter(elem => elem.result !== undefined && elem.result !== null && elem.result !== "").length
         // calculate dropdown
         const allOptions = dropdownTypeFields.reduce((acc, item) => {
             return acc.concat(item.dropdownOptions);
         }, []);
         const distinctOptions = Array.from(new Set(allOptions))
         let optionsCount = {}
         distinctOptions.map(option => {
             let countRes = dropdownTypeFields.filter(elem => elem.result === option).length
             optionsCount[option] = countRes
         })
         // calculate scale
         let scoreSum = 0
         scaleTypeFields.map(scale => {
             scoreSum += parseInt(scale.result)
         })
 
         const scoreAvg = parseFloat(scoreSum / scaleTypeFields.length).toFixed(1)
 
         const result = {
             textResult: answeredCount,
             dropdownResult: JSON.stringify(optionsCount),
             scaleResult: scoreAvg
         }
         console.log("result of file =>", answeredCount, optionsCount, scoreAvg)
 
 
         // post rubrics
           // calculate text
           let answeredCountPost = textTypeFieldsPost.filter(elem => elem.result !== undefined && elem.result !== null && elem.result !== "").length
           
           // calculate dropdown
           const allOptionsPost = dropdownTypeFieldsPost.reduce((acc, item) => {
               return acc.concat(item.dropdownOptions);
           }, []);
 
           const distinctOptionsPost = Array.from(new Set(allOptions))
           let optionsCountPost = {}
           distinctOptionsPost.map(option => {
               let countRes = dropdownTypeFieldsPost.filter(elem => elem.result === option).length
               optionsCountPost[option] = countRes
           })
           // calculate scale
           let scoreSumPost = 0
           scaleTypeFieldsPost.map(scale => {
             scoreSumPost += parseInt(scale.result)
           })
   
           const scoreAvgPost = parseFloat(scoreSumPost / scaleTypeFieldsPost.length).toFixed(1)
   
           const resultPost = {
               textResult: answeredCountPost,
               dropdownResult: JSON.stringify(optionsCountPost),
               scaleResult: scoreAvgPost
           }
           console.log("result of file =>", answeredCount, optionsCount, scoreAvg)
 
 

           const cleanedRubrics = sortedRubrics.map(({ __typename, id, ...rest }) => {
            // Remove __typename from inner "dropdownOptions" if it exists
            if (rest.dropdownOptions) {
                rest.dropdownOptions = rest.dropdownOptions.map(option => removeTypename(option));
            }
            return removeTypename(rest); // Recursively remove __typename from the rest
        });


        client.mutate({
            mutation: updateApplicationRubricsMutation,
            variables: {
                reviewerId: user.userId,
                applicationId: application.id,
                rubrics: cleanedRubrics,
                rubricsResult: result,
                postRubricsResult: resultPost

            }
        })
        .then(res => {
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
                setIsRubricCompleted(true)
            }, 3000);
        })


    }


    const handleSelectApplication = () => {
        setIsSelectLoading(true);
        console.log(application.id);
        selectApplicationForInterview({ variables: { applicationId: application.id, reviewerId:user.userId } })
    };


    const allRubricsCompleted = sortedRubrics.every(rubric => {
        if (rubric.inputType === 'scale' || rubric.inputType === 'dropdown') {
            return rubric.result !== undefined && rubric.result !== null && rubric.result !== "";
        }
        return true; // If it's not a scale or dropdown, it doesn't affect the condition
    });

    // if(isLoading) {
    //     return (
    //         <div style={{display:'flex', justifyContent:'space-around'}}>
    //      <Spinner />
    //      </div>
    //     )
    // }
 

    console.log("sortedRubrics =>", sortedRubrics)
    return (
        <>
        {isArchived&&(
            <div className="application-view-rubric-cont">
            <div className="application-view-rubric-header">
        <p style={{ color: 'red', fontFamily: 'Montserrat', textAlign: 'center'}}>This Application is Rejected and Archived.</p>
        </div>
        </div>
        )}
        {!isArchived&&

<div className="application-view-rubric-cont">
<ToastContainer 
                    theme= "colored"
                  />
    <div className="application-view-rubric-header">





        <h2 className="application-view-rubric-header-title"
        style={{
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '3vh',
            color: "#4A4A4A",
            lineHeight: "25px",
            borderBottom: '2px solid #145388',
            textAlign: 'center',
            padding:'1vh',
            // marginTop:'5vh'
        }}
        >Evaluation Form</h2>
        <seperator className="application-view-rubric-header-seperator"/>
        </div>
        {isLoading &&  (<Spinner />)}
        {!isLoading && (
        <div className="application-view-rubric-content" style={{marginTop:'1vh'}}>
            {sortedRubrics.map((rubric, index) => (
                  <>
                  <div className="rubric-item-container" style={{marginBottom:'3vh'}}>
                    <div className="rubric-item-header">
                     <h3 style={{fontSize:'16px'}}>{rubric.label}</h3>
                    </div>
                    
                    <div className="rubric-item-container" style={{display:'flex', flexDirection:'row', marginBottom:'1vh'}}>
                    <div className='rubric-item-handler'>
                    </div>
                    
                    {rubric.inputType === 'text' && <textarea key={rubric.id} disabled={isRubricCompleted} autoFocus="autoFocus" style={{width:'100%', height: '20vh'}} value={rubric.result} className='application-form-standard-input' rows="4" cols="50" onChange={(event) => updateRubricResult(index, rubric, event.target.value)}></textarea>}
                    {rubric.inputType === 'dropdown' && (
    <select 
        style={{width: '100%'}} 
        className='application-form-standard-input' 
        value={rubric.result ? rubric.result : ''}
        onChange={(event) => {
            // Parse the selected option before passing it to updateRubricResult
            const selectedOption = JSON.parse(event.target.value);
            updateRubricResult(index, rubric, selectedOption);
        }}
        disabled={isRubricCompleted}
    >
        <option value="" disabled>Select an option</option>
        {rubric.dropdownOptions.map((dropdownOption, optionIndex) => (
            <option 
                key={dropdownOption.id}
                value={JSON.stringify(dropdownOption)}
            >
                {dropdownOption.option} - Score: {dropdownOption.score}
            </option>
        ))}
    </select>
)}

{rubric.inputType === 'scale' && (
    <div style={{width: '100%'}}>
        <label className='scale-label'>
            <input
                type="range"
                min="1" // Start the minimum value at 1 if your scale starts at 1
                max={rubric.maxScale}
                value={rubric.result || 1} // Ensure this is within the range of min and max
                className='scale-input'
                disabled={isRubricCompleted}
                onChange={(event) => updateRubricResult(index, rubric, Number(event.target.value))}
            />
        </label>
        <div className='scale-numbers' style={{padding:'0 1vw'}}>  
            {Array.from({length: rubric.maxScale}, (_, i) => i + 1).map(number => (
                <span key={number} style={{textAlign: 'left', display: 'inline-block', width: '1%'}}>{number}</span>
            ))}
        </div>
        <div style={{display:'flex', justifyContent:'space-between'}}>
            <span style={{fontSize:'10px'}}>{rubric.lowestScaleText}</span>
            <span style={{fontSize:'10px'}}>{rubric.highestScaleText}</span>
        </div>
    </div>
)}
        
                </div>
                
                </div>
                </>
                // <RubricItem key={rubric.id} rubric={{...rubric}} index={index} rubricValue={rubric.result} updateRubricResult={updateRubricResult}/>
            ))}
            </div>
            )}
            {isSelectLoading&& <Spinner />}
            {success && <label style={{color: "#145388"}}>Review saved successfully.</label>}
            {!isLoading&& !isRubricCompleted&&relevantReview!==null&&(
            <button 
            disabled={(selectedReviewerApplications && selectedReviewerApplications.filter(elem => elem.id.toString() === application.id.toString() && user.userId.toString() === elem.reviewerId.toString()).length === 0) || sortedRubrics.length ===0}
            className="green-button" style={{width:'100%', marginTop:'3vh'}} onClick={saveRubric}>Save</button>)}
            {!isLoading&& !isRubricCompleted&& relevantReview===null&&(
                <button 
                className="green-button" style={{width:'100%', marginTop:'3vh'}} onClick={saveRubricAndAssignToReviewer}>Save2</button>
            )}




            {/* {!isLoading&& isRubricCompleted&&(
            <button 
            className="green-button" style={{width:'100%', marginTop:'3vh'}} onClick={()=>setIsRubricCompleted(false)}>Open Rubric</button>)}
            {
                // !isLoading && hasDataFetched && relevantReview === null &&
                !isLoading &&  relevantReview === null &&
                <label style={{ color: 'red', fontFamily: 'Montserrat', textAlign: 'center'}}>You are not assigned to this application.</label>
            } */}
             {user.userPremissions.includes('PD') && !application.archived && !isSelectLoading&& <button className="green-button"  onClick={()=>{handleSelectApplication(); saveRubric()}} style={{background:'#30B776', marginTop:'1vh'}}>Save and Select For Interview</button>}
                
             {saveError!==' '&&<label style={{color:'red'}}>{saveError}</label>}
        
    </div>
}
    </>
    );
}

export default ApplicationViewRubric;