import { gql } from '@apollo/client';

export const getHomeCalcsQuery = gql`
    query getHomeCalcs($userId: ID!, $programId: ID!) {
        getHomeCalcs(userId: $userId, programId: $programId) {
            positionNames
            positionApplicationsCount
        }
    }
`

export const getUserById = gql`
    query getUserById($id: ID!){
        getUserById(id: $id) {
            id
            email
            firstName
            lastName
            phone
            token
            verificationToken
            userType
            isVerified
            status
            programId        
            profilePhotoUrl
            institutionId
            institution
            isPending
            isRecommender
        }
    }
`