import React, { useState, useContext } from 'react';
import logo from '../../../assets/imgs/logo.svg'
import {useMutation} from '@apollo/client';
import {INVITE_USER,inviteUserToPortalMutation} from './graphql/mutations';
import { ProgramIdContext } from "../../faculty/progIdContext";
import { InstitutionIdContext } from '../institutionIdContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InviteUserModal = ({ isOpen, onClose, onAddUser }) => {
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState([]);
    const [isReviewerAutoSelected, setIsReviewerAutoSelected] = useState(false);
    const [inviteUser, { data, loading, error }] = useMutation(inviteUserToPortalMutation);
    const institution = useContext(InstitutionIdContext);
    const institutionId = institution.id;

if (!isOpen) return null;

const handleSubmit = async (event) => {
    event.preventDefault();
    const newUser = {
        // programId: '00000',
        // userName: 'Pending Invitation',
        email: email,
        institutionId: institutionId,
        // roles: roles,
        // action: 'action',
    };
    try {
        const result = await inviteUser({ variables: { email:email, institutionId:institutionId } });
        toast.success('User invited successfully');

        onAddUser()
        onClose();
    } catch (err) {
        toast.error('Error inviting user');
    }

    setRoles([]); // reset roles
    setEmail(''); // reset email

}

const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'PD' || value === 'PC') {
        setRoles((prevRoles) => {
          let newRoles = checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value);
  
          // Automatically add 'Reviewer' if either 'PD' or 'PC' is checked
          if (checked && !newRoles.includes('Reviewer')) {
            newRoles.push('Reviewer');
            setIsReviewerAutoSelected(true); // Mark as auto-selected
          }
  
          // Remove 'Reviewer' only if both 'PD' and 'PC' are unchecked
          if (!checked && newRoles.includes('Reviewer') && !newRoles.includes(value === 'PD' ? 'PC' : 'PD')) {
            newRoles = newRoles.filter((role) => role !== 'Reviewer');
            setIsReviewerAutoSelected(false); // Mark as not auto-selected
          }
  
          return newRoles;
        });
      } else if (value === 'Reviewer') {
        // Only allow 'Reviewer' to be toggled if neither 'PD' nor 'PC' is checked
        if (!roles.includes('PD') && !roles.includes('PC')) {
          setRoles((prevRoles) =>
            checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
          );
          setIsReviewerAutoSelected(false); // Mark as not auto-selected
        }
      } else {
        setRoles((prevRoles) =>
          checked ? [...prevRoles, value] : prevRoles.filter((role) => role !== value)
        );
      }

};


return (

<div className="modal-backdrop">
<div className="add-field-modal">
<ToastContainer 
                    theme= "colored"
                  />
    <div className='add-field-modal-header'>
    <h2>INVITE A MEMBER</h2>
    <img src={logo} alt='Logo' className='logo' style={{width:'4vw'}}/>
    </div>
    {error && <p style={{ color: 'red' }}>{error.message}</p>}
    <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                color: '#848484',
                lineHeight: '24px',
                marginBottom: '19px',
            }}>Enter the email address of the team member you would like to invite.</label>
          <input
                            type="email"
                            className="application-form-standard-input"
                            onChange={(e) => setEmail(e.target.value)}
                        />
       
        </div>

        <div className='button-container' style={{display:'flex', flexDirection:'row', justifyContent:'space-between', gap:'2vw'}}>
        <button type="submit" className='gradient-button'  style={{fontWeight:'500'}}>Send Invite</button>
            <button type="button" className='white-button'  onClick={onClose}>Cancel</button>
        </div>
    </form>
    
</div>
</div>
)
};

export default InviteUserModal;